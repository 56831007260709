import React from 'react';
import {columnsImpuestos,hiddenColumns,} from '../resources'
import Select from 'react-select';
import Buttons from '../../../Components/Buttons/Buttons';
import GeneralTable from '../../GeneralTable';
import {toggleLoader,ExisteSession} from '../../../ApiRequests/general'
import {CSVLink,CSVDownload} from 'react-csv'
import { inArray } from 'jquery';
import CompBtnTotal from '../../../Components/PagoImpuestos/LoteImpuestos/CompBtnTotal';


const index = ({
    idTipoFormato,              
    showTblSSM,                 
    changeSelect,             
    changeDate,               
    expirationDate,           
    cat_Anios,                
    optionAnio,              
    catMeses,                   
    optionMeses,             
    catLotes,                 
    optionLotes,           
    catTipoImpuestos,        
    optionTipoImpuestos,  
    catEstatus,           
    optionEstatus,       
    respFormat,          
    dataSolicitudes,     
    searchPetition,
    handleChangeCbxM     ,
    handleChangeChkAllM  ,            
    chckAllM             ,  
    uploadPdf            ,
    actionChenge         ,
    showPDF              ,
    canBeAuthorized      ,
    isCbxautorized       ,
    handleChangeCbxAutr  ,
    showModalSavePDFZip,
    showModalSaveAutorizate,
    cancelTblZip,
    valuesDownload,
    optionDwNl,
    downloadPdf,
    uploadZip,
    actionChangeZip,
    autorizate_LC,
    showModalCargaCP,
    actionChangeZipCP,
    showTblTesoreria,
    cancelSendZipCP,
    sendZIP_CP,
    showReason,
    showModalAutorizate,
    styleRow,
    printLineaCaptura,
    printComprobante ,
    _getTotal,
    total,
    saldoAfavor,
    updateSearch
                

}) => {
    // console.log('dataSolicitudes ',dataSolicitudes)
    // const showReason = () => {console.log('showReason')}
    const DownLoadCsvMonitor = () => {
        let dataSolicitudesDownload = []
        dataSolicitudes.forEach(element =>{
           if(element.check) dataSolicitudesDownload.push(element)
        })
        let fileName = "SolicitudesContabilidad" 
        let _Headers =   [
            { label: "Lote"   , key: "lote" },
            { label: idTipoFormato === 1 ? "Marca" : "Registro Patronal", key: idTipoFormato === 1 ? "marca":"registroPatronal" },
            { label: "RFC", key: "RFC" },
            { label: idTipoFormato === 1 ? "Empresa" : "Razón Social" , key: "empresa" },
            { label: "Prioritario"       , key: "prioritario" },
            { label: "Tipo Impuesto"     , key: "tipoImpuesto" },
            { label: "Importe total"     , key: "importeTotal" },
            { label: "Mes"               , key: "mes" },
            { label: "Año"               , key: "anio" },
            { label: "Linea de captura"  , key: "lineaCaptura" },
            { label: "Fecha vencimiento" , key: "fechaVencimiento" },
            { label: "Estatus"           , key: "estatus" },
            { label: "Fecha pago" , key: "fechaPago" },
            { label: "Pagadora" , key: "pagadora" },
            { label: "Fecha modificado" , key: "fechaAct" },


        ]
        if(idTipoFormato === 2){
            _Headers.push({ label: "Folio SUA" , key: "folioSUA" })
            fileName = "SolicitudesSS"
        } 
        // if(dataSolicitudesDownload.length > 0){
            return  <div className="row">
            <div className="col-md-12">
            {/* <span>&nbsp;</span> */}
            <CSVLink data={dataSolicitudesDownload} 

                headers={_Headers}     
                filename={fileName}>
                <Buttons 
                    btnUpload= {true}  btnUploadText={'Descargar'} btnUploadShowIcon={true} btnUploadIconClass="fa fa-download" />
                </CSVLink>
            </div>
        </div>
      

    }
 
    const BtnMonitor = ({type}) => {
        let isOkSend = false
        let isUploadZip = false;
        dataSolicitudes.forEach(element =>{
            if(element.check)  isOkSend = true;              
            
        })
    
        if(isOkSend){
            switch(type){
                case 1:            
                    return <DownLoadCsvMonitor/>
                case 2:
                    return <>
                                <Buttons 
                                btnUpload={true} btnUploadAction={() => downloadPdf(type)} btnUploadShowIcon={true} btnUploadText="Descargar"  btnUploadIconClass="fa fa-download"
                                /> 
                    </> 
                case 3:return <>        
                <Buttons 
                                btnUpload={true} btnUploadAction={() => downloadPdf(type)} btnUploadShowIcon={true} btnUploadText="Descargar"   btnUploadIconClass="fa fa-download"
                                /> 
                                
                </>
                case 4:
                    {
                        if(isUploadZip) return <></> 
                        else return <> 
                            <Buttons btnUpload={true} btnUploadAction={() => uploadZip(1)} btnUploadShowIcon={true}  btnUploadIconClass="fa fa-upload" /> 
                            <input id={"IUploadZip"} type="file" hidden  onChange={(e) =>  actionChangeZip(e,false)} />   
                        </>
                    }
                case 5:
                    {
                        if(isUploadZip) return <></> 
                        else return <> 
                            <Buttons btnUpload={true} btnUploadAction={() => uploadZip(2)} btnUploadShowIcon={true}  btnUploadIconClass="fa fa-upload" /> 
                            <input id={"IUploadZipCP"} type="file" hidden  onChange={(e) =>  actionChangeZip(e,false)} />   
                        </>
                    }  
                case 6:
                    return <>
                                <Buttons 
                                btnUpload={true} btnUploadAction={() => showPDF('',false,false)} btnUploadText="Imprimir"  btnUploadShowIcon={true}  btnUploadIconClass="fa fa-print"
                                /> 
                    </>  
                case 7:
                        return <>
                                    <Buttons 
                                    btnUpload={true} btnUploadAction={() => showPDF('',false,true)} btnUploadText="Imprimir"  btnUploadShowIcon={true}  btnUploadIconClass="fa fa-print"
                                    /> 
                        </>       
                default:
                    return  <></>
                
        
            }
        }else return <></>
       }
    const BtnConfirmSS = () =>{
        if((idTipoFormato === 1 || idTipoFormato === 2) && showTblSSM){
            
            return <div className="row">
            <div className="col-md-1">
            <Buttons
                btnSave={true} btnSaveAction={() => showModalSavePDFZip()} btnSaveShowIcon={true}   
                        /> 
            </div>
            <div className="col-md-1">
                {
                    (ExisteSession.getPerfil() === "Administrador" || ExisteSession.getPerfil() === "ContabilidadSupervisor" || ExisteSession.getPerfil() === "SeguridadSocialSupervisor" || ExisteSession.getPerfil() === "TesoreríaSupervisor" || ExisteSession.getPerfil() === "SSContabilidadSupervisor" || ExisteSession.getPerfil() === "SSContabilidadTesoreriaSupervisor") ?
                    <Buttons 
                    btnUpload={true} btnUploadAction={() => showModalSaveAutorizate()} btnUploadShowIcon={true} btnUploadText="Guardar y autorizar"  btnUploadIconClass="fa fa-check"
                    />:<></>    
                    //      <Buttons 
                    // btnUpload={true} btnUploadAction={() => showModalSaveAutorizate()} btnUploadShowIcon={true} btnUploadText="Guardar y autorizar"  btnUploadIconClass="fa fa-check"/>
                }
           
            </div>     
            <div className="col-md-1">
            <Buttons
                btnCancel={true} btnCancelAction={() => cancelTblZip()} btnCancelShowIcon={true}  
                        /> 
            </div>
            
            <div className="col-md-1">
                <label>{ExisteSession.getPerfil()}</label>
            </div>
        
        
            </div>
        }else return <>
        <div className="row">
                    <div className="col-md-6">
                                {/* <label>Con elementos seleccionados</label> */}
                                <Select
                                                className=""
                                                isClearable
                                                isSearchable
                                                name="funcElement"
                                                onChange={(event,name) => changeSelect(event,name)}
                                                options={valuesDownload}
                                                value={optionDwNl}
                                                
                                            />
    
                        
                    </div>
                    <div className="col-md-1">{<BtnMonitor type={optionDwNl.value}/>}
                    </div>
                    {
                        showTblTesoreria ? <div className="col-md-4">
                        <Buttons 
                        btnUpload={true} btnUploadAction={() => sendZIP_CP()} btnUploadShowIcon={true} btnUploadText="autorizar"  btnUploadIconClass="fa fa-check"
                        btnCancel={true} btnCancelAction={() => cancelSendZipCP()} btnCancelShowIcon={true} btnCancelText="cancelar" />
                    </div>:
                    <div className="col-md-1">
                        {
                            isCbxautorized ? <>
                            {
                                (ExisteSession.getPerfil() === "Administrador" || ExisteSession.getPerfil() === "ContabilidadSupervisor" || ExisteSession.getPerfil() === "SeguridadSocialSupervisor" || ExisteSession.getPerfil() === "TesoreríaSupervisor" || ExisteSession.getPerfil() === "SSContabilidadSupervisor" || ExisteSession.getPerfil() === "SSContabilidadTesoreriaSupervisor") ?
                                idTipoFormato !== 3 &&
                                <Buttons 
                                btnUpload={true} 
                                // btnUploadAction={() => autorizate_LC(1)}
                                btnUploadAction={() => showModalAutorizate()}
                                btnUploadShowIcon={true} 
                                btnUploadText="autorizar" 
                                btnUploadIconClass="fa fa-check"

                            /> : 
                            <>
                            <span></span>
                            </>
                            }
                            </>:<></>
                        }
                            
                     
                    </div>
                    }
                    
    
                 
                 
    
            </div>
            <br/>
        </>
           
       } 
    return (
        <>
         {
            (idTipoFormato === 1 || idTipoFormato === 2) && showTblSSM  ?  <></> :
        <>
        <div className='row'>
            <div className='col-lg-10 col-md-12'>
                <div className='row'>
                    <div className="col-sm-2">
                                <label>Año</label>
                                <Select
                                                className=""
                                                isClearable
                                                isSearchable
                                                name="anio"
                                                onChange={(event,name) => changeSelect(event,name)}
                                                options={cat_Anios}
                                                value={optionAnio}
                                            />
                    </div>
                    <div className="col-sm-2">
                                <label>Periodo</label>
                                <Select
                                    className=""
                                    isClearable
                                    isSearchable
                                    name="periodo"
                                    onChange={(event,name) => changeSelect(event,name)}
                                    options={catMeses}
                                    value={optionMeses}
                                />
                    </div>
                    <div className="col-sm-3">
                                <label>Lote</label>
                                <Select
                                    className=""
                                    isClearable
                                    isSearchable
                                    name="lote"
                                    onChange={(event,name) => changeSelect(event,name)}
                                    options={catLotes}
                                    value={optionLotes}
                                />
                    </div>
                    <div className="col-sm-2">
                            <label>Tipo&nbsp;impuesto</label>
                            <Select
                                className=""
                                isClearable
                                isSearchable
                                name="tipoImpuesto"
                                onChange={(event,name) => changeSelect(event,name)}
                                options={catTipoImpuestos}
                                value={optionTipoImpuestos}
                            />
                    </div>
                    <div className="col-sm-3">
                    <label>Estatus</label>
                            <Select
                                className=""
                                isClearable
                                isSearchable
                                name="estatus"
                                onChange={(event,name) => changeSelect(event,name)}
                                options={catEstatus}
                                value={optionEstatus}
                            />
                    
                    </div>
                </div>
            </div>
            <div className='col-lg-2 col-md-12'>
                <div className='row'>
                    <div className="col-lg-8 col-md-2 col-sm-2">
                    <label>fecha&nbsp;vencimiento</label> 
                        <input 
                        type='date' 
                        className='form-control'
                        onChange={(e) => changeDate(e)}
                        value={expirationDate}
                        />
                    </div>
                    <div className="col-sm-2">
                    <div>&nbsp;</div> 
                    <div>
                    <Buttons
                                btnAdd  = {true}
                                btnAddAction  = {() => searchPetition(true)}
                                btnAddText="Buscar"
                                btnAddShowIcon={true}
                                btnAddIconClass="fa fa-search"
                            />
                    </div>
                        
                </div>
                </div>
            </div>

        </div>
            
            <br/>
            <br/>
            
        </>
        }
        <div className="row">
                            <div className="col-md-12">
                                <div className="mt-5">
                                {
                                <GeneralTable
                                columns={columnsImpuestos(handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr,idTipoFormato,showModalCargaCP,showReason)}
                                data={ showTblSSM  ? respFormat : dataSolicitudes}
                                // action={undefined}
                                updateSearch={updateSearch}

                                doubleClick={(original,values) => {}} 
                                // isStyleRow={true}
                                // styleRow={styleRow}
                                // isNotPagination={true}
                                // pageSize={500}
                                isCpmpBtn={true}
                                // compnnt={idTipoFormato === 2 && showTblSSM  ?  BtnConfirmSS : <div></div>}
                                compnnt={BtnConfirmSS}
                                hiddenColumnsIDs={
                                    hiddenColumns(idTipoFormato === 2 && showTblSSM  ? 4 : idTipoFormato === 3 &&  showTblTesoreria ? 5 : idTipoFormato === 1 && showTblSSM ? 6 : idTipoFormato)
                                }
                                isCompTable={true}
                                compTbl={<CompBtnTotal total={dataSolicitudes.length > 0 ? total: 0.00} saldoAfavor={dataSolicitudes.length > 0 ? saldoAfavor: 0.00}/>}
                                />
                            } 

                            </div>
                
                            </div>
                            <div className="col-md-12">
                            </div>
        </div> 
        
        </>
       
    );
};

export default index;