import { getInfo, saveInfo, updateInfo, deleteInfo,cargaArvhivo,loadFile } from '../../ApiRequests/req_CRUD'
// import { ExisteSession } from '../../../ApiRequests/general'
import { ExisteSession } from '../../ApiRequests/general'
import axios from 'axios';

const getTDispSaldos = (request,addCollection) => {
    request = parametersReuest(request)
    getInfo("Tesoreria/MovimientosEntreCompanias/SaldosDisponibles/"+request.endPoit,request,(collection) =>{
        addCollection(collection)
    })

}
const loadFile2 = (request,archivo, addCollectionResp) => {
    request = parametersReuest(request)
    // saveInfo('Contabilidad/MovimientosBancarios' ,request,(respons) =>  {        
    //     addCollectionResp(respons)
    // })
    // debugger;
    var data = new FormData()
    data.append('archivo.archivo[0]', archivo.archivo[0])
    archivo.archivo.forEach((element,i) => {
        data.append('file'+i, archivo.archivo[i])
    });
    data.append('cargarRequest', JSON.stringify(request))
    cargaArvhivo('Tesoreria/MovimientosEntreCompanias/SaldosDisponibles',data, request,(respons) =>  {        
        addCollectionResp(respons)
    })
}
// const loadFile = async (request,archivo,addCollection) => {
//     console.log(request)
//     // request = parametersReuest(request)
// debugger
//         var data = new FormData()
//         var frm = new FormData();
//         data.append('file', archivo.archivo[0])
//         const parameters = {
//             idUsuarioAct : ExisteSession.getInfoUsuario().idUsuario,
//             connection : ExisteSession.conexion(),
//             token : ExisteSession.getInfoUsuario().token
//             // Agrega más parámetros si es necesario
//         };
//         for (const key in parameters) {
//             data.append(key, parameters[key]);
//         }

//     console.log(data)
//     debugger
    
//   try {
//     const response = await axios.post('https://localhost:7074/api/Tesoreria/MovimientosEntreCompanias/SaldosDisponibles/Upload', data, {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     });

//     // Procesa la respuesta
//     console.log(response.data);
//     addCollection(response.data.Data)

//   } catch (error) {
//     console.error('Error:', error);
//     // fnNotification(['Error de conexion con Upload' +   + '/Get.  verificar el acceso a la red' , 'error', false])

//   }

//     // procInfo("https://localhost:7206/api/ProcesoPDF/Upload",data,request,(collection) =>{
//     //     addCollection(collection)
//     // })




// }
const upLoadFile = async (request,archivo,addCollection) => {
    console.log(request)
    console.log('archivo',archivo)
    console.log('archivo.archivo[0]',archivo.archivo[0])
    console.log(addCollection)
    debugger

    debugger
    let parameters = parametersReuest(request)

    console.log('parameters',parameters)
    debugger


        var dataPdfrm = new FormData()
        var frm = new FormData();
        dataPdfrm.append('file', archivo.archivo[0])
        if(request.endPoint == 'ProgramacionMovimientos')
        {
            console.log('rchivo.archivoXlsx[0]',archivo.archivoXlsx[0])
            dataPdfrm.append('fileXls', archivo.archivoXlsx[0])

        }
        console.log('dat',dataPdfrm);
        // const parameters = {
        //     idUsuarioAct : ExisteSession.getInfoUsuario().idUsuario,
        //     connection : ExisteSession.conexion(),
        //     token : ExisteSession.getInfoUsuario().token,
        //     listRegEx:request.listRegEx,
        //     referencia:request.referencia,
        //     fechaEvidencia:request.fechaEvidencia,
        //     importe:request.importe,

        //     // Agrega más parámetros si es necesario
        // };
        console.log(parameters);
        console.log(dataPdfrm);
        for (const key in parameters) {
            dataPdfrm.append(key, parameters[key]);
        }
        console.log(dataPdfrm);

        debugger


        loadFile('Tesoreria/MovimientosEntreCompanias/'+request.endPoint,dataPdfrm,(response,type,success) =>{
            console.log(response)
            console.log(type)
            console.log(success)
            // debugger
        addCollection(response,type,success)

        })

    // console.log(data)
}
const savedFile = async (request,archivo,addCollection) => {
    console.log(request)
    console.log('archivo',archivo)
    console.log('archivo.archivo[0]',archivo.archivo[0])
    console.log(addCollection)
    // console.log('rchivo.archivoXlsx[0]',archivo.archivoXlsx[0])
    debugger
    let parameters = parametersReuest(request)

        var dataPdfrm = new FormData()
        var frm = new FormData();
        dataPdfrm.append('file', archivo.archivo[0])
        // dataPdfrm.append('fileXls', archivo.archivoXlsx[0])
        console.log('dat',dataPdfrm);
        // const parameters = {
        //     idUsuarioAct : ExisteSession.getInfoUsuario().idUsuario,
        //     connection : ExisteSession.conexion(),
        //     token : ExisteSession.getInfoUsuario().token,
        //     listRegEx:request.listRegEx,
        //     referencia:request.referencia,
        //     fechaEvidencia:request.fechaEvidencia,
        //     importe:request.importe,

        //     // Agrega más parámetros si es necesario
        // };
        console.log(parameters);
        console.log(dataPdfrm);
        for (const key in parameters) {
            dataPdfrm.append(key, parameters[key]);
        }
        console.log(dataPdfrm);

        loadFile('Tesoreria/MovimientosEntreCompanias/'+request.endPoint,dataPdfrm,(response,type,success) =>{
            console.log(response)
            console.log(type)
            console.log(success)
            // debugger
        addCollection(response,type,success)

        })

    // console.log(data)
}
const saveDataSaldos = (request,addCollection) => {
    request = parametersReuest(request)
    saveInfo("Tesoreria/MovimientosEntreCompanias/SaldosDisponibles",request,(response,type,success) =>{
        addCollection(response,type,success)
    })
}
const getMovimientosCompanias = (request,addCollection) => {
    request = parametersReuest(request)
    console.log(request)
    console.log(request.endPoint)
    let newEndPoint = request.endPoint === 1 ? 'MonitorSolicitudes/SolicitudesMovimiento':'ProgramacionMovimientos/relMovimientosSolicitud' 
    getInfo("Tesoreria/MovimientosEntreCompanias/"+newEndPoint,request,(collection) =>{
        addCollection(collection)
    })
    
}
const saveNewSolicitud = (request,addCollection) => {
    request = parametersReuest(request)
    saveInfo("Tesoreria/MovimientosEntreCompanias/MonitorSolicitudes/"+request.endPoit,request,(response,type,success) =>{
        addCollection(response,type,success)
    })
}
const saveNewMov = (request,addCollection) => {
    request = parametersReuest(request)
    saveInfo("Tesoreria/MovimientosEntreCompanias/MonitorSolicitudes/"+request.endPoit,request,(response,type,success) =>{
        addCollection(response,type,success)
    })
}
const updateSolicitud = (request,addCollection) => {
    request = parametersReuest(request)
    updateInfo("Tesoreria/MovimientosEntreCompanias/MonitorSolicitudes/"+request.endPoit,request,(response,type,success) =>{
        addCollection(response,type,success)
    })
    

}
const updateMov = (request,addCollection) => {
    console.log(request)
    debugger
    request = parametersReuest(request)
    updateInfo("Tesoreria/MovimientosEntreCompanias/MonitorSolicitudes/"+request.endPoit,request,(response,type,success) =>{
        addCollection(response,type,success)
    })
    

}
const deleteTypeCompanies = (request,deleteCollection) => {
    request = parametersReuest(request)
    deleteInfo("Catalogos/catTipoCompanias",request,(response,type,success) =>{
        console.log(response,type,success)
        deleteCollection({response:response[0],type,success})
    })
}

const parametersReuest = request =>{
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()
    return request;
}




export{
    getTDispSaldos,
    upLoadFile,
    saveDataSaldos,
    getMovimientosCompanias,
    saveNewSolicitud,
    updateSolicitud,
    saveNewMov,
    savedFile,
    updateMov
}