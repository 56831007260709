import React from 'react';
import Select from 'react-select';
import { getAmbiente ,getAmbiente2} from '../ApiRequests/login';
import { getInfo } from '../ApiRequests/req_CRUD';
import {fnNotification} from '../ApiRequests/Notification'
import Session from '../Application/session'
import {toggleLoader,ExisteSession} from '../ApiRequests/general'
import dataM from '../Data/MenuData.json'

export default class Ambientes extends React.Component{
    constructor(props){
        super(props);
        this.state = { Ambientes : [], idAmbiente : 0, idUsuarioAct : 0, token : ''}
        
    }
    componentDidMount(){

        let collAmbientes = JSON.parse(localStorage.getItem('ambientes'));
        let newAmbientes = [];

        console.log(collAmbientes)
        if(collAmbientes !== null){
            console.log(collAmbientes)
            collAmbientes.map((x, index) => (
                newAmbientes.push({value : x.idAmbiente, label : x.ambiente})
            ));
            this.setState({Ambientes : newAmbientes});            
        var session = JSON.parse(localStorage.getItem("Session_Usuario"));
        this.setState({idUsuarioAct : session.idUsuario,  token: session.token });
        }else {
        Session.remove();
         setTimeout(function(){ window.location.replace(`${process.env.PUBLIC_URL}/`); });
         
        }
        
    }
    
    handleClickAmbiente = (e) => {
        toggleLoader(true)
        e.preventDefault();
        if(ExisteSession.getMenu() !== null && ExisteSession.getMenu() !== undefined){
            const {idAmbiente, idUsuarioAct, token} = this.state;

            if(idAmbiente !== 0){
                getAmbiente({idAmbiente, idUsuarioAct, token,  typeRequest:true}, (resp) => {
                    console.log(resp)
                    window.location.replace(`${process.env.PUBLIC_URL}/Inicio`);
                });
            }else{
                fnNotification(['Error : Selecciona Ambiente.','error',true])
                toggleLoader(false)
            }
        }else{
            Session.remove();
            ExisteSession.remove();
            window.location.replace(`${process.env.PUBLIC_URL}`);
        }
    }

    handleClickLogout = (e) => {
        Session.remove();
        setTimeout(function(){ window.location.replace(`${process.env.PUBLIC_URL}/`); });
    }
    handleChangeSelect = (e) => {
        this.setState({idAmbiente : e.value})
        let id_Ambiente = e.value
        toggleLoader(true)
        const {idUsuarioAct, token} = this.state;
        if(id_Ambiente !== 0){
            if(this.props.NOT_SEG === 1) getAmbiente({idAmbiente : id_Ambiente, idUsuarioAct, token, typeRequest:false}, (resp) => {this.getMenu(resp)});
            if(this.props.NOT_SEG === 2){
                alert('錯誤：請記住您正在沒有安全連線的情況下工作 Cuòwù: Qǐng jì zhù nín zhèngzài méiyǒu ānquán lián xiàn de qíngkuàng xià gōngzuò')
                getAmbiente2({idAmbiente : id_Ambiente, idUsuarioAct, token, typeRequest:false}, (resp) => {this.getMenu(resp)});

            }
        }else{
            // fnNotification(['Error : Selecciona Ambiente.','error',true])
            toggleLoader(false)
        }
    }
    // handleChangeSelect = (e) => {
    //     this.setState({idAmbiente : e.value})
    //     let id_Ambiente = e.value
    //     toggleLoader(true)
    //     const {idUsuarioAct, token} = this.state;
    //     if(id_Ambiente !== 0){
    //         getAmbiente({idAmbiente : id_Ambiente, idUsuarioAct, token, typeRequest:false}, (resp) => {this.getMenu(resp)});
    //     }else{
    //         // fnNotification(['Error : Selecciona Ambiente.','error',true])
    //         toggleLoader(false)
    //     }
    // }
  
    getMenu = (response) => {
        console.log(response)
        console.log(response)
        let dataInit = {
            NOT_SEG:this.props.NOT_SEG,
            IsMenu : true,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            idUsuario: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion(),
        }
        // getInfo("http://localhost:46707/api/Menu/Menu/Get", dataInit, (response) => {
       // if(this.props.NOT_SEG === 1){
        getInfo("Menu/Menu", dataInit, (response) => {
            console.log(response);
            console.log(dataM)
            // if(this.props.NOT_SEG === 1){

                if (this.props.NOT_SEG === 1) {
                    debugger

                       if (response !== undefined && response !== null) {
                         if (response[0] !== undefined && response[0] !== null) localStorage.setItem('Permisos', JSON.stringify(response[0]))
                         if (response[1] !== undefined && response[1] !== null) localStorage.setItem('MenuSession', JSON.stringify(response[1]))
                         if(response[2] !== undefined && response[2] !== null)  localStorage.setItem('PerfilSession',response[2])  
                         toggleLoader(false)
                        }else{
                            setTimeout(() => {
                                window.location.replace(`${process.env.PUBLIC_URL}/Ambientes`);
            
                            }, 3000)
                        }

                } 
                if(this.props.NOT_SEG === 2){
                    debugger
                    localStorage.setItem('Permisos', JSON.stringify(dataM[0]))
                    localStorage.setItem('MenuSession', JSON.stringify(dataM[1]))
                  
                }
        });
    // }

    }

    //Contiene a los demás componentes
    render(){
        return(
            <div className="paper-back-full">
                <div className="login-form-null col-md-4 col-md-offset-4">
                    <br />
                    <br />
                    <br />
                    <div className="fix-box">
                        <div className="text-center animated fadeInDown animation-delay-5">
                            <img  style={{width:'275px'}} src={`${process.env.PUBLIC_URL}/assets/img/logoSIAN/logo171.png`} alt="SIAN"/>
                        </div>
                    </div>
                    <br />

                    <div className="transparent-div no-padding animated fadeInUp animation-delay-8">
                        <ul className="nav nav-tabs nav-tabs-transparent">
                            <li className="active"><a href="#home" data-toggle="tab">Ambientes</a></li>                       
                        </ul>
                        <div className="tab-content" >
                            <div className="tab-pane active" id="home">
                                <div>
                                    <form className="form-group">
                                        <div className=''>
                                        <div className="input-group login-input" style={{position:"absolute"}}>
                                            <span className="input-group-addon"><i className="fa fa-cog"></i></span>
                                            <Select 
                                            isSearchable={false}
                                            options={ this.state.Ambientes } 
                                            onChange={(e) => this.handleChangeSelect(e)}
                                            ref={selectElement => this.idAmbiente = selectElement}                                          
                                            />
                                        </div>
                                        </div>
                                        <br />
                                        <br />
                                        <br />
                                        <button onClick={this.handleClickAmbiente} type="submit" className="btn btn-ar btn-primary pull-right">Aceptar</button>
                                        <button onClick={this.handleClickLogout} type="submit" className="btn btn-ar btn-info pull-left">Salir</button>

                                        <div className="clearfix"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

