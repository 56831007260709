// import {APISeguridad, APISIAN, Cryp} from './general';
import {APISIAN,URLAPISIAN,Cryp,validaMensaje} from './general';
import axios from 'axios';
import {fnNotification} from './Notification'
import {toggleLoader} from '../ApiRequests/general'

// import Session from '../Application/session'

var result = null;
var success = false;

function getInfo(endPoint, objectData, _callBack){
    // toggleLoader(true)
    toggleLoader(true)
    console.log(APISIAN + endPoint + '/Get')
    console.log(APISIAN + endPoint )
    console.log(APISIAN)
    
    axios({
        method: 'post',
        // url: API + tipoData + '/Get?connection='+connection, 
        url: APISIAN + endPoint + '/Get',
        data: objectData
    }).then(function(response){
        
    if(response.status === 200) {        
        result = response.data.Data
        
        if (response.data.Code !== 200) {
          
            validaMensaje(response.data.Message)
            result = []

            fnNotification([response.data.Message, 'error', true])    
            

        }else{            
            result = response.data.Data
        }
    }
    toggleLoader(false) 
    }).catch(function(error){
        fnNotification(['Error de conexion con ' + APISIAN + endPoint + '/Get.  verificar el acceso a la red' , 'error', false])   
         toggleLoader(false)
    }).then(() => _callBack(result));
}

function getInfoPoliza(endPoint, objectData, _callBack){
    axios({
    method: 'post',
     // url: API + tipoData + '/Get?connection='+connection, 
    url: APISIAN + endPoint + '/Get',
    data: objectData
    }).then(function(response){
        
        if(response.status === 200) {
        
        if (response.data.Code !== 200) {

            result = []
            result = response.data

            
            

        }else{
            result = response.data
            }
        } 
    }).catch(function(error){
    }).then(() => _callBack(result));
}


function getInfoGral(endPoint, objectData, _callBack){
    axios({
    method: 'post',
     // url: API + tipoData + '/Get?connection='+connection, 
    url: APISIAN + endPoint,
    data: objectData
    }).then(function(response){
        
        if(response.status === 200) {
        
        if (response.data.Code !== 200) {
            fnNotification(['Error: ' + response.data.Message, 'error', true])
            result = []
            result = response.data           

        }else{
            result = response.data
            }
        } 
    }).catch(function(error){
    }).then(() => _callBack(result));
}

function saveInfo(endPoint, objectData, _callback) {
    if (objectData !== null) {
        toggleLoader(true)
        axios({
            method: 'post',
            url: APISIAN + endPoint + '/Post',
            data: objectData
        }).then(function (response) {
            // console.log("saveInfo",response);
            if (response.status === 200) {
                if (response.data.Code !== 200) {
                    success = false;
                    fnNotification(['Error: ' + response.data.Message, 'error', true])
                    // validaMensaje(response.data.Message)
                }
                else {

                    // paramObjData = response.data.Data[0]
                    if (response.data.Data && response.data.Data[0] != null) objectData = response.data.Data[0]
                    success = true;
                    fnNotification([response.data.Message, 'success', true])
                }
            } else {
                success = false;

                fnNotification(['Error: ' + response.statusText, 'error', true])
            }
            toggleLoader(false)
        }).catch(function (error) {
            // console.log("saveInfo error",error );
            success = false;
            let msj = error.config.url + ' - ' + error.message
            fnNotification(['Error: ' + msj, 'error', false])
            toggleLoader(false)
        }).then(() => _callback(objectData, 'save', success));
    }


}
function updateInfo(endPoint, objectData, _callback) {
    toggleLoader(true)
    console.log(objectData)

    axios({
        method: 'post',
        url: APISIAN + endPoint + '/Put',
        data: objectData
    }).then(function (response) {
        if (response.status === 200) {
            console.log(response)
   

            if (response.data.Code !== 200) {
                success = false;
                fnNotification(['Error: ' + response.data.Message, 'error', true])
                // validaMensaje(response.data.Message)
            }
            else {
                success = true;
                fnNotification([response.data.Message, 'success', true])
                if (response.data.Data[0] != null) objectData = response.data.Data[0]
                result = response.data.Data
            }
        } else {
            success = false;
            fnNotification(['Error: ' + response.statusText, 'error', true])
        }
        toggleLoader(false)
    }).catch(function (error) {
        success = false;
        toggleLoader(false)
        // result = false;
        // let msj = error.config.url + ' - ' + error.message
        // fnNotification(['Error: ' + msj, 'error', false])
    }).then(() => _callback(result, 'update', success));
}
function deleteInfo(endPoint, objectData, _callback) {
    toggleLoader(true)
    axios({
        method: 'post',
        url: APISIAN + endPoint + '/Delete',
        data: objectData
    }).then(function (response) {
        if (response.status === 200) {
            if (response.data.Code !== 200) {
                success = false;
                fnNotification(['Error: ' + response.data.Message, 'error', true])
                // validaMensaje(response.data.Message)
            }
            else {
                success = true;
                fnNotification([response.data.Message, 'success', true])
                // if (response.data.Data[0] != null) paramObjData = response.data.Data[0]
                result = response.data.Data
            }
        } else {
            success = false;
            fnNotification(['Error: ' + response.statusText, 'error', true])
        }
        toggleLoader(false)
    }).catch(function (error) {
        success = false;
        toggleLoader(false)
        // result = false;
        // let msj = error.config.url + ' - ' + error.message
        // fnNotification(['Error: ' + msj, 'error', false])

    }).then(() => _callback(result,'delete',success));
}
function getPreviewReport(paramTipo, paramObjData){
    var url = URLAPISIAN + 'ReportViwer.aspx?RepId='+paramTipo+'&data=' + Cryp.encriptar(paramObjData)
    var win = window.open(url, '_blank');
    win.focus();
}

function getInfoErase(endPoint, objectData, _callBack){
    axios({
        method: 'post',
        
        url: APISIAN + endPoint + '/GetErase',
        data: objectData
    }).then(function(response){
    if(response.status === 200) {
        result = response.data.Data
        
        if (response.data.Code !== 200) {
          
            validaMensaje(response.data.Message)
            result = []

            fnNotification([response.data.Message, 'error', true])    
            

        }else{
            result = response.data.Data
            
    
    
        }
    } 
    }).catch(function(error){
        fnNotification(['Error de conexion con ' + APISIAN + endPoint + '/Get.  verificar el acceso a la red' , 'error', false])   
        
    }).then(() => _callBack(result));
}

function downloadHelp(endPoint, objectData, _callBack){
    // var archivo = objectData.archivo
    axios({
        method: 'post',
        
        url: APISIAN + endPoint + '/DescargaArchivoAyuda',
        responsiveType : 'blob',
        data: objectData        
        
    }).then(function(response){
    
    
    let infoFile = response.data[0];       
    var link = window.document.createElement('a');
    link.href = infoFile.mime;
    
    link.setAttribute('target', '_blank');
    link.setAttribute('download', infoFile.fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
            
         
    }).catch(function(error){
        
        fnNotification(['Error de conexion con ' + APISIAN + endPoint + '/Get.  verificar el acceso a la red' , 'error', false])   
        
    }).then(() => _callBack(result));
}

function cargaArvhivo(endPoint, formData, objectData, _callBack){
    // debugger;
    console.log(endPoint)
    console.log(formData)
    // console.log(_callBack)
    debugger;
    axios({
        method: 'post',
        
        url: APISIAN + endPoint + '/SubeArchivo',        
        data: formData        
        
    }).then(function(response){
    
    // debugger;
    if(response.status === 200) {
        console.log(response)
        // console.log(_callBack)
        debugger;
        if (response.data.Code !== 200) {
          
            validaMensaje(response.data.Message)
            
            fnNotification([response.data.Message, 'error', true])    
            

         }
        
        result = response.data.Data
    } else{
        console.log(response)
        // console.log(_callBack)
        debugger;
    }
            
         
    }).catch(function(error){
        // debugger;
        fnNotification(['Error de conexion con ' + APISIAN + endPoint + '/SubeArchivo.  verificar el acceso a la red' , 'error', false])   
        
    }).then(() => _callBack(result));
}

function cargaCertificados(endPoint, formData, objectData, _callBack){
    
    axios({
        method: 'post',
        
        url: APISIAN + endPoint + '/SubeArchivoCertificado',        
        data: formData        
        
    }).then(function(response){
    
    // debugger;
    if(response.status === 200) {
        if (response.data.Code !== 200) {
          
            validaMensaje(response.data.Message)
            
            fnNotification([response.data.Message, 'error', true])    
            

         }
        
        result = response.data.Data
    } 
            
         
    }).catch(function(error){
        // debugger;
        fnNotification(['Error de conexion con ' + APISIAN + endPoint + '/SubeArchivoCertificado.  verificar el acceso a la red' , 'error', false])   
        
    }).then(() => _callBack(result));
}
const loadFile = async (endPoint, formData, _callBack) => {
    console.log(APISIAN)
    console.log(APISIAN+ endPoint)
    console.log(formData)

    try {
        const response = await axios.post(APISIAN+ endPoint +'/Upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
        });
        console.log(response)
        console.log(response.data)
        debugger
        if(response.status === 200) {
        debugger

            if (response.data.Code !== 200) {
        debugger
            
                validaMensaje(response.data.Message)
                
                fnNotification([response.data.Message, 'error', true])    
                _callBack(response.data.Data,'upload',false)
                
    
            }else{
        debugger

                _callBack(response.data.Data,'upload',true)
            }
            result = response.data.Data
            console.log(response)
            // debugger
        }
        // return result;
    
        // Procesa la respuesta
        // console.log(response.data);
        // addCollection(response.data.Data)
    
      } catch (error) {
        console.error('Error:', error);
        _callBack([],'upload',false)

        fnNotification(['Error de conexion con Upload' +  endPoint + '/Get.  verificar el acceso a la red' , 'error', false])
    
      }
}
/*Función sin notificación*/
function saveData(endPoint, objectData, _callback) {
    if (objectData !== null) {
        toggleLoader(true)
        axios({
            method: 'post',
            url: APISIAN + endPoint + '/Post',
            data: objectData
        }).then(function (response) {
            console.log("response1",response);
            if (response.status === 200) {
                if (response.data.Code !== 200) {
                    success = false;
            console.log("response2",response);

                    // fnNotification(['Error: ' + response.data.Message, 'error', true])
                    // validaMensaje(response.data.Message)
                    objectData = response.data;

                }
                else {
                    console.log("response3",response);

                    // paramObjData = response.data.Data[0]
                    // if (response.data.Data && response.data.Data[0] != null && response.data.Data[0] != undefined) objectData = response.data.Data[0]
                    objectData = response.data;
                    success = true;
                    // fnNotification([response.data.Message, 'success', true])
                }
            } else {
                success = false;

                fnNotification(['Error: ' + response.statusText, 'error', true])
            }
            toggleLoader(false)
        }).catch(function (error) {
            // console.log("saveInfo error",error );
            success = false;
            let msj = error.config.url + ' - ' + error.message
            fnNotification(['Error: ' + msj, 'error', false])
            toggleLoader(false)
        }).then(() => _callback(objectData, 'save', success));
    }


}


export {getInfo,saveInfo,updateInfo,deleteInfo,getInfoPoliza,getPreviewReport,getInfoErase, downloadHelp, cargaArvhivo,cargaCertificados,getInfoGral,loadFile,saveData}
