import React from 'react';
import GeneralTable from '../GeneralTable';
import {columns,hiddenColumns,columnsProgramMov,columnsEvidenncias,columnsEvidenncias2} from './resources'
import Filter from './Filter'
import Buttons from '../Buttons/Buttons';

const ProgramacionMovimientos = ({
    typeTab,
    movimientosTbl,
    petAut,
    handleCbxMov,
    showDetailMov,
    showModalDeleteMov,
    loadEvidence,
    showPDF              ,
    downloadHelp,
    changeSelectSolicitudes,
    changeDateSolicitud,
    btnSearch,
    optionTipoMovimiemto   ,
    optionEmpresaOrigen    ,    
    optionEmpresaDestino   ,
    empresasOrigenDestino  ,
    empresasBanco          ,
    tipoMovimiemto,
    empresas,
    changeTab,
    numTab,
    isEvidMov,
    dataRespEvMov,
    saveFileEvidence,
    showUpMasiv,
    searchFilXlsPdf,
    CurrencyFormat,
    cancelSaveChange

}) => {
    const BtnConfirmMov = () =>{
        console.log(typeTab)
                return <div className="row">
                <div className="col-md-1">
                <Buttons btnSave={true} btnSaveAction={() => saveFileEvidence(true)} btnSaveText={'Guardar'} btnSaveShowIcon={true}
                
                    />
                </div>
                <div className="col-md-1">

                <Buttons 
                        btnCancel ={true}  
                        btnCancelAction  = {e => cancelSaveChange()} 
                        btnCancelText="Descartar" 
                        btnCancelShowIcon={true} 
                    />

</div>

                
              
    
                </div>
                

        } 
    const BtnUpMasiv = () =>{
            console.log(typeTab)
                    return <div className="row">
                        <div className="col-md-1">
                            <Buttons btnSave={true} btnSaveAction={() => searchFilXlsPdf(true)} btnSaveText={'Busca archiovs'} btnSaveShowIcon={true} btnSaveIconClass={'fa fa-upload'}/>
                        </div>
                        <div className="col-md-1">
                        <Buttons btnAyuda={true} btnAyudaAction={downloadHelp} btnAyudaText={'Ayuda'} btnAyudaShowIcon={true}/>
                    
                </div>
                    </div>
            } 

    return (
    <>
    {   isEvidMov ? <>
        
        <GeneralTable
                                    // columns={columnsImpuestos(handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr,idTipoFormato,showModalCargaCP)}
                                    columns={columnsEvidenncias2(numTab,showDetailMov,CurrencyFormat)}
                                    data={dataRespEvMov}
                                    // action={undefined}
                                    doubleClick={(original,values) => {}} 
                                    // isNotPagination={true}
                                    // pageSize={500}
                                    isCpmpBtn={true}
                                    // // compnnt={idTipoFormato === 2 && showTblSSM  ?  BtnConfirmSS : <div></div>}
                                    compnnt={BtnConfirmMov}
                                    hiddenColumnsIDs={
                                        hiddenColumns(typeTab)
                                    }
                                    // isCompTable={true}
                                    // compTbl={BtnConfirmSS()}

                                    />
        </>:
            <div>
                <div>
                    <ul className="nav nav-pills nav-justified ar-nav-pills center-block margin-bottom">
                    <li className={'active'} onClick={e => changeTab(3)}>
                        <a id='liMovProg' href="#MovimientosProg" data-toggle="tab" >
                                        <span>Movimientos</span> 
                                    </a>
                    </li>
                    <li className={''} onClick={e => changeTab(4)}>
                        <a id='liMonitorSolicitudes' href="#SolicitudesAprovadas" data-toggle="tab" >
                                        <span>Solicitudes</span> 
                                    </a>
                    </li>
                    </ul>
                </div>
                <div className="tab-content margin-top">
                        <div className={'tab-pane active'}  id="MovimientosProg">
                            <div className="form-group" >
                                <Filter 
                                    typeFilter={typeTab} 
                                    tipoMovimiemto={tipoMovimiemto}
                                    empresas={empresas}
                                    changeSelectSolicitudes={changeSelectSolicitudes}
                                    changeDateSolicitud={changeDateSolicitud}
                                    btnSearch={() => btnSearch(numTab)}
                                    optionTipoMovimiemto   ={optionTipoMovimiemto}
                                    optionEmpresaOrigen    ={optionEmpresaOrigen}
                                    optionEmpresaDestino   ={optionEmpresaDestino}
                                    empresasOrigenDestino={empresasOrigenDestino}
                                    empresasBanco={empresasBanco}

                                />
                            </div>
                            <GeneralTable
                                    // columns={columnsImpuestos(handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr,idTipoFormato,showModalCargaCP)}
                                    columns={columnsProgramMov(typeTab,handleCbxMov,showDetailMov,showModalDeleteMov,loadEvidence,numTab,showPDF,0)}
                                    data={movimientosTbl}
                                    // action={undefined}
                                    doubleClick={(original,values) => {}} 
                                    // isNotPagination={true}
                                    // pageSize={500}
                                    isCpmpBtn={true}
                                    // // compnnt={idTipoFormato === 2 && showTblSSM  ?  BtnConfirmSS : <div></div>}
                                    compnnt={BtnUpMasiv}
                                    // hiddenColumnsIDs={
                                    //     hiddenColumns(typeTab)
                                    // }
                                    // isCompTable={true}
                                    // compTbl={BtnConfirmSS()}

                                    />

                        </div>
                        <div className={'tab-pane'} id="SolicitudesAprovadas">
                        <div className="form-group" >
                                <Filter 
                                    typeFilter={typeTab} 
                                    tipoMovimiemto={tipoMovimiemto}
                                    empresas={empresas}
                                    changeSelectSolicitudes={changeSelectSolicitudes}
                                    changeDateSolicitud={changeDateSolicitud}
                                    btnSearch={() => btnSearch(numTab)}
                                    optionTipoMovimiemto   ={optionTipoMovimiemto}
                                    optionEmpresaOrigen    ={optionEmpresaOrigen}
                                    optionEmpresaDestino   ={optionEmpresaDestino}
                                    empresasOrigenDestino={empresasOrigenDestino}
                                    empresasBanco={empresasBanco}

                                />
                            </div>
                            <GeneralTable
                                    // columns={columnsImpuestos(handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr,idTipoFormato,showModalCargaCP)}
                                    columns={columnsProgramMov(typeTab,handleCbxMov,showDetailMov,showModalDeleteMov,loadEvidence,numTab,0)}
                                    data={petAut}
                                    // action={undefined}
                                    doubleClick={(original,values) => {}} 
                                    // isNotPagination={true}
                                    // pageSize={500}
                                    // isCpmpBtn={true}
                                    // // compnnt={idTipoFormato === 2 && showTblSSM  ?  BtnConfirmSS : <div></div>}
                                    // compnnt={BtnConfirmSS}
                                    // hiddenColumnsIDs={
                                    //     hiddenColumns(typeTab)
                                    // }
                                    // isCompTable={true}
                                    // compTbl={BtnConfirmSS()}

                                    />
                        </div>


                </div>

            </div>
            
    }
    </>


    );
};

export default ProgramacionMovimientos;