import React from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap'
import Buttons from '../../../Components/Buttons/Buttons';
import Select from 'react-select';
import { loadFile } from '../../../Components/PagoImpuestos/service';


const ModalCP = ({
    showModalCP,
    dtModalPC,
    changeSelectCP,
    closeMCP,
    pagadoras,
    cuentas,
    optionCuenta,
    optionPagadora,
    cambiaPagadora,
    actionChangePDFCP,
    isComprobanteOK,
    saveReceipt,
    changeDLC,
    changeForceFile,
    rechazaSolicitud,
    changeIsComprobanteOK,
    showMotivo,
    statusMotivo,
    changeTextMotiv,
    motivText,
    loadFileComp,
    lineaCapturaF,
    fechaPago 

}) => {

   
   

    return (
        <Modal size="xl" show={showModalCP} animation={false} backdrop="static"  aria-labelledby="modalConfirmImp">
        <Modal.Header className="header">
            <Container>
            <Row>
                    <Col md="11" className="text-left">
                    {<span>Carga comprobantes</span> }
                    </Col>
                    </Row>
            </Container>
        </Modal.Header>
        <Modal.Body>
        <Row>
            <Col md="4">
                    <label>Impuesto</label>
            </Col>
            <Col md="8">
            <span>{dtModalPC.impuesto}</span>
               

            </Col>
            </Row>
            
            <Row>
            <Col md="4">
                    <label>Empresa</label>
            </Col>
            <Col md="8">
            <span>{dtModalPC.empresa}</span>

               

            </Col>
            </Row>

            <Row>
            <Col md="4">
                    <label>Linea de captura</label>
            </Col>
            <Col md="8">
            <span>{dtModalPC.lineaCaptura}</span>
            </Col>
            </Row>
            <Row>
            <Col md="4">
                    <label>Importe total</label>
            </Col>
            <Col md="8">
            <span>{dtModalPC.impuestoTotal}</span>
            </Col>
            </Row>
            <Row>
            <Col md="4">
                    <label>Fecha vencimiento</label>
            </Col>
            <Col md="8">
            <span>{dtModalPC.fechaVencimiento}</span>
            </Col>
            </Row>
            <Row>
            <Col md="4">
            <label>Pagadora</label>

            </Col>
            <Col md="8">
                <div className='form-group'>
                    {
                    // isComprobanteOK === 0 ?
                    <Select
                            isSearchable
                            name="pagadora"
                            onChange={(event,name) => changeSelectCP(event,name)}
                            options={pagadoras}
                            value={optionPagadora}
                        /> 
                        // :
                        // optionPagadora !== undefined && optionPagadora !== null ?
                        // <span>{ optionPagadora.label }</span> :
                        // <span></span>
                    }
                </div>
            </Col>
            </Row>
            <Row>
            <Col md="4">
            <label>Banco</label>

            </Col>
            <Col md="8">
                <div className='form-group'>
                    {

                        // isComprobanteOK === 0 ?

                        <Select
                                                    isSearchable
                                                    name="cuenta"
                                                    onChange={(event,name) => changeSelectCP(event,name)}
                                                    options={cuentas}
                                                    value={optionCuenta}
                                                />
                                                // :
                                                // optionCuenta !== undefined && optionCuenta !== null ?
                                                // <span>{optionCuenta.label}</span> :
                                                // <span></span>
                    }
                

                </div>

            </Col>
            </Row>

            <br />
            <Row>
            <Col md="10"></Col>
            <Col md="1">
            <div className="text-left">
                {
                 isComprobanteOK === 0 && 
                <Buttons 
                btnAdd={true} btnAddAction={() => cambiaPagadora()} btnAddText="Cambiar pagadora" btnAddShowIcon={true} btnAddIconClass="fa fa-save"

                />
                }
                
               </div>
            </Col>

            </Row>

                
                <Row>
                <Row>
                    <Col md="2"></Col>
                    <Col md="8">
                        <div className='text-center'>
                            {
                                isComprobanteOK === 1 &&
                                <label>La linea de captura es correcta </label>
                            }
                            {
                                (isComprobanteOK === 2 || isComprobanteOK === 4) &&
                                <div>
                                    <label style={{color:'red'}} >{isComprobanteOK === 2  ? "La linea de captura en el archivo es incorrecta" : ""} </label>
                                    <label>¿Desea cargar manualmente? </label>
                                </div>     
                            }
                        </div>
                    </Col>
                    <Col md="2"></Col>
                </Row>

                <Row>
                    <Col md="12">
                        {
                                isComprobanteOK === 1 &&
                                <Row>
                                <Col md="1"></Col>
                                <Col md="10">

                                <div className='text-center'>
                                <span>{dtModalPC.lineaCaptura}</span>
                                </div> 
                                </Col>

                                </Row>

                        }
                        {
                                (isComprobanteOK === 2 || isComprobanteOK === 4) &&
                                <>
                                <Row>
                                <Col md="1"></Col>
                                <Col md="10">
                                    <div className='form-group'>
                                        <input
                                        type="text" 
                                        name='lineaCpatur'
                                        className="form-control" 
                                        placeholder='Linea de captura' 
                                        onChange={(e) => changeDLC(e)} 
                                        value={lineaCapturaF}
                                        />
                                    </div>
                                </Col>
                                <Col md="1"></Col>
                                </Row>
                               
                                <Row>
                                <Col md="1"></Col>
                                <Col md="10">
                                    <div className='form-group'>
                                        <input
                                        type="date"
                                        className="form-control" 
                                        name='fechaPago'
                                        onChange={(e) => changeDLC(e)}  
                                        value={fechaPago}
                                        />
                                    </div>
                                </Col>
                                <Col md="1"></Col>
                                </Row>
                                <Row>
                                    <Col md="4"></Col>
                                    <Col md="4">
                                    <div className='form-group'   style={{marginTop:'10px', marginBottom:'14px'}}>
                                        <input id={"inputForceFileL"} type="file" accept='.pdf, image/jpeg, image/png'  onChange={(e) => changeForceFile(e)} />   
                                    </div>
                                    </Col>
                                    <Col md="4"></Col>
                                </Row>
                                <Row>
                                <Col md="5"></Col>
                                <Col md="2">
                                    <di>
                                        <Buttons btnAdd={true} btnAddAction={() => saveReceipt()} btnAddText="Guardar manualmente" btnAddShowIcon={true} btnAddIconClass="fa fa-save"/>
                                    </di>
                                </Col>
                                <Col md="5"></Col>
                                </Row>
                                </>
                        }
                        
                    </Col>
                    </Row>

                    <Row>

                    <Col md="2"></Col>
                    <Col md="8">
                        <div className='text-center'>
                        {isComprobanteOK === 1 &&  <span>Desea guardar esta información</span>}
                        </div>
                    </Col>
                    <Col md="2"></Col>
                    </Row>
                   
                    <Row>
                        <Col md="5"></Col>
                        <Col md="2">
                        {
                        isComprobanteOK === 1 &&
                        <div  style={{marginTop:'10px'}}>
                        <Buttons 
                            btnAdd={true}
                            btnAddAction={() => saveReceipt()} 
                            btnAddText="Guardar comprobante" 
                            btnAddShowIcon={true} 
                            btnAddIconClass="fa fa-save"/>
                        </div>
                        }                        
                        </Col>
                        <Col md="5"></Col>
                    </Row>
             
                 
                </Row>         
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
        <Row>
            <Col md="1"></Col>
            <Col md="6" >
                {
                    statusMotivo &&<>
                        <div className='form-group'>
                        <span>Favor de escribir un motivo de cancelación</span>
                        <textarea className='form-control' value={motivText} onChange={(e) => changeTextMotiv(e)}>

                        </textarea>
                    </div>
                    <div className='form-group'>
                        <Buttons 
                            btnAdd={true} btnAddAction={() => rechazaSolicitud()} btnAddText="Rechazar solicitud" btnAddShowIcon={true} btnAddIconClass='fa fa-check'
                            btnCancel={true} btnCancelAction={() => showMotivo(false)} btnCancelText="Cancelar" btnCancelShowIcon={true}

                        />
                    </div></>
                }
            </Col>
            <Col md="1" >
            {
                isComprobanteOK === 0  && <>
                    {!statusMotivo &&
                        <Buttons 
                        btnDelete={true} btnDeleteAction={() => showMotivo(true)} btnDeleteText="Rechazar solicitud" btnDeleteShowIcon={true} btnDeleteIconClass='fa fa-ban fa-lg'
                        />}
                </>
            
            }
            </Col>
            <Col md="1" >
                {
                    isComprobanteOK === 0  && 
                    <div>
                        <Buttons btnAdd={true} btnAddAction={() => loadFileComp()} btnAddText="Cargar comprobante" btnAddShowIcon={true} btnAddIconClass="fa fa-upload"/>                        
                        <input id={"IUploadCP"} type="file" hidden  accept='.pdf' onChange={(e) =>  actionChangePDFCP(e,isComprobanteOK)} />   
                    </div>
                    //  : 
                    // <div>
                    //     <Buttons btnAdd={true} btnAddAction={() => saveReceipt()} btnAddText="Guardar comprobante" btnAddShowIcon={true} btnAddIconClass="fa fa-save"/>
                    // </div>
                    
                }
                {
                    isComprobanteOK === 2  && 
                    <div>
                        <Buttons 
                            btnDelete={true} btnDeleteAction={() => console.log('rechazar solicitud')} btnDeleteText="Rechazar solicitud" btnDeleteShowIcon={true} btnDeleteIconClass='fa fa-ban fa-lg'
                        />  
                    </div>
                }
                


            </Col>
                {
                     isComprobanteOK === 0  && 
            <Col md="1" >

                     <div className="text-left">
                     <Buttons btnAdd={true} btnAddAction={() => changeIsComprobanteOK(4)} btnAddText="Cargar de forma manual" btnAddShowIcon={true} />                        
         
                     </div>
            </Col>

                }
     

            <Col md="1" >
            <div className="text-left">
                <Buttons 
                btnCancel={true} btnCancelAction={() => closeMCP()} btnCancelText="Cancelar" btnCancelShowIcon={true}
                />
                </div>
            </Col>
        </Row>
            <br />

        </Modal>
    );
};

export default ModalCP;