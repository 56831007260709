import React,{Fragment} from 'react';
import { Modal, Button, Accordion, Container, Row, Col } from 'react-bootstrap'
import Buttons from '../../Buttons/Buttons';
import GeneralTable from '../../GeneralTable';
import Select from 'react-select';



const ModalNuevoMovimiento = ({
    show,
    onCerrarMNS             , /*onCerrarModalNuevaSolicitud*/
    saveNuevaSolicitud      ,
    changeSelectNMN         ,
    changeInfoSolicitudNMN  ,
    saveAutorizate          ,
    tipoMovimiemto          ,
    optiontipoMovimiemtoMNM ,
    fechaMovimientoNMN      ,
    conceptoValue           ,
    empresasBancoCuentaOrigenDestino   ,
    optionEmpresaOrigenMNM  ,
    optionEmpresaDestinoMNM ,
    cuentasOrigen           ,
    cuentasDestino          ,
    optionCuentaOrigenMNM   ,
    optionCuentaDestinoMNM  ,
    solicitanteMNM          ,
    importeValueNMN         ,
    comentariosNMN          ,
    movPetition,
    columns,
    typeTab,
    solicitudes, 
    solicitudes_NewMov       , 
    handdleAddNewMov         ,
    totalMovimiento          ,
    nuevoTotMovimiento       ,
    clickDeleteRow           ,
    isDetailPet              ,
    rowDetail                ,
    cambiaStatusSolicitud    ,
    solicitantes             ,
    optionSolicitante        ,

}) => {
    return (
        <Fragment>
             <Modal  size="lg" show={show} animation={false} backdrop="static" aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header className="header">
                <Container>
                        <Row>
                        <Col md="9" className="text-center">
                            {
                            isDetailPet ?
                                <label>Detalle de solicitudes fondeo</label> 
                                :
                                movPetition === 1 ?  <label>Nuevo movimiento</label> :
                                <label>Nueva solicitud de movimiento</label> 
                            }

                        </Col>
                        </Row>
                </Container>
            </Modal.Header>
            {
                isDetailPet ?
                <Modal.Body>
                    <Row>
                        <Col md='3'><span><label>Tipo solicitud :</label>  </span></Col>
                        <Col md='3'><span><label>Fecha movimiento :</label></span>  </Col>
                        <Col md='3'><span><label>Estatus actual : </label></span></Col>
                        <Col md='3'><span><label>Concepto :</label></span> </Col>
                    </Row>
                    <Row>
                        <Col md='3'><span>{rowDetail.tipoMovimiento}</span></Col>
                        <Col md='3'><span>{rowDetail.fechaAct ? rowDetail.fechaAct.split('T')[0] : ''}</span>  </Col>
                        <Col md='3'><span>{rowDetail.estatus}</span></Col>
                        <Col md='3'><span>{rowDetail.concepto}</span> </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md='6'>
                        <label>Datos origen</label>
                        <Row>
                            <Col md='2'><label>Empresa:</label></Col>
                            <Col md='10'><span>{rowDetail.empresaOrigen}</span></Col>
                        </Row>
                        <Row>
                            <Col md='2'><label>Banco:</label></Col>
                            <Col md='10'><span>{rowDetail.bancoOrigen}</span></Col>
                        </Row>
                        <Row>
                            <Col md='2'><label>Cuenta:</label></Col>
                            <Col md='10'><span>{rowDetail.cuentaOrigen}</span></Col>
                        </Row>
                        </Col>

                        <Col md='6'>
                        <label>Datos destino</label>
                        <Row>
                            <Col md='2'><label>Empresa:</label></Col>
                            <Col md='10'><span>{rowDetail.empresaDestino}</span></Col>
                        </Row>
                        <Row>
                            <Col md='2'><label>Banco:</label></Col>
                            <Col md='10'><span>{rowDetail.bancoDestino}</span></Col>
                        </Row>
                        <Row>
                            <Col md='2'><label>Cuenta:</label></Col>
                            <Col md='10'><span>{rowDetail.cuentaDestino}</span></Col>
                        </Row>
                        </Col>

                    </Row>
                    <Row>
                    <Col md="4"></Col>
                    <Col md="4"><label>Cambiar estatus a:</label></Col>
                    </Row>
                    <Row>
                    <Col md="4"></Col>
                    <Col md="4">
                        <Buttons 
                        btnAdd ={movPetition === 0} btnAddAction  = {(e) => cambiaStatusSolicitud(rowDetail.idSolicitud,2)} btnAddText="Autorizado" btnAddShowIcon={true} btnAddIconClass="fa fa-save"
                        btnSave ={true} btnSaveAction  = {(e) => cambiaStatusSolicitud(rowDetail.idSolicitud,3)} btnSaveText="sin movimiento aplicado" btnSaveShowIcon={true} 
                        />
                    </Col>
                </Row>
                </Modal.Body>:
                <Modal.Body>
                <Row>
                    <Col md="4">
                        <label>Tipo de movimiento</label>
                        <Select
                                        className=""
                                        isClearable
                                        isSearchable
                                        name="tipoMovNMN"
                                        onChange={(event,name) => changeSelectNMN(event,name)}
                                        options={tipoMovimiemto}
                                        value={optiontipoMovimiemtoMNM}
                                    />
                    </Col>
                    <Col md="4">
                        <label>Fecha de movimiento</label>
                        <input 
                        className="form-control" 
                        type='date' 
                        name="fechaMovNMN" 
                        onChange={(event,name) => changeInfoSolicitudNMN(event,name)}  
                        value={fechaMovimientoNMN}
                        />

                    </Col>
                    <Col md="4">
                        <label>Concepto</label>
                        <input 
                        className="form-control" 
                        type='text' 
                        name="conceptoNMN" 
                        onChange={(event,name) => changeInfoSolicitudNMN(event,name)}  
                        value={conceptoValue}
                        />

                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                    <label>Empresa Banco Cuenta origen</label>
                            <Select
                                className=""
                                isClearable
                                isSearchable
                                name="empresaBancoCuentaOrigenNMN"
                                onChange={(event,name) => changeSelectNMN(event,name)}
                                options={empresasBancoCuentaOrigenDestino}
                                value={optionEmpresaOrigenMNM}
                            />
                    </Col>
                    <Col md="4">
                    <label>Empresa Banco Cuenta destino</label>
                            <Select
                                className=""
                                isDisabled={movPetition === 1}
                                isClearable
                                isSearchable
                                name="empresaBancoCuentaDestinoNMN"
                                onChange={(event,name) => changeSelectNMN(event,name)}
                                options={empresasBancoCuentaOrigenDestino}
                                value={optionEmpresaDestinoMNM}
                            />
                  

                    </Col>
                    <Col md="4">
                        <label>Solicitante</label><br />
                        {/* <span>{solicitanteMNM}</span> solicitantes*/}
                        <Select
                                className=""
                                // isDisabled={movPetition === 1}
                                isClearable
                                isSearchable
                                name="cbSolicitantes"
                                onChange={(event,name) => changeSelectNMN(event,name)}
                                options={solicitantes}
                                value={optionSolicitante}
                            />
                        {/* <input 
                        className="form-control" 
                        // type='txt' 
                        name="solicitante" 
                        onChange={(event,name) => changeInfoSolicitudNMN(event,name)}
                        value={solicitanteMNM}
                        /> */}

                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <label>Importe</label>
                        <input 
                        className="form-control" 
                        type='number' 
                        name="importeNMN" 
                        onChange={(event,name) => changeInfoSolicitudNMN(event,name)}
                        value={importeValueNMN}
                        />

                    </Col>
                    <Col md="4">
                        <label>Comentarios</label>
                        <textarea 
                        className="form-control" 
                        name="comentNMN"
                        onChange={(event,name) => changeInfoSolicitudNMN(event,name)}  
                        value={comentariosNMN}
                        />
                    
                    </Col>
                    <Col md="4">
                    <label>&nbsp;</label>
                    {
                        movPetition === 1 &&<Buttons 
                        btnAdd ={true} btnAddAction  = {(e) => handdleAddNewMov(e)} btnAddText="Guardar" btnAddShowIcon={true} 
                        /> 
                    }
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <line />
                    </Col>
                </Row>
                {
                    movPetition === 1 &&<>
                    <Row>
                    <Col md="12">
                        <GeneralTable
                            // columns={columnsImpuestos(handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr,idTipoFormato,showModalCargaCP)}
                            columns={columns(0,clickDeleteRow)}
                            data={solicitudes_NewMov}
                            notSearch={true}
                            // action={undefined}
                            doubleClick={(original,values) => {}} 
                            isNotPagination={true}
                            // pageSize={500}
                            // isCpmpBtn={true}
                            // compnnt={BtnHeader}
                            hiddenColumnsIDs={'idMov'}
                            // isCompTable={true}
                            // compTbl={BtnConfirmSS()}

                            />
                    </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                        </Col>
                        <Col md="4">
                        </Col>
                        <Col md="4">
                           <label>Importe parcial $</label><label>{nuevoTotMovimiento}</label> 
                        </Col>
                    </Row>
                    <Row>
                    <Col md="12">
                        <GeneralTable
                            // columns={columnsImpuestos(handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr,idTipoFormato,showModalCargaCP)}
                            columns={columns(1,clickDeleteRow)}
                            data={solicitudes}
                            // action={undefined}
                            doubleClick={(original,values) => {}} 
                            isNotPagination={true}
                            notSearch={true}
                            // pageSize={500}
                            // isCpmpBtn={true}
                            // // compnnt={idTipoFormato === 2 && showTblSSM  ?  BtnConfirmSS : <div></div>}
                            // compnnt={BtnHeader}
                            hiddenColumnsIDs={'idMov' }
                            // isCompTable={true}
                            // compTbl={BtnConfirmSS()}

                            />
                    </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                        </Col>
                        <Col md="4">
                        </Col>
                        <Col md="4">
                            <label>Importe total $</label><label>{totalMovimiento}</label> 
                        </Col>
                    </Row>
                    </>
                }
        
            </Modal.Body>
            }
            {
                isDetailPet ?<Modal.Footer>
                <Buttons 
                btnCancel ={true}  btnCancelAction  = {e => onCerrarMNS()} btnCancelText="Cancelar" btnCancelShowIcon={true} 
                /> 
                
                </Modal.Footer>
                :
                <Modal.Footer>    
                
                <Buttons 
                btnAdd ={movPetition === 0} btnAddAction  = {(e) => saveNuevaSolicitud(false,movPetition)} btnAddText="Guardar" btnAddShowIcon={true} btnAddIconClass="fa fa-save"
                btnSave ={true} btnSaveAction  = {(e) => saveNuevaSolicitud(true,movPetition)} btnSaveText="Guardar y autorizar mov" btnSaveShowIcon={true} 
                btnCancel ={true}  btnCancelAction  = {e => onCerrarMNS()} btnCancelText="Cancelar" btnCancelShowIcon={true} 
                /> 


            
            </Modal.Footer>
            }
            
            
        </Modal>
            
        </Fragment>
    );
};

export default ModalNuevoMovimiento;