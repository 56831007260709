import React,{useEffect} from 'react';
import GeneralTable from '../GeneralTable';
import {columnsDisponibilidad,hiddenColumns} from './resources'
import Filter from './Filter'
import Buttons from '../Buttons/Buttons';
import DropZone from './DropZone'
// import ModalRespuesta from '../ModalRespuestaLayout/ModalRespuestaLayout'

const Disponibilidadsaldos = ({
    typeTab,
    dataDisponibilidad,
    changeDate,
    changeSelect,
    empresasBanco,
    allCuentas,
    downloadHelpDisp,
    optionEmpresa,
    optionCuenta,
    btnSearch,
    isShowDragAndDrop,
    showModalUpSaldo,
    closeDragAndDrop,
    select_File,
    selected_File,
    uploadLayout,
    showModalResponse,
    onCerrar,
    xlsDataRespons,
    xlsDownloadRespons,
    downSaldosExcel,
    totSaldo,
    handleChangeChkAll,
    chckAll,
    handleChangeCbx,
    saldoParcial,
    

}) => {

    const BtnConfirmSS = () =>{
    console.log(typeTab)
      
            return <div className="row">
          
            <div className="col-md-1">
            <Buttons btnAyuda={true} btnAyudaAction={() => downloadHelpDisp(true)} btnAyudaText={'Ayuda'} btnAyudaShowIcon={true}/>
            </div>
        
            <div className="col-md-1">
            <Buttons btnAdd={true} btnAddAction={() => showModalUpSaldo()} btnAddText={'Cargar nuevo saldo'} btnAddShowIcon={true} btnAddIconClass='fa fa-upload'/>
            </div>
            <div className="col-md-1">
            <Buttons btnAdd={true} btnAddAction={() => downloadHelpDisp(false)} btnAddText={'Descarga saldos'} btnAddShowIcon={true} btnAddIconClass='fa fa-download'/>
            </div>
            {/* <div className="col-md-3"></div>
            <div className="col-md-3"><span>{saldoParcial}</span></div>
            <div className="col-md-3"><span>{totSaldo}</span></div> */}

            </div>
    } 
   
    return (
    <>
        {
            isShowDragAndDrop ? 
            <>
            <div style={{border:'solid 1px #000'}}>
            </div>
            <br />
            <div className="row">
                    <div className="col-lg-2">
                    </div>
                    <div className="col-lg-8">
                        <div className="text-center" >
                                <label className="Span__titleDrop"> Saldos en cuentas </label>
                                <DropZone 
                                selected_File={selected_File}
                                select_File={select_File}
                                />
                        </div>
                        <br/>
                        <div>
                        <Buttons
                            btnSave={true} btnSaveAction={() => uploadLayout()} btnSaveText={"Subir layout"}  btnSaveShowIcon={true} btnSaveIconClass={'fa fa-upload'}
                            // btnDelete={!dataSaved.isSaved} btnDeleteAction={clickDelete} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                            btnCancel={true} btnCancelAction={() => closeDragAndDrop()} btnCancelText={" Cancelar"} btnCancelShowIcon={true} />

                        </div>
                            
                    </div>
                    <div className="col-lg-2">
                    </div>
            </div>
            </>
            :
            <div>
                        <div className="form-group" >
                        <Filter 
                        typeFilter={typeTab}
                        changeSelect={changeSelect}
                        changeDate={changeDate}
                        empresasBanco={empresasBanco}
                        allCuentas={allCuentas}
                        optionCuenta={optionCuenta}
                        optionEmpresa={optionEmpresa}
                        btnSearch={() => btnSearch(typeTab)}

                        />
                        </div>
                        <div>
                            <GeneralTable
                                        // columns={columnsImpuestos(handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr,idTipoFormato,showModalCargaCP)}
                                        columns={columnsDisponibilidad(typeTab,handleChangeChkAll,chckAll,handleChangeCbx)}
                                        data={dataDisponibilidad}
                                        // action={undefined}
                                        doubleClick={(original,values) => {}} 
                                        // isNotPagination={true}
                                        // pageSize={500}
                                        isCpmpBtn={true}
                                        // // compnnt={idTipoFormato === 2 && showTblSSM  ?  BtnConfirmSS : <div></div>}
                                        compnnt={BtnConfirmSS}
                                        hiddenColumnsIDs={
                                            hiddenColumns(typeTab)
                                        }
                                        // hiddenColumnsIDs={
                                        //     hiddenColumns(idTipoFormato === 2 && showTblSSM  ? 4 : idTipoFormato === 3 &&  showTblTesoreria ? 5 : idTipoFormato)
                                        // }
                                        
                                        // isCompTable={true}
                                        // compTbl={BtnConfirmSS()}

                                        />
                        </div>
                        <div className="container" style={{border: '1px solid blue'}}>
                            <div>Calculadora</div>
                            <div className="row" >
                                <div className="col-md-6">
                                    <span><label>Total seleccionado : &nbsp; </label>{saldoParcial}<span></span></span>
                                </div>
                                <div className="col-md-6">
                                <span><label>Total saldo : &nbsp; </label>{totSaldo}<span></span></span>
                                </div>
                            </div>
                        </div>
            </div>
        }
    </>
    );
};

export default Disponibilidadsaldos;