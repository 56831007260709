import React,{Fragment} from 'react';
import { Modal, Button, Accordion, Container, Row, Col } from 'react-bootstrap'
import Buttons from '../../Buttons/Buttons';

const ModalDisponibilidadSaldos = ({
    show              ,
    onCerrarDispSald  , 
    dataSaldo         ,
}) => {
    return (
        <Fragment>
            <Modal  size="lg" show={show} animation={false} backdrop="static" aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header className="header">
            <Row>
            <Col md="9" className="text-center">
                <label>Disponibilidad de saldo</label>
            </Col>

            </Row>

            </Modal.Header>
            <Modal.Body>
            <Row>
            <Col md="6">
                <label>Empresa origen</label>
            
            </Col>
            <Col md="6">
                <span>{dataSaldo.cuentaOrigen}</span>
            
            </Col>
            </Row>
            <Row>
            <Col md="6">
                <label>Banco origen</label>
            
            </Col>
            <Col md="6">
            <span>{dataSaldo.bancoOrigen}</span>

            
            </Col>
            </Row>
            <Row>
            <Col md="6">
                <label>Cuenta origen</label>
            
            </Col>
            <Col md="6">
            <span>{dataSaldo.cuentaOrigen}</span>

            
            </Col>
            </Row>
            <Row>
            <Col md="6">
                <label>Saldo</label>
            
            </Col>
            <Col md="6">
                <span>{dataSaldo.montoSolicitado}</span>
            
            </Col>
            </Row>
            <Row>
            <Col md="6">
                <label>Fecha actualización</label>
            
            </Col>
            <Col md="6">
                <span>{dataSaldo.fechaAct ? dataSaldo.fechaAct.split('T')[0]:''}</span>
            
            </Col>
            </Row>
            </Modal.Body>
            <Modal.Footer>
            <Row>
            <Col md="6"></Col>
            <Col md="6">

                <Buttons 
                btnCancel ={true}  btnCancelAction  = {e => onCerrarDispSald()} btnCancelText="Aceptar" btnCancelShowIcon={true} btnCancelIconClass="fa fa-check"
                />
            </Col>
            </Row>
            </Modal.Footer> 
            </Modal>
        </Fragment>

    );
};

export default ModalDisponibilidadSaldos;