import React,{Fragment} from 'react';
import { Modal, Button, Accordion, Container, Row, Col } from 'react-bootstrap'
import Buttons from '../../Buttons/Buttons';
import GeneralTable from '../../../Components/GeneralTable';
import {columns,hiddenColumns,columnsProgramMov} from '../../../Components/MovimientosEntreCompanias/resources'
import Filter from '../../../Components/MovimientosEntreCompanias/Filter'
import DropZonePdf from '../DropZone/DropZoneFile'
import DropZoneZip from '../DropZone/DropZoneZip'
import DropZoneXls from '../DropZone'

const ModalActionMovePetition = ({
    show              ,
    typeAction        ,
    onCancel   ,
    typeTab,
    typeColumns,
    relPetitionMovAll,
    relPetitionMov,
    idMovimiento,
    handleCbxMov,
    showDetailMov,
    showModalDeleteMov,
    loadEvidence,
    numTab    ,
    selected_Pdf,
    select_Pdf,
    uploadEvidence,
    changeInfoEvidence,
    referenceEvidece,
    dateEvidence,
    importEvidence,
    uploadEvidenceMasive,
    selected_ExcelFormat,
    select_ExcelFormat,
    selected_Zip,
    select_Zip,
    cancelMov,
    selectTypeCancel,
    isCheckedTypeCancelMov,
    isCheckedTypeCancelMovPet


}) => {
    return (
        <Fragment>
        <Modal  size="lg" show={show} animation={false} backdrop="static" aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header className="header">
        <Row>
        <Col md="11">
            <label>{idMovimiento} acciónes: typeTab </label><label>{typeTab}</label>
        </Col>
        <Col md="1">
            <div
            className=''
            onClick={e => onCancel()}
            data-placement="top"

            >
                <i className="fa fa-close" style={{color: '#888'}} alt=""></i>

            </div>
        {/* <Buttons 
            btnCancel ={true}  btnCancelAction  = {e => onCancel()} btnCancelText="Cancelar" btnCancelShowIcon={true} btnCancelIconClass={"fa fa-close"}
            /> */}
        </Col>

        </Row>

        </Modal.Header>
        <Modal.Body>
            {
                typeAction === 1 && 
                <Row>
                <Col md="12">
                        {/*
                        <div className="form-group" >
                                            <Filter 
                                                typeFilter={typeTab} 
                                                tipoMovimiemto={tipoMovimiemto}
                                                empresas={empresas}
                                                changeSelectSolicitudes={changeSelectSolicitudes}
                                                changeDateSolicitud={changeDateSolicitud}
                                                btnSearch={() => btnSearch(numTab)}
                                                optionTipoMovimiemto   ={optionTipoMovimiemto}
                                                optionEmpresaOrigen    ={optionEmpresaOrigen}
                                                optionEmpresaDestino   ={optionEmpresaDestino}
                                                empresasOrigenDestino={empresasOrigenDestino}
                                                empresasBanco={empresasBanco}

                                            />
                            </div> 
                            */}
                        <GeneralTable
                                // columns={columnsImpuestos(handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr,idTipoFormato,showModalCargaCP)}
                                columns={columnsProgramMov(typeTab,handleCbxMov,showDetailMov,showModalDeleteMov,loadEvidence,9,typeAction)}
                                data={relPetitionMov}
                                // action={undefined}
                                doubleClick={(original,values) => {}} 
                                // isNotPagination={true}
                                // pageSize={500}
                                // isCpmpBtn={true}
                                // // compnnt={idTipoFormato === 2 && showTblSSM  ?  BtnConfirmSS : <div></div>}
                                // compnnt={BtnConfirmSS}
                                hiddenColumnsIDs={
                                    hiddenColumns(typeColumns)
                                }
                                // isCompTable={true}
                                // compTbl={BtnConfirmSS()}

                                />
                </Col>
            </Row>
            }
            {
                typeAction === 2  && 
                <>
                <Row>
                    <Col md='12'>
                    <div className="text-center" >
                    <label className="Span__titleDrop"> Subir evidencia y referencia </label><br /><br />

                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <label>Rerencia</label>
                        <input 
                        className="form-control" 
                        type='text' 
                        name="referencia" 
                        onChange={(event,name) => changeInfoEvidence(event,name)}  
                        value={referenceEvidece}
                        />

                    </Col>
                    <Col md="4">
                    <label>Fecha de movimiento</label>
                    <input 
                        className="form-control" 
                        type='date' 
                        name="fechaMov" 
                        onChange={(event,name) => changeInfoEvidence(event,name)}  
                        value={dateEvidence}
                        />

                    </Col>
                    <Col md="4">
                    <label>Importe</label>

                    <input 
                        className="form-control" 
                        type='number' 
                        name="importeEvidencia" 
                        onChange={(event,name) => changeInfoEvidence(event,name)}  
                        value={importEvidence}
                        />
                    </Col>
                    
                </Row>
                <Row>
                <Col md="12">
                <div className="text-center" >
                                <DropZonePdf 
                                selected_File={selected_Pdf}
                                select_File={select_Pdf}
                                />
                        </div>
                        <br/>
                </Col>
                </Row>
                </>
                

            }   
            {
                typeAction === 3  && <>
                <Row style={{padding:'30px'}}>
                <Col md="12" style={{textAlign:'center'}}>
                  <div>Este movimiento y los movimientos relacionados seran cancelados, </div>  
                  <div>¿como lo quieres realizar? </div>
                </Col>
                </Row>
                <Row style={{padding:'10px'}}>
                <Col md="10">
                  <div>Cancelar el o los movimientos y cancelar sus solicitudes</div>  
                  <div>Esto implica que la información ya no sera visible</div>
                </Col>
                <Col md="2">
                <label className="radio_movProg">
                  <input 
                  checked={isCheckedTypeCancelMov}
                  type="radio" 
                  name="optionMovPet" 
                  value="5" 
                  onChange={(e) => {selectTypeCancel(e)}}
             
                  />
                </label>
                </Col>
                </Row>
                <Row style={{padding:'10px'}}>
                <Col md="10">
                  <div>Cancelar el o los movimientos y cambiar las solicitudes a no autorizado </div>  
                  <div>Esto implica que las solicitudes regresaran al monitor para poder ser reagrupadas </div>

                </Col>
                <Col md="2">
   
                    <label className="radio_movProg">
                        <input 
                        checked={isCheckedTypeCancelMovPet}
                        type="radio" 
                        name="optionMov" 
                        value="1"
                        onChange={(e) => {selectTypeCancel(e)}}
                        />
                    </label>
                </Col>
                </Row>
                </>

            } 
            {
                typeAction === 4 &&<>
                <Row>
                    <Col md='12'>
                    <div className="text-center" >
                    <label className="Span__titleDrop"> Seleccionar referencias en formato Excel</label><br /><br />

                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col md='12'>
                    <div className="text-center" >
                                <DropZoneXls 
                                selected_File={selected_ExcelFormat}
                                select_File={select_ExcelFormat}
                                />
                        </div>
                        <br/>
                        
                    </Col>
                </Row>
                <Row>
                    <Col md='12'>
                    <div className="text-center" >
                    <label className="Span__titleDrop"> Seleccionar evidencias en formato Zip</label><br /><br />
                    <DropZoneZip 
                                selected_File={selected_Zip}
                                select_File={select_Zip}
                                />
                    </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Buttons
                            btnSave={true} btnSaveAction={() => uploadEvidenceMasive()} btnSaveText={"Subir"}  btnSaveShowIcon={true} btnSaveIconClass={'fa fa-upload'}

                            // btnDelete={!dataSaved.isSaved} btnDeleteAction={clickDelete} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                            // btnCancel={true} btnCancelAction={() => closeDragAndDropEvidence()} btnCancelText={" Cancelar"} btnCancelShowIcon={true} 
                        />

                    </Col>
                </Row>
                </>
            } 


        

        
        </Modal.Body>
        <Modal.Footer>
        <Row>
        <Col md="10"></Col>
        <Col md="1">
            {
                typeAction === 2  && 
                <Buttons 
                btnSave={true} 
                btnSaveAction={() => uploadEvidence()} 
                btnSaveText={"Carga evidencia"}  
                btnSaveShowIcon={true} 
                btnSaveIconClass={'fa fa-upload'}
                />
            }
            {
                typeAction === 3  && 
                <Buttons 
                btnSave={true} 
                btnSaveAction={e => cancelMov(idMovimiento)} 
                btnSaveText={"Aceptar " + idMovimiento}  
                btnSaveShowIcon={true} 
                />
            }
       
        </Col>
        <Col md="1">

            <Buttons 
                btnCancel ={true}  
                btnCancelAction  = {e => onCancel()} 
                btnCancelText="Cancelar" 
                btnCancelShowIcon={true} 
                btnCancelIconClass={"fa fa-close"}
            />
        </Col>
        </Row>
        </Modal.Footer> 
        </Modal>
    </Fragment>
    );
};

export default ModalActionMovePetition;