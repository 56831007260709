import React, {useRef} from 'react';
import { Modal, Button, Accordion, Container, Row, Col } from 'react-bootstrap'
import Buttons from '../../../Components/Buttons/Buttons';
// import { Modal, Button, Accordion, Container, Row,  Col } from 'react-bootstrap'
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'


import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import iconOk from './icons/icon-ok-big.png'
import iconFail from './icons/icon-fail.png'
import iconWarning from './icons/icon-warning.ico'
import './styles.css'

    const GenerarRegistro = ({ index, registro,errorFila }) => {

    const { agregadoABaseDeDatos, celdas, celdasConErrores, idFilaXLS, mensaje } = registro
    return (
            <Accordion.Item  eventKey={index}>
                <Accordion.Header>
                <Container fluid>
                            <Row>
                                <Col lg="1" className="text-left" style={{padding: "0 5px"}}>
                                    {`Fila: ${idFilaXLS}`}
                                </Col>
                                <Col lg="7" className="text-left">
                                    <Image src={celdasConErrores ? iconFail : iconOk} roundedCircle className="icon-medium" />
                                    {celdasConErrores ? errorFila ? mensaje :  " Algunas celdas contienen errores (ver detalle)."  : " Registro sin errores (ver detalle)."}
                                </Col>
                                <Col lg="4" className="text-left">
                                    <Image src={agregadoABaseDeDatos ? iconOk : iconWarning} roundedCircle className="icon-medium icon-agregadoABD" />
                                    {` ${errorFila  ? 'El registro no fue procesado': mensaje}`}
                                </Col>
                            </Row>
                        </Container>
                </Accordion.Header>
                <Accordion.Body>
                        <Container fluid>
                            <Row className="header-detalle">
                                <Col lg="1" className="text-center"></Col>
                                <Col lg="2" className="text-center">
                                    Celda
                                    </Col>
                                <Col lg="4" className="text-center">
                                    Valor
                                    </Col>
                                <Col lg="5" className="text-center">
                                    Errores
                                </Col>
                            </Row>
                            {Array.isArray(celdas) &&
                                celdas.map((e,i) => {
                                    const { Celda, Valor,Nombre, Errores, ConErrores } = e
                                    return (
                                        <Row key={i} className='cell-detalle'>
                                            <Col lg="1" className="text-left">
                                                <Image src={ConErrores ? iconFail : iconOk} roundedCircle className="icon-small" />
                                            </Col>
                                            <Col lg="2" className="text-center">
                                                {Celda}
                                            </Col>
                                            <Col lg="4" className="text-left">
                                                {
                                                Nombre === 'Monto' || Nombre === 'MONTO' ? 
                                                    Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(Valor) :
                                                Nombre === 'FORMATO' ?
                                                    Valor.split('-')[1] === 'NA' ? Valor.split('-')[0] : Valor.split('-')[1]  : 
                                                Valor 
                                                }
                                            </Col>
                                            <Col lg="5" className="text-left">
                                                {Array.isArray(Errores) &&
                                                    Errores.map((er,j) => {
                                                        return (<div key={j} style={{ display: "block" }}> {er} </div>)
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                    )
                                })


                            }
                        </Container>
                </Accordion.Body>
            </Accordion.Item>
    );
}

const ModalRespuesta = ({
    show,
    onCerrar,
    xlsDataRespons,
    xlsDownloadRespons,
    idTipoFormato
    
}) => {
    const { filas, idPoliza, conErrores, mensaje,errorFila } = xlsDataRespons
    const tableRef = useRef(null);
    // const hoy = new Date(Date.now());
    const fileType = 'aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = idTipoFormato === 1 ? 'ErroresEF' : 'ErroresSS'

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(xlsDownloadRespons)
        const wb = {Sheets:{'data':ws},SheetNames:['data']}
        const excelBuffer = XLSX.write(wb,{bookType:'xlsx', type:'array'})
        const data = new Blob([excelBuffer],{type:fileType})
        FileSaver.saveAs(data,fileName + fileExtension)

    }

    return (
        <>
          <Modal  size="lg" show={show} animation={false} backdrop="static"  aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header className="header">
                <Container>
                    {
                        <Row>
                        <Col md="1" className="text-center">
                            <Image src={conErrores ? iconFail : iconOk} roundedCircle className="icon-medium" />
                        </Col>
                        {/* <Col md="3" className="text-left">
                            {archivoProcesado}
                        </Col> */}
                        <Col md="11" className="text-left">
                            {mensaje}
                        </Col>
                        </Row>
                    }
                </Container>
            </Modal.Header>
            <Modal.Body>
                {/* <span>Datos</span> */}
                        <Accordion  ref={tableRef} defaultActiveKey="0">
                            {Array.isArray(filas) &&
                                filas.map((e, i) => {
                                    return (<GenerarRegistro key={i} index={i} registro={e} errorFila={errorFila}></GenerarRegistro>)
                                })
                                }
                        </Accordion>
            </Modal.Body>
            <Modal.Footer>
            <Buttons 
            btnAdd ={true} btnAddAction  = {(e) => exportToExcel()} btnAddText="Descargar excel" btnAddShowIcon={true} btnAddIconClass="fa fa-file-excel-o"
            btnCancel ={true}  btnCancelAction  = {e => onCerrar(conErrores)} btnCancelText="Cerrar" btnCancelShowIcon={true} btnCancelIconClass="fa fa-close"
        /> 
        <Buttons 
          
            // btnAddIconClass="fa fa-search"
        /> 
{/*                 
                <Button onClick={(e) => exportToExcel()}>ExportExcel</Button>
                <Button variant="primary" size="mini" onClick={e => onCerrar(conErrores)}>Cerrar</Button> */}
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default ModalRespuesta;
