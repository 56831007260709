import Buttons from '../Buttons/Buttons';
import React, {useEffect, useState} from 'react';

const hiddenColumns = (type) => {
    // console.log(type)
    switch(type) {
        case 1:
            return ['idSolicitud','idTipoImpuesto','btnLoadPdf','idEstatus', 'registroPatronal','pathLineaCaptura','folio_SUA','Format','coment','dataStB','bimestrePago','dataStA','ba_nco','pagadora','formaPago']
        case 2:
            return ['idSolicitud','idTipoImpuesto','btnLoadPdf','idEstatus','marca', 'pathLineaCaptura','coment','dataStB','folio_SUA','Format','bimestrePago','dataStA','ba_nco','pagadora','formaPago']
        case 3:
            return ['idSolicitud','idTipoImpuesto','registroPatronal','btnLoadPdf','idEstatus','marca', 'pathLineaCaptura','idReferenc','chckAllMonitorSS','prioritario','actionUpload','txtStatus','realizadoPor','fechaVencimiento','dataStA','Fecha_pago','coment']
        case 4:
            return  ['idSolicitud','idTipoImpuesto','chckAllMonitor','estatus','marca','loadFile','btnLoadPdf','idEstatus','idReferenc','folio_SUA','Format', 'pathLineaCaptura','chckAllMonitorSS','prioritario','actionUpload','actionStatus','txtStatus','pagadora','realizadoPor','fechaPago','fechaModificacion','ba_nco','pagadora','formaPago','idPeriodo'] 
        case 5:
            return  ['idSolicitud','fechaVencimiento','registroPatronal','idTipoImpuesto','chckAllMonitor','estatus','marca','idReferenc','folio_SUA','Format','loadFile','btnLoadPdf','idEstatus', 'pathLineaCaptura','chckAllMonitorSS','prioritario','pagadora','actionUpload','actionStatus','txtStatus','realizadoPor','fechaPago','fechaModificacion','ba_nco','pagadora','formaPago'] 
            case 6:
                return  ['idSolicitud','idTipoImpuesto','chckAllMonitor','estatus','registroPatronal','marca','loadFile','fechaVencimiento','btnLoadPdf','idEstatus','idReferenc','folio_SUA','Format', 'pathLineaCaptura','chckAllMonitorSS','prioritario','actionUpload','actionStatus','txtStatus','pagadora','realizadoPor','fechaPago','fechaModificacion','ba_nco','pagadora','formaPago'] 
        default:
            return  [] 
    } 
}
const inicializa = (type) => {
    switch(type) {
        case 1:
            return{
                title: 'Pago de impuestos',
                breadcrum: [{ nombre: 'Contabilidad' }, { nombre: 'Pago de impuestos' }]
            }           
            case 2:
                return{
                    title: 'Pago de impuestos',
                    breadcrum: [{ nombre: 'Seguridad social' }, { nombre: 'Pago de impuestos' }]
                }
            case 3: 
            return{
                title: 'Pago de impuestos',
                breadcrum: [{ nombre: 'Tesorería' }, { nombre: 'Pago de impuestos' }]
            }
                
                
            default: 

    }
   
}
const columnsLote = [
    {
        Header: "idError",
        accessor: 'consecutivoError',
    },
    {
        Header: "Mensaje Error",
        accessor: 'mensaje',
    },
    
]

const columnsImpuestos = (handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr,idTipoFormato,showModalCargaCP,showReason) =>{
    
    const CompontText = ({rowN,value}) => (
        <>
            {rowN.lote ? rowN.idEstatus === 5 ? 
            <span>{value}</span> : 
            <span style={rowN.idPrioritario ?{color:'red'}:{color:'blue'}}>{value}</span> : <></>}
            </>
            )
    return [
        {
            id:'idSolicitud',
            Header:'ID Solicitud',
            accessor:'idSolicitud'
        },
        
        { 
                id: "chckAllMonitor",
                // Header: "CheckAll",
                Header: () => {
                    return <div className="border-checkbox-section">
                        <div className="border-checkbox-group border-checkbox-group-success">
                        {/* <div className="border-checkbox-group border-checkbox-group-success" style={{padding: '0 0 0 42%'}}> */}
                        <input
                                onChange={e => handleChangeChkAllM(e.target.checked)}
                                checked={chckAllM}
                                className="border-checkbox"
                                type="checkbox"
                                id={"chkAllTblMonitor"}
                            />
                            <label className="border-checkbox-label"
                                htmlFor={"chkAllTblMonitor"}></label>
                        </div>
                    </div>
    
                },
                Cell: k =>(
                    <div className="border-checkbox-section">
                        <div className="border-checkbox-group border-checkbox-group-success">
                            <input
                                onChange={(e) => handleChangeCbxM(k.row.original, e.target.checked, k)}
                                checked={k.row.original.check}
                                className="border-checkbox"
                                type="checkbox"
                                id={"chkSolM" + k.row.original.idSolicitud}
                            />
                            <label className="border-checkbox-label"
                                htmlFor={"chkSolM" + k.row.original.idSolicitud}></label>
                        </div>
                    </div>)
        },
        {
            id:'loadFile',
            Header: "LCpdf",
            Cell: k =>{
            const CompntSt = () => {
                switch(k.row.original.idEstatus){
                    case 1:
                        return <div>
                        <label style={{cursor:'pointer',color:'yellow'}} onClick={() =>uploadPdf(k.row,k)}><i class="fa fa-upload fa-2x"></i></label>
                        <input id={"myinput"+k.row.original.idSolicitud} type="file" accept='.pdf' hidden  onChange={(e) => actionChenge(e,k.row,k)} />   
                    </div>
                    case 2:
                        return <label title='Linea de captura' style={{cursor:'pointer',color:'red'}} onClick={() => showPDF(k.row.original.pathLineaCaptura,true)}><i class="fa fa-file-pdf-o fa-2x"></i></label>

                    case 3:
                        return <label title='Linea de captura autorizada' style={{cursor:'pointer',color:'red'}} onClick={() => showPDF(k.row.original.pathLineaCaptura,true)}><i class="fa fa-file-pdf-o fa-2x"></i></label> 
                    case 4:
                        if(idTipoFormato === 3)
                    return <label title='Solicitud rechazada' style={{color:'orange'}} onClick={() => {}}><i class="fa fa-window-close-o fa-2x"></i></label>
                        else
                        return <div>
                        <label style={{cursor:'pointer',color:'yellow'}} onClick={() =>uploadPdf(k.row,k)}><i class="fa fa-upload fa-2x"></i></label>
                        <input id={"myinput"+k.row.original.idSolicitud} type="file" accept='.pdf' hidden  onChange={(e) => actionChenge(e,k.row,k)} />   
                    </div>

                    case 5:
                        return <label title='3' style={{cursor:'pointer',color:'red'}} onClick={() => showPDF(k.row.original.pathLineaCaptura,true)}><i class="fa fa-file-pdf-o fa-2x"></i></label> 

                        // return <label title='5' style={{color:'green'}} ><i class="fa fa-file-text-o fa-2x"></i></label> 
                    // case 6:
                    //         return <label style={{color:'red'}}><i class="fa fa-ban fa-2x"></i></label>
                    default:
                       return <></>
                }

            } 


             return  <CompntSt />
            },
            accessor: 'load',

        },
        {
            Header: () =>( <label>Lote&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.lote} />),
            accessor: 'lote',
        },      
        {
            id:"actionStatus",
            Header: () =>(
                <div>estatuspago
                    {
                        // canBeAuthorized ? <div>
                        // <div className="border-checkbox-section" style={{marginLeft:'20px'}}>
                        // <div className="border-checkbox-group border-checkbox-group-success">
                        //     <input
                        //         onChange={(e) => handleChangeCbxAutr(e)}
                        //         checked={isCbxautorized}
                        //         className="border-checkbox"
                        //         type="checkbox"
                        //         id={"checkAutorizate"}
                        //     />
                        //     <label className="border-checkbox-label"
                        //         htmlFor={"checkAutorizate"}></label>
                        // </div>
                        // </div>

                        // </div> : 
                        // <></>
                    }

                </div>
            ),
            Cell: k =>{
                // k.row.allCells[9].value === 2 ? 
            const CompntSt = () => {
                switch(k.row.original.idEstatus){
                    case 2:
                        return <label style={{color:'blue'}} ><i class="fa fa-square-o fa-2x"></i></label> 
                        case 3:

                            return <label title={idTipoFormato === 3 ? 'Editar' : 'Autorizado'} style={idTipoFormato === 3 ? {color:'green', cursor: 'pointer'} :{color:'green'}} onClick={(e) =>
                                idTipoFormato === 3 ? showModalCargaCP(k.row) : console.log('ok')
                                } >
                                <i class="fa fa-check-square-o fa-2x"></i></label> 
                    case 4:
                     return <div>
                        <label title={'solicitud rechazada'} style={{color:'orange'}} onClick={() => showReason(k.row.original.motivoRechazo,k.row.original.idSolicitud)}><i class="fa fa-ban fa-2x"></i></label>
                        </div> 
                    case 5:
                        // return <label title='pagado' style={{color:'green',cursor:'pointer'}} onClick={() => showPDF(k.row.original.idSolicitud,true,true,true)}><i class="fa fa-file-text-o fa-2x"></i></label> 
                        return <label title='pagado' style={{color:'green',cursor:'pointer'}} onClick={() => showPDF(k.row.original.pathComprobantePago,true,true)}><i class="fa fa-file-text-o fa-2x"></i></label> 
                    default:
                        return <>
                           <div>
                                <label style={{color:'red'}}><i class="fa fa-ban fa-2x"></i></label>
                            </div> 
                        </>

                }

            } 


             return  <CompntSt />
            },
            accessor: 'loadA',
        },
        {
            Header: () =>( <label>Registro&nbsp;patronal&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>),
            Cell: k =>(<CompontText rowN={k.row.original}  value={k.row.original.registroPatronal}/>),
            accessor: 'registroPatronal',
        },
        {
            Header: () =>( <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Estatus&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.estatus}/>),
            accessor: 'estatus',
        },
        {
            Header: () =>( <label>Marca&nbsp;</label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.marca}/>),
            accessor: 'marca',
        },
        {
            Header: () =>( <label>RFC&nbsp;</label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.RFC}/>),
            accessor: 'RFC',
        },
        {
            Header: (k) =>{
                return <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{idTipoFormato === 1 ? 'Empresa': idTipoFormato === 1 ? 'Razón_social' : 'Empresa/Razón_social'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            },
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.empresa}/>),
            accessor: 'empresa',
        },
        {
            Header: () =>( <label>Prioritario&nbsp;</label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.prioritario}/>),
            accessor: 'prioritario',
        },
        {
            id:'idTipoImpuesto',
            Header:'ID TipoImpuesto',
            accessor:'idTipoImpuesto'
        },
        {
            // Header: "Tipo impuesto",
            Header: () =>( <label>Tipo&nbsp;impuesto</label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.tipoImpuesto}/>),
            accessor: 'tipoImpuesto',
        },
        {
            id:'impTot',
            Header: () =>( <label>Total&nbsp;</label>),
            Cell:(k) =>{
                let total = '';
                if(k.rows) {
                    if(k.row.original.importeTotal){
                        let v = k.row.original.importeTotal.toString()
                        total = v.split('.')[1] ? "$" +k.row.original.importeTotal : "$" +k.row.original.importeTotal + '.00'; 
                    }
                    
                }
                return <CompontText rowN={k.row.original} value={total}/>
            },
            accessor: 'importeTotal',
        },
        {
            id:'idPeriodo',
            Header: () =>( <label>Periodo&nbsp;</label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.periodo}/>),
            accessor: 'periodo',
        },
        {
            id:'idReferenc',
            Header: () =>( <label>{idTipoFormato === 1 ? 'Formato': 'Folio_sua'}&nbsp;</label>),
            Cell: k => {
                let formatSt = k.row.original.idReferencia;
                if(k.row.original.idReferencia === 'DIF') formatSt = 'CDMX'
                if(k.row.original.idReferencia === 'MEX') formatSt = 'EDOMEX'

                return <CompontText rowN={k.row.original} value={formatSt}/>
            },
            accessor:"idReferencia"
        },   
        {
            
            id:'folio_SUA',
            Header: () =>( <label>folio&nbsp;SUA</label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.folio_SUA} />),
            accessor: 'folio_SUA',
        },
        {
            
            id:'Format',
            Header: () =>( <label>Formato</label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.Format} />),
            accessor: 'Format',
        },
        {
            // Header: "Fecha vencimiento",
            id:'fechaVencimiento',
            Header: () =>( <label>Fecha&nbsp;vencimiento</label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.fechaVencimiento}/>),
            accessor: 'fechaVencimiento',
        },
        {
            // Header: "Linea de captura",
            Header: () =>( <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Linea&nbsp;de&nbsp;captura&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            </label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.lineaCaptura}/>),
            accessor: 'lineaCaptura',
        },
        {
            // Header: "Fecha pago",
            id:'fechaPagoS',
            Header: () =>( <label>Fecha&nbsp;-pago</label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.fechaPagoStr} />),
            accessor: 'fechaPagoStr',
        },
        {
            id:"dataStA",
            Header:"-",
            Cell: k =>{
                // k.row.allCells[9].value === 2 ? 

            const CompntSt = () => {
                if(k.row.original.isRespOk) return  <label style={{color:'blue'}} ><i class="fa fa-check-square-o fa-2x"></i></label>
                else return  <label style={{color:'red'}}><i class="fa fa-window-close-o fa-2x"></i></label>
                

                // switch(k.row.original.isRespOk){
                //     case 2:
                //         return <label style={{color:'blue'}} ><i class="fa fa-check-square-o fa-2x"></i></label> 
                  
                //     default:
                //         return <>
                //            <div>
                //                 <label style={{color:'red'}}><i class="fa fa-window-close-o fa-2x"></i></label>
                //             </div> 
                //         </>

                // }

            } 


             return  <CompntSt />
            },
            accessor: 'loadStA',
        },
        
        {
            id:'coment',
            // Header: "Realizado por",
            Header: () =>( <label>_Comentarios</label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.comentario}/>),
            accessor: 'comentario',
        },
        // {
        //     // Header: "Fecha pago",
        //     id:'fechaPago',
        //     Header: () =>( <label>Fecha&nbsp;pago</label>),
        //     Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.fechaPago} />),
        //     accessor: 'fechaPago',
        // },
        
        
       
        {
            id:'ba_nco',
            Header: () =>( <label>&nbsp;Banco&nbsp;</label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.ba_nco} />),
            accessor: 'ba_nco'
        },
        {
            id:'pagadora',
            Header: () =>( <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pagadora&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.pagadora} />),
            accessor: 'pagadora',
        },
        {
            id:'formaPago',
            Header: () =>( <label>Forma&nbsp;de&nbsp;pago</label>),
            Cell: k => {
                return <CompontText rowN={k.row.original} value={k.row.original.Descripcion === '' ? 'Transferencia' : k.row.original.Descripcion} />
            },
            accessor: 'Descripcion'
        },
        {
            // Header: "Fecha modificación",
            id:'fechaModificacion',
            Header: () =>( <label>Fecha&nbsp;modificación</label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.fechaAct}/>),
            accessor: 'fechaAct',
        },
        
        {
            // Header: "Realizado por",
            Header: () =>( <label>Realizado&nbsp;por</label>),
            Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.realizadoPor}/>),
            accessor: 'realizadoPor',
        },
        {
            id:'idEstatus',
            Header:'ID estatus',
            accessor:'idEstatus'
        },
        {
            id:'pathLineaCaptura',
            Header:'pathLCaptura',
            // Cell: k =>{
            //     return <>
            //     <iframe src={k.row.original.pathLineaCaptura} style="width:100%;height:700px;"  />
            //     </>
            // },
            accessor:'pathLCaptura'
        },
    ]

}


const columnsImpuestosSS = (handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr) =>{

    return [
        {
            id:'idSolicitud',
            Header:'ID Solicitud',
            accessor:'idSolicitud'
        },
        { 
                id: "chckAllMonitorSS",
                // Header: "CheckAll",
                Header: () => (
                    <div className="border-checkbox-section">
                        <div className="border-checkbox-group border-checkbox-group-success">
                        {/* <div className="border-checkbox-group border-checkbox-group-success" style={{padding: '0 0 0 42%'}}> */}
                        <input
                                onChange={e => handleChangeChkAllM(e.target.checked)}
                                checked={chckAllM}
                                className="border-checkbox"
                                type="checkbox"
                                id={"chkAllTblSSMonitor"}
                            />
                            <label className="border-checkbox-label"
                                htmlFor={"chkAllTblSSMonitor"}></label>
                        </div>
                    </div>
    
                ),
                Cell: k =>(
                    <div className="border-checkbox-section">
                        <div className="border-checkbox-group border-checkbox-group-success">
                            <input
                                onChange={(e) => handleChangeCbxM(k.row.original, e.target.checked, k)}
                                checked={k.row.original.check}
                                className="border-checkbox"
                                type="checkbox"
                                id={"chkMSS" + k.row.original.idSolicitud}
                            />
                            <label className="border-checkbox-label"
                                htmlFor={"chkMSS" + k.row.original.idSolicitud}></label>
                        </div>
                    </div>)
        },
        {
            id:"actionUpload",
            Header: "",
            Cell: k =>{
            const CompntSt = () => {
                switch(k.row.original.idEstatus){
                    case 2:
                        return <label style={{cursor:'pointer',color:'red'}} onClick={() => showPDF(k.row.original.pathLineaCaptura,true)}><i class="fa fa-file-pdf-o fa-2x"></i></label>

                    case 3:
                        return <label style={{cursor:'pointer',color:'red'}} onClick={() => showPDF(k.row.original.pathLineaCaptura,true)}><i class="fa fa-file-pdf-o fa-2x"></i></label>
                    case 4:
                    return <label style={{cursor:'pointer',color:'orange'}} onClick={() =>{console.log('Nueva linea de capture')}}><i class="fa fa-window-close-o fa-2x"></i></label>
                    
                    case 5:
                        return <label style={{color:'green'}} ><i class="fa fa-file-text-o fa-2x"></i></label> 
                    case 6:
                            return <label style={{color:'red'}}><i class="fa fa-ban fa-2x"></i></label>
                    default:
                        return <div>
                                <label style={{cursor:'pointer',color:'yellow'}} onClick={() =>uploadPdf(k.row,k)}><i class="fa fa-upload fa-2x"></i></label>
                                <input id={"myinput"+k.row.original.idSolicitud} type="file" hidden  onChange={(e) => actionChenge(e,k.row,k)} />   
                            </div>
                }

            } 


             return  <CompntSt />
            },
            accessor: 'load',

        },
       
        {
            Header: () =>( <label>Lote&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>),
            accessor: 'lote',
        },
        {
            id:"actionStatus",
            Header: () =>(
                <div>
                    {
                        canBeAuthorized ? <div>
                        <div className="border-checkbox-section" style={{marginLeft:'20px'}}>
                        <div className="border-checkbox-group border-checkbox-group-success">
                            <input
                                onChange={(e) => handleChangeCbxAutr(e)}
                                checked={isCbxautorized}
                                className="border-checkbox"
                                type="checkbox"
                                id={"checkAutorizate"}
                            />
                            <label className="border-checkbox-label"
                                htmlFor={"checkAutorizate"}></label>
                        </div>
                        </div>

                        </div> : 
                        <></>
                    }

                </div>
            ),
            Cell: k =>{

            const CompntSt = () => {
                switch(k.row.original.idEstatus){
                    case 2:
                        return <label style={{color:'blue'}} ><i class="fa fa-square-o fa-2x"></i></label> 
                        case 3:
                            return <label style={{color:'green'}} ><i class="fa fa-check-square-o fa-2x"></i></label> 
                    default:
                        return <>
                           <div>
                                <label style={{color:'red'}}><i class="fa fa-ban fa-2x"></i></label>
                            </div> 
                        </>

                }

            } 


             return  <CompntSt />
            },
            accessor: 'loadA',
        },
        {
            id:"txtStatus",
            Header: () =>( <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Estatus&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>),
            accessor: 'estatus',
        },
        {
            id:'_registroPatronal',
            Header: () =>( <label>Registro&nbsp;patronal&nbsp;</label>),
            accessor: 'registroPatronal',
        },
        {
            Header: () =>( <label>RFC&nbsp;</label>),
            accessor: 'RFC',
        },
        {
            Header: () =>( <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Razón&nbsp;social&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>),
            accessor: 'empresa',
        },
        {
            id: "prioritario",
            Header: () =>( <label>Prioritario&nbsp;</label>),
            accessor: 'prioritario',
        },
        {
            id:'idTipoImpuesto',
            Header:'ID TipoImpuesto',
            accessor:'idTipoImpuesto'
        },
        {
            // Header: "Tipo impuesto",
            Header: () =>( <label>Tipo&nbsp;impuesto</label>),
            accessor: 'tipoImpuesto',
        },
        {
            Header: () =>( <label>Total&nbsp;</label>),
            accessor: 'importeTotal',
        },
        {
            Header: () =>( <label>Periodo&nbsp;</label>),
            accessor: 'periodo',
        },
        {
            id:"dataStA",
            Header:"",
            Cell: k =>{

            const CompntSt = () => {
                switch(k.row.original.isRespOk){
                    case 2:
                        return <label style={{color:'blue'}} ><i class="fa fa-check-square-o fa-2x"></i></label> 
                  
                    default:
                        return <>
                           <div>
                                <label style={{color:'red'}}><i class="fa fa-window-close-o fa-2x"></i></label>
                            </div> 
                        </>

                }

            } 


             return  <CompntSt />
            },
            accessor: 'loadStA',
        },
        {
            // Header: "Linea de captura",
            Header: () =>( <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Linea&nbsp;de&nbsp;captura&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            </label>),
            accessor: 'lineaCaptura',
        },
       
        {
            // Header: "Fecha vencimiento",
            Header: () =>( <label>Fecha&nbsp;vencimiento</label>),
            accessor: 'fechaVencimiento',
        },
        {
            id: "bimestrePago",
            Header: () =>( <label>Bimestre&nbsp;pago</label>),
            accessor: 'bimestreIMSS',
        },

        {
            id:"dataStB",
            Header: "",
            Cell: k =>{

            const CompntSt = () => {

                if(k.row.original.isRespOk) 
                return <div><label style={{color:'blue'}} ><i class="fa fa-check-square-o fa-2x"></i></label></div>
                else 
                return <div><label style={{color:'red'}}><i class="fa fa-window-close-o fa-2x"></i></label></div>       
            } 
            return  <CompntSt />
            },
            accessor: 'loadStB',
        },
        {
            id: "Fecha_pago",
            Header: () =>( <label>Fecha&nbsp;pago</label>),
            accessor: 'fechaPago',
        },
        {
            id:'pagadora',
            Header: () =>( <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pagadora&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>),
            accessor: 'pagadora',
        },
        {
            id:'fechaModificación',

            // Header: "Fecha modificación",
            Header: () =>( <label>Fecha&nbsp;modificación</label>),
            accessor: 'fechaAct',
        },
        {
            id:'realizadoPor',
            // Header: "Realizado por",
            Header: () =>( <label>Realizado&nbsp;por</label>),
            accessor: 'realizadoPor',
        },
   
        {
            id:'coment',
            // Header: "Realizado por",
            Header: () =>( <label>Comentarios</label>),
            accessor: 'comentario',
        },
    
    ]

}
const columnsImpuestosTesoreria = (handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr) => {
    return [
        {
            id:'idSolicitud',
            Header:'ID Solicitud',
            accessor:'idSolicitud'
        },
        { 
                id: "chckAllMonitorSS",
                // Header: "CheckAll",
                Header: () => (
                    <div className="border-checkbox-section">
                        <div className="border-checkbox-group border-checkbox-group-success">
                        {/* <div className="border-checkbox-group border-checkbox-group-success" style={{padding: '0 0 0 42%'}}> */}
                        <input
                                onChange={e => handleChangeChkAllM(e.target.checked)}
                                checked={chckAllM}
                                className="border-checkbox"
                                type="checkbox"
                                id={"chkAllTblSSMonitor"}
                            />
                            <label className="border-checkbox-label"
                                htmlFor={"chkAllTblSSMonitor"}></label>
                        </div>
                    </div>
    
                ),
                Cell: k =>(
                    <div className="border-checkbox-section">
                        <div className="border-checkbox-group border-checkbox-group-success">
                            <input
                                onChange={(e) => handleChangeCbxM(k.row.original, e.target.checked, k)}
                                checked={k.row.original.check}
                                className="border-checkbox"
                                type="checkbox"
                                id={"chkMSS" + k.row.original.idSolicitud}
                            />
                            <label className="border-checkbox-label"
                                htmlFor={"chkMSS" + k.row.original.idSolicitud}></label>
                        </div>
                    </div>)
        },
        {
            id:"actionUpload",
            Header: "",
            Cell: k =>{
            const CompntSt = () => {
                switch(k.row.original.idEstatus){
                    case 2:
                        return <label style={{cursor:'pointer',color:'red'}} onClick={() => showPDF(k.row.original.pathLineaCaptura,true)}><i class="fa fa-file-pdf-o fa-2x"></i></label>

                    case 3:
                        return <label style={{cursor:'pointer',color:'red'}} onClick={() => showPDF(k.row.original.pathLineaCaptura,true)}><i class="fa fa-file-pdf-o fa-2x"></i></label>
                    case 4:
                    return <label style={{cursor:'pointer',color:'orange'}} onClick={() =>{console.log('Nueva linea de capture')}}><i class="fa fa-window-close-o fa-2x"></i></label>
                    
                    case 5:
                        return <label style={{color:'green'}} ><i class="fa fa-file-text-o fa-2x"></i></label> 
                    case 6:
                            return <label style={{color:'red'}}><i class="fa fa-ban fa-2x"></i></label>
                    default:
                        return <div>
                                <label style={{cursor:'pointer',color:'yellow'}} onClick={() =>uploadPdf(k.row,k)}><i class="fa fa-upload fa-2x"></i></label>
                                <input id={"myinput"+k.row.original.idSolicitud} type="file" hidden  onChange={(e) => actionChenge(e,k.row,k)} />   
                            </div>
                }

            } 


             return  <CompntSt />
            },
            accessor: 'load',

        },
       
        {
            Header: () =>( <label>Lote&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>),
            accessor: 'lote',
        },
        {
            id:"actionStatus",
            Header: () =>(
                <div>
                    {
                        canBeAuthorized ? <div>
                        <div className="border-checkbox-section" style={{marginLeft:'20px'}}>
                        <div className="border-checkbox-group border-checkbox-group-success">
                            <input
                                onChange={(e) => handleChangeCbxAutr(e)}
                                checked={isCbxautorized}
                                className="border-checkbox"
                                type="checkbox"
                                id={"checkAutorizate"}
                            />
                            <label className="border-checkbox-label"
                                htmlFor={"checkAutorizate"}></label>
                        </div>
                        </div>

                        </div> : 
                        <></>
                    }

                </div>
            ),
            Cell: k =>{

            const CompntSt = () => {
                switch(k.row.original.idEstatus){
                    case 2:
                        return <label style={{color:'blue'}} ><i class="fa fa-square-o fa-2x"></i></label> 
                        case 3:
                            return <label style={{color:'green'}} ><i class="fa fa-check-square-o fa-2x"></i></label> 
                    default:
                        return <>
                           <div>
                                <label style={{color:'red'}}><i class="fa fa-ban fa-2x"></i></label>
                            </div> 
                        </>

                }

            } 


             return  <CompntSt />
            },
            accessor: 'loadA',
        },
        {
            id:"txtStatus",
            Header: () =>( <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Estatus&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>),
            accessor: 'estatus',
        },
        {
            id:'_registroPatronal',
            Header: () =>( <label>Registro&nbsp;patronal&nbsp;</label>),
            accessor: 'registroPatronal',
        },
        {
            Header: () =>( <label>RFC&nbsp;</label>),
            accessor: 'RFC',
        },
        {
            Header: () =>( <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Razón&nbsp;social&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>),
            accessor: 'empresa',
        },
        {
            id: "prioritario",
            Header: () =>( <label>Prioritario&nbsp;</label>),
            accessor: 'prioritario',
        },
        {
            id:'idTipoImpuesto',
            Header:'ID TipoImpuesto',
            accessor:'idTipoImpuesto'
        },
        {
            // Header: "Tipo impuesto",
            Header: () =>( <label>Tipo&nbsp;impuesto</label>),
            accessor: 'tipoImpuesto',
        },
        {
            Header: () =>( <label>Total&nbsp;</label>),
            accessor: 'importeTotal',
        },
        {
            Header: () =>( <label>Periodo&nbsp;</label>),
            accessor: 'periodo',
        },
        {
            id:"dataStA",
            Header:"",
            Cell: k =>{

            const CompntSt = () => {
                switch(k.row.original.isRespOk){
                    case 2:
                        return <label style={{color:'blue'}} ><i class="fa fa-check-square-o fa-2x"></i></label> 
                  
                    default:
                        return <>
                           <div>
                                <label style={{color:'red'}}><i class="fa fa-window-close-o fa-2x"></i></label>
                            </div> 
                        </>

                }

            } 


             return  <CompntSt />
            },
            accessor: 'loadStA',
        },
        {
            // Header: "Linea de captura",
            Header: () =>( <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Linea&nbsp;de&nbsp;captura&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            </label>),
            accessor: 'lineaCaptura',
        },
       
        {
            // Header: "Fecha vencimiento",
            Header: () =>( <label>Fecha&nbsp;vencimiento</label>),
            accessor: 'fechaVencimiento',
        },
        {
            id: "bimestrePago",
            Header: () =>( <label>Bimestre&nbsp;pago</label>),
            accessor: 'bimestreIMSS',
        },

        {
            id:"dataStB",
            Header: "",
            Cell: k =>{

            const CompntSt = () => {

                if(k.row.original.isRespOk) 
                return <div><label style={{color:'blue'}} ><i class="fa fa-check-square-o fa-2x"></i></label></div>
                else 
                return <div><label style={{color:'red'}}><i class="fa fa-window-close-o fa-2x"></i></label></div>       
            } 
            return  <CompntSt />
            },
            accessor: 'loadStB',
        },
        {
            id: "Fecha_pago",
            Header: () =>( <label>Fecha&nbsp;pago</label>),
            accessor: 'fechaPago',
        },
        {
            id:'pagadora',
            Header: () =>( <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pagadora&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>),
            accessor: 'pagadora',
        },
        {
            id:'fechaModificación',

            // Header: "Fecha modificación",
            Header: () =>( <label>Fecha&nbsp;modificación</label>),
            accessor: 'fechaAct',
        },
        {
            id:'realizadoPor',
            // Header: "Realizado por",
            Header: () =>( <label>Realizado&nbsp;por</label>),
            accessor: 'realizadoPor',
        },
   
        {
            id:'coment',
            // Header: "Realizado por",
            Header: () =>( <label>Comentarios</label>),
            accessor: 'comentario',
        },
    
    ]
}

const columnsImpuestosDet = (updatePetition,deletePetition,handleChangeCbx,handleChangeChkAll,chckAll) => {
    const CompontText = ({rowN,value}) => {
       return <>
            {rowN.lote ? rowN.idEstatusPago === 5 ? 
            <span>{value}</span> : 
            <span style={rowN.idPrioritario ?{color:'red'}:{color:'blue'}}>{value}</span> : <></>}
            </>
    }
return [
    {
        id:'idSolicitudPago',
        Header:'ID SolicitudPago',
        accessor:'idSolicitudPago'
    },
    {
        id:'idDetalle',
        Header:'ID detalle',
        accessor:'idSolicitudPagoDetalle'
    },
     { 
            id: "chckAllDet",
            // Header: "CheckAll",
            Header: () => (
                <div className="border-checkbox-section">
                    <div className="border-checkbox-group border-checkbox-group-success">
                    {/* <div className="border-checkbox-group border-checkbox-group-success" style={{padding: '0 0 0 42%'}}> */}
                    <input
                            onChange={e => handleChangeChkAll(e.target.checked)}
                            checked={chckAll}
                            className="border-checkbox"
                            type="checkbox"
                            id={"chkAllTblDetalle"}
                        />
                        <label className="border-checkbox-label"
                            htmlFor={"chkAllTblDetalle"}></label>
                    </div>
                </div>

            ),
            Cell: k =>(
                <div className="border-checkbox-section">
                    <div className="border-checkbox-group border-checkbox-group-success">
                        <input
                            onChange={(e) => handleChangeCbx(k.row.original, e.target.checked, k)}
                            checked={k.row.original.check}
                            className="border-checkbox"
                            type="checkbox"
                            id={"chkAPlicarDet" + k.row.original.idSolicitudPagoDetalle}
                        />
                        <label className="border-checkbox-label"
                            htmlFor={"chkAPlicarDet" + k.row.original.idSolicitudPagoDetalle}></label>
                    </div>
                </div>)
    },
    {
        Header: "Lote",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.lote} />),

        accessor: 'lote',
    },
    {
        Header: "Marca",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.marca} />),
        accessor: 'marca',

    },
    {
        id:'idPrioritario',
        Header: "IDPrioritario",
        accessor: 'idPrioritario',
    },
    {
        Header: "Prioritario",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.prioritario} />),
        accessor: 'prioritario',
    },
    {
        Header: "RFC",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.rfc} />),
        accessor: 'rfc',
    },
    {
        Header: "Empresa",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.empresa} />),
        accessor: 'empresa',
    },
    {
        id: 'idTipoImpuesto',
        Header: "IDTIPOIMPUESTO",
        accessor: 'idTipoImpuesto',
    },
    {
        Header: "TIPO_IMPUESTO",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.tipoImpuesto} />),
        accessor: 'tipoImpuesto',
    },
    {
        id: 'idDetTipoImpuesto',
        Header: "IDDETALLE",
        accessor: 'idDetTipoImpuesto',
    },
    {
        Header: "Detalle",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.detalle} />),
        accessor: 'detalle',
    },
    {
        Header: "Monto",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.importeDetalle} />),
        accessor: 'importeDetalle',
    },
    {
        Header: "Mes",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.mes} />),
        accessor: 'mes',
    },
    {
        Header: "Anio",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.anio} />),
        accessor: 'anio',
    },
    {
        id:'idReferencia',
        Header:'Formato',
        Cell: k =>{
            let format = "";
            // switch(k.row.original.idReferencia){
            //     case'MEX':
            //         format = 'EDOMEX'
            //         break;
            //     case'Federales':
            //         format = 'Federales'
            //         break;
            //     case'DIF':
            //         format = 'CDMX'
            //         break;
            //     case'NLE':
            //     format = 'NLE'
            //         break;
            //     default:
            //     format = ''


            // }
            // return <div>{format}</div>
        return <CompontText rowN={k.row.original} value={k.row.original.idReferencia} />

        },
        accessor:'idReferencia'
    
    },
    {
        id:'multiRow',
        Header: "multiRow",
        accessor: 'bandera',
    },
    {
        id:'actualiza',
        Header: "Actualizar",
        Cell: k =><div>{
            <Buttons 
            btnUpload={true} 
            btnUploadAction={() => k.row.original.idEstatusPago === 5 ? {} : updatePetition(1,k.row)} 
            btnUploadShowIcon={true} 
            btnUploadText={k.row.original.idEstatusPago === 5 ? 'Pagado' :'Actualizar'}
            btnUploadIconClass={k.row.original.idEstatusPago === 5 ? "fa fa-file-text-o" : "fa fa-pencil"}

            />
            }
            
            
        </div>
        
    },
    {
        id:'elimina',
        Header: "Eliminar",
        Cell: k =><div>
            <Buttons 
            btnDelete={true} btnDeleteAction={() => deletePetition(1,k.row)} btnDeleteShowIcon={true}

            /> 
            
        </div>
        
    }   

]
}
const columnsImpuestosDetSS = (updatePetition,deletePetition,handleChangeCbx,handleChangeChkAll,chckAll) => { 
    const CompontText = ({rowN,value}) => (
        <>
            {rowN.lote ? rowN.idEstatus === 5 ? 
            <span>{value}</span> : 
            <span style={rowN.idPrioritario ?{color:'red'}:{color:'blue'}}>{value}</span> : <></>}
            </>
            )
return [
    
    {
        id:'idSolicitudPago',
        Header:'ID SolicitudPago',
        accessor:'idSolicitudPago'
    },
    {
        id:'idDetalle',
        Header:'ID detalle',
        accessor:'idSolicitudPagoDetalle'
    },
    { 
        id: "chckAllDetSS",
        // Header: "CheckAll",
        Header: () => (
            <div className="border-checkbox-section">
                <div className="border-checkbox-group border-checkbox-group-success">
                {/* <div className="border-checkbox-group border-checkbox-group-success" style={{padding: '0 0 0 42%'}}> */}
                <input
                        onChange={e => handleChangeChkAll(e.target.checked)}
                        checked={chckAll}
                        className="border-checkbox"
                        type="checkbox"
                        id={"chkAllTblDetalleSS"}
                    />
                    <label className="border-checkbox-label"
                        htmlFor={"chkAllTblDetalleSS"}></label>
                </div>
            </div>

        ),
        Cell: k =>(
            <div className="border-checkbox-section">
                <div className="border-checkbox-group border-checkbox-group-success">
                    <input
                        onChange={(e) => handleChangeCbx(k.row.original, e.target.checked, k)}
                        checked={k.row.original.check}
                        className="border-checkbox"
                        type="checkbox"
                        id={"chkAPlicarDetSS" + k.row.original.idSolicitudPagoDetalle}
                    />
                    <label className="border-checkbox-label"
                        htmlFor={"chkAPlicarDetSS" + k.row.original.idSolicitudPagoDetalle}></label>
                </div>
            </div>)
    },
    
    {
        Header: K => <div><span> Lote </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>,
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.lote} />),
        accessor: 'lote',
    },
    {
        Header: "REGISTRO_PATRONAL",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.registroPatronal} />),
        accessor: 'registroPatronal',
    },
    {
        Header: "RFC",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.rfc} />),
        accessor: 'rfc',
    },
    {
        Header: "RAZON_SOCIAL",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.empresa} />),
        accessor: 'empresa',
    },
    {
        id:'idPrioritario',
        Header: "IDPrioritario",
        accessor: 'idPrioritario',
    },
    {
        Header: "PRIORITARIO",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.prioritario} />),
        accessor: 'prioritario',
    },
    {
        id: 'idTipoImpuesto',
        Header: "IDTIPOIMPUESTO",
        accessor: 'idTipoImpuesto',
    },
    {
        Header: "TIPO_IMPUESTO",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.tipoImpuesto} />),
        accessor: 'tipoImpuesto',
    },
    {
        id: 'idDetTipoImpuesto',
        Header: "IDDETALLE",
        accessor: 'idDetTipoImpuesto',
    },
    {
        Header: "DETALLE",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.detalle} />),
        accessor: 'detalle',
    },
    {
        Header: "MONTO",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.importeDetalle} />),
        accessor: 'importeDetalle',
    },
    {
        Header: "MES",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.mes} />),
        accessor: 'mes',
    },
    {
        Header: "ANIO",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.anio} />),
        accessor: 'anio',
    },
    {
        Header: "FOLIO_SUAs",
        Cell: k =>(<CompontText rowN={k.row.original} value={k.row.original.folioSUA} />),
        accessor: 'folioSUA',
    },
    {
        id:'multiRow',
        Header: "multiRow",
        accessor: 'bandera',
    },
    {
        id:'actualiza',
        Header: "Actualizar",
        Cell: k =><div>{
            <Buttons 
            btnUpload={true}
            btnUploadAction={() =>  k.row.original.idEstatusPago === 5 ? {} : updatePetition(2,k.row,k)} 
            btnUploadShowIcon={true} 
            btnUploadText={k.row.original.idEstatusPago === 5 ? 'Pagado' :'Actualizar'} 
            btnUploadIconClass={k.row.original.idEstatusPago === 5 ? "fa fa-file-text-o" : "fa fa-pencil"}
            /> 
          
            }
           
            
        </div>
        
    },
    {
        id:'elimina',
        Header: "Eliminar",
        Cell: k =><div>
            <Buttons 
            btnDelete={true} btnDeleteAction={() => deletePetition(2,k.row)} btnDeleteShowIcon={true}

            /> 
            
        </div>
        
    },
]
}

const columnsImpuestosOK = [
    {
        Header: "Marca",
        accessor: 'MARCA',
    },
    {
        Header: "Prioritario",
        accessor: 'PRIORITARIO',
    },
    {
        Header: "RFC",
        accessor: 'RFC',
    },
    {
        Header: "Empresa",
        accessor: 'EMPRESA',
    },
    
    {
        Header: "Tipo de impuesto",
        accessor: 'TIPO_IMPUESTO',
    },
    {
        Header: "Detalle",
        accessor: 'DETALLE',
    },
    {
        Header: "Monto",
        accessor: 'MONTO',
    },
    {
        Header: "Mes",
        accessor: 'MES',
    },
    {
        Header: "Anio",
        accessor: 'ANIO',
    },
    {
        Header: "FORMATO",
        accessor: 'IDESTADO',
    }
   
]
const columnsImpuestosOKSS = [
   
    {
        Header: "REGISTRO_PATRONAL",
        accessor: 'REGISTRO_PATRONAL',
    },
    {
        Header: "RFC",
        accessor: 'RFC',
    },
    {
        Header: "RAZON_SOCIAL",
        accessor: 'RAZON_SOCIAL',
    },
    
    {
        Header: "PRIORITARIO",
        accessor: 'PRIORITARIO',
    },
    {
        Header: "Tipo de impuesto",
        accessor: 'TIPO_IMPUESTO',
    },
    {
        Header: "DETALLE",
        accessor: 'DETALLE',
    },
    {
        Header: "Monto",
        accessor: 'MONTO',
    },
    {
        Header: "Mes",
        accessor: 'MES',
    },
    {
        Header: "Anio",
        accessor: 'ANIO',
    },
    {
        Header: "Folio_SUA",
        accessor: 'FOLIO_SUA',
    }
]
const optinsA =[
    {value:'1' ,label:'Impuestos Estatales y Federales'},
    {value:'2' ,label:'Otros'}
]

const calasTabs = {
    tabUser : 'active',
    tabVista : '',
    tabContUser : 'tab-pane active',
    tabContVista : 'tab-pane',
    tabUexpanded : 'true',
    tabVexpanded : 'false'
}
const swichTabs = {
    tabUser : '',
    tabVista : 'active',
    tabContUser : '',
    tabContVista : '',
    tabUexpanded : '',
    tabVexpanded : ''
}
const errorModal = [{
    errorRight : 'text-right errModalX',
    errorLeft : 'text-left errModalX',
    cRight : 'text-right',
    cLeft : 'text-left',
}]
const columns = (handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr,type) => {
    switch(type){

        case 2:
            // console.log(type,'columnsImpuestosSS')
            return columnsImpuestosSS(handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr)
        case 3:
            // console.log(type,'columnsImpuestosTesoreria')
            return columnsImpuestosTesoreria(handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr)
        default:
            // console.log(type,'columnsImpuestos')
            return columnsImpuestos(handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr)
    }

}
export { columnsLote,columnsImpuestos,inicializa,hiddenColumns,optinsA,columnsImpuestosOK,columnsImpuestosOKSS,columnsImpuestosDet,columnsImpuestosDetSS,errorModal}
