const inicializa = {
    title : 'Descarga Facturas',
    breadcrum : [{nombre: 'Facturación'},{nombre: 'Descarga Facturas'}]
}


const tableColumns = [
    { 
        Header: "idControl",
        accessor: 'idControl',
    },
    { 
        Header: "idOrigen",
        accessor: 'idOrigen',
    },
    { 
        Header: "idNotificación",
        accessor: 'idNotificacion',
    },
    { 
        Header: "Parametros",
        accessor: 'parametros',
    },
    { 
        Header: "Mensaje",
        accessor: 'mensajeError',
    },

    
]

export{
    tableColumns,
    inicializa
}