import React, {Fragment} from 'react';
import Disponibilidadsaldos from './Disponibilidadsaldos'
import MonitorSolicitud from './MonitorSolicitud'
import ProgramacionMovimientos from './ProgramacionMovimientos'
import Consiliacion from './Consiliacion'


const ContainerBody = ({
    estatusSolicitudes,
    tipoMovimiemto,
    // bancos,
    allCuentas,
    empresasBanco,
    empresasOrigenDestino,
    downloadHelp,

    changeSelect,
    changeDate,
    dataDisponibilidad,
    cuentasOrigen          ,
    bancosOrigen           ,
    downloadHelpDisp,
    optionEmpresa,
    optionCuenta,
    btnSearch,
    showModalUpSaldo,
    isShowDragAndDrop,
    selected_File,
    select_File,  
    uploadLayout, 
    showModalResponse,
    onCerrar,
    xlsDataRespons,
    xlsDownloadRespons,
    downSaldosExcel,
    totSaldo,
    handleChangeChkAll,
    chckAll,
    handleChangeCbx,
    saldoParcial,
    closeDragAndDrop,
    changeTab,
    numTab,
    solicitudes,
    allSolicitudes,
    changeSelectSolicitudes,
    changeDateSolicitud,
    optionTipoMovimiemto,
    optionEmpresaOrigen,
    optionEmpresaDestino ,  
    nuevaSolicitud         ,
    nuevoMovimiento        ,
    changeComboSolicitudes ,
    changeChkAllSolicitudes ,
    chckAllSolicitudes ,
    handleChangeCbxSolicitud,
    isNuevoMov,
    showDetailSolFond,
    showDispSaldo,
    movimientosTbl,
    petAut,
    handleCbxMov,
    showDetailMov,
    showModalDeleteMov,
    loadEvidence,
    isEvidMov,
    dataRespEvMov,
    showPDF      ,
    saveFileEvidence,
    showUpMasiv,
    searchFilXlsPdf,
    CurrencyFormat,
    cancelSaveChange
}) => {
    return (
        <Fragment>
            <div>
                <ul className="nav nav-pills nav-justified ar-nav-pills center-block margin-bottom">
                    <li className={'active'}>
                    <a id='liDisponibilidad' href="#Disponibilidadsaldos" data-toggle="tab" >
                                    <span>Disponibilidad de saldos</span> 
                                </a>
                    </li>
                    <li className={''} onClick={e => changeTab(2)}>
                                <a id='liMonitorSolicitudes' href="#MonitorSolicitud" data-toggle="tab" >
                                    <span>Monitor de solicitudes</span> 
                                </a>
                            </li>
                            <li className={''} onClick={e => changeTab(3)}>
                                <a href="#ProgramacionMov" data-toggle="tab" >
                                    <span>Programacion de movimientos</span>
                                
                                    
                                </a>
                            </li>
                            <li  className={''}>
                                <a id='liConsiliacion' href="#Consiliacion" data-toggle="tab" >
                                    <span>Consiliación</span>                               
                                
                                </a>
                            </li>

                </ul>
                
                <div className="tab-content margin-top">
                    <div className={'tab-pane active'}  id="Disponibilidadsaldos">
                    <Disponibilidadsaldos 
                    typeTab={1}
                    empresasBanco={empresasBanco}

                    changeSelect={changeSelect}
                    dataDisponibilidad={dataDisponibilidad ? dataDisponibilidad : []}
                    allCuentas={allCuentas}
                    downloadHelpDisp={downloadHelpDisp}
                    optionCuenta={optionCuenta}
                    optionEmpresa={optionEmpresa}
                    btnSearch={btnSearch}
                    changeDate={changeDate}
                    showModalUpSaldo={showModalUpSaldo}
                    isShowDragAndDrop={isShowDragAndDrop}
                    selected_File={selected_File}
                    select_File  ={select_File}
                    uploadLayout ={uploadLayout}
                    showModalResponse={showModalResponse}
                    onCerrar={onCerrar}
                    xlsDataRespons={xlsDataRespons}
                    xlsDownloadRespons={xlsDownloadRespons}
                    downSaldosExcel={downSaldosExcel}
                    totSaldo={totSaldo}
                    handleChangeChkAll={handleChangeChkAll}
                    chckAll={chckAll}
                    handleChangeCbx={handleChangeCbx}
                    saldoParcial={saldoParcial}
                    closeDragAndDrop={closeDragAndDrop}    
                    />
                    </div>
                    <div className={'tab-pane'} id="MonitorSolicitud">
                    <MonitorSolicitud 
                    typeTab={2}
                    estatusSolicitudes={estatusSolicitudes}
                    tipoMovimiemto={tipoMovimiemto}
                    bancosOrigen={bancosOrigen}
                    empresasOrigenDestino={empresasOrigenDestino}
                    empresasBanco={empresasBanco}
                    
                    changeSelect={changeSelect}
                    empresas={[]}
                    solicitudes={solicitudes}
                    allSolicitudes={allSolicitudes} 
                    changeSelectSolicitudes={changeSelectSolicitudes}
                    changeDateSolicitud={changeDateSolicitud}
                    btnSearch={btnSearch}
                    optionTipoMovimiemto   ={optionTipoMovimiemto}
                    optionEmpresaOrigen    ={optionEmpresaOrigen}
                    optionEmpresaDestino   ={optionEmpresaDestino}
                    cuentasOrigen          ={cuentasOrigen}
                    nuevaSolicitud         ={nuevaSolicitud}
                    nuevoMovimiento        ={nuevoMovimiento}
                    changeComboSolicitudes ={changeComboSolicitudes}
                    handleCbxSolicitud     ={handleChangeCbxSolicitud}
                    isNuevoMov             ={isNuevoMov}
                    showDetailSolFond      ={showDetailSolFond}
                    showDispSaldo          ={showDispSaldo}
                    />
                    </div>
                    <div className={'tab-pane'} id="ProgramacionMov">
                    <ProgramacionMovimientos 
                    typeTab={3}
                    movimientosTbl={movimientosTbl}
                    petAut={petAut}
                    handleCbxMov={handleCbxMov}
                    showDetailMov={showDetailMov}
                    showModalDeleteMov={showModalDeleteMov}
                    loadEvidence={loadEvidence}
                    changeSelectSolicitudes={changeSelectSolicitudes}
                    changeDateSolicitud={changeDateSolicitud}
                    optionTipoMovimiemto   ={optionTipoMovimiemto}
                    optionEmpresaOrigen    ={optionEmpresaOrigen}
                    optionEmpresaDestino   ={optionEmpresaDestino}
                    empresasOrigenDestino={empresasOrigenDestino}
                    empresasBanco={empresasBanco}
                    tipoMovimiemto={tipoMovimiemto}
                    btnSearch={btnSearch}
                    numTab={numTab}
                    changeTab={changeTab}
                    isEvidMov={isEvidMov}
                    dataRespEvMov={dataRespEvMov}
                    saveFileEvidence={saveFileEvidence}
                    showPDF              ={showPDF}
                    showUpMasiv          ={showUpMasiv}
                    searchFilXlsPdf      ={searchFilXlsPdf}
                    downloadHelp={downloadHelp}
                    CurrencyFormat={CurrencyFormat}
                    cancelSaveChange={cancelSaveChange}


                    />
                    </div>
                    <div className={'tab-pane'} id="Consiliacion">
                    <Consiliacion
                    changeSelect={changeSelect} 
                    typeTab={4} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ContainerBody;