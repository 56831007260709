import React, {Fragment, useEffect, useState, useRef} from 'react';
import {fnNotification} from '../../../ApiRequests/Notification'
import {ExisteSession, toggleLoader} from '../../../ApiRequests/general'
import {inicializa ,columnsModal,dataDisponibilidad,toggleModal} from '../../../Components/MovimientosEntreCompanias/resources'
import {getTDispSaldos,upLoadFile,saveDataSaldos,getMovimientosCompanias,saveNewSolicitud,updateSolicitud,saveNewMov,savedFile,updateMov} from '../../../Components/MovimientosEntreCompanias/service'
// import {getInfoPagosImp,loadFile,saveInfoPagosImp,getSolicitudes,updateSolicitudes,deleteSolicitudes,sendPDF,donwnloadHelpDocument,autorizatLC,getEmpresas,getCuentas,updateSolicitud,sendPDFTST,printPDFSolicitud} from '../../../Components/PagoImpuestos/service'
import ContainerBody from '../../../Components/MovimientosEntreCompanias/ContainerBody'
import Session from '../../../Application/session'
import Header from '../../../Components/Header';

import Container from '../../../Components/Container/ContainerInitial'

// import ModalRespuesta from './ModalRespuesta'
// import ModalConfirma from './ModalConfirma'
// import ModalEditaDetalle from './ModalEditaDetalle';
// import ModalPDF from './ModalPDF';
// import ModalCP from './ModalCP';
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import ModalNuevoMovimiento from '../../../Components/MovimientosEntreCompanias/Modal/ModalNuevoMovimiento'
import ModalRespuesta from '../../../Components/ModalRespuestaLayout/ModalRespuestaLayout'
import ModalDisponibilidadSaldos from '../../../Components/MovimientosEntreCompanias/Modal/ModalDisponibilidadSaldos'
import ModalActionMovePetition from '../../../Components/MovimientosEntreCompanias/Modal/ModalActionMovePetition'
import ModalDetailMovChange from '../../../Components/MovimientosEntreCompanias/Modal/ModalDetailMovChange'
import ModalPDF from '../../../Components/MovimientosEntreCompanias/Modal/ModalPDF';
import * as XLSXRead from 'xlsx'
// import * as ExcelJS from 'exceljs';
// import { error } from 'jquery';



// import jsonTbl from './test.json'

const MovimientosCompanias = () => {
    //inicio filtros iniciales
    const [estatusSolicitudes,setEstatusSolicitudes]                  = useState([])
    const [tipoMovimiemto,setTipoMovimiento]                          = useState([])
    const [bancos,setBancos]                                          = useState([])
    const [allCuentas,setAllCuentas]                                  = useState([])
    const [allEmpresas,setAllEmpresas]                                = useState([])
    const [empresasBanco,setEmpresasBanco]                            = useState([])
    const [empresasOrigenDestino,setEmpresasOrigenDetino]             = useState([])
    //fin filtros iniciales          

    const [dataHelp,setDataHelp]                                      = useState()
    const [dataAllDisponibilidad,setAllDataDisponibilidad]            = useState([])
    const [dataDisponibilidad,setDataDisponibilidad]                  = useState([])
    const [changeInitE,setChangeInitE]                                = useState(false)
    const [optionEmpresaOrigen,setOptionEmpresaOrigen]                = useState([])
    const [optionEmpresaDestino,setOptionEmpresaDestino]              = useState([])
    const [optionTipoMovimiemto,setOptionTipoMovimiento]              = useState([])
    const [optiontipoMovimiemtoMNM,setOptiontipoMovimiemtoMNM]        = useState([])
    const [cuentasFilt,setCuentasFilt]                                = useState([])
    const [optionCuentaOrigenMNM,setOptionCuentaOrigenMNM]            = useState([])
    const [optionCuentaDestinoMNM,setOptionCuentaDestinoMNM]          = useState([])
    const [optionEmpresa,setOptionEmpresa]                            = useState([])
    const [optionEmpresaOrigenMNM,setOptionEmpresaOrigenMNM]          = useState([])
    const [optionEmpresaDestinoMNM,setOptionEmpresaDestinoMNM]        = useState([])
    const [optionCuenta,setOptionCuenta]                              = useState([])
    const [optionSolicitante,setOptionSolicitante]                    = useState([])
    const [cuentasOrigen,setCuentasOrigen]                            = useState([])
    const [cuentasDestino,setCuentasDestino]                          = useState([])
    const [cuentasOrigenMonitor,setCuentasOrigenMonitor]              = useState([])
    const [bancosOrigen,setBancosOrigen]                              = useState([])
         
    const [fecha_Movimiento,setFechaMovimiento]                       = useState('')
    const [fechaMovimientoNMN,setFechaMovimientoNMN]                  = useState('')
    const [conceptoValue,setConceptoValue]                            = useState('')
    const [comentariosNMN,setComentariosNMN]                          = useState('')
    const [solicitanteMNM,setSolicitanteMNM]                          = useState('')

    const [selectedFile, setSelectedFile]                             = useState([]);
    const [selected_File, setSelected_File]                           = useState([]);

    const [selectedExcelDispSaldos, setSelectedExcelDispSaldos]       = useState([]);
    const [selected_ExcelDispSaldos, setSelected_ExcelDispSaldos]     = useState([]);

    const [selectedExcelFormat, setSelectedExcelFormat]               = useState([]);
    const [selected_ExcelFormat, setSelected_ExcelFormat]             = useState([]);

    const [selectedPdf, setSelectedPdf]                               = useState([]);
    const [selected_Pdf, setSelected_Pdf]                             = useState([]);

    const [selectedZip, setSelectedZip]                               = useState([]);
    const [selected_Zip, setSelected_Zip]                             = useState([]);


    const [showModalResponse, setShowModalResponse]                   = useState(false);
    const [showModalDispSaldo, setShowModalDispSaldo]                 = useState(false);
    const [xlsDataRespons, setXlsDataRespons]                         = useState([]);
    const [xlsDownloadRespons, setXlsDownloadRespons]                 = useState([]);
    const [isShowDragAndDrop, setIsShowDragAndDrop]                   = useState(false);
    const [dataSaldo,setDataSaldo]                                    = useState([])
    const [jsonSave,setJsonSave]                                      = useState([])
    const [totSaldo,setTotSaldo]                                      = useState(0)
    const [saldoParcial,setSaldoParcial]                              = useState(0)
    const [importeValueNMN,setImporteValueNMN]                        = useState(0)
    const [chckAll,setchckAll]                                        = useState(false)
    const [allSolicitudes,setAllSolicitudes]                          = useState([])
    const [solicitudes,setSolicitudes]                                = useState([])
    const [showModalNuevaSolicitud,setShowModalNuevaSolicitud]        = useState(false)
    const [showModalNuevoMovimiento,setShowModalNuevoMovimiento]      = useState(false)
    const [idEmpresaOrigenSave,setIdEmpresaOrigenSave]                = useState(0)
    const [idBancoOrigenSave,setIdBancoOrigenSave]                    = useState(0)
    const [idCuentaOrigenSave,setIdCuentaOrigenSave]                  = useState(0)
    const [idEmpresaDestinoSave,setIdEmpresaDestinoSave]              = useState(0)
    const [idBancoDestinoSave,setIdBancoDestinoSave]                  = useState(0)
    const [idCuentaDestinoSave,setIdCuentaDestinoSave]                = useState(0)
    const [movPetition,setMovPetition]                                = useState(0)
    const [isNuevoMov,setIsNuevoMov]                                  = useState(false)
    const [solicitudesaNuevoMovimiento,setSolicitudesaNuevoMovimiento]= useState([])
    const [solicitudesRelMov,setSolicitudesRelMov]                    = useState([])
    const [solicitudes_NewMov,setsolicitudes_NewMov]                  = useState([])
    const [totalMovimiento,setTotalMovimiento]                        = useState(0)
    const [nuevoTotMovimiento,setNuevoTotMovimiento]                  = useState(0)
    const [isDetailPet,setIsDetailPet]                                = useState(false)
    const [rowDetail,setRowDetail]                                    = useState([])
    const [cuentasBancosEmpresas,setCuentasBancosEmpresas]            = useState([])
    const [typeAction,setTypeAction]                                  = useState([]) //aux
    const [showModalAction,setShowModalAction]                        = useState(false)
    const [showModalDetailChange,setShowModalDetailChange]            = useState(false)
    const [typeColumns,setTypeColumns]                                = useState(false)
    const [typeCancelMov,setTypeCancelMov]                            = useState(5)
 
    const [relPetitionMovAll, setRelPetitionMovAll]                   = useState([])
    const [relPetitionMov, setRelPetitionMov]                         = useState([])
    const [allMovimientos, setAllMovimientos]                         = useState([])
    const [detailMovimiento, setdetailMovimiento]                     = useState([])
    const [movimientosTbl, setMovimientosTbl]                         = useState([])
    const [movimientosMasive, setMovimientosMasive]                   = useState([])
    const [allPetAut, setAllPetAut]                                   = useState([])
    const [petAut, setPetAut]                                         = useState([])
    const [numTab, setNumTab]                                         = useState([])
    const [dataMovRow, setDataMovRow]                                 = useState([])
    const [idMovimiento, setIdMovimiento]                             = useState([])
    const [movCancel, setMovCancel]                                   = useState([])
    const [referenceEvidece, setReferenceEvidece]                     = useState("")
    const [dateEvidence, setDateEvidence]                             = useState("")
    const [importEvidence, setimportEvidence]                         = useState(0)
    const [isEvidMov, setIsEvidMov]                                   = useState(false)
    const [dataRespEvMov, setDataRespEvMov]                           = useState([])
    const [showModalPDF,setShowModalPDF]                              = useState(false);
    const [infoPDF,setInfoPDF]                                        = useState('');
    const [esUnPDF,setEsUnPDF]                                        = useState(false);
    const [showUpMasiv,setShowUpMasiv]                                = useState(false);
    const [isEvidenceMasiv,setIsEvidenceMasiv]                        = useState(false);
    const [isCheckedTypeCancelMov,setIsCheckedTypeCancelMov]                = useState(true);
    const [isCheckedTypeCancelMovPet,setIsCheckedTypeCancelMovPet]                = useState(false);
    
    // setIsEvidMov(true)
    // setDataRespEvMov(response)
    const calculSaldo = useRef();

    const fileType = 'aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'

    const solicitantes = [
        {value:'Adrian',label:'José Adrián Pérez Chávez'},
        {value:'Luis',label:'José Luis Martinez Almazan'},
        {value:'Lucio',label:'Lucio Garcia López'}
    ]

    useEffect(() =>{
        /* getTDispSaldos con end point:catalogosIniciales traera los catalogos inicialres que sirven para llenar los combos de los filtros: 
            Empresa => servira para combo Empresas, Empresa origen, Empresa Destino
            Cuenta
            Tipo de moviemiento
            Estatus => no es un filtro, es el combo de estatus para la tabla de Monitor de solicitudes
        */ 
        getTDispSaldos({
            endPoit:'catalogosIniciales',
        },respCatalogos =>{
            console.log('respCatalogos',respCatalogos)
            let jsonResp = JSON.parse(respCatalogos[0].JSON)

            let auxEstatus = []
            let auxTipoMov = []
            let auxBancos = []
            let auxCuenta = []
            let auxEmpresas = []
            let auxEmpresasOrigenDetino = []
            let auxEmpresaAyuda = []
            console.log('jsonResp',jsonResp)
            setCuentasBancosEmpresas(jsonResp)
            jsonResp.Estatus.forEach(e => auxEstatus.push({label:e.descripcion,value:e.idEstatus})) /*Extraer combo de estatus para la tabla de Monitor de solicitude*/
            jsonResp.TipoMovimiento.forEach(e => auxTipoMov.push({label:e.descripcion,value:e.idTipoMovimiento})) /*Extrae Filtro TipoMovimiento */
            jsonResp.bancos.forEach(e => auxBancos.push({label:e.banco,value:e.idbanco}))/*Extrae combo banco origen-destino para tabla Monitor de solicitudes */
            jsonResp.cuentas.forEach(e => auxCuenta.push({label:e.numerodecuenta,value:e.idcuenta,idEmpresa:e.ID_empresa,idBanco:e.idbanco,idcuenta:e.idcuenta}))
            jsonResp.cuentas.forEach(e => auxEmpresas.push({label:e.empresa+'-'+e.banco+'-'+e.numerodecuenta,value:e.ID_empresa+'-'+e.idbanco+'-'+e.idcuenta,idEmpresa:e.ID_empresa,idBanco:e.idbanco,idcuenta:e.idcuenta}))
            jsonResp.cuentas.forEach(e =>{
                auxEmpresasOrigenDetino.push({label:e.empresa+'-'+e.banco+'-'+e.numerodecuenta,value:e.ID_empresa+'-'+e.idbanco+'-'+e.idcuenta,idEmpresa:e.ID_empresa})
            })
            setAllEmpresas(jsonResp.empresas)
            jsonResp.cuentas.forEach(e => auxEmpresaAyuda.push(
                {
                    Empresa_Banco:e.empresa+'-'+e.banco,
                    cuenta:e.numerodecuenta,
                    saldo:'',
                    comentarios:''

                }))
            console.log(auxEmpresasOrigenDetino)
            setEstatusSolicitudes(auxEstatus)/* Combo de estatus para la tabla de Monitor de solicitude*/
            setTipoMovimiento(auxTipoMov) /*Filtro TipoMovimiento en filtro Monitor de solicitudes*/
            setBancos(auxBancos)/*Data banco origen-destino para tabla Monitor de solicitudes */

            /*  allCuentas, se usara para llenar combo de Disponibilidad de saldos en cascada con 
                la empresa seleccionada. Se usara también para la información de cuenta origen y 
                destino de la tabla Monitor de solicitudes 
            */
            setAllCuentas(auxCuenta)

            /*  EmpresasBanco, se usara para llenar combo Empresa de Disponibilidad de saldos en cascada con 
                el combo cuentas de la misma pantalla. Al seleccionar una empresa se mostraran las cuentas 
                que esta maneje                                                                            */ 
            setEmpresasBanco(auxEmpresas)

            /*  empresasOrigenDetino, se usara para llenar combo de Disponibilidad de saldos en cascada con 
                la empresa seleccionada. Se usara también para la información de cuenta origen y 
                destino de la tabla Monitor de solicitudes 
            */
            setEmpresasOrigenDetino(auxEmpresasOrigenDetino)
            // setCuentasFilt(auxCuenta)
            setDataHelp(auxEmpresaAyuda)

            /*   getTDispSaldos con end point:saldosDisponibles 
                traera el la lista de saldos disponibles para mostrar en la tabla 
            */ 
            getTDispSaldos({
                endPoit:'saldosDisponibles',
            },respuesta =>{
                console.log('respSaldos',respuesta)
                let auxData = []
                let auxTot = 0
                let indxRow = 1;
                respuesta.forEach((element,j) => {
                    
                    auxTot = auxTot + parseFloat(element.saldo)
                    element.check = false
                    element.idRowSaldo = j+1;


                });
                console.log(auxTot)
                setTotSaldo(auxTot)
                setDataDisponibilidad(respuesta)
                setAllDataDisponibilidad(respuesta)
            })
        })

    },[])
    const changeSelect = (e,v) => {
        console.log(e)
        console.log(v)
        console.log(v.name)
        switch(v.name)
        {
            case 'empresa':
                if(v.action === 'clear'){
                    setOptionEmpresa([])
                    setCuentasFilt(allCuentas)

                }else{
                setOptionEmpresa(e)
                setChangeInitE(true)
                console.log(allCuentas)
                console.log(e.idEmpresa)
                console.log(e.idBanco)

                    // let auxC = [{label:'Todos',value:'all'}] 
                    let allC = allCuentas.filter(x => x.idEmpresa === e.idEmpresa && x.idBanco === e.idBanco)
                    // allCuentas.forEach(c =>{
                    //     if(c.idEmpresa ===  e.value) auxC.push(c) 
                    // })
                    
                    console.log(allC)
                    setCuentasFilt(allC)
                }
                break
            case 'cuenta':
                if(v.action === 'clear'){
                    setOptionCuenta([])
                    // setCuentasFilt(allCuentas)
                }else{
                    setOptionCuenta(e)
                    if(e.value === 'all'){
                        setOptionCuenta([])
                        setCuentasFilt(allCuentas)
                    }
                }

                break

            default:
        }
    }
    const changeSelectSolicitudes = (e,n) => {
        console.log(e)
        console.log(n)
        switch(n.name){
            case 'tipoMov':
                if(e !== null) setOptionTipoMovimiento(e)
                else           setOptionTipoMovimiento([])
                break
            case 'empresaOrigen':
                if(e !== null) setOptionEmpresaOrigen(e)
                else           setOptionEmpresaOrigen([])
                break
            case 'empresaDestino':
                if(e !== null)  setOptionEmpresaDestino(e)
                else            setOptionEmpresaDestino([])
                break
                default:
        }
        
        

    }


    
    // const ExcelReader = (archivo) => {
    //     const [excelData, setExcelData] = useState(null);

    //     console.log(archivo)
    //     debugger
      
    //     // const handleFileUpload = (e) => {
    //     //   const file = e.target.files[0];
    //     //   const reader = new FileReader();
      
    //     //   reader.onload = (event) => {
    //     //     const binaryString = event.target.result;
    //     //     const workbook = XLSX.read(binaryString, { type: 'binary' });
    //     //     const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    //     //     const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      
    //     //     setExcelData(data);
    //     //   };
      
    //     //   reader.readAsBinaryString(file);
    //     };

    const CurrencyFormat = (value) =>{
        const formattedValue = value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD', // Cambia a la moneda deseada (ejemplo: EUR, GBP, etc.)
          });

          return formattedValue

    }
    
    const getFormatImp = (_imp) =>{
          console.log(_imp)
          let stImp = _imp.substring(1,_imp.length)
          console.log(stImp)
          return stImp
    }

    const uploadEvidenceMasive = () => {
        debugger
    
        console.log('selectedExcelFormat',selectedExcelFormat)
        setSelected_ExcelFormat(selectedExcelFormat)
        console.log('selectedZip',selectedZip)
        setSelected_Zip(selectedZip)
        const archivo = selectedExcelFormat[0];
        const lector = new FileReader();
        console.log('archivo',archivo)

        console.log('dataMovRow',dataMovRow)
        console.log(CurrencyFormat(785320.55))
        debugger
        let auxref = ''
        let auxFech = ''
        let auxImp = ''
        let auxTitRef = ''
        let auxTitFech = ''
        let auxTitImp = ''

        let listRow = []
        // let respValidateFilter = validateFilterEvidence()
        // if(respValidateFilter.error){
        //     fnNotification(['Error: '+respValidateFilter.message , 'error', true])
        //     return false
        // }
        // listRow.push(dataMovRow)
        debugger

        lector.onload = (evento) => {
            const datos = evento.target.result;
            const libro = XLSX.read(datos, { 
                type: 'binary', 
                cellText:false,
                cellDates:true
            });
            const wsname = libro.SheetNames[0];
            console.log(wsname);
            const ws = libro.Sheets[wsname];
            console.log(ws);
            const datosHoja = XLSX.utils.sheet_to_json(ws,{raw:false,dateNF:'dd/mm/yyyy',});
            console.log('Datos de la hoja:', datosHoja);
            datosHoja.forEach(x => x.idRow = x.referencia+'¬¬'+x.importesolicitado)
            
            // let jsomRgex = [
            //     {"ReferenciaEvidencia":[
            //         {"Etiqueta":"(No. de Autorización|Número de Referencia|Referencia)"},
            //         {"valor":":*\\s*[0-9]+"}
                    
            //     ]},
            //     {"FechaMovimiento":[
            //         {"Etiqueta":"(Fecha de Operación|Fecha y Hora de Captura|Fecha de Ejecución|Fecha|Liquidación)"},
            //         {"valor":":*\\s*[0-9]{1,2}[/|-]([0-9]{1,2}|[A-Za-z]{3})[/|-][0-9]{2,4}"}
            //     ]},
            //     {"ImporteEvidencia":[
            //         {"Etiqueta":"(Importe|Importe a Transferir)"},
            //         {"valor":":*\\s*[$]*\\s*([0-9]|[,])*[.](\\d{2})"}
            //     ]}
            // ]
            console.log('Datos de la hoja:', datosHoja);
            debugger

            let jsomRgex = [
                {"ReferenciaEvidencia":[
                    {"Etiqueta":"(Número de Referencia|Referencia|No. de Autorización|Referencia numérica)"},
                    {"valor":":*\\s*(¬o"}
                    
                ]},
                {"FechaMovimiento":[
                    {"Etiqueta":"(Fecha de Operación|Fecha y Hora de Captura|Fecha de Ejecución|Fecha|Liquidación)"},
                    {"valor":":*\\s*[0-9]{1,2}[/|-]([0-9]{1,2}|[A-Za-z]{3})[/|-][0-9]{2,4}"}
                ]},
                {"ImporteEvidencia":[
                    {"Etiqueta":"(Importe|Importe a Transferir)"},
                    {"valor":":*\\s*[$]*\\s*("}
                ]}
            ]
            for(let ho = 0; ho < datosHoja.length; ho++){
                console.log(datosHoja[ho]);
                console.log(datosHoja[ho].idMovimiento);
                console.log(datosHoja[ho].fechasolicitud);
                datosHoja[ho].type_Save = 1
                datosHoja[ho].typePetMov = 1
               
                if((datosHoja[ho].idMovimiento === '' && datosHoja[ho].fechasolicitud === '')||(datosHoja[ho].idMovimiento === undefined && datosHoja[ho].fechasolicitud === undefined)) {
                    datosHoja[ho].typePetMov = 2
                    datosHoja[ho].type_Save = 2

                }
                // else  if(datosHoja[ho].idMovimiento === '' || datosHoja[ho].idMovimiento === undefined) {
                //     fnNotification(['Error: idMovimiento en registro ' +ho+ ' no puede ser vacio' , 'error', true])

                //     return null
                // }else if(datosHoja[ho].fechasolicitud === '' || datosHoja[ho].fechasolicitud === undefined) {
                //     fnNotification(['Error: fechasolicitud  en registro ' +ho+ '  no puede ser vacio' , 'error', true])
                //     return null
                // }




                if(datosHoja[ho].referencia === '') {
                   auxref += datosHoja[ho].idMovimiento +'¬0|0'  
                }else auxref += datosHoja[ho].idMovimiento +'¬'+ datosHoja[ho].referencia  +'|'+  datosHoja[ho].referencia+'|'

                if(datosHoja[ho].importereaplicado === '') {
                auxImp += datosHoja[ho].idMovimiento +'¬'+ datosHoja[ho].importesolicitado +'-o-'+ datosHoja[ho].importereaplicado +'0|0|'
                   
                }else auxImp += datosHoja[ho].idMovimiento +'¬'+ datosHoja[ho].importesolicitado +'-o-'+ datosHoja[ho].importereaplicado +'|'+ getFormatImp(CurrencyFormat(parseFloat(datosHoja[ho].importereaplicado)))+'|'

                
                console.log(datosHoja[ho]);
                
                // auxFech += datosHoja[ho].fechasolicitud+'|'
                
                let idM = (datosHoja[ho].idMovimiento === undefined ||datosHoja[ho].idMovimiento === ''  ) ? '0.1' : datosHoja[ho].idMovimiento;
                let dateP = (datosHoja[ho].fechasolicitud === undefined ||datosHoja[ho].fechasolicitud === ''  ) ? 'Not' : datosHoja[ho].fechasolicitud;
                auxTitRef += '|(┤o'+ idM +'┤'+datosHoja[ho].referencia+')'
                auxTitImp +=  '|(┤o'+idM +'┤'+datosHoja[ho].importereaplicado+')'
                auxTitFech += '|(┤o'+idM +'┤'+dateP+')'
                // let stImp = parseFloat(datosHoja[ho].importereaplicado)
                // stImp = stImp.substring(1,stImp.length)
                // console.log(stImp)
                // auxImp += stImp +'|' 
                // const numberInScientificNotation = datosHoja[ho].referencia;
                // const numberAsString = numberInScientificNotation.toFixed(0).toString();
                console.log(CurrencyFormat(parseFloat(datosHoja[ho].importereaplicado)));
                console.log(auxImp);

           
             
                // console.log(jsomRgex[2]['ImporteEvidencia'][1])



                
            }
            auxref  = auxref  == '' ? '' : auxref.substring(0,auxref.length-1) + '|o¬)'
            // auxFech = auxFech == '' ? '' : auxFech.substring(0,auxFech.length-1)
            auxImp  = auxImp  == '' ? '' : auxImp.substring(0,auxImp.length-1) + ')'
            console.log('Datos de la hoja:', datosHoja);

            let listDataValida = [{'allCuentas':allCuentas,'bancos':bancos,'allEmpresas':allEmpresas, }]
            debugger




            // console.log(jsomRgex[0]['ReferenciaEvidencia'][1]['valor'])
            // // console.log(jsomRgex[1]['FechaMovimiento'][1]['valor'])
            // console.log(jsomRgex[2]['ImporteEvidencia'][1]['valor'])

            // jsomRgex[0]['ReferenciaEvidencia'][0]['Etiqueta'] += auxTitRef +')'
            jsomRgex[0]['ReferenciaEvidencia'][1]['valor'] += auxref 
            // jsomRgex[1]['FechaMovimiento'][1]['valor'] += auxFech
            // jsomRgex[1]['FechaMovimiento'][0]['Etiqueta'] += auxTitFech +')'

            // jsomRgex[2]['ImporteEvidencia'][0]['Etiqueta'] += auxTitImp +')'
            jsomRgex[2]['ImporteEvidencia'][1]['valor'] += auxImp

            console.log(jsomRgex);

            debugger
                upLoadFile({
                endPoint:'ProgramacionMovimientos',
                referencia:referenceEvidece,
                fechaEvidencia:dateEvidence,
                importe:importEvidence,
                listMovs:JSON.stringify(datosHoja),
                listRegEx:JSON.stringify(jsomRgex),
                allCuentas: JSON.stringify(allCuentas),
                allBancos: JSON.stringify(bancos),
                allEmpresas :JSON.stringify(allEmpresas)
                
                

            },{archivo: selectedZip,archivoXlsx:selectedExcelFormat},(response,type,success) =>{
                console.log(response,type,success)
                // let respData = respons[0];
                debugger

                toggleLoader(false)
                // console.log('allCuentas',allCuentas)
                // console.log('allEmpresas',allEmpresas)
                // console.log('bancos',allEmpresas)
                if(success){
                    setShowModalAction(false)
                    let auxResp = []

                    response.forEach(e =>{
                         if(e.typePeError === 0){
                            e.respMessage = 'Evidencia correcta'
                            e.errorMessage = 'Evidencia correcta'
                         }
                        }
                            
                    )
                    // alert('Respuesta correcta')
                    setIsEvidMov(true)
                    setIsEvidenceMasiv(true)
                    setDataRespEvMov(response)
                }
                let auxTbl = []
                let auxRespJson = []

            })





            
            // libro.SheetNames.forEach((nombreHoja) => {
            //     console.log(nombreHoja)
            //     const datosHoja = XLSX.utils.sheet_to_json(libro.Sheets[nombreHoja],{raw:false,dateNF:'dd-mm-yyyy',});
            //     console.log('Datos de la hoja:', datosHoja);
            // });
        };
        lector.readAsBinaryString(archivo);
    }
    const cancelSaveChange = () => {
        setIsEvidMov(false)
        setIsEvidenceMasiv(false)
        setDataRespEvMov([])

    }
    const changeDateSolicitud = (e) => {
        console.log(e)
        

    }
    const downloadHelpDisp = async (type) => {
        console.log('descarga',type)
        let sheet_Help = type ? dataHelp: dataAllDisponibilidad
        let nameHelp = type ? 'Disponibilidad_saldos' : 'Data_DiponibilidadSaldos'
        const _data = XLSX.utils.json_to_sheet(sheet_Help)
        const wb =  {Sheets:{'data':_data,'Hoja2':[]},SheetNames:['data','Hoja2']}
        // const excelBuffer = XLSX.write(wb,{bookType:'xlsx', type:'array'})
        // const data = new Blob([excelBuffer],{type:fileType})
        // FileSaver.saveAs(data,nameHelp + fileExtension)
        
        
        const excelBuffer = XLSX.write(wb,{bookType:'xlsx', type:'array'})
        const dat_a = new Blob([excelBuffer],{type:fileType})
        FileSaver.saveAs(dat_a,nameHelp + fileExtension)
    }
    
    const closeDragAndDrop = (isShow) =>{
        setIsShowDragAndDrop(false) 
    } 
    const showModalUpSaldo = (isShow) =>   setIsShowDragAndDrop(true)
    const uploadLayout = () => {
        debugger
        console.log('selected_File',selected_File)
        console.log('selectedFile',selectedFile)
        debugger
        if(selectedFile.length > 0){
            toggleLoader(true)
        debugger

            setSelected_File(selectedFile)
            upLoadFile({
                endPoint:'SaldosDisponibles',
                imputJSON:'{json1}',

            },{archivo: selectedFile,},respons =>{
                console.log(respons)
                let respData = respons[0];
                let auxTbl = []
                let auxRespJson = []
                let celdaletraN = 'A-B-C-D-E'

                respData.filas.forEach((element,j) => {
                    console.log(element)
                    console.log(element.celdasConErrores)
                    let indexCelda = '0';
                            let _ErroresFila = '';
                            for(let ic = 0;  ic < element.celdas.length ; ic++){
                                
                                if(element.celdas[ic].Errores.length > 0){
                                    _ErroresFila += element.celdas[ic].Errores[0]
                                }

                                

                                if(ic === 0) indexCelda = element.celdas[ic].Celda.substring(1,element.celdas[ic].Celda.length)
                                let _indexCelda = element.celdas[ic].Celda.substring(1,element.celdas[ic].Celda.length) 
                                // indexCelda = element.celdas[ic].Celda.substring(1,2)
                                let letraCelda = celdaletraN.split('-')[ic]
                                let celda = letraCelda+indexCelda
                                let celdaN = element.celdas.find(cl => cl.Celda === celda)

                                // if(letraCelda === 'D' && element.celdas.find(c => c.Celda     === 'D'+ _indexCelda)){
                                //     if(element.celdas.find(c => c.Celda === 'D'+ _indexCelda)['Valor'] === 'NoHayEmpresa')  respData.filas[j].celdas[ic].Valor = ''
                                // }

                                if(celdaN === undefined || celdaN === null){
                                    // letraCelda === 'D' ? respData.filas[j].celdas.push({Celda:celda,ConErrores:false,Errores:[""]}) :
                                    respData.filas[j].celdas.push({Celda:celda,Valor:'Este campo no existe ',ConErrores:true,Errores:["Este campo no puede ser vacío-"]})
                                        
                                }
                            
                            }
                            let isEmpresaBanco       = element.celdas.find(c => c.Celda === 'A'+ indexCelda);
                            let isCuenta = element.celdas.find(c => c.Celda === 'B'+ indexCelda);
                            let isSaldo = element.celdas.find(c => c.Celda         === 'C'+ indexCelda);
                            let isComentario = element.celdas.find(c => c.Celda     === 'D'+ indexCelda);

                            // console.log('isEmpresaBanco',isEmpresaBanco)
                            // console.log('isCuenta',isCuenta)
                            // console.log('isSaldo',isSaldo)
                            // console.log('isComentario',isComentario)
                            if(!element.celdasConErrores){
                                console.log('guardar fila:',element.celdas)
                                auxRespJson.push(
                                    {
                                        'Empresa_Banco'        :isEmpresaBanco         ? isEmpresaBanco['Valor']         : '',
                                        'Cuenta'  :isCuenta   ? isCuenta['Valor']   : '',
                                        'Saldo'          :isSaldo           ? isSaldo['Valor']           : '',
                                        'Comentarios'      :isComentario       ? isComentario['Valor']       : '',
                                            
            
                                    }
                                ) 
                            }

                    auxTbl.push(
                        {
                            'EMPRESA_BANCO'        :isEmpresaBanco         ? isEmpresaBanco['Valor']         : '',
                            'CUENTA'  :isCuenta   ? isCuenta['Valor']   : '',
                            'SALDO'          :isSaldo           ? isSaldo['Valor']           : '',
                            'COMENTARIO'      :isComentario       ? isComentario['Valor']       : '',
                                

                        }
                    )                   
                })
                setXlsDataRespons(respData)
                toggleLoader(false)
                setShowModalResponse(true)
                setXlsDownloadRespons(auxTbl)
                setJsonSave(auxRespJson)

            })
        }
    }
    const uploadEvidence = () => {
        // console.log('selected_File',selected_File)
        // console.log('selectedFile',selectedFile)

        console.log('dataMovRow',dataMovRow)
        let listRow = []
        let respValidateFilter = validateFilterEvidence()
        if(respValidateFilter.error){
            fnNotification(['Error: '+respValidateFilter.message , 'error', true])
            return false
        }
        listRow.push(dataMovRow)
        debugger

            // toggleLoader(true)
            setSelected_Pdf(selectedPdf)
            console.log(referenceEvidece)
            console.log(dateEvidence)
            console.log(importEvidence)
            // debugger
            // let jsomRgex = [
            //     {"ReferenciaEvidencia":[
            //         {"Etiqueta":"Número de Referencia|Referencia"},
            //         {"valor":"(:)*\\s*"+referenceEvidece}
                    
            //     ]},
            //     {"FechaMovimiento":[
            //         {"Etiqueta":"(Fecha de Operación|Fecha y Hora de Captura|Fecha de Ejecución|Fecha|Liquidación)"},
            //         {"valor":"(:)*\\s*[0-9]{1,2}[/|-]([0-9]{1,2}|[A-Za-z]{3})[/|-][0-9]{2,4}"}
            //     ]},
            //     {"ImporteEvidencia:":[
            //         {"Etiqueta":"(Importe|Importe a Transferir)"},
            //         {"valor":"(:)*\\s*[$]*\\s*([0-9]|[,])*[.](\\d{2})"}
            //     ]},
            // ]
          
            let jsomRgex = [
                {"ReferenciaEvidencia":[
                    {"Etiqueta":"(Número de Referencia|Referencia|No. de Autorización)"},
                    {"valor":":*\\s*"+referenceEvidece}
                    
                ]},
                {"FechaMovimiento":[
                    {"Etiqueta":"(Fecha de Operación|Fecha y Hora de Captura|Fecha de Ejecución|Fecha|Liquidación)"},
                    {"valor":":*\\s*[0-9]{1,2}[/|-]([0-9]{1,2}|[A-Za-z]{3})[/|-][0-9]{2,4}"}
                ]},
                {"ImporteEvidencia":[
                    {"Etiqueta":"(Importe(?: a Transferir)?)"},
                    {"valor":":\\s*\\$([0-9,]+(?:\\.\\d{2})?)"}
                ]}
            ]

            upLoadFile({
                endPoint:'ProgramacionMovimientos/Pdf',
                referencia:referenceEvidece,
                fechaEvidencia:dateEvidence,
                importe:importEvidence,
                listMovs:JSON.stringify(listRow),
                listRegEx:JSON.stringify(jsomRgex),
                // importesolicitado:dataMovRow.montoSolicitado,
                // bancoDestino:dataMovRow.bancoDestino,
                // bancoOrigen:dataMovRow.bancoOrigen,
                // comentarios:dataMovRow.comentarios,

                // cuantaOrigen:dataMovRow.cuantaOrigen,
                // cuentaDestino:dataMovRow.cuentaDestino,

                // empresaDestino:dataMovRow.empresaDestino,
                // empresaOrigen:dataMovRow.empresaOrigen,
                // idBancoDestino:dataMovRow.idBancoDestino,
                // idBancoOrigen:dataMovRow.idBancoOrigen,
                // idCuentaDestino:dataMovRow.idCuentaDestino,
                // idCuentaOrigen:dataMovRow.idCuentaOrigen,
                // idEmpresaDestino:dataMovRow.idEmpresaDestino,
                // idEmpresaOrigen:dataMovRow.idEmpresaOrigen,
                // idMovimiento:dataMovRow.idMovimiento,


                
                

            },{archivo: selectedPdf,},(response,type,success) =>{
                console.log(response,type,success)
        debugger

                // let respData = respons[0];
                toggleLoader(false)

                if(success){
                    console.log(response[0])
                    let auxResp = [];
        debugger

                    // response.forEach(e => {
                        response[0].empresaBancoOrigen = response[0].empresaOrigen + "-" + response[0].bancoOrigen;
                        response[0].empresaBancoDestino = response[0].empresaDestino + "-" + response[0].bancoDestino;
        debugger
                        
                        let cantidad = response[0].importereaplicado.replace(/,/g, "");

                    console.log(cantidad)
                    debugger

                        response[0].importereaplicado = parseFloat(cantidad).toString()
                    console.log(response[0].importereaplicado)

                        console.log(response[0].fechaEvidencia)
                        let fechaOriginal = response[0].fechaEvidencia;
                        // Crear un objeto Date a partir de la cadena
                        let fecha = new Date(fechaOriginal);
                        debugger

                        // Obtener el día, mes y año
                        let dia = ("0" + fecha.getDate()).slice(-2);  // Asegura dos dígitos
                        let mes = ("0" + (fecha.getMonth() + 1)).slice(-2);  // Los meses empiezan desde 0, por lo que se suma 1
                        let año = fecha.getFullYear();

        debugger
        // Formatear en dd/mm/aaaa
                        let fechaFormateada = `${dia}/${mes}/${año}`;

                        response[0].fechasolicitud = fechaFormateada;
                        debugger


                        // auxResp.push(e)
                        
                    // });
                    setShowModalAction(false)
                    // alert('Respuesta correcta')
                    setIsEvidenceMasiv(false)
                    setIsEvidMov(true)
                    setDataRespEvMov(response)
                }
                let auxTbl = []
                let auxRespJson = []

            })
    }
    const saveFileEvidence = () => {
        console.log('movimientosTbl',movimientosTbl)
        console.log('dataRespEvMov',dataRespEvMov)
        console.log('idMovimiento',dataRespEvMov[0].idMovimiento)
        let newMovSave = []
        console.log('isEvidenceMasiv',isEvidenceMasiv)
        debugger
        dataRespEvMov.forEach(e =>{
            e.type_Save = 1;
            if(e.typePetMov == 1) newMovSave.push(e)
        })
     console.log(newMovSave)

     let _endPoint = isEvidenceMasiv ? 'ZipSave' : 'PdfSave' 
        debugger
        savedFile({
            endPoint:'ProgramacionMovimientos/PdfSave',
            listMovSave:JSON.stringify(newMovSave),
            typeSave: isEvidenceMasiv,
    },{archivo: isEvidenceMasiv ? selectedZip :selectedPdf,},(response,type,success)=>{
                    console.log(response,type,success)
                    
                    if(success){
                        fnNotification(['Se han aplicado los movimientos con las evidencias correpondientes exitosamente', 'success', true])
                        setIsEvidMov(false)
                        getDataSolicitudesMov(2)

                    }
                    


                })
    }
    const selectTypeCancel = (e) =>{
        // console.log(e)
        // console.log(e.target)
        // console.log(e.target.name)
        // console.log(e.target.value)
        console.log(e.target.checked)
        if(e.target.value === '5'){
            setTypeCancelMov(5)
        setIsCheckedTypeCancelMov(e.target.checked)
        setIsCheckedTypeCancelMovPet(!e.target.checked)


        } 
        if(e.target.value === '1'){
            setTypeCancelMov(1)
            setIsCheckedTypeCancelMov(!e.target.checked)
            setIsCheckedTypeCancelMovPet(e.target.checked)
        } 


    }
    const validateFilterEvidence =() =>{
        console.log(importEvidence)
        let error = false
        let message = "";
        let messagePart = "Se requiere capturar";
        if(referenceEvidece === "")  {error = true; message= messagePart + "una referencia";}
        if(dateEvidence === '')      {error = true; message=messagePart  + "fecha de movimiento";}
        if(importEvidence <= 0 )     {error = true; message=messagePart  + "un importe valido";}
        if(selectedPdf.length === 0){error = true; message="No se ha seleccionado ningún archivo";}

        return {error: error, message: message}
    }
    const btnSearch = (type) => {
        console.log('busca', type)
        switch(type)
        {
            case 1:
                btnSearchSaldos(type)
                break;
            case 2:
                console.log('busca Solicitudes sin aprobar',btnSearchPetMov(type,allSolicitudes))
                
                // setSolicitudes()
                break;
            case 3:
                console.log('busca Movimientos')
                setMovimientosTbl(btnSearchPetMov(type,allMovimientos))
                break
            case 4:
                console.log('busca solicitudes aprobadas')
                setPetAut(btnSearchPetMov(type,allPetAut))
                    break
            case 5:
                console.log('busca consiliación')


                break
            case 6:

                break
            default:
        }

    }
    const btnSearchSaldos = (type) => {
        if(changeInitE){
            console.log('optionEmpresa',optionEmpresa)
            console.log('optionCuenta',optionCuenta)
            console.log(dataAllDisponibilidad)
            console.log('tipo',type)

            
            
            if(optionEmpresa.value && optionCuenta.value === undefined && fecha_Movimiento === ''){
                setDataDisponibilidad(dataAllDisponibilidad.filter(d => d.idEmpresa === optionEmpresa.idEmpresa && d.idBanco === optionEmpresa.idBanco))
            }
            if(optionEmpresa.value && optionCuenta.value && fecha_Movimiento === ''){
                setDataDisponibilidad(dataAllDisponibilidad.filter(d => d.idEmpresa ===  optionEmpresa.idEmpresa && d.idBanco === optionEmpresa.idBanco && d.cuenta === optionCuenta.label))
            }
            if(optionEmpresa.value && optionCuenta.value && fecha_Movimiento !== ''){
                setDataDisponibilidad(dataAllDisponibilidad.filter(d => d.idEmpresa ===  optionEmpresa.idEmpresa && d.idBanco === optionEmpresa.idBanco && d.cuenta === optionCuenta.label && d.fechaMovimiento ? d.fechaMovimiento.split('T')[0] : '' === fecha_Movimiento)) 
            }
            if(optionEmpresa.value === undefined && optionCuenta.value && fecha_Movimiento !== ''){
                setDataDisponibilidad(dataAllDisponibilidad.filter(d => d.cuenta === optionCuenta.label && d.fechaMovimiento ? d.fechaMovimiento.split('T')[0] : '' === fecha_Movimiento)) 
            }
            if(optionEmpresa.value === undefined && optionCuenta.value && fecha_Movimiento === ''){
                setDataDisponibilidad(dataAllDisponibilidad.filter(d => d.cuenta === optionCuenta.label )) 

            }
            if(optionEmpresa.value === undefined && optionCuenta.value === undefined && fecha_Movimiento !== ''){
                console.log('busca por fecah',fecha_Movimiento)
                let auxE = []
                dataAllDisponibilidad.forEach(element => {
                    let fecha  = element.fechaMovimiento ? element.fechaMovimiento.split('T')[0] : 'fec'
                    if(fecha_Movimiento === fecha){
                        console.log('la fecha que se usa')
                        auxE.push(element)
                    }
                    
                });
                setDataDisponibilidad(auxE)
                // console.log(dataAllDisponibilidad.filter(d => d.fechaMovimiento ? d.fechaMovimiento.split('T')[0] : "f" === fecha_Movimiento))
                // setDataDisponibilidad(dataAllDisponibilidad.filter(d => d.fechaMovimiento ? d.fechaMovimiento.split('T')[0] : "f" === fecha_Movimiento))
            }
            if(optionEmpresa.value === undefined && optionCuenta.value === undefined && fecha_Movimiento === ''){
                setDataDisponibilidad(dataAllDisponibilidad)
            }
            // if(optionEmpresa.value || fecha_Movimiento !== ''){
            // console.log('buscar datos')
            // // let auxData = dataAllDisponibilidad.filter(d => d.idEmpresa === optionEmpresa.value)
            // // console.log(auxData)
            // // let aux2 = optionCuenta.value ? dataAllDisponibilidad.filter(d => d.idEmpresa === optionEmpresa.value && d.cuenta === optionCuenta.label) :''
            // // console.log(aux2)
            // if(optionCuenta.value && fecha_Movimiento !== ''){
            //     setDataDisponibilidad(dataAllDisponibilidad.filter(d => d.idEmpresa === optionEmpresa.value && d.cuenta === optionCuenta.label && d.fechaMovimiento.split('T')[0] === fecha_Movimiento)) 
            // }
            // if(optionCuenta.value === undefined && fecha_Movimiento !== ''){
            //     setDataDisponibilidad(dataAllDisponibilidad.filter(d => d.idEmpresa === optionEmpresa.value && d.fechaMovimiento.split('T')[0] === fecha_Movimiento))
            // }

            // setDataDisponibilidad(dataAllDisponibilidad.filter(d => d.idEmpresa === optionEmpresa.value))
            // // dataAllDisponibilidad.filter(d => d.idEmpresa === optionEmpresa.value)
            // // setDataDisponibilidad(dataAllDisponibilidad.filter(d => d.idEmpresa === optionEmpresa.value))
            
            // }

            // if(optionEmpresa.value === undefined && fecha_Movimiento === ''){
            //       console.log('todos los datos')
            //     setDataDisponibilidad(dataAllDisponibilidad)
            // }
        }else{
            console.log('No busca')

        }
        
    }
    const btnSearchPetMov = (type,dataSearch) => {
        console.log('btnSearchPetMov type', type)
        console.log('dataSearch', dataSearch)
        console.log('allSolicitudes',allSolicitudes)
        console.log('optionTipoMovimiemto',optionTipoMovimiemto)
        console.log('optionEmpresaOrigen',optionEmpresaOrigen)
        console.log('optionEmpresaDestino',optionEmpresaDestino)
        console.log('fecha_Movimiento',fecha_Movimiento)
        
        if(optionTipoMovimiemto.value === undefined && optionEmpresaOrigen.value === undefined && optionEmpresaDestino.value === undefined && (fecha_Movimiento === '' || fecha_Movimiento === null)) return dataSearch
        if(optionTipoMovimiemto.value && optionEmpresaOrigen.value === undefined && optionEmpresaDestino.value === undefined && (fecha_Movimiento === '' || fecha_Movimiento === null))               return  dataSearch.filter(s => s.idTipoMovimiento === optionTipoMovimiemto.value);
        if(optionTipoMovimiemto.value === undefined && optionEmpresaOrigen.value  && optionEmpresaDestino.value === undefined && (fecha_Movimiento === '' || fecha_Movimiento === null))              return  dataSearch.filter(s => s.idEmpresaOrigen === optionEmpresaOrigen.idEmpresa);                                                       
        if(optionTipoMovimiemto.value && optionEmpresaOrigen.value && optionEmpresaDestino.value === undefined && (fecha_Movimiento === '' || fecha_Movimiento === null))                             return  dataSearch.filter(s => s.idTipoMovimiento === optionTipoMovimiemto.value && s.idEmpresaOrigen === optionEmpresaOrigen.idEmpresa);
        if(optionTipoMovimiemto.value === undefined && optionEmpresaOrigen.value === undefined  && optionEmpresaDestino.value  && (fecha_Movimiento === '' || fecha_Movimiento === null))             return  dataSearch.filter(s => s.idEmpresaDestino === optionEmpresaDestino.idEmpresa);
        if(optionTipoMovimiemto.value && optionEmpresaOrigen.value && optionEmpresaDestino.value && (fecha_Movimiento === '' || fecha_Movimiento === null))                                           return  dataSearch.filter(s => s.idTipoMovimiento === optionTipoMovimiemto.value && s.idEmpresaOrigen === optionEmpresaOrigen.idEmpresa && s.idEmpresaDestino === optionEmpresaDestino.idEmpresa);




    }
    // const btnSearchSolicitudes = (type) => {
    //     console.log('type',type)
    //     console.log('btnSearchSolicitudes')
    //     console.log('allSolicitudes',allSolicitudes)
    //     console.log('optionTipoMovimiemto',optionTipoMovimiemto)
    //     console.log('optionEmpresaOrigen',optionEmpresaOrigen)
    //     console.log('optionEmpresaDestino',optionEmpresaDestino)
    //     console.log('fecha_Movimiento',fecha_Movimiento)
    //     let t = null;
    //     if(optionTipoMovimiemto.value === undefined && optionEmpresaOrigen.value === undefined && optionEmpresaDestino.value === undefined && (fecha_Movimiento === '' || fecha_Movimiento === null)){
    //      t =  type === 2 ? setSolicitudes(allSolicitudes) : type === 3 ? setRelMovimientosSolicitudes(allMovimientos) : '' 
    //     }
    //     if(optionTipoMovimiemto.value && optionEmpresaOrigen.value === undefined && optionEmpresaDestino.value === undefined && (fecha_Movimiento === '' || fecha_Movimiento === null)){
    //     t = type === 2 ? 
    //         setSolicitudes(allSolicitudes.filter(s => s.idTipoMovimiento === optionTipoMovimiemto.value)) : 
    //         type === 3 ? setRelMovimientosSolicitudes(allMovimientos.filter(s => s.idTipoMovimiento === optionTipoMovimiemto.value)) : ''

    //     }
    //     if(optionTipoMovimiemto.value && optionEmpresaOrigen.value  && optionEmpresaDestino.value === undefined && (fecha_Movimiento === '' || fecha_Movimiento === null)){
    //     t = type === 2 ? 
    //         setSolicitudes(allSolicitudes.filter(s => s.idTipoMovimiento === optionTipoMovimiemto.value && s.idEmpresaOrigen === optionEmpresaOrigen.idEmpresa)):
    //         type === 3 ? setRelMovimientosSolicitudes(allMovimientos.filter(s => s.idTipoMovimiento === optionTipoMovimiemto.value && s.idEmpresaOrigen === optionEmpresaOrigen.idEmpresa)) : ''
    //     }
    //     if(optionTipoMovimiemto.value === undefined && optionEmpresaOrigen.value  && optionEmpresaDestino.value === undefined && (fecha_Movimiento === '' || fecha_Movimiento === null)){
    //         setSolicitudes(allSolicitudes.filter(s => s.idEmpresaOrigen === optionEmpresaOrigen.idEmpresa))
    //     }
    //     if(optionTipoMovimiemto.value === undefined && optionEmpresaOrigen.value === undefined  && optionEmpresaDestino.value && (fecha_Movimiento === '' || fecha_Movimiento === null)){
    //         setSolicitudes(allSolicitudes.filter(s => s.idEmpresaDestino === optionEmpresaDestino.idEmpresa))
    //     }
    // }
    const changeDate = (e,n) => {
        console.log(e.target)
        console.log(e.target.value)
        let fechaAux = '' 
        if(e.target.value !== undefined && e.target.value !== ''){
            fechaAux = e.target.value
            setChangeInitE(true)

        }
        setFechaMovimiento(fechaAux)
        console.log(fechaAux)

    }
    const onCerrar = () => {
        setShowModalResponse(false)
        closeDragAndDrop(false)
        setSelected_File([])
        getTDispSaldos({
            endPoit:'saldosDisponibles',
        },respuesta =>{
            console.log('respSaldos',respuesta)
            let auxTot = 0
            respuesta.forEach((element,j) => {
                
                auxTot = auxTot + parseFloat(element.saldo)
                element.check = false
                element.idRowSaldo = j+1;


            });
            console.log(auxTot)
            setTotSaldo(auxTot)
            setDataDisponibilidad(respuesta)
            setAllDataDisponibilidad(respuesta)
        })
    }
    const upload = () => {
        console.log('upload')
        console.log('xlsDataRespons',xlsDataRespons)
        console.log('jsonSave',jsonSave)
        console.log('jsonSaveString',JSON.stringify(jsonSave))
        saveDataSaldos({
            JSON:JSON.stringify(jsonSave)
        },(resp,type,success) =>{
            console.log('resp',resp)
            console.log('success',success)
            if(success) onCerrar();

        })
    }
    const downSaldosExcel = () => {

    }
    const handleChangeChkAll = (status) => {
        console.log('status',status)
        console.log(chckAll,'chckAll')
        setchckAll(status)
        let auxData = []
        dataDisponibilidad.forEach(e =>{
            e.check = status
        })
        if(status){
        setSaldoParcial(totSaldo)
        }else{
        setSaldoParcial(0)

        }
    }
    const handleChangeCbx = (rowVal,statusRow) => {

        // console.log('dataDisponibilidad',dataDisponibilidad)
        let auxData = [...dataDisponibilidad]
        // console.log(auxData)
        // let solicitudN = auxSolicitudes.find(x => x.idSolicitudPagoDetalle === rowVal.idSolicitudPagoDetalle)
        let indx = auxData.findIndex(x => x.idRowSaldo === rowVal.idRowSaldo)

        auxData[indx].check = statusRow
        // setDataSolicitudesTbl(auxSolicitudes)
        setDataDisponibilidad(auxData)
        // console.log(rowVal)
        // console.log(rowVal.saldo)
        // console.log(statusRow)
        let saldoAux = saldoParcial;
        saldoAux = statusRow ? (saldoAux + parseFloat(rowVal.saldo)) : (saldoAux - parseFloat(rowVal.saldo)) 
        setSaldoParcial(saldoAux)
        // console.log(saldoAux)
        // calculSaldo.current.value = saldoAux

    }
    const getDataSolicitudesMov = (endPoint) => {
        console.log('endPoint',endPoint)
        debugger
        getMovimientosCompanias({
            endPoint: endPoint
        },resp=>{
            console.log(resp)
            if(endPoint === 1){
                debugger
                for(let i = 0; i < resp.length; i++) {
                 console.log('resp[i]',resp[i])

                   debugger

                    resp[i].optionEmpresaBancoCuenta = {label:resp[i].empresaOrigen+'-'+resp[i].bancoOrigen+'-'+resp[i].cuentaOrigen,value:resp[i].idEmpresaOrigen+'-'+resp[i].idBancoOrigen+'-'+resp[i].idCuentaOrigen,idEmpresa:resp[i].idEmpresaOrigen}
                    resp[i].optionEstatus = estatusSolicitudes.find(s => s.value === resp[i].idEstatus)
                    resp[i].check = false
    
                }
                debugger
                
                console.log('resp',resp)
                debugger

                setAllSolicitudes(resp)
                setSolicitudes(resp)
                console.log('resp[i]',resp)
            }else{
                let jsonRsp = JSON.parse(resp[0].JSON)
                // console.log(resp[0].JSON)
                console.log(jsonRsp)

                // console.log('Movimientos',jsonRsp['Movimientos'])
                // console.log('SolicitudesNoRelacionadas',jsonRsp['SolicitudesNoRelacionadas'])
                // console.log('SolicitudesRelacionadas',jsonRsp['SolicitudesRelacionadas'])
                // console.log('cuentasBancosEmpresas',cuentasBancosEmpresas)

                // let bancosAll =  cuentasBancosEmpresas.bancos
                // let cuentasAll =  cuentasBancosEmpresas.cuentas
                // let empresasAll = cuentasBancosEmpresas.empresas
                // let auxMovTbl = []
                // jsonRsp['Movimientos']?.map((x,i) =>{
                //     console.log(x,i)
              
                //     // auxMovTbl.push({
                //     //     idMovimiento:x.idMovimiento,
                //     //     tipoMovimiento: cuentasBancosEmpresas.TipoMovimiento.find(m => m.idTipoMovimiento === x.idTipoMovimiento).descripcion,
                //     //     concepto:x.concepto,
                //     //     empresaOrigen:empresasAll.find(e => e.ID_empresa === x.idEmpresaOrigen).nom_corto +'-'+bancosAll.find(b => b.idbanco === x.idBancoOrigen).banco+'-'+cuentasAll.find(c => c.idcuenta === x.idCuentaOrigen).numerodecuenta,
                //     //     empresaDestino:empresasAll.find(empd => empd.ID_empresa === x.idEmpresaDestino).nom_corto +'-'+bancosAll.find(bc => bc.idbanco === x.idBancoDestino).banco+'-'+cuentasAll.find(cnt => cnt.idcuenta === x.idCuentaDestino).numerodecuenta,
                //     //     importeAplicado:x.montoAplicado,
                //     //     importeSolicitado:x.montoSolicitado,
                //     //     estatus:'Autorizado'
                //     // })  
                // }
                // )

                // console.log('bancosAll',bancosAll)
                // console.log('allCuentas',allCuentas)
                // console.log('empresasAll',empresasAll)
                // console.log('auxMovTbl',auxMovTbl)
                let auxMov = [];

                jsonRsp['Movimientos'].forEach((m,i) => {
                    console.log('cuantaOrigen',allCuentas.find(x=>x.idcuenta === m.idCuentaOrigen).label)
                    m.cuantaOrigen =  allCuentas.find(x=>x.idcuenta === m.idCuentaOrigen).label
                    m.cuentaDestino =  allCuentas.find(x=>x.idcuenta === m.idCuentaDestino).label
                    m.check = false
                    auxMov.push(m)
                    // console.log('m',m)
                    // console.log('i',i)
                })

                setAllMovimientos(auxMov)
                console.log(auxMov)
                setMovimientosTbl(auxMov)
                setRelPetitionMovAll(jsonRsp['SolicitudesRelacionadas'])
                // setRelPetitionMov(jsonRsp['SolicitudesRelacionadas'])
                setPetAut(jsonRsp['SolicitudesNoRelacionadas'])
                setAllPetAut(jsonRsp['SolicitudesNoRelacionadas'])
            }
        })

    }
    const changeTab = (t) => {
        console.log('Cambio de tab',t)
        setOptionTipoMovimiento([])
        setOptionEmpresaOrigen([])
        setOptionEmpresaDestino([])
        setNumTab(t)
        setFechaMovimiento(null)
        switch (t){
            case 1:
                break;
            case 2:
                setFechaMovimiento('')
                getDataSolicitudesMov(1)
                setCuentasOrigenMonitor(allCuentas)
                setBancosOrigen(bancos) 
                break;
            case 3:
                getDataSolicitudesMov(2)

                console.log('ProgramacionMov')
                break;
            case 4:
                console.log('Tab Movimientos')
                
             
                break;
            case 5:
                    console.log('Tab Solicitudes')
                    
                 
                    break;
            default:

        }


    }
    const nuevaSolicitud = (mov_Petition) => {
        console.log(mov_Petition,'nuevaSolicitud')
        toggleLoader(true)
        let auxError = false
        setMovPetition(mov_Petition)
        setOptiontipoMovimiemtoMNM([])
        setFechaMovimientoNMN(null)
        setConceptoValue('')
        setOptionEmpresaOrigenMNM([])
        setOptionEmpresaDestinoMNM([])
        setSolicitanteMNM('')
        setOptionSolicitante([])
        setImporteValueNMN(0)
        setComentariosNMN('')
        if(mov_Petition === 0){
            console.log('ExisteSession.getInfoUsuario()',ExisteSession.getInfoUsuario())
           
            setShowModalNuevaSolicitud(true)
            setIsDetailPet(false)
            toggleLoader(false)

        }
        if(mov_Petition === 1){
        let auxData = [...solicitudes]
            console.log('auxData',auxData)
            let auxDataSoli = []
            let totalMovimiento = 0
            auxData.forEach(element => {
                if(element.check){
                    if(auxDataSoli.length === 0){
                        console.log('es el primero')
                        auxDataSoli.push(element)
                        totalMovimiento = element.montoSolicitado
                    }else{
                        console.log('NO es el primero')
                        let auxR = auxDataSoli[0].idEmpresaDestino
                        console.log('auxR==',auxR)
                        console.log('element.idEmpresaDestino==',element.idEmpresaDestino)

                        if(element.idEmpresaDestino === auxR){
                        console.log('las cuentas son iguales, se agregara el elemento',element)
                        auxDataSoli.push(element)
                        totalMovimiento = totalMovimiento + element.montoSolicitado

                            
                        }else{
                        console.log('las cuentas NO son iguales, NO se agregara el elemento',element)
                    
                        auxError = true

                        }


                    }

                }
            });

            toggleLoader(false)
            if(auxError){
                fnNotification(['Error: Para generar un nuevo movimiento la cuenta destino de los registros seleccionados tiene que ser la misma ' , 'error', true])

            } else{
                if(auxDataSoli.length > 0){
                    console.log('auxDataSoli',auxDataSoli)
                    setTotalMovimiento(totalMovimiento)
                    setShowModalNuevaSolicitud(true)
                    setIsDetailPet(false)
                    setSolicitudesaNuevoMovimiento(auxDataSoli)
                    setOptionEmpresaDestinoMNM({label:auxDataSoli[0].empresaDestino+'-'+auxDataSoli[0].bancoDestino+'-'+auxDataSoli[0].cuentaDestino,value:auxDataSoli[0].idEmpresaDestino+'-'+auxDataSoli[0].idBancoDestino+'-'+auxDataSoli[0].idCuentaDestino})
                    // setImporteValueNMN(auxDataSoli[0].montoSolicitado)
                    // setComentariosNMN(auxDataSoli[0].comentarios)
        
                    }
            }
        }

    
        
    }
    const nuevoMovimiento = () => {
        console.log('nuevoMovimiento')

    }
    const onCerrarMNS = () => {
        setShowModalNuevaSolicitud(false)
        setsolicitudes_NewMov([])
        setSolicitudesaNuevoMovimiento([])
    }
    const saveNuevaSolicitud = (status,typeSave) => {
        console.log('saveNuevaSolicitud')
        console.log('optiontipoMovimiemtoMNM',optiontipoMovimiemtoMNM)
        console.log('fechaMovimientoNMN',fechaMovimientoNMN)
        console.log('conceptoValue',conceptoValue)
        console.log('optionEmpresaOrigenMNM',optionEmpresaOrigenMNM)
        console.log('optionEmpresaDestinoMNM',optionEmpresaDestinoMNM)
        console.log('optionCuentaOrigenMNM',optionCuentaOrigenMNM)
        console.log('optionCuentaDestinoMNM',optionCuentaDestinoMNM)
        console.log('importeValueNMN',importeValueNMN)
        console.log('comentariosNMN',comentariosNMN)

        if(typeSave === 0){
        debugger
            let _validation = validateForm();
            !_validation? 
            saveNewSolicitud({
                endPoit:'nuevaSolicitud',
                idTipoMovimiento:optiontipoMovimiemtoMNM.value,
                fechaMovimientoNMN:fechaMovimientoNMN,
                concepto:conceptoValue,
                idEmpresaOrigen:idEmpresaOrigenSave,
                idBancoOrigen:idBancoOrigenSave,
                idCuentaOrigen:idCuentaOrigenSave,
                idEmpresaDestino:idEmpresaDestinoSave,
                idBancoDestino:idBancoDestinoSave,
                idCuentaDestino:idCuentaDestinoSave,
                montoSolicitado:importeValueNMN,
                comentarios:comentariosNMN,
                isAutorizate:status

            },(response,t,succues) =>{
                console.log(response)
                console.log(t)
                console.log(succues)
                if(succues){
                    console.log('guardadoOK')
                    setShowModalNuevaSolicitud(false)
                    getDataSolicitudesMov(1)


                }
            }):
            fnNotification(['Error: ' + _validation, 'error', true])
        }else{
            debugger
            console.log('Crear nuevo movimiento')
            console.log('tot parcial',nuevoTotMovimiento)
            console.log('total',totalMovimiento)
            console.log('solicitudes_NewMov',solicitudes_NewMov)
            console.log('solicitudesaNuevoMovimiento',solicitudesaNuevoMovimiento)
            console.log('optionEmpresaDestinoMNM',optionEmpresaDestinoMNM)
            let auxIdSolicitudes = ''
            let auxArraySave = []
            let auxArraySolicitud = []
            
            debugger

            if(parseInt(nuevoTotMovimiento) < parseInt(totalMovimiento))
                fnNotification(['Error: El monto acumulado no puede ser menor al monto solicitado' , 'error', true])
            else {
                
                solicitudesaNuevoMovimiento.forEach(e =>{
                    auxIdSolicitudes += e.idSolicitud +',' 
                    // auxArraySolicitud.push({
                    //     idSolicitud                   :e.idSolicitud                ,
                    //     idTipoMovimiento              :e.idTipoMovimiento           ,
                    //     concepto                      :e.concepto                   ,
                    //     idEmpresaOrigen               :e.idEmpresaOrigen            ,
                    //     idBancoOrigen                 :e.idBancoOrigen              ,
                    //     idCuentaOrigen                :e.idCuentaOrigen             ,
                    //     idEmpresaDestino              :e.idEmpresaDestino           ,
                    //     idBancoDestino                :e.idBancoDestino             ,
                    //     idCuentaDestino               :e.idCuentaDestino            ,
                    //     montoSolicitado               :e.montoSolicitado            ,
                    //     montoAplicado                 :e.montoAplicado              ,
                    //     fechaAplicacion               :e.fechaAplicacion            ,
                    //     comentarios                   :e.comentarios                ,
                    //     idEstatus                     :e.idEstatus                  ,
                    //     fechaPago                     :e.fechaPago                  ,
                    //     numReferencia                 :e.numReferencia              ,
                    //     pathComprobanteMov            :e.pathComprobanteMov         ,
                    //     originPathComprbanteMov       :e.originPathComprbanteMov    ,
                    //     nombreArchivoComprobanteMov   :e.nombreArchivoComprobanteMov,

                    // })
                    auxArraySolicitud.push({
                        idSolicitud                   :e.idSolicitud ,
                        idEstatus                     :2             ,

                    })
                })
                console.log('auxArraySolicitud',auxArraySolicitud)
                debugger
                
                if(solicitudes_NewMov.length > 0){
                for(var s = 0; s < solicitudes_NewMov.length; s++){
                    console.log('solicitudes_NewMov'+s,solicitudes_NewMov[s])

                    auxArraySave.push({
                        idTipoMovimiento:solicitudes_NewMov[s].idTipoMovimiento,
                        concepto:solicitudes_NewMov[s].concepto,
                        idEmpresaOrigen:solicitudes_NewMov[s].idEmpresaOrigen,
                        idBancoOrigen:solicitudes_NewMov[s].idBancoOrigen,
                        idcuentaOrigen:solicitudes_NewMov[s].idCuentaOrigen,
                        idEmpresaDestino:solicitudes_NewMov[s].idEmpresaDestino,
                        idBancoDestino:solicitudes_NewMov[s].idBancoDestino,
                        idCuentaDestino:solicitudes_NewMov[s].idCuentaDestino,
                        montoSolicitado:solicitudes_NewMov[s].montoSolicitado,
                        montoAplicado:'',
                        fechaAplicacion:'',
                        comentarios:solicitudes_NewMov[s].comentarios,
                        fechaPago:solicitudes_NewMov[s].fechaMovimientoNMN,
                        idSolicitudes:auxIdSolicitudes.substring(0,auxIdSolicitudes.length-1),
                        numReferencia:'',
                        originPathLineaCaptura:'',
                        nombreArchivoLineaCaptura:'',
                        pathComprobanteMov:'',
                        originPathComprbanteMov:'',
                        nombreArchivoComprobanteMov:''
                    })
                }
                console.log(auxArraySave)
                console.log(JSON.stringify(auxArraySave))
                console.log(auxArraySolicitud)
                console.log(JSON.stringify(auxArraySolicitud))
                debugger

                saveNewMov({
                    endPoit:'saveMov',
                    JSONMOV:JSON.stringify(auxArraySave),
                    JSONPET:JSON.stringify(auxArraySolicitud)
                },(resp,t,succues) => {
                    console.log(resp)
                    console.log(t)
                    console.log(succues)
                    if(succues){
                        setShowModalNuevaSolicitud(false)
                        let auxData = [...solicitudes]
                        auxData.forEach(e => {
                            e.check = false
                        });
                        setSolicitudes(auxData)
                        setsolicitudes_NewMov([])
                        setSolicitudesaNuevoMovimiento([])
                        getDataSolicitudesMov(1)


                    }
                })
    
                }else fnNotification(['Error: No se han seleccionado registros para crear el nuevo movimiento' , 'error', true])
        

            }


        }

        
        


    }
    const cambiaStatusSolicitud = (idSolicitud,status) => {
        console.log('idSolicitud',idSolicitud)
        console.log('status',status)
        toggleLoader(true)
        updateSolicitud({
            endPoit:'solicitud',
            idSolicitud:idSolicitud,
            idEstatus:status,
        },(resp,t,succues) => {
            console.log(resp);
            console.log(t)
            console.log(succues)
            getDataSolicitudesMov(1)
            if(succues){
                // getDataSolicitudesMov()
                setShowModalNuevaSolicitud(false)

            }
            toggleLoader(false)
        })

    }
    const validateForm = (value) => {
        console.log(parseInt(optiontipoMovimiemtoMNM.value))
        console.log(fechaMovimientoNMN)
        var _mesage = "";

        if(!optiontipoMovimiemtoMNM.value) _mesage += "Se requiere un tipo de movimiento. ";
        if(!fechaMovimientoNMN) _mesage += "El campo fecha no puede ser vacío";   
        if(conceptoValue === "") _mesage += "El campo concepto no puede ser vacío"
        if(!optionEmpresaOrigenMNM.value)_mesage += "El campo Empresa Banco Cuenta origen no puede ser vacío"
        if(!optionEmpresaDestinoMNM.value)_mesage += "El campo Empresa Banco Cuenta destino no puede ser vacío"
        if(!importeValueNMN)_mesage += "El campo importe no puede ser vacío"
        return _mesage;

        // idEmpresaOrigen:idEmpresaOrigenSave,
        // idBancoOrigen:idBancoOrigenSave,
        // idCuentaOrigen:idCuentaOrigenSave,
        // idEmpresaDestino:idEmpresaDestinoSave,
        // idBancoDestino:idBancoDestinoSave,
        // idCuentaDestino:idCuentaDestinoSave,
        // montoSolicitado:importeValueNMN,
        // comentarios:comentariosNMN,
    }
    const changeSelectNMN = (e,n) => {
        console.log(e)
        console.log(n)
        switch(n.name) {
            case 'tipoMovNMN':
                if(e !== null) setOptiontipoMovimiemtoMNM(e)
                else setOptiontipoMovimiemtoMNM([])
                break;
            case 'empresaBancoCuentaOrigenNMN':
                if(e !== null){
                    console.log(e)
                    console.log('bancos',bancos)
                    console.log('allCuentas',allCuentas)
                    let idEmpresa = e.value ? e.value.split('-')[0] : 0; 
                    let idBanco = e.value ? e.value.split('-')[1] : 0;
                    let idCuent = e.value ? e.value.split('-')[2] : 0; 
                    
                    setIdEmpresaOrigenSave(parseInt(idEmpresa))           
                    setIdBancoOrigenSave(parseInt(idBanco))               
                    setIdCuentaOrigenSave(parseInt(idCuent))             
  

                    setOptionEmpresaOrigenMNM(e)
                    // let auxCuentO = allCuentas.filter(x => x.idEmpresa === e.value)
                    // setCuentasOrigen(auxCuentO)
                } 
                else{
                    // setOptionEmpresaOrigenMNM([])
                    // setCuentasOrigen([])
                } 
                break;
            // case 'cuentaOrigenNMN':
            //     if(e !== null) setOptionCuentaOrigenMNM(e)
            //     else setOptionCuentaOrigenMNM([])
            //     break;
            case 'empresaBancoCuentaDestinoNMN':
                // if(e !== null) setOptionEmpresaDestinoMNM(e)
                // else setOptionEmpresaDestinoMNM([])
                if(e !== null){
                    console.log(e)
                    console.log('bancos',bancos)
                    console.log('allCuentas',allCuentas)
                    let idEmpresaD = e.value ? e.value.split('-')[0] : 0; 
                    let idBancoD = e.value ? e.value.split('-')[1] : 0;
                    let idCuentD = e.value ? e.value.split('-')[2] : 0; 
                    
                    setIdEmpresaDestinoSave(parseInt(idEmpresaD))           
                    setIdBancoDestinoSave(parseInt(idBancoD))               
                    setIdCuentaDestinoSave(parseInt(idCuentD))  
                    setOptionEmpresaDestinoMNM(e)
                    // let auxCuentD = allCuentas.filter(x => x.idEmpresa === e.value)
                    // setCuentasDestino(auxCuentD)
                } 
                else{
                    // setOptionEmpresaDestinoMNM([])
                    // setCuentasDestino([])
                } 
                break;
            case 'cbSolicitantes':
                if(e !== null) setOptionSolicitante(e)
                else setOptionSolicitante([])
                break;          
                default:
        }
    }
    const changeInfoSolicitudNMN = (e) => {
        console.log(e.target.value)
        switch(e.target.name) {
            case 'fechaMovNMN':
                setFechaMovimientoNMN(e.target.value)
                break;
            case 'conceptoNMN':
                setConceptoValue(e.target.value)
                break;
            case 'solicitante':
                setSolicitanteMNM(e.target.value)
                break;
            case 'importeNMN':
                setImporteValueNMN(e.target.value)
                break;
            case 'comentNMN':
                setComentariosNMN(e.target.value)
                break;         
                default:
        }
    }
    const changeComboSolicitudes = (e,datos) => {
        console.log(e.target)
        console.log(e.target.value)
        console.log(datos)
        let dataJson = {
            idSolicitud: datos.idSolicitud,
            idEmpresaOrigen:e.target.value.split('-')[0],
            idBancoOrigen:e.target.value.split('-')[1],
            idCuentaOrigen:e.target.value.split('-')[2],
            idEmpresaDestino:datos.idEmpresaDestino,
            idBancoDestino:datos.idBancoDestino,
            idCuentaDestino:datos.idCuentaDestino
        }
        debugger

        console.log(JSON.stringify(dataJson))
        debugger

        switch(e.target.name){
            case 'EmpresaBancoCuentaOrigen':
                console.log('EmpresaBancoCuentaOrigen')
                    // setIdEmpresaOrigenSave(datos.optionEmpresaBancoCuenta.idEmpresaOrigen)
                    // setIdBancoOrigenSave(datos.optionEmpresaBancoCuenta.idBancoOrigen)
                    // setIdCuentaOrigenSave(datos.optionEmpresaBancoCuenta.idCuentaOrigen)
                    updateSolicitud({
                        endPoit:'solicitud',
                        idSolicitud:datos.idSolicitud,
                        idEmpresaOrigen:e.target.value.split('-')[0],
                        idBancoOrigen:e.target.value.split('-')[1],
                        idCuentaOrigen:e.target.value.split('-')[2],
                        idEmpresaDestino:datos.idEmpresaDestino,
                        idBancoDestino:datos.idBancoDestino,
                        idCuentaDestino:datos.idCuentaDestino,
                       JSONPET:JSON.stringify(dataJson)

                    },(resp,t,succues) => {
                        console.log(resp);
                        console.log(t)
                        console.log(succues)
                        getDataSolicitudesMov(1)

                        if(succues){
                            getDataSolicitudesMov(1)
                            setShowModalNuevaSolicitud(false)

                        }
                    })
                break;
            case 'estatusSolicitud':
               
                console.log('estatus')
                updatePetition(datos.idSolicitud,e.target.value)
                // setIdBancoOrigenSave(a.target.value)
                break;
            case 'cuentaOrigen':
                // setIdCuentaOrigenSave(a.target.value)


                break;
                default:

        }
        // console.log(r)
        // console.log(r.row)
        // console.log(bancos)
        // console.log(allCuentas)

        
        // let auxCuentO = allCuentas.filter(x => x.idEmpresa === parseInt(a.target.value))
        // console.log(auxCuentO)
        // setCuentasOrigenMonitor(auxCuentO)

        // let auxBancO = bancos.filter(x => x.idEmpresa === parseInt(a.target.value))
        // console.log(auxBancO)
        // setBancosOrigen(auxBancO)


        // setCuentasOrigen(auxCuentO)
    }
    const updatePetition = (idSolicitud,value) => {
        let dataJson = {
            idSolicitud:idSolicitud,
            idEstatus:value
        }
        updateSolicitud({
            endPoit:'solicitud',
            idSolicitud:idSolicitud,
            idEstatus:value,
            JSONPET:JSON.stringify(dataJson)
        },(resp,t,succues) => {
            console.log(resp);
            console.log(t)
            console.log(succues)
            getDataSolicitudesMov(1)

            if(succues){
                getDataSolicitudesMov(1)
                setShowModalNuevaSolicitud(false)


            }
        })
    }
    const saveAutorizate = () => {
        console.log()
    }
    const changeChkAllSolicitudes = () => {
        console.log('Todas')
    }
    const handleChangeCbxSolicitud = (rowVal,statusRow) => {
         // console.log('dataDisponibilidad',dataDisponibilidad)

        let auxData = [...solicitudes]
        console.log(rowVal)
        //  console.log(solicitudes)
         // let solicitudN = auxSolicitudes.find(x => x.idSolicitudPagoDetalle === rowVal.idSolicitudPagoDetalle)
        let indx = auxData.findIndex(x => x.idSolicitud === rowVal.idSolicitud)

        setSolicitudes(auxData)
        console.log(indx)
        auxData[indx].check = statusRow
        // setTimeout(() => {
            setIsNuevoMov(false)
            console.log(auxData) 
            auxData.forEach(element => {
                if(element.check) setIsNuevoMov(true)
            });
        // },10);
        //  console.log(rowVal)
        //  console.log(rowVal.saldo)
        //  console.log(statusRow)
        //  let saldoAux = saldoParcial;
        //  saldoAux = statusRow ? (saldoAux + parseFloat(rowVal.saldo)) : (saldoAux - parseFloat(rowVal.saldo)) 
        //  setSaldoParcial(saldoAux)
    }
    const handleCbxMov = (rowVal,statusRow) => {
        console.log(rowVal)

        // console.log(statusRow)
        // console.log('movimientosTbl',movimientosTbl)
        let auxMovTbl = [...movimientosTbl]
        console.log(auxMovTbl)
        let auxMovMasive = [...movimientosMasive]
        console.log(auxMovMasive)

        for (let i = 0; i < auxMovTbl.length; i++){
            // console.log(rowVal)
            // console.log(auxMovTbl[i])
            if(auxMovTbl[i].idMovimiento === rowVal.idMovimiento)
            {
                auxMovTbl[i].check = statusRow;
                if(statusRow){
                    auxMovMasive.push(auxMovTbl[i])
               
                }else{
                    let indx = auxMovTbl.findIndex(x => x.idMovimiento === rowVal.idMovimiento)
                    auxMovMasive.splice(indx, 1);

                }
                

            }


          

        }

        let auxStatusT = auxMovTbl.filter(x => x.check)
        console.log(auxStatusT.length)
        setShowUpMasiv(auxStatusT.length >0)
        setMovimientosTbl(auxMovTbl)
        console.log(auxMovMasive)
        setMovimientosMasive(auxMovMasive)

        // setShowModalAction(true)

    }
   
    const showDetailMov = (row,type) => {
        console.log('type',type)
        console.log(row)
        setTypeAction(1)
        if(type === 1){
            console.log('solicitudes relacionadas',relPetitionMovAll)
            let auxrelMov = [];
            auxrelMov = relPetitionMovAll.filter(r => r.idMovimiento === row.idMovimiento)
            console.log(auxrelMov)
            let labelMov = (auxrelMov.length === 0) ? 'Movimiento:'+ row.idMovimiento +',No se tienen solicitudes relacionadas': 'Movimiento:'+ row.idMovimiento  
            setTypeColumns('REL_SOLICITUDES_MOV')
            setIdMovimiento(labelMov)
            setRelPetitionMov(auxrelMov)
            setShowModalAction(true)
        }
        if(type === 2){
            setdetailMovimiento(row)
            debugger
            setShowModalDetailChange(true)
        }

        


    }
    const loadEvidence = (row) => {
        console.log(row)
        setReferenceEvidece('')
        setimportEvidence(0)
        setDateEvidence(null)
        setDataMovRow(row)
        setShowModalAction(true)
        setTypeAction(2)


    }
    const showModalDeleteMov = (row,action,type) => {
        console.log(type)
        console.log(row)
        console.log('solicitudes relacionadas',relPetitionMovAll)
        debugger

        setIdMovimiento(row.idMovimiento)


        if(type===0){
            setTypeAction(3)
            let auxrelMov = [];
            let auxrelPett = [];
            auxrelPett = relPetitionMovAll.filter(r => r.idMovimiento === row.idMovimiento)
            // auxrelMov.forEach(x => x.idEstatus = )
            console.log(auxrelPett)
           
            setRelPetitionMov(auxrelPett)
            if(auxrelPett.length > 0){
                auxrelMov = relPetitionMovAll.filter(r => r.idSolicitud === auxrelPett[0].idSolicitud)
                console.log('auxrelPett',auxrelMov)
                setMovCancel(auxrelMov)
            }
            setShowModalAction(true)

       
        }else {
           
            console.log('eliminar movimiento ')
            debugger
        }
        

    }
    const hideModalAction = (row) => {
        setShowModalAction(false)
    }
    const hideModalDetailMov = (row) => {
        setShowModalDetailChange(false)
    }
    const onAceptChange = (idM) =>{
        console.log('idM',idM)
        console.log('Mov', dataRespEvMov.find(x => x.idMovimiento == idM))
        let newMovSave = []
        let movAux = []
        newMovSave.push(dataRespEvMov.find(x => x.idMovimiento == idM))
       
        console.log('idMovimiento',newMovSave)
        debugger
        if(newMovSave[0].typePetMov === 2 || newMovSave[0].typePetMov === 3) 
        {
        debugger

            movAux = [...dataRespEvMov]
            let indx = movAux.findIndex(x => x.idMovimiento == idM)
            console.log(movAux)
            console.log(indx)
            console.log(movAux[indx].typePetMov)
            debugger
            movAux[indx].typePetMov = 1
            console.log(movAux[indx].typePetMov)
            console.log(movAux[indx])
            debugger

        debugger

            setDataRespEvMov(movAux)
            setShowModalDetailChange(false)

        }
        // if(newMovSave.typePetMov === 1) 
        // {
        //     //     savedFile({
        //         //         endPoint:'ProgramacionMovimientos/PdfSave',
        //         //         listMovSave:JSON.stringify(newMovSave),
        //         //         typeSave: isEvidenceMasiv,
        //         // },{archivo: selectedZip,},(response,type,success)=>{
        //         //                 console.log(response,type,success)
                                
        //         //                 if(success){
        //         //                     fnNotification([response.data.Message, 'success', true])
        //         //                     setIsEvidMov(false)


        //         //                 }
                                


        //         //             })
        // }
   
    }
    const cancelMov = (idMovimiento) =>{
        console.log(idMovimiento)
        console.log(typeCancelMov)
        console.log('relPetitionMov',relPetitionMov)
        console.log('isCheckedTypeCancelMov',isCheckedTypeCancelMov)
        console.log('isCheckedTypeCancelMovPet',isCheckedTypeCancelMovPet)
        let auxArraySolicitud = []
        debugger
        relPetitionMov.forEach(x => x.idEstatus = typeCancelMov)
        console.log('relPetitionMov',relPetitionMov)
        // relPetitionMov.forEach(r =>{
        //     auxArraySolicitud.push({
        //         idSolicitud                   :e.idSolicitud ,
        //         idEstatus                     :2             ,

        //     })
        // })
        // debugger
                    
        // JSONPET:JSON.stringify(auxArraySolicitud)
        
        updateMov({
            endPoit:'cancelMov',
            // idMovimiento:idMovimiento,
            listMovCancel:movCancel,
            idEstatus:5,
            JSONPET:JSON.stringify(relPetitionMov)
        },(resp,t,succues) => {
            console.log(resp);
            console.log(t)
            console.log(succues)
            // getDataSolicitudesMov(1)

            if(succues){
                // getDataSolicitudesMov(1)
                setShowModalAction(false)



            }
        })
        
    }
    const handdleAddNewMov = () => {

        console.log('Add nee mov')
        console.log('optionEmpresaOrigenMNM',optionEmpresaOrigenMNM)
        console.log('optionEmpresaDestinoMNM',optionEmpresaDestinoMNM)

        let auxNuevoTotMovimiento = 0
        let _validation = validateForm();
        if(!_validation){
            let newMov = [...solicitudes_NewMov]

            newMov.push({
                idTipoMovimiento:optiontipoMovimiemtoMNM.value,
                tipoMovimiento:optiontipoMovimiemtoMNM.label,
                fechaMovimientoNMN:fechaMovimientoNMN,
                concepto:conceptoValue,
                idEmpresaOrigen:idEmpresaOrigenSave,
                idBancoOrigen:idBancoOrigenSave,
                idCuentaOrigen:idCuentaOrigenSave,
                empresaOrigen:optionEmpresaOrigenMNM.label.split('-')[0],
                bancoOrigen:optionEmpresaOrigenMNM.label.split('-')[1],
                cuentaOrigen:optionEmpresaOrigenMNM.label.split('-')[2],
                idEmpresaDestino:parseInt(optionEmpresaDestinoMNM.value.split('-')[0]),
                idBancoDestino:parseInt(optionEmpresaDestinoMNM.value.split('-')[1]),
                idCuentaDestino:parseInt(optionEmpresaDestinoMNM.value.split('-')[2]),
                montoSolicitado:importeValueNMN,
                comentarios:comentariosNMN,
                empresaDestino:optionEmpresaDestinoMNM.label.split('-')[0],
                bancoDestino:optionEmpresaDestinoMNM.label.split('-')[1],
                cuentaDestino:optionEmpresaDestinoMNM.label.split('-')[2],
            })
            console.log(optionEmpresaOrigenMNM)
            auxNuevoTotMovimiento = parseInt(nuevoTotMovimiento) + parseInt(importeValueNMN)
        
            console.log(newMov)
            setNuevoTotMovimiento(auxNuevoTotMovimiento)
            setsolicitudes_NewMov(newMov)

            setOptiontipoMovimiemtoMNM([])
            setFechaMovimientoNMN(null)
            setConceptoValue('')
            setOptionEmpresaOrigenMNM([])
            // setOptionEmpresaDestinoMNM([])
            setSolicitanteMNM('')
            setImporteValueNMN(0)
            setComentariosNMN('')

        }else fnNotification(['Error: ' + _validation, 'error', true])
        
    }
    const clickDeleteRow = (r,typeDelete) => {
        console.log('typeDelete',typeDelete)
        console.log('clickDeleteRow',r.row.original)
        console.log('solicitudes_NewMov',solicitudes_NewMov)
        if(typeDelete === 0){
            let indx 
            const newRow = solicitudes_NewMov.filter(item => !objectsAreEqual(item, r.row.original));
            setsolicitudes_NewMov(newRow)
        }
        
    }
    // Función para comparar dos objetos
     const objectsAreEqual = (obj1, obj2) => {
         return JSON.stringify(obj1) === JSON.stringify(obj2);
        };
    const showDetailSolFond = (row) => {
        console.log('showDetail',row)
        setShowModalNuevaSolicitud(true)
        setIsDetailPet(true)
        setRowDetail(row)
    }
    const showDispSaldo = (row) => {
        console.log('Disponibilidad Saldo',row)
        setShowModalDispSaldo(true)
        setDataSaldo(row)
        

    }
    const changeInfoEvidence = (e) => {
        console.log('name',e.target.name)
        console.log('value',e.target.value)
        let name = e.target.name
        let value = e.target.value
        name === 'referencia' && setReferenceEvidece(value)
        name === 'fechaMov' && setDateEvidence(value)
        name === 'importeEvidencia' && setimportEvidence(value)


    }
    const onCloseMP = () => {
        setShowModalPDF(false)
    }
    const showPDF = (pdf,isOne,isComprobante,isImg) => {
        console.log(pdf)
        setInfoPDF(pdf)
        setShowModalPDF(true)
    }
    const searchFilXlsPdf = () => {
        debugger
        setSelected_ExcelFormat([])
        setSelected_Zip([])
        setShowModalAction(true)
        setTypeAction(4)

    }


    const sheetHelp = [
        {
                        'idMovimiento' :'',
                        'fechasolicitud':'',
                        'tipoMovimiento':'',
                        'concepto'      :'',
                        'comentarios':  '',
                        'empresaBancoOrigen':'',
                        'cuentaorigen'        :'',
                        'empresaBancoDestino'          :'',
                        'cuentaDestini':'',
                        'importesolicitado'         :'',
                        'importereaplicado'      :'',
                        'referencia'       : '',
                        'observaciones'    :'',
                        'nombreArchivo' : ''
        }]


    const downloadHelp = async () => {
        // let r = idTipoFormato === 1 ? 'PagoImpuestos' : 'PagoImpuestos/SS'
        console.log(movimientosTbl)
        let movXls = []
        movXls = movimientosMasive.length > 0 ? movimientosMasive : movimientosTbl
        let dataSet = []
        // debugger
        movXls.forEach(m =>{
            dataSet.push({
                        'idMovimiento' :m.idMovimiento,
                        'fechasolicitud':'',
                        'tipoMovimiento':m.tipoMovimiento,
                        'concepto'      :m.concepto,
                        'comentarios':  '',
                        'empresaBancoOrigen':m.empresaOrigen+'-'+m.bancoOrigen,
                        'cuentaorigen'        :m.cuantaOrigen,
                        'empresaBancoDestino'          :m.empresaDestino+'-'+m.bancoDestino,
                        'cuentaDestino':m.cuentaDestino,
                        'importesolicitado'         :m.montoSolicitado,
                        'importereaplicado'      :'',
                        'referencia'       : '',
                        'observaciones'    :'',
                        'nombreArchivo' : ''
                    })
        }
        )
        console.log(dataSet)
        // debugger
        let sheet_Data = dataSet 
        // let sheet_Help = sheetHelp  
        let nameHelp = 'layout-Movimientos'
        const worksheet = XLSX.utils.json_to_sheet(sheet_Data)
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        for (let row = range.s.r + 1; row <= range.e.r; ++row) { // Empezamos en la fila 1 para omitir el encabezado
            const cellAddressImpS = XLSX.utils.encode_cell({ c: 9, r: row }); // Columna 1 corresponde a "Sueldo"
            const cellAddressImpA = XLSX.utils.encode_cell({ c: 10, r: row });

            if (!worksheet[cellAddressImpS]) continue;
            worksheet[cellAddressImpS].z = '"$"#,##0.00'; // Formato de moneda

            if (!worksheet[cellAddressImpA]) continue;
            worksheet[cellAddressImpA].z = '"$"#,##0.00'; // Formato de moneda
        }
            // Ajustar el ancho de las columnas
            worksheet['!cols'] = [
                { wch: 11 },
                { wch: 11 },  
                { wch: 13 },  
                { wch: 11 },  
                { wch: 11 },  
                { wch: 12 },  
                { wch: 12 },  
                { wch: 12 },  
                { wch: 12 },  
                { wch: 22 },  
                { wch: 22 },  
                
            ];


            // Crear un nuevo libro de Excel
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja1');
    
            // Generar el archivo Excel
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    
            // Guardar el archivo Excel
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            FileSaver.saveAs(blob, 'layout-Movimientos.xlsx');

        // const wb = {Sheets:{'data':_data},SheetNames:['data']}
        // const excelBuffer = XLSX.write(wb,{bookType:'xlsx', type:'array'})
        // const data = new Blob([excelBuffer],{type:fileType})
        // FileSaver.saveAs(data,nameHelp + fileExtension)

    }

    
    return (
        <Fragment>
            <Header title={inicializa(1).title +' '+ Session.getInfoAmbiente()} breadcrum={inicializa(1).breadcrum}/>
            <Container title={inicializa(1).title} contBody={
                <ContainerBody
                estatusSolicitudes     ={estatusSolicitudes}
                tipoMovimiemto         ={tipoMovimiemto}
                // bancos                 ={bancos}
                allCuentas             ={cuentasFilt}
                empresasBanco          ={empresasBanco}
                empresasOrigenDestino  ={empresasOrigenDestino}

                dataDisponibilidad     ={dataDisponibilidad}
                changeSelect           ={changeSelect}
                changeDate             ={changeDate}
                // cuentasSolicitudes     ={allCuentas}
                cuentasOrigen          ={cuentasOrigenMonitor}
                bancosOrigen           ={bancosOrigen}
                downloadHelpDisp       ={downloadHelpDisp}
                optionCuenta           ={optionCuenta}
                optionEmpresa          ={optionEmpresa}
                btnSearch              ={btnSearch}
                showModalUpSaldo       ={showModalUpSaldo}
                isShowDragAndDrop      ={isShowDragAndDrop}
                selected_File          ={selected_File}
                select_File            ={(file) => setSelectedFile(file)}
                uploadLayout           ={uploadLayout}
                downSaldosExcel        ={downSaldosExcel}
                totSaldo               ={totSaldo}
                saldoParcial           ={saldoParcial}
                handleChangeChkAll     ={handleChangeChkAll}
                chckAll                ={chckAll}
                handleChangeCbx        ={handleChangeCbx}
                closeDragAndDrop       ={closeDragAndDrop}
                changeTab              ={changeTab}
                numTab                 = {numTab}
                solicitudes              ={solicitudes}
                allSolicitudes           ={allSolicitudes}
                changeSelectSolicitudes  ={changeSelectSolicitudes}
                changeDateSolicitud      ={changeDate}
                optionTipoMovimiemto     ={optionTipoMovimiemto}
                fechaMovimientoNMN       ={fechaMovimientoNMN}
                conceptoValue            ={conceptoValue}
                optionEmpresaOrigen      ={optionEmpresaOrigen}
                optionEmpresaDestino     ={optionEmpresaDestino}
                nuevaSolicitud           ={nuevaSolicitud}
                nuevoMovimiento          ={nuevoMovimiento}
                showModalNuevaSolicitud  ={showModalNuevaSolicitud}
                showModalNuevoMovimiento ={showModalNuevoMovimiento}
                changeComboSolicitudes   ={changeComboSolicitudes}
                changeChkAllSolicitudes  ={changeChkAllSolicitudes}
                chckAllSolicitudes       ={false}
                handleChangeCbxSolicitud ={handleChangeCbxSolicitud}
                isNuevoMov               ={isNuevoMov}
                showDetailSolFond        ={showDetailSolFond}
                showDispSaldo            ={showDispSaldo}
                movimientosTbl = {movimientosTbl}
                petAut={petAut}
                handleCbxMov={handleCbxMov}
                loadEvidence={loadEvidence}
                showDetailMov={showDetailMov}
                showModalDeleteMov={showModalDeleteMov}
                isEvidMov={isEvidMov}
                dataRespEvMov={dataRespEvMov}
                saveFileEvidence={saveFileEvidence}
                showPDF                  ={showPDF}
                showUpMasiv             ={showUpMasiv}
                searchFilXlsPdf         ={searchFilXlsPdf}
                downloadHelp             ={downloadHelp}
                CurrencyFormat          ={CurrencyFormat}
                cancelSaveChange        ={cancelSaveChange}

                />
                
            } spaceTitle='6' spaceBtn='6'/>
            <ModalPDF
                show={showModalPDF}
                dataPDF={infoPDF}
                onCloseMP={onCloseMP}
                isOne={true}
                />
            <ModalActionMovePetition 
                    show={showModalAction}
                    onCancel={hideModalAction}
                    typeAction={typeAction}
                    uploadEvidence={uploadEvidence}
                    changeInfoEvidence={changeInfoEvidence}
                    referenceEvidece={referenceEvidece}
                    dateEvidence={dateEvidence}
                    importEvidence={importEvidence}
                    // xlsDataRespons={xlsDataRespons}
                    // xlsDownloadRespons={xlsDownloadRespons}
                    // idTipoFormato={idTipoFormato}
                    relPetitionMov={relPetitionMov}
                    relPetitionMovAll={relPetitionMovAll}
                    movimientosTbl={movimientosTbl}
                    idMovimiento={idMovimiento}
                    handleCbxMov={handleCbxMov}
                    showDetailMov={showDetailMov}
                    showModalDeleteMov={showModalDeleteMov}
                    loadEvidence={loadEvidence}
                    changeSelectSolicitudes={changeSelectSolicitudes}
                    changeDateSolicitud={changeDateSolicitud}
                    optionTipoMovimiemto   ={optionTipoMovimiemto}
                    optionEmpresaOrigen    ={optionEmpresaOrigen}
                    optionEmpresaDestino   ={optionEmpresaDestino}
                    empresasOrigenDestino={empresasOrigenDestino}
                    empresasBanco={empresasBanco}
                    tipoMovimiemto={tipoMovimiemto}
                    btnSearch={btnSearch}
                    numTab={numTab}
                    changeTab={changeTab}
                    selected_Pdf={selected_Pdf}
                    select_Pdf={(file) => setSelectedPdf(file)}
                    selected_ExcelFormat = {selected_ExcelFormat}
                    select_ExcelFormat = {(file) => setSelectedExcelFormat(file)}
                    selected_Zip = {selected_Zip}
                    select_Zip = {(file) => setSelectedZip(file)}
                    uploadEvidenceMasive   = {uploadEvidenceMasive}
                    cancelMov={cancelMov}
                    typeColumns={typeColumns}
                    selectTypeCancel={selectTypeCancel}
                    isCheckedTypeCancelMovPet={isCheckedTypeCancelMovPet}
                    isCheckedTypeCancelMov={isCheckedTypeCancelMov}

        />
        <ModalDetailMovChange
         show={showModalDetailChange}
         onCancel={hideModalDetailMov}
         idMovimiento={idMovimiento}
         detailMovimiento={detailMovimiento}
         onAceptChange={onAceptChange}

        />
        <ModalRespuesta 
                    show={showModalResponse}
                    onCerrar={onCerrar}
                    xlsDataRespons={xlsDataRespons}
                    xlsDownloadRespons={xlsDownloadRespons}
                    upload={upload}
                    // idTipoFormato={idTipoFormato}
        />
        <ModalDisponibilidadSaldos 
                    show={showModalDispSaldo}
                    onCerrarDispSald={() => setShowModalDispSaldo(false)}
                    dataSaldo={dataSaldo}

        />
        <ModalNuevoMovimiento
            show                     ={showModalNuevaSolicitud}
            onCerrarMNS              ={onCerrarMNS} /*onCerrarModalNuevaSolicitud*/
            saveNuevaSolicitud       ={saveNuevaSolicitud}
            changeSelectNMN          ={changeSelectNMN}
            changeInfoSolicitudNMN   ={changeInfoSolicitudNMN} 
            tipoMovimiemto           ={tipoMovimiemto}
            optiontipoMovimiemtoMNM  ={optiontipoMovimiemtoMNM}
            fechaMovimientoNMN       ={fechaMovimientoNMN}
            conceptoValue            ={conceptoValue}
            empresasBancoCuentaOrigenDestino    ={empresasOrigenDestino}
            optionEmpresaOrigenMNM   ={optionEmpresaOrigenMNM}
            optionEmpresaDestinoMNM  ={optionEmpresaDestinoMNM}
            cuentasOrigen            ={cuentasOrigen}
            cuentasDestino           ={cuentasDestino}
            optionCuentaOrigenMNM    ={optionCuentaOrigenMNM}
            optionCuentaDestinoMNM   ={optionCuentaDestinoMNM}
            // solicitanteMNM           ={ExisteSession.getInfoUsuario().nombre+' '+ExisteSession.getInfoUsuario().aP+' '+ExisteSession.getInfoUsuario().aM}
            solicitanteMNM           ={solicitanteMNM}
            importeValueNMN          ={importeValueNMN}
            comentariosNMN           ={comentariosNMN}
            saveAutorizate           ={saveAutorizate}
            movPetition              ={movPetition}
            typeTab                  ={2}
            solicitudes              = {solicitudesaNuevoMovimiento}
            columns                  ={columnsModal}
            solicitudes_NewMov       ={solicitudes_NewMov}
            handdleAddNewMov         ={handdleAddNewMov}
            totalMovimiento          ={totalMovimiento}
            nuevoTotMovimiento       ={nuevoTotMovimiento}
            clickDeleteRow           ={clickDeleteRow}
            isDetailPet              ={isDetailPet}
            rowDetail                ={rowDetail}
            cambiaStatusSolicitud    ={cambiaStatusSolicitud}
            solicitantes             ={solicitantes}
            optionSolicitante        ={optionSolicitante}

        />

       </Fragment>
    );
};

export default MovimientosCompanias;