import React, {Fragment, useEffect,useCallback, useState, useRef,createRef} from 'react';
import GeneralTable from '../../GeneralTable';
import {columnsImpuestosOK,columnsImpuestosOKSS} from '../resources'
import CompBtnTotal from './CompBtnTotal';
import Buttons from '../../../Components/Buttons/Buttons';



const TableOK = ({idTipoFormato,xlsDownloadRespons,total,_loadFile,cancelLoad}) => {
   
    

    return <>
    <GeneralTable
                                columns={idTipoFormato === 1 ? columnsImpuestosOK : columnsImpuestosOKSS}
                                data={xlsDownloadRespons}
                                action={undefined}
                                doubleClick={(original,values) => {}} 
                                isNotPagination={true}
                                pageSize={500}
                                isCompTable={true}
                                compTbl={<CompBtnTotal total={total}/>}

                                />
        <Buttons 
        btnAdd={true} btnAddAction={() => _loadFile()} btnAddText="Guardar" btnAddShowIcon={true} btnAddIconClass="fa fa-save"
        btnCancel={true} btnCancelAction={() => cancelLoad()} btnCancelText="Cancelar" btnCancelShowIcon={true}
    />
        </> 
    
};

export default TableOK;