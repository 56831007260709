import axios from 'axios';
import CryptoJS from 'crypto-js';
import { HandlerSession } from '../Config/HandlerSession';

const { REACT_APP_APISEGURIDAD,REACT_APP_APISIAN,REACT_APP_APISIAN_REPORTES } = process.env;


const APISeguridad = REACT_APP_APISEGURIDAD;
const APISIAN = REACT_APP_APISIAN;
const URLAPISIAN = REACT_APP_APISIAN_REPORTES;


var Cryp = {
    stringToHex: function (str) {
        var hex = '';
        for (var i = 0; i < str.length; i++) {
            hex += '' + str.charCodeAt(i).toString(16);
        }
        return hex;
    },
    str_pad: function (str, max) {
        str = str.toString();
        return str.length < max ? this.str_pad(str + '0', max) : str;
    },
    encriptar: function (data) {
        return btoa(JSON.stringify(data));
    },
    desencriptar: function (data, key = 'default') {
        return atob(data);
    },
    convertKeyHex: function (key) {
        key = this.str_pad(this.stringToHex(key), 64).substr(0, 64);
        return key;
    }
}



var fechas = {
    current() {
        var fecha = new Date();
        return fecha;
    }
}
let ExisteSession = {
    remove: function () {
        localStorage.removeItem('Session_Usuario');
        localStorage.removeItem('Ambiente');
        localStorage.removeItem('conexion');
        localStorage.removeItem('MenuSession');
        localStorage.removeItem('Permisos');
        localStorage.removeItem('idView');
        localStorage.removeItem('setTimeLogin');
        localStorage.removeItem('setLogin');
    },
    getInfoUsuario: function () {
        // let objSesionUsuario = JSON.parse(localStorage.getItem('Session_Usuario'));
        let _session = HandlerSession.getLogin();
        // console.log("session Usuario", _session);
        if (_session) {
            if ( localStorage.getItem("setTimeLogin"))
                HandlerSession.setTimeLogin();
            return _session;
        }
        else {
            ExisteSession.remove()
            window.location.replace(`${process.env.PUBLIC_URL}/`);
            return null;
        }
    },
    getAmbiente: function () {
        let objAmbiente = JSON.parse(localStorage.getItem('Ambiente'));
        return objAmbiente
    },
    conexion: function () {
        return localStorage.getItem('conexion');
    },
    getMenu: () => JSON.parse(localStorage.getItem('MenuSession')),
    getPermisos: () => JSON.parse(localStorage.getItem('Permisos')),
    getView: () => JSON.parse(localStorage.getItem('idView')),
    getPerfil: () => localStorage.getItem('PerfilSession')
}

function toggleLoader(isVisible) {
    // window.scroll({
    //     top: 0,
    //     left: 0,
    //     behavior: 'smooth'
    //   });
    if (isVisible) window.$('#loaderG').show();
    else window.$('#loaderG').hide();
}
var validaMensaje = function (paramMsj) {
    console.log(paramMsj);
    let msjToken = "Token de acceso no válido"
    console.log(msjToken);

    if (paramMsj === msjToken) {
        ExisteSession.remove()

        setTimeout(() => {
            window.location.replace(`${process.env.PUBLIC_URL}/`);
        }, 500)
    }


}

export { APISeguridad, APISIAN, Cryp, fechas, ExisteSession, toggleLoader, URLAPISIAN, validaMensaje }
