import React from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap'
import Buttons from '../../../Components/Buttons/Buttons';
import Select from 'react-select';

const ModalEditaDetalle = ({
    showModalEdit,
    isUpdate,
    onCloseEdit,
    onAceptUpdate,
    dataSolicitdUpdate,
    EF,
    changeData,
    changeCheck,
    folioSua,
    prioritario,
    monto,
    changeSelect,
    catTipoImpuestos,
    optionTipoImpuestos,
    catTipoImpuestoDetalle,
    optTipoImpuestoDetalle,
    catTipoFormato,
    optTipoFormato
}) => {
    // console.log('optTipoFormato',optTipoFormato)
    // console.log('dataSolicitdUpdate',dataSolicitdUpdate)
    return (
        <>
            <Modal size="xl" show={showModalEdit} animation={false} backdrop="static"  aria-labelledby="modalConfirmImp">
            <Modal.Header className="header">
                <Container>
                <Row>
                        <Col md="11" className="text-left">
                        {isUpdate ? <span>Editar Solicitud</span> : <span>Eliminar Solicitud</span> }
                        </Col>
                        </Row>
                </Container>
            </Modal.Header>
            <Modal.Body style={{border:'1px solid black'}}>
                <Row>
                <Col md="12" className="text-center">
                    {
                        isUpdate ? <div>
                            <div className="row">
                                <div className="col-md-3 text-right">
                                    <span>Lote : </span>
                                </div>
                                <div className="col-md-9 text-left">
                                    <span> {dataSolicitdUpdate.lote}</span>  
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 text-right">
                                    
                                {
                                    EF?<span>Marca : </span>:<span>Registro Patronal : </span>
                                }
                                

                                </div>
                                <div className="col-md-9 text-left">
                                    { EF ? <span>{dataSolicitdUpdate.marca}</span>  : <span> {dataSolicitdUpdate.registroPatronal}</span> }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 text-right">
                                {  <span>RFC : </span>}
                                </div>
                                <div className="col-md-9 text-left">
                                   { <span> {dataSolicitdUpdate.rfc}</span> }
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 text-right">
                                    { EF ? <span>Empresa : </span> : <span>Razon social : </span>}
                                </div>
                                <div className="col-md-9 text-left">
                                    <div className="form-group">
                                        {EF  ? <span> {dataSolicitdUpdate.empresa}</span> : <span> {dataSolicitdUpdate.razonSocial}</span> }
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 text-right">
                                <span>Tipo impuesto : </span>                                
                                </div>
                                <div className="col-md-9 text-left">
                                    <div className="form-group">
                                    {
                                        dataSolicitdUpdate.isMultipleDetail === 0 ? 
                                        <span> {dataSolicitdUpdate.tipoImpuesto}</span> :
                                        <div>
                                                <Select
                                                    isSearchable
                                                    name="tipoImpuesto"
                                                    onChange={(event,name) => changeSelect(event,name)}
                                                    options={catTipoImpuestos}
                                                    value={optionTipoImpuestos}
                                                />
                                            
                                        </div> 
                                    }
                                    </div> 
                                    

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 text-right">
                                <span>Detalle : </span>                                

                                </div>
                                <div className="col-md-9 text-left">

                                        <div className="form-group">
                                        {
                                            dataSolicitdUpdate.isMultipleDetail === 0 ? 
                                            <span> {dataSolicitdUpdate.detalle}</span> :
                                            <div>
                                                    <Select
                                                        isSearchable
                                                        name="tipoImpDetalle"
                                                        onChange={(event,name) => changeSelect(event,name)}
                                                        options={catTipoImpuestoDetalle}
                                                        value={optTipoImpuestoDetalle}
                                                    />
                                                
                                            </div> 
                                          
                                        }
                                        </div>


                                            
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 text-right">
                                {
                                    EF ? <span> Formato : </span> : <span>Folio sua : </span>
                                }

                                </div>
                                <div className="col-md-9 text-left">
                                {
                                EF ?
                                optionTipoImpuestos.value === 3 ?
                                    <div className="form-group">
                                        <Select
                                                        isSearchable
                                                        name="tipoFormato"
                                                        onChange={(event,name) => changeSelect(event,name)}
                                                        options={catTipoFormato}
                                                        value={optTipoFormato}
                                                    />
                                    </div> :<div> <span>Federales</span></div>
                                    : 
                                    <div className="form-group">
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        name='folioSua'
                                        value={folioSua} 
                                        onChange={(e) => changeData(e)} 
                                        />
                                    </div> 
                                } 
                                </div>
                            </div>
                            {optionTipoImpuestos.value !== 3 && <br />}
                            <div className="row">
                                <div className="col-md-3 text-right">
                                <span>Prioritario : </span>                                

                                </div>
                                <div className="col-md-9 text-left">
                                            <div className="form-group">
                                                <div className="border-checkbox-section">
                                                    <div className="border-checkbox-group border-checkbox-group-success">
                                                        <input
                                                            onChange={e => changeCheck(e)}
                                                            checked={prioritario}
                                                            className="border-checkbox"
                                                            type="checkbox"
                                                            id={"chkPrioritario"}
                                                        />
                                                        <label className="border-checkbox-label"
                                                            htmlFor={"chkPrioritario"}>{prioritario ? 'SI': 'NO'}</label>
                                                    </div>
                                                </div>
                                            </div>                                   

                                </div>
                            </div>   

                            <div className="row">
                                <div className="col-md-3 text-right">
                                <span>Monto : </span>                                

                                </div>
                                <div className="col-md-9 text-left">
                                    <div className="form-group"> 
                                        <input type="number"
                                        className="form-control"
                                        name='monto'
                                        min="0"
                                        onChange={(e) => changeData(e)} 
                                        value={monto} /> 
                                    </div>  
                                </div>
                            </div>
                        </div> :
                        <span>Al aceptar se eliminara la solicitud permanentemente de la base de datos </span>  
                    } 
                    </Col>
                </Row>

                <br />
                <Row>
                <Col md="4"></Col>
                <Col md="4" className="text-Center">
                    {
                        !isUpdate &&   
                        <>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   ¿Desea continuar?
                        </>  

                    }
                    </Col>
                <Col md="4"></Col>

                </Row>
                <br />

                <Row>
                <Col md="4"></Col>

                        <Col  md="2" >
                            
                        <Buttons 
                            btnAdd={true} btnAddAction={() => onAceptUpdate(EF)} btnAddText={isUpdate ?"Guardar" : "Aceptar"} btnAddShowIcon={true} btnAddIconClass={isUpdate ? "fa fa-save" : "fa fa-check"}
                        />
                        </Col>
                        <Col  md="2" >
                        <Buttons 
                            btnCancel={true} btnCancelAction={() => onCloseEdit()} btnCancelText="Cancelar" btnCancelShowIcon={true}
                            />

                        </Col>
                <Col md="4"></Col>


                        
                </Row>
            </Modal.Body>

            </Modal>
     </>
    );
};

export default ModalEditaDetalle;