import React, {useState,useEffect} from 'react'
import Login from '../Components/Login';
import MenuNavBar from '../Components/MainMenu'
import  Ambiente  from './Ambientes';
const Home = () =>{
    const [isLogin,setIsLogin] = useState(false)
    const [isAmbiente,setIsAmbiente] = useState(false)
    const [isMenuBar,setIsMenuBar] = useState(false)
    const NOT_SEG = 1
    useEffect(()=>{
        let _session = localStorage.getItem('Session_Usuario');
        let _ambiente = localStorage.getItem('ambientes');
        let _sian = localStorage.getItem('sian');
        if(_session !== undefined && _session !== null) setIsLogin(true)
        if(_ambiente !== undefined && _ambiente !== null) setIsAmbiente(true)
        if(_sian !== undefined && _sian !== null) setIsMenuBar(true)
        
    },[]) 
    return (
        <div className="paper-back-color">
            {!isLogin && <Login NOT_SEG={NOT_SEG}/>}
            {isLogin && isAmbiente && !isMenuBar && <Ambiente NOT_SEG={NOT_SEG}/>}
            {isLogin && isAmbiente && isMenuBar && <MenuNavBar/>}
            {/* {isLogin && isAmbiente && <MenuNavBar/>} */}

            <div id="divNotification"></div> 
        </div>
    )
}

export default Home

