import React  ,{useRef} from 'react';
import { Modal, Button, Accordion, Container, Row, Col} from 'react-bootstrap'
import Buttons from '../../../Components/Buttons/Buttons';
import Image from 'react-bootstrap/Image'
import iconOk from './icons/icon-ok-big.png'
import iconFail from './icons/icon-fail.png'
import iconWarning from './icons/icon-warning.ico'
import './styles.css'
const GenerarRegistro = ({ index, pdfN,errorFila }) => {

    const { agregadoABaseDeDatos, celdas, celdasConErrores, mensaje } = pdfN
    return (
            <></>
    );
}


const ModalConfirma = ({
    show,
    onCerrar,
    onAceptConfirm,
    onSaveLineaCaptura,
    onSaveLineaCapturaZip,
    typeConfirm,
    respFormat,
    idTipoFormato,
    errorModal,
    dataModalLC,
    isZip,
    isErrorZip,
    modalType,
    lote
}) => {
    // const tableRefSol = useRef(null);
    return (
        <>
        <Modal size="xl" show={show} animation={false} backdrop="static"  aria-labelledby="modalConfirmImp">
            <Modal.Header className="header">
                <Container>
                <Row>
                        <Col md="11" className="text-left">
                        {!typeConfirm ? <label>Confirmación de nuevo lote de impuestos</label>  : 
                                <> <label>Asignación de PDF con linea de Captura</label> &nbsp;&nbsp;&nbsp; <label>{respFormat.Formato}</label></> } 
                        </Col>
                       
                </Row>
                </Container>
            </Modal.Header> 
            <Modal.Body style={{border:'1px solid black'}}>
                <Row>
                    <Col md='12'>
                        <>
                        {

                        dataModalLC.listImg ? 
                      dataModalLC.listImg.map((el,i) =>{
                        //  return <img src={'data:image/png;base64, '+el} alt='' />
                         return <></>
                       } )
                        :<></>
                    }

                      </>
                 
                    </Col>
                </Row>
            <Row>
            <Col md='12'>
                { 

                
              }
              </Col>
          </Row> 
               
            {
                isZip ? <>
                    {
                        isErrorZip ? <>
                        <Row>
                        <Col md="12" className="text-center">
                            <div>Error</div>
                        </Col>
                        </Row>
                        </> : 
                        <>
                        <Row>
                        <Col md="12" className="text-center">
                            {
                                modalType ?
                                    <div>
                                        <label>Se guardará nuevo bloque de impuestos</label>
                                        <div>
                                        Al guardar este nuevo lote, será enviado una notificación 
                                        informando el monto total necesario para cubrir los impuestos 
                                        </div>
                                        <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   ¿Desea continuar? </label> 
                                    </div>  :
                                <>
                                    <div>
                                    <span>Se ha identificado lote completo con lineas de captura </span>
                                    <label> {'¿Desea autorizar el lote a tesorería para proceder con el pago correspondiente ?'} </label> 

                                    </div>
                                </>
                            }
                           
                            
                        </Col>
                       
                        </Row>
            <br />
            <br />

                        </>
                    }


                </> :
                <>

            <Row>
                <Col md="12" className="text-center">
                {!typeConfirm ? <label>Se guardará nuevo bloque de impuestos</label> : 
                <>
                {
                    idTipoFormato === 2 &&
                    <Row>
                        <Col md="5" className={!respFormat.IsRegPatronal ? errorModal[0].errorRight : "text-right"}>
                            <span>Registro patronal</span>
                        </Col>
                        <Col md="6" className={!respFormat.IsRegPatronal ? errorModal[0].errorLeft : "text-left" }>
                        <span>{dataModalLC.registroPatronal}</span>

                        </Col>
                        <Col md="1">
                        <Image src={!respFormat.IsRegPatronal ? iconFail : iconOk} roundedCircle className="icon-medium" />
                        </Col>
                    </Row>
                }
                    <Row>
                        <Col md="5" className={!respFormat.RFCOK ? errorModal[0].errorRight : "text-right" }>
                            <span>RFC</span>
                        </Col>
                        <Col md="6" className={!respFormat.RFCOK ? errorModal[0].errorLeft : "text-left" }>
                        <span>{dataModalLC.RFC}</span>

                        </Col>
                        <Col md="1">
                        <Image src={!respFormat.RFCOK ? iconFail : iconOk} roundedCircle className="icon-medium" />
                        </Col>

                    </Row>
                    
                    
                    <Row>
                        <Col md="5" className={!respFormat.yearOK ? errorModal[0].errorRight : "text-right" }>
                            <span>Año</span>
                        </Col>
                        <Col md="6" className={!respFormat.yearOK ? errorModal[0].errorLeft : "text-left" }>
                        <span>{dataModalLC.anio}</span>
                        </Col>
                        <Col md="1" >
                        <Image src={!respFormat.yearOK ? iconFail : iconOk} roundedCircle className="icon-medium" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="5" className={!respFormat.monthOK ? errorModal[0].errorRight : "text-right" }>
                        <span>Mes</span>
                        </Col>
                        <Col md="6" className={!respFormat.monthOK ? errorModal[0].errorLeft : "text-left" }>
                        <span>{dataModalLC.mes}</span>

                        </Col>
                        <Col md="1">
                        <Image src={!respFormat.monthOK ? iconFail : iconOk} roundedCircle className="icon-medium" />
                        </Col>
                        
                    </Row>
                    
                    {
                    idTipoFormato === 2 &&
                    <Row>
                        <Col md="5" className={!respFormat.IsFolioSua ? errorModal[0].errorRight : "text-right" }>
                            <span>FolioSUA</span>
                        </Col>
                        <Col md="6" className={!respFormat.IsFolioSua ? errorModal[0].errorLeft : "text-left" }>
                        <span>{dataModalLC.idReferencia}</span>
                        </Col>
                        <Col md="1">
                        <Image src={!respFormat.IsFolioSua ? iconFail : iconOk} roundedCircle className="icon-medium" />
                        </Col>

                    </Row>
                    }
                    <Row>
                        <Col md="5" className={!respFormat.isImporteOK ? errorModal[0].errorRight : "text-right" }>
                        <span>Impuesto total</span>
                        </Col>
                        <Col md="6" className={!respFormat.isImporteOK ? errorModal[0].errorLeft : "text-left" }>
                        <span>${dataModalLC.importeTotalStr}</span>

                        </Col>
                        <Col md="1">
                        <Image src={!respFormat.isImporteOK ? iconFail : iconOk} roundedCircle className="icon-medium" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="5" className={"text-right" }>
                        <span>Linea de captura</span>
                        </Col>
                        <Col md="6" className={"text-left" }>
                        <span>{respFormat.lineaCaptura}</span>

                        </Col>
                        <Col md="1">
                        <Image src={!respFormat.isRespOk ? iconFail : iconOk} roundedCircle className="icon-medium" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="5" className={"text-right" }>
                        <span>Fecha de vencimiento</span>
                        </Col>
                        <Col md="6" className={"text-left" }>
                        <span>{respFormat.fechaLimite}</span>

                        </Col>
                        <Col md="1">
                        <Image src={!respFormat.isRespOk ? iconFail : iconOk} roundedCircle className="icon-medium" />
                        </Col>

                    </Row>
                </>}


                </Col>
            </Row>
            <br />
            <Row>
            <Col md="12" className="text-center">
            {!typeConfirm ? <>
                Al guardar este nuevo lote, será enviado una notificación 
                        informando el monto total necesario para cubrir los impuestos 
            </> : <>
            <div >
                {
                    !respFormat.isRespOk ?                        
                        <span style={{color: 'red'}}>
                            Error : los datos encontrados en el PDF que se intenta cargar no coincide con los datos del registro seleccionado                             
                        </span> :
                        <span style={{color: 'blue'}}>
                        Los datos del PDF seleccionado coinciden con los datos del registro seleccionado
                        </span>
                    
                }
            </div>
            </>}
                </Col>
            </Row>
            <br />
            <Row>
            <Col md="3"></Col>
            <Col md="8" className="text-Center">
                {!typeConfirm ?
                
                    
                    <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   ¿Desea continuar? </label>    
                    :
                    <>
                        {
                            respFormat.isRespOk ? 
                            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   ¿Desea guardar la linea de captura?</label> :
                            <Row>
                            <Col md="3"></Col>
                            <Col md="4">
                            <Buttons 
                                btnCancel={true} btnCancelAction={() => onCerrar()} btnCancelText="Cerrar" btnCancelShowIcon={true} btnCancelIconClass={"fa fa-window-close"}
                                />
                            </Col>
                                
                           
                            </Row>
                        }                    
                    
                    </>

                }

                </Col>
            </Row>
            <br />
         

                </>
            

            }
                

                {
                    respFormat.isRespOk || isZip ? 
                    <Row>
                    <Col md="4"></Col>
                            <Col  md="2" >
                                    
                                    <Buttons 
                                    btnAdd={true} btnAddAction={() => isZip ? onSaveLineaCapturaZip(isZip) :  onSaveLineaCaptura(isZip)} btnAddText={modalType ? "Guardar Lineas de captura" : "Aceptar"} btnAddShowIcon={true} btnAddIconClass="fa fa-save"
                                    /> 
                            </Col>
                            <Col  md="2" >
                            <Buttons 
                                btnCancel={true} btnCancelAction={() => onCerrar()} btnCancelText="Cancelar" btnCancelShowIcon={true}
                                />

                            </Col>
                    <Col md="4"></Col>


                        
                    </Row>: <>
                    {!typeConfirm  && <Row>
                        <Col  md="4" ></Col>
                            <Col  md="2" >

                        <Buttons 
                        btnAdd={true} btnAddAction={() => onAceptConfirm()} btnAddText="Guardar lote" btnAddShowIcon={true} btnAddIconClass="fa fa-save"

                        />
                        </Col>
                        <Col  md="2" >
                        <Buttons 
                            btnCancel={true} btnCancelAction={() => onCerrar()} btnCancelText="Cancelar" btnCancelShowIcon={true}
                            />

                        </Col>
                        </Row>
                    }
                    </>
                }
               
            </Modal.Body>

           </Modal>
        </>

    );
};

export default ModalConfirma;