import React from 'react';
import GeneralTable from '../GeneralTable';
import {columns,hiddenColumns} from './resources'
import Filter from './Filter'

const Consiliacion = ({
    typeTab,
    dataConsiliacion
}) => {
    return (
        <div>
            <div className="form-group" >
            <Filter 
                typeFilter={typeTab}
            />
            </div>
            <GeneralTable
                                // columns={columnsImpuestos(handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr,idTipoFormato,showModalCargaCP)}
                                columns={columns(typeTab)}
                                data={[]}
                                // action={undefined}
                                doubleClick={(original,values) => {}} 
                                // isNotPagination={true}
                                // pageSize={500}
                                // isCpmpBtn={true}
                                // // compnnt={idTipoFormato === 2 && showTblSSM  ?  BtnConfirmSS : <div></div>}
                                // compnnt={BtnConfirmSS}
                                hiddenColumnsIDs={
                                    hiddenColumns(typeTab)
                                }
                                // isCompTable={true}
                                // compTbl={BtnConfirmSS()}

                                />
        </div>
    );
};

export default Consiliacion;