import React, { Fragment } from 'react';
import { BrowserRouter as Route, Switch } from 'react-router-dom';

import Inicio from '../../Pages/Inicio'
import FacturacionElectronica from '../../Pages/FacturacionElectronica'
import Polizas from '../../Pages/Polizas'
import Test from '../../Pages/test'
import Usuarios from '../../Pages/Administrador/Usuarios/Usuarios';
import Plantilla from '../../Pages/Administrador/Plantilla';
import PerfilesVistas from '../../Pages/Administrador/Perfiles/PerfilesVistas/PerfilesVistas'
import AdministradorVistas from '../../Pages/Administrador/Vistas/AdministradorVistas';
import AdministradorAcciones from '../../Pages/Administrador/Acciones/AdministradorAcciones';
import AdministradorTiposComponentes from '../../Pages/Administrador/TiposComponentes/AdministradorTiposComponentes';
import MantenimientoMarcas from '../../Pages/Catalogos/MantenimientoMarcas/MantenimientoMarcas';
import GiroEmpresa from '../../Pages/Catalogos/GiroEmpresa/GiroEmpresa';
import ConfiguracionMatriz from '../../Pages/Facturacion/ConfiguracionMatricez/ConfiguracionMatricez'
import MantenimientoEmpresas from '../../Pages/Catalogos/MantenimientoEmpresas/MantenimientoEmpresas';
import CatTipoCompanias from '../../Pages/Catalogos/CatTipoCompanias/CatTipoCompanias';
import TesDisponibilidad from '../../Pages/Tesoreria/TesDisponibilidad';
import Dispersion from '../../Pages/Facturacion/Dispersion/Dispersion';
import MasivaDisponibilidad from '../../Pages/Tesoreria/MasivaDisponibilidad/MasivaDisponibilidad';
import Configuracion from '../../Pages/Configuraciones/Configuracion';
import ProgramacionIngresos from '../../Pages/Tesoreria/ProgramacionIngresos/ProgramacionIngresos';
import GeneracionArchivosDispersion from '../../Pages/Facturacion/GeneracionArchivosDispersion/GeneracionArchivosDispersion';
import CargaMovimientosBancarios from '../../Pages/Contabilidad/CargaMovimientosBancarios/CargaMovimientosBancarios';
import TiposMovimientos from '../../Pages/Contabilidad/TiposMovimientos/TiposMovimientos';
import MttoRelUsuariosEmpresas from '../../Pages/BuzonTributario/RelacionUsuarioEmpresa/index';
import PagoImpuestos from '../../Pages/Contabilidad/PagoImpuestos/PagoImpuestos';
import CatMotivosCorreo from '../../Pages/Catalogos/CatMotivosCorreo/CatMotivosCorreo';
import MonitorNotificaciones from '../../Pages/Administrador/MonitorNotificaciones/MonitorNotificaciones';
import MonitorSeguimientoBuzones from '../../Pages/Contabilidad/MonitorSeguimientoBuzones/Index';
import SeguimientoBuzonTributario from '../../Pages/Contabilidad/SeguimientoBuzonTributario/Index';
import MovimientosCompanias from '../../Pages/Tesoreria/MovimientosEntreCompanias/MovimientosCompanias';
import CatAnios from '../../Pages/Catalogos/CatAnios/CatAnios';
import CatCuentasBancarias from '../../Pages/Catalogos/CatCuentasBancarias/CatCuentasBancarias';
import ProductosServicios from '../../Pages/Catalogos/ProductosServicios/ProductosServicios';
import ActividadesEconomicas from '../../Pages/Catalogos/ActividadesEconomicas/ActividadesEconomicas';
import ConfigHistoricoEmpresas from '../../Pages/Configuraciones/ConfigHistoricoEmpresas/ConfigHistoricoEmpresas';
import FacturasComplementos from '../../Pages/Facturacion/FacturasComplementos/FacturasComplementos';
const Menu = ({handleClickRefresh}) => {
   return (
      <Fragment>
         <Switch>
            <Route exact path="/"></Route>
            <Route path="/Inicio"><Inicio /></Route>
            <Route path="/Plantilla"><Plantilla /></Route>
            <Route path="/facturacion"><FacturacionElectronica /></Route>
            <Route path="/Test"><Test /></Route>



            {/*Administrador*/}
            <Route path="/usuarios"><Usuarios handleClickRefresh={handleClickRefresh} /></Route>
            <Route path="/AdministradorVistas"><AdministradorVistas /></Route>
            <Route path="/AdministradorAcciones"><AdministradorAcciones /></Route>
            <Route path="/AdministradorTiposComponentes"><AdministradorTiposComponentes /></Route>
            <Route path="/PerfilesVistas"><PerfilesVistas handleClickRefresh={handleClickRefresh}/></Route>
            <Route path="/MonitorNotificaciones"><MonitorNotificaciones/></Route>
            <Route path="/MttoRelUsuariosEmpresas"><MttoRelUsuariosEmpresas /></Route>

            {/*Contabilidad*/}
            <Route path="/Polizas"><Polizas /></Route>
            <Route path="/CargaMovimientosBancarios"><CargaMovimientosBancarios /></Route>
            <Route path="/TiposMovimientos"><TiposMovimientos /></Route>
            <Route path="/MonitorSeguimientoBuzones"><MonitorSeguimientoBuzones /></Route>
            <Route path="/SeguimientoBuzonTributario"><SeguimientoBuzonTributario /></Route>
            <Route path="/PagoImpuestos"><PagoImpuestos /></Route>
            <Route path="/PagoImpuestosSS"><PagoImpuestos /></Route>
            <Route path="/PagoImpuestosTesoreria"><PagoImpuestos /></Route>

            {/* Facturación*/}
            <Route path="/ConfiguracionMatriz"><ConfiguracionMatriz /></Route>   
            <Route path="/Dispersion"><Dispersion /></Route> 
            <Route path="/GeneracionArchivosDispersion"><GeneracionArchivosDispersion /></Route>     
            <Route path="/TesDisponibilidad"><TesDisponibilidad /></Route> 
            <Route path="/MasivaDisponibilidad"><MasivaDisponibilidad /></Route>  
            <Route path="/FacturasComplementos"><FacturasComplementos/></Route>
            
            {/* //catalogos */}
            <Route path="/MantenimientoMarcas"><MantenimientoMarcas /></Route>
            <Route path="/GiroEmpresa"><GiroEmpresa /></Route>
            <Route path="/MantenimientoEmpresas"><MantenimientoEmpresas /></Route>
            <Route path="/CatTipoCompanias"><CatTipoCompanias /></Route>
            <Route path="/CatMotivosCorreo"><CatMotivosCorreo/></Route>
            <Route path="/CatTipoCompanias"><CatTipoCompanias /></Route>
            <Route path="/ProductosServicios"><ProductosServicios /></Route>
            <Route path="/ActividadesEconomicas"><ActividadesEconomicas /></Route>
            <Route path="/CatAnios"><CatAnios /></Route>
            <Route path="/CatCuentasBancarias"><CatCuentasBancarias /></Route>

            {/**Tesorería */}
            <Route path="/ConfiguracionMatriz"><ConfiguracionMatriz /></Route>   
            <Route path="/TesDisponibilidad"><TesDisponibilidad /></Route> 
            <Route path="/MasivaDisponibilidad"><MasivaDisponibilidad /></Route>
            <Route path="/Dispersion"><Dispersion /></Route>   
            <Route path="/ProgramacionIngresos"><ProgramacionIngresos /></Route>   
            <Route path="/MovimientosCompanias"><MovimientosCompanias /></Route>   

            {/*Configuración*/}
            <Route path="/Configuracion"><Configuracion handleClickRefresh={handleClickRefresh} /></Route>
            <Route path="/ConfigHistoricoEmpresas"><ConfigHistoricoEmpresas  /></Route>

         </Switch>
      </Fragment>

   )
}
export default Menu