import React,{Fragment} from 'react';
import { Modal, Button, Accordion, Container, Row, Col } from 'react-bootstrap'
import Buttons from '../../Buttons/Buttons';
import GeneralTable from '../../../Components/GeneralTable';
import {columns,hiddenColumns,columnsProgramMov} from '../../../Components/MovimientosEntreCompanias/resources'
import Filter from '../../../Components/MovimientosEntreCompanias/Filter'
import DropZonePdf from '../DropZone/DropZoneFile'
import DropZoneZip from '../DropZone/DropZoneZip'
import DropZoneXls from '../DropZone'

const ModalDetailMovChange = ({
    show              ,
    typeAction        ,
    onCancel   ,
    typeTab,
    relPetitionMovAll,
    relPetitionMov,
    idMovimiento,
    detailMovimiento,
    handleCbxMov,
    showDetailMov,
    showModalDeleteMov,
    loadEvidence,
    numTab    ,
    selected_Pdf,
    select_Pdf,
    uploadEvidence,
    changeInfoEvidence,
    referenceEvidece,
    dateEvidence,
    importEvidence,
    uploadEvidenceMasive,
    selected_ExcelFormat,
    select_ExcelFormat,
    selected_Zip,
    select_Zip,
    onAceptChange


}) => {
    return (
        <Fragment>
        <Modal  size="lg" show={show} animation={false} backdrop="static" aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header className="header">
        <Row>
        <Col md="11">
            <label>Detalle de la actualización de movimiento id ========= {detailMovimiento.idMovimiento}</label>
        </Col>
        <Col md="1">
            <div
            className=''
            onClick={e => onCancel()}
            data-placement="top"

            >
                <i className="fa fa-close" style={{color: '#888'}} alt=""></i>

            </div>
        {/* <Buttons 
            btnCancel ={true}  btnCancelAction  = {e => onCancel()} btnCancelText="Cancelar" btnCancelShowIcon={true} btnCancelIconClass={"fa fa-close"}
            /> */}
        </Col>

        </Row>

        </Modal.Header>
        <Modal.Body>

           <Row style={{padding:'10px'}}>
            <Col md="12">
               <Row>
                 <Col md="12">Información actual</Col>
               </Row>
               <Row style={{border:'solid 1px',padding:'5px', backgroundColor:'#C7F4FE'}}>
            <Col md="12">
                <Row>
                    <Col md="4">
                        <Row>
                            <Col md='6'>Id Movimiento</Col>
                            <Col md='6'>{detailMovimiento.idMovimiento}</Col>

                        </Row>

                    </Col>
                    <Col md="4">
                        <Row>
                            <Col md='6'>Fecha Solicitud</Col>
                            <Col md='6'>{detailMovimiento.fechasolicitud}</Col>

                        </Row>
                    </Col>
                    <Col md="4">
                        <Row>
                            <Col md='7'>Tipo de moviemiento</Col>
                            <Col md='5'>{detailMovimiento.tipoMovimiento}</Col>

                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <Row>
                            <Col md='6'>Concepto</Col>
                            <Col md='6'>{detailMovimiento.concepto}</Col>

                        </Row>

                    </Col>
                    <Col md="4">
                        <Row>
                            <Col md='6'>Comentarios</Col>
                            <Col md='6'></Col>

                        </Row>
                    </Col>
                    <Col md="4">
                        <Row>
                            <Col md='7'>Cuenta origen</Col>
                            <Col md='5'>{detailMovimiento.cuentaOrigen}</Col>

                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <Row>
                            <Col md='6'>Importe solicitado</Col>
                            <Col md='6'>{detailMovimiento.importereaplicado}</Col>

                        </Row>

                    </Col>
                    <Col md="4">
                        <Row>
                            <Col md='6'>Importe realizado</Col>
                            <Col md='6'>{detailMovimiento.importereaplicado}</Col>

                        </Row>
                    </Col>
                    <Col md="4">
                        <Row>
                            <Col md='7'>Referencia </Col>
                            <Col md='5'>{detailMovimiento.referencia}</Col>

                        </Row>
                    </Col>
                </Row>
            </Col>
               </Row>
            </Col>
           </Row>
           <Row style={{padding:'10px'}}>
            <Col md="12">
                 <Row>
                   <Col md="12">Información layout</Col>
                 </Row>
                 <Row style={{border:'solid 1px',padding:'5px', backgroundColor:'#C7F4FE'}}>
                   <Col md="12">
                      <Row>
                       <Col md="4">
                        <Row>
                          <Col md='6'>Id Movimiento</Col>
                          <Col md='6'>{detailMovimiento.idMovimiento}</Col>
                        </Row>
                       </Col>
                       <Col md="4">
                          <Row>
                             <Col md='6'>Fecha Solicitud</Col>
                             <Col md='6'>{detailMovimiento.fechasolicitud}</Col>

                         </Row>
                    </Col>
                    <Col md="4">
                        <Row>
                            <Col md='7'>Tipo de moviemiento</Col>
                            <Col md='5'>{detailMovimiento.tipoMovimiento}</Col>

                        </Row>
                    </Col>
                   </Row>
                    <Row>
                        <Col md="4">
                            <Row>
                                <Col md='6'>Concepto</Col>
                                <Col md='6'>{detailMovimiento.concepto}</Col>

                            </Row>

                        </Col>
                        <Col md="4">
                            <Row>
                                <Col md='6'>Comentarios</Col>
                                <Col md='6'></Col>

                            </Row>
                        </Col>
                        <Col md="4">
                            <Row>
                                <Col md='7'>Cuenta origen</Col>
                                <Col md='5'>{detailMovimiento.cuentaOrigen}</Col>

                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <Row>
                                <Col md='6'>Importe solicitado</Col>
                                <Col md='6'>{detailMovimiento.importereaplicado}</Col>

                            </Row>

                        </Col>
                        <Col md="4">
                            <Row>
                                <Col md='6'>Importe realizado</Col>
                                <Col md='6'>{detailMovimiento.importereaplicado}</Col>

                            </Row>
                        </Col>
                        <Col md="4">
                            <Row>
                                <Col md='7'>Referencia </Col>
                                <Col md='5'>{detailMovimiento.referencia}</Col>

                            </Row>
                        </Col>
                    </Row>
                    </Col>
                </Row>
               </Col>
           </Row>
           <Row style={{padding:'10px'}}>
                <Col md="12">
                    <Row>
                    <Col md="12">Informe</Col>
                    </Row>
                    <Row style={{border:'solid 1px',padding:'5px', backgroundColor:'#C7F4FE'}}>
                        <Col md='12'>
                             <div>{detailMovimiento.errorMessage}</div>
                        </Col>
                    </Row>

                </Col>
           </Row>
           <Row >
                <Col md="1">
                        <Buttons 
                        btnCancel ={true}  
                        btnCancelAction  = {e => onCancel()} 
                        btnCancelText="Cerrar" 
                        btnCancelShowIcon={true} 
                        btnCancelIconClass={"fa fa-close"}
                    />
                </Col>
                <Col md="1">
                <Buttons 
                        btnCancel ={true}  
                        btnCancelAction  = {e => onCancel()} 
                        btnCancelText="Descartar" 
                        btnCancelShowIcon={true} 
                    />

                </Col>
                <Col md="1">
                {
                detailMovimiento.typePeError == 1 &&
                <Buttons 
                btnSave ={true}  
                btnSaveAction  = {e => onAceptChange(detailMovimiento.idMovimiento)} 
                btnSaveText="Aceptar" 
                btnSaveShowIcon={true} 
            />

                }
              
                </Col>
                <Col md="9">
                   

                   </Col>
           </Row>
        </Modal.Body>
        <Modal.Footer>
        <Row>
        <Col md="10"></Col>
   
        </Row>
        </Modal.Footer> 
        <Row>
        <Col md="10"></Col>
        {/* <Col md="1">
            {
                typeAction === 2  && 
                <Buttons 
                btnSave={true} 
                btnSaveAction={() => uploadEvidence()} 
                btnSaveText={"Carga evidencia"}  
                btnSaveShowIcon={true} 
                btnSaveIconClass={'fa fa-upload'}
                />
            }
       
        </Col> */}
        <Col md="1">

            {/* <Buttons 
                btnCancel ={true}  
                btnCancelAction  = {e => onCancel()} 
                btnCancelText="Cancelar" 
                btnCancelShowIcon={true} 
                btnCancelIconClass={"fa fa-close"}
            /> */}
        </Col>
        </Row>
        </Modal>
    </Fragment>
    );
};

export default ModalDetailMovChange;