import { getInfo,getInfoErase, downloadHelp,cargaArvhivo,saveInfo,updateInfo, deleteInfo, loadFile } from '../../ApiRequests/req_CRUD'
import { fnNotification } from '../../ApiRequests/Notification'
import {ExisteSession,APISIAN,validaMensaje} from '../../ApiRequests/general';

import axios from 'axios';
var result = null;
var success = false;




const _load_File = (request,archivo, addCollectionResp) => {
    request = prepareRequest(request)
    // saveInfo('Contabilidad/MovimientosBancarios' ,request,(respons) =>  {        
    //     addCollectionResp(respons)
    // })
    // debugger;
    var data = new FormData()
    data.append('archivo.archivo[0]', archivo.archivo[0])
    archivo.archivo.forEach((element,i) => {
        data.append('file'+i, archivo.archivo[i])
    });
    data.append('cargarRequest', JSON.stringify(request))
    cargaArvhivo('Contabilidad/PagoImpuestos',data, request,(respons) =>  {        
        addCollectionResp(respons)
    })
}

const sendPDF = (request,archivo, addCollectionResp) => {
    console.log('request',archivo)
    console.log('request',request)
    request = prepareRequest(request)
    // saveInfo('Contabilidad/MovimientosBancarios' ,request,(respons) =>  {        
    //     addCollectionResp(respons)
    // })
    // debugger;
  
    
    var data = new FormData()
    data.append('archivo.archivo[0]', archivo.archivo)
    // archivo.archivo.forEach((element,i) => {
    //     data.append('file'+i, archivo.archivo[i])
    // });
    data.append('file0', archivo.archivo)
    data.append('cargarRequest', JSON.stringify(request))
    console.log('archivo',archivo.archivo)
    console.log('JSON.stringify(request)',JSON.stringify(request))
    console.log('request.typeSendPDF',request.typeSendPDF)

    if(request.typeSendPDF === 'PDFvalido'){
        carga_Arvhivo('Contabilidad/PagoImpuestos/'+request.typeSendPDF,data, request,(respons) =>  {        
            addCollectionResp(respons)
        })
    }else cargaArvhivo('Contabilidad/PagoImpuestos/'+request.typeSendPDF,data, request,(respons) =>  {        
        addCollectionResp(respons)
    })
}
const sendPDF2 = (request,addCollectionResp) => {
    console.log('request',request)
    request = prepareRequest(request)
    console.log('request.typeSendPDF',request.typeSendPDF)
    saveInfo('Contabilidad/PagoImpuestos/'+request.typeSendPDF ,request,(respons) =>  {  
        addCollectionResp(respons)
    })
}
const sendPDFTST = (request,archivo, respClbk) => {

    console.log('request',archivo)
    console.log('request',request)
    request = prepareRequest(request)
    // saveInfo('Contabilidad/MovimientosBancarios' ,request,(respons) =>  {        
    //     addCollectionResp(respons)
    // })
    // debugger;
  
    
    var data = new FormData()
    data.append('archivo.archivo[0]', archivo.archivo)
    // archivo.archivo.forEach((element,i) => {
    //     data.append('file'+i, archivo.archivo[i])
    // });
    data.append('file0', archivo.archivo)
    data.append('cargarRequest', JSON.stringify(request))
    console.log('archivo',archivo.archivo)
    console.log('JSON.stringify(request)',JSON.stringify(request))
    console.log('request.typeSendPDF',request.typeSendPDF)

    carga_Arvhivo('Contabilidad/PagoImpuestos/'+request.typeSendPDF,data, request,(respons) =>  {        
        respClbk(respons)
    })

    // console.log('request',archivo)
    // console.log('request',request)
    // debugger
    // // request = prepareRequest(request)
    // // saveInfo('Contabilidad/MovimientosBancarios' ,request,(respons) =>  {        
    // //     addCollectionResp(respons)
    // // })
    // // debugger;
  
    
    // var data = new FormData()
    // // data.append('archivo.archivo[0]', archivo.archivo)
    // // archivo.archivo.forEach((element,i) => {
    // //     data.append('file'+i, archivo.archivo[i])
    // // });
    // console.log('request',request)
    // console.log('Contabilidad/PagoImpuestos/'+request.typeSendPDF)

    // debugger
    // data.append('file0', 'datos')
    // data.append('file', archivo.archivo)

    // // data.append('cargarRequest', JSON.stringify(request))
    // // console.log('archivo',archivo.archivo)
    // // console.log('JSON.stringify(request)',JSON.stringify(request))
    // // console.log('request.typeSendPDF',request.typeSendPDF)
    // console.log('file', archivo.archivo)
    // console.log('request',request)
    // console.log('request',request.typeSendPDF)
    // console.log('https://ocr-development-sjjsjmnyqa-uc.a.run.app/'+request.typeSendPDF)
    // console.log('file', archivo.archivo)
    // console.log('data',data)

    // debugger

    // // carga_Arvhivo('https://ocr-development-sjjsjmnyqa-uc.a.run.app/'+request.typeSendPDF,data,(respons) =>  {        
    // //     respClbk(respons)
    // // })

    // axios({
    //     method: 'post',
        
    //     url: 'https://ocr-development-sjjsjmnyqa-uc.a.run.app/ocr',        
    //     file: archivo.archivo        
        
    // }).then(function(response){
    // if(response.status === 200) {
    //     debugger
    //     if (response.data.Code !== 200) {
    //     debugger

    //         validaMensaje(response.data.Message)
    //         fnNotification([response.data.Message, 'error', true])    
    //     }
    //     debugger

    //     if(response.data.Code === 200){
    //         fnNotification(['Carga completada con éxito ', 'success', true])    
    //     }
    //     result = response.data.Data
    // } 
            
         
    // }).catch(function(error){
    //     // debugger;
    //     fnNotification(['Error de conexion con https://ocr-development-sjjsjmnyqa-uc.a.run.app/ocr verificar el acceso a la red' , 'error', false])   
        
    // }).then(() => respClbk(result));
    
    
}

const upLoadFile = async (request,archivo,addCollection) => {
    console.log(request)
    console.log(archivo)
    console.log(addCollection)
    debugger

    let parameters = prepareRequest(request)

        var dataPdfrm = new FormData()
        var frm = new FormData();
        dataPdfrm.append('file', archivo.archivo)
        console.log('archivo',archivo);
        console.log('archivo',archivo.archivo);
        console.log('dat',dataPdfrm);
        debugger
        // const parameters = {
        //     idUsuarioAct : ExisteSession.getInfoUsuario().idUsuario,
        //     connection : ExisteSession.conexion(),
        //     token : ExisteSession.getInfoUsuario().token,
        //     listRegEx:request.listRegEx,
        //     referencia:request.referencia,
        //     fechaEvidencia:request.fechaEvidencia,
        //     importe:request.importe,

        //     // Agrega más parámetros si es necesario
        // };
        console.log(parameters);
        console.log(dataPdfrm);
        for (const key in parameters) {
            dataPdfrm.append(key, parameters[key]);
        }
        // console.log(dataPdfrm);
        console.log('load');
        debugger
        loadFile('Contabilidad/PagoImpuestos/'+request.typeSendPDF,dataPdfrm,(response,type,success) =>{
            console.log(response)
            console.log(type)
            console.log(success)
            // debugger
        addCollection(response,type,success)

        })

    // console.log(data)
}

const getInfoPagosImp = (request, addVistaAccionHandler) => {
    request = prepareRequest(request)
    getInfo('Contabilidad/PagoImpuestos',request, (res) =>{
        addVistaAccionHandler(res)
    })
}
// const getSolicitudes = (request, addVistaAccionHandler) => {
//     request = prepareRequest(request)
//     console.log(request)
//                 debugger
//     console.log(request)
//     debugger
//     getInfo('Contabilidad/PagoImpuestos/GetSolicitudes',request, (res) =>{
//         addVistaAccionHandler(res)
//     })
// }
const getSolicitudes = (request, addVistaAccionHandler) => {
    request = prepareRequest(request)
    getInfo('Contabilidad/PagoImpuestos/'+request.endPoint,request, (res) =>{
        addVistaAccionHandler(res)
    })
}
const updateSolicitudes = (request, addVistaAccionHandler) => {
    request = prepareRequest(request)
    console.log(request)
    updateInfo('Contabilidad/PagoImpuestos',request, (res,t,succes) =>{
        addVistaAccionHandler(res,t,succes)
    })
}
const deleteSolicitudes = (request, addVistaAccionHandler) => {
    request = prepareRequest(request)
    deleteInfo('Contabilidad/PagoImpuestos',request, (res,t,succes) =>{
        addVistaAccionHandler(res,t,succes)
    })
}
const donwnloadHelpDocument = (request, addCollection) => {   
    request = prepareRequest(request)
    downloadHelp('Contabilidad/'+request.typeHelp ,request,(respons) =>  {        
        addCollection(respons)
    })
}
const saveInfoPagosImp = (request, _callBack) => {
    request = prepareRequest(request)
    save_Info('Contabilidad/PagoImpuestos',request, (res,t,succes) =>{
        _callBack(res,t,succes,request)
    })
}
const autorizatLC = (request,_callBack) =>{
    request = prepareRequest(request)
    saveInfo('Contabilidad/PagoImpuestos/AutorizateLC',request, (res,t,succes) =>{
        _callBack(res,t,succes,request)

    })


}
const getEmpresas = (request,_callBack) =>{
    request = prepareRequest(request)
    getInfo('Contabilidad/PagoImpuestos/GetEmpresas',request, (res) =>{
        _callBack(res)
    })
}
const getCuentas = (request,_callBack) =>{
    request = prepareRequest(request)
    getInfo('Contabilidad/PagoImpuestos/GetCuentas',request, (res) =>{
        _callBack(res)
    })
}
const updateSolicitud = (request, addVistaAccionHandler) => {
    request = prepareRequest(request)
    debugger
    updateInfo('Contabilidad/PagoImpuestos/UpdateSolicitud',request, (res,t,succes) =>{
        console.log('request',request)
        debugger
        addVistaAccionHandler(res,t,succes)
    })
}
const printPDFSolicitud = (request, addVistaAccionHandler) => {
    request = prepareRequest(request)
    getInfo('Contabilidad/PagoImpuestos/printPDF',request, (res,t,succes) =>{
        addVistaAccionHandler(res,t,succes)
    })
}
const prepareRequest = request => {
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    // request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()

    return request;
}
const save_Info = (endPoint, objectData, _callback) => {
    if (objectData !== null) {
        axios({
            method: 'post',
            url: APISIAN + endPoint + '/Post',
            data: objectData
        }).then(function (response) {
            if (response.status === 200) {
                if (response.data.Code !== 200) {
                    success = false;
                    // fnNotification(['Error: ' + response.data.Message, 'error', true])
                    // validaMensaje(response.data.Message)
                    console.log(response.data.Data)
                    if (response.data.Data[0] != null) objectData = response.data.Data[0]
                    if(response.data.Data.length === 0) fnNotification([response.data.Message, 'error', true]) 

                }
                else {

                    // paramObjData = response.data.Data[0]
                    if (response.data.Data[0] != null) objectData = response.data.Data[0]
                    success = true;
                    fnNotification([response.data.Message, 'success', true])
                }
            } else {
                success = false;
                fnNotification(['Error: ' + response.statusText, 'error', true])
            }
        }).catch(function (error) {
            success = false;
            let msj = error.config.url + ' - ' + error.message
            fnNotification(['Error: ' + msj, 'error', false])

        }).then(() => _callback(objectData, 'save', success));
    }


}
const carga_Arvhivo = (endPoint, formData, objectData, _callBack)=>{
    debugger;
    console.log(formData)
    axios({
        method: 'post',
        
        url: APISIAN + endPoint + '/SubeArchivo',        
        data: formData        
        
    }).then(function(response){
    if(response.status === 200) {
        debugger;
        console.log(response)
        if (response.data.Code !== 200) {
            validaMensaje(response.data.Message)
            fnNotification([response.data.Message, 'error', true])    
        }
        if(response.data.Code === 200){
            fnNotification(['Carga completada con éxito ', 'success', true])    
        }
        result = response.data.Data
    } else{
        debugger;
        console.log(response)
    }
            
         
    }).catch(function(error){
        // debugger;
        fnNotification(['Error de conexion con ' + APISIAN + endPoint + '/SubeArchivo.  verificar el acceso a la red' , 'error', false])   
        
    }).then(() => _callBack(result));
}
export { 
    saveInfoPagosImp, 
    donwnloadHelpDocument, 
    getInfoPagosImp,
    _load_File,
    getSolicitudes,
    updateSolicitudes,
    deleteSolicitudes,
    sendPDF,
    autorizatLC,
    getEmpresas,
    getCuentas,
    updateSolicitud,    
    sendPDFTST,
    printPDFSolicitud,
    sendPDF2,
    upLoadFile
    
}