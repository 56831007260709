import React from 'react';
import LoteImpuestos from '../LoteImpuestos'
import MonitorImpuestos from '../MonitorImpuestos'
import DetalleSolicitud from '../DetalleSolicitud'
import TableOK from '../LoteImpuestos/TableOK';
const index = ({
    idTipoFormato,
    nombre,
    isOKSend,
    downloadHelp,
    xlsDownloadRespons,
    total,
    saldoAfavor,
    _loadFile,
    cancelLoad,
    showTblSSM,                 
    changeSelect,             
    changeDate,               
    expirationDate,           
    cat_Anios,                
    optionAnio,              
    catMeses,                   
    optionMeses,             
    catLotes,                 
    optionLotes,           
    catTipoImpuestos,        
    optionTipoImpuestos,  
    catEstatus,           
    optionEstatus,       
    respFormat,          
    dataSolicitudes,     
    searchPetition,
    handleChangeCbxM     ,
    handleChangeChkAllM  ,            
    chckAllM             ,  
    uploadPdf            ,
    actionChenge         ,
    showPDF              ,
    canBeAuthorized      ,
    isCbxautorized       ,
    handleChangeCbxAutr  ,
    showModalSavePDFZip,
    showModalSaveAutorizate,
    showModalAutorizate,
    cancelTblZip,
    valuesDownload,
    optionDwNl,
    downloadPdf,
    uploadZip,
    actionChangeZip,
    autorizate_LC,
    dataSolicitudesTbl,
    updatePetition,
    deletePetition,
    chckAll,
    handleChangeCbx,
    handleChangeChkAll,
    getPetitionAll,
    onSearchDataDetail,
    select_File,
    selected_File,
    onSelectFile,
    showModalCargaCP,
    showModalCP,
    actionChangeZipCP,
    actionChengeTestPDF,
    showTblTesoreria,
    cancelSendZipCP, 
    sendZIP_CP,
    printLineaCaptura,
    printComprobante ,
    showReason       ,
    styleRow,
    _getTotal,
    updateSearch,

}) => {
    // console.log(CompDropzone)
    return (
        <div className="row">
        <div className="col-md-12" >
        {
            idTipoFormato === 3 ?<>
             <div>
                <div style={{textAlign: 'center'}}>
                <label>Monitor de pago de impuestos Tesorería</label>    
                </div>
                <br />
                <br />
                    <MonitorImpuestos
                        idTipoFormato       ={idTipoFormato}            
                        showTblSSM          ={showTblSSM}       
                        changeSelect        ={changeSelect}     
                        changeDate          ={changeDate}     
                        expirationDate      ={expirationDate}     
                        cat_Anios           ={cat_Anios}     
                        optionAnio          ={optionAnio}    
                        catMeses            ={catMeses}       
                        optionMeses         ={optionMeses}    
                        catLotes            ={catLotes}     
                        optionLotes         ={optionLotes}  
                        catTipoImpuestos    ={catTipoImpuestos}    
                        optionTipoImpuestos ={optionTipoImpuestos} 
                        catEstatus          ={catEstatus} 
                        optionEstatus       ={optionEstatus}
                        respFormat          ={respFormat}
                        dataSolicitudes     ={dataSolicitudes}
                        // BtnConfirmSS        ={BtnConfirmSS}
                        searchPetition      ={searchPetition}
                        handleChangeCbxM     ={handleChangeCbxM}
                        handleChangeChkAllM  ={handleChangeChkAllM}            
                        chckAllM             ={chckAllM}  
                        uploadPdf            ={uploadPdf}
                        actionChenge         ={actionChenge}
                        showPDF              ={showPDF}
                        canBeAuthorized      ={canBeAuthorized}
                        isCbxautorized       ={isCbxautorized}
                        handleChangeCbxAutr  ={handleChangeCbxAutr}
                        showModalSavePDFZip     = {showModalSavePDFZip}
                        showModalSaveAutorizate = {showModalSaveAutorizate} 
                        cancelTblZip            = {cancelTblZip}
                        valuesDownload          = {valuesDownload}
                        optionDwNl              = {optionDwNl}
                        downloadPdf             = {downloadPdf}
                        uploadZip               = {uploadZip}
                        actionChangeZip         = {actionChangeZip}
                        autorizate_LC           = {autorizate_LC}
                        showModalCargaCP        = {showModalCargaCP}
                        actionChangeZipCP       = {actionChangeZipCP}
                        showTblTesoreria        = {showTblTesoreria}
                        cancelSendZipCP         = {cancelSendZipCP}
                        sendZIP_CP              = {sendZIP_CP}
                        printLineaCaptura       = {printLineaCaptura}
                        printComprobante        = {printComprobante}
                        showReason              = {showReason}
                        updateSearch={updateSearch}
                        total={total}
                        // saldoAfavor={saldoAfavor}
                        _getTotal={_getTotal}


                    />
                </div>
            </>:
            <>
            <ul className="nav nav-pills nav-justified ar-nav-pills center-block margin-bottom">
                        <li className={'active'}>
                            <a id='liNuevoLoteImpuestos' href="#LoteImpuestos" data-toggle="tab" >
                                <span>Nuevo lote de impuestos {nombre}</span> 
                            </a>
                        </li>
                        <li className={''} onClick={e => getPetitionAll()}>
                            <a href="#MonitorImpuestos" data-toggle="tab" >
                                <span>Monitor de impuestos {nombre}</span>
                            
                                
                            </a>
                        </li>
                        <li  className={''} onClick={e=> onSearchDataDetail()}>
                            <a id='liDetalleImpuestos' href="#DetalleImpuestos" data-toggle="tab" >
                                <span>Detalle de impuestos {nombre}</span>                               
                            
                            </a>
                        </li>
            </ul>
            <div className="tab-content margin-top">
                <div className={'tab-pane active'}  id="LoteImpuestos">
                    {
                            isOKSend ? 
                            <>
                            <div className="row">
                                <div className="col-md-12">
                                <TableOK
                                idTipoFormato = {idTipoFormato}
                                xlsDownloadRespons = {xlsDownloadRespons}
                                _loadFile={_loadFile}
                                cancelLoad={cancelLoad}
                                total={total}
                                saldoAfavor={saldoAfavor}

                                />
                                </div>
                               

                            </div>

                            </> :
                            <>
                            <LoteImpuestos
                            idTipoFormato={idTipoFormato}
                            nombre={nombre}
                            isOKSend={isOKSend} 
                            downloadHelp={downloadHelp}
                            selected_File={selected_File}
                            select_File={select_File}
                            xlsDownloadRespons={xlsDownloadRespons}
                            total={total}
                            saldoAfavor={saldoAfavor}
                            _loadFile={_loadFile}
                            cancelLoad={cancelLoad}
                            onSelectFile={onSelectFile}
                            />

                            </>
                    }
                                        {/* <input id={"myinputpdfL"} type="file"  onChange={(e) => actionChengeTestPDF(e)} />    */}
                </div>
                <div className={'tab-pane '} id="MonitorImpuestos">
                    <MonitorImpuestos
                        idTipoFormato       ={idTipoFormato}            
                        showTblSSM          ={showTblSSM}       
                        changeSelect        ={changeSelect}     
                        changeDate          ={changeDate}     
                        expirationDate      ={expirationDate}     
                        cat_Anios           ={cat_Anios}     
                        optionAnio          ={optionAnio}    
                        catMeses            ={catMeses}       
                        optionMeses         ={optionMeses}    
                        catLotes            ={catLotes}     
                        optionLotes         ={optionLotes}  
                        catTipoImpuestos    ={catTipoImpuestos}    
                        optionTipoImpuestos ={optionTipoImpuestos} 
                        catEstatus          ={catEstatus} 
                        optionEstatus       ={optionEstatus}
                        respFormat          ={respFormat}
                        dataSolicitudes     ={dataSolicitudes}
                        // BtnConfirmSS        ={BtnConfirmSS}
                        searchPetition      ={searchPetition}
                        handleChangeCbxM     ={handleChangeCbxM}
                        handleChangeChkAllM  ={handleChangeChkAllM}            
                        chckAllM             ={chckAllM}  
                        uploadPdf            ={uploadPdf}
                        actionChenge         ={actionChenge}
                        showPDF              ={showPDF}
                        canBeAuthorized      ={canBeAuthorized}
                        isCbxautorized       ={isCbxautorized}
                        handleChangeCbxAutr  ={handleChangeCbxAutr}
                        showModalSavePDFZip     = {showModalSavePDFZip}
                        showModalSaveAutorizate = {showModalSaveAutorizate} 
                        showModalAutorizate = {showModalAutorizate} 
                        cancelTblZip            = {cancelTblZip}
                        valuesDownload          = {valuesDownload}
                        optionDwNl              = {optionDwNl}
                        downloadPdf             = {downloadPdf}
                        uploadZip               = {uploadZip}
                        actionChangeZip         = {actionChangeZip}
                        autorizate_LC           = {autorizate_LC}
                        printLineaCaptura       = {printLineaCaptura}
                        printComprobante        = {printComprobante}
                        showReason              = {showReason}
                        styleRow                = {styleRow}
                        total={total}
                        saldoAfavor={saldoAfavor}
                        _getTotal={_getTotal}
                        updateSearch={updateSearch}
                        


                    />
                </div>
                <div className={'tab-pane '} id="DetalleImpuestos">
                    <DetalleSolicitud
                        idTipoFormato        ={idTipoFormato}                   
                        changeSelect         ={changeSelect}         
                        cat_Anios            ={cat_Anios}         
                        optionAnio           ={optionAnio}        
                        catMeses             ={catMeses}           
                        optionMeses          ={optionMeses}        
                        catLotes             ={catLotes}         
                        optionLotes          ={optionLotes}                               
                        dataSolicitudesTbl  ={dataSolicitudesTbl}                  
                        catTipoImpuestos     ={catTipoImpuestos}               
                        optionTipoImpuestos  ={optionTipoImpuestos}         
                        searchPetition       ={searchPetition}
                        updatePetition       ={updatePetition}
                        deletePetition       ={deletePetition}
                        handleChangeCbx      ={handleChangeCbx}
                        handleChangeChkAll   ={handleChangeChkAll}
                        chckAll              ={chckAll}
                        total={total}
                        saldoAfavor={saldoAfavor}



                    />
        
                </div>
            </div> 
            </>

        }
            
        
        </div>
    </div>
    );
};

export default index;