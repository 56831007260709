import React from 'react';

const CompBtnTotal = ({total,saldoAfavor,type}) => {
    return (
        <div className="row">
        <div className="col-md-8">
        
        </div>
        <div className="col-md-4" style={{fontWeight: 'bold', color: '#337ab7'}}><span > Total </span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
        <span>{Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(total)}</span> <span>&nbsp;&nbsp;</span>   
        {type === 1 && <>
            <span> Saldo a favor </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
            <span>{Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(saldoAfavor)}</span> <span>&nbsp;&nbsp;</span>   
            </>
        }
        </div> 
        <br />
        <br />
        <br />
        </div>
    );
};

export default CompBtnTotal;