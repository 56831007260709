import React, {Fragment, useEffect, useState, useRef} from 'react';
import {fnNotification} from '../../../ApiRequests/Notification'
import {toggleLoader} from '../../../ApiRequests/general'
import {inicializa ,errorModal} from '../../../Components/PagoImpuestos/resources'
import {getInfoPagosImp,_load_File,saveInfoPagosImp,getSolicitudes,updateSolicitudes,deleteSolicitudes,sendPDF,sendPDF2,donwnloadHelpDocument,autorizatLC,getEmpresas,getCuentas,updateSolicitud,sendPDFTST,printPDFSolicitud,upLoadFile} from '../../../Components/PagoImpuestos/service'
import ContainerBody from '../../../Components/PagoImpuestos/CntainerBody'
import Session from '../../../Application/session'
import Container from '../../../Components/Container/ContainerInitial'
import ModalRespuesta from './ModalRespuesta'
import ModalConfirma from './ModalConfirma'
import ModalEditaDetalle from './ModalEditaDetalle';
import ModalPDF from './ModalPDF';
import ModalCP from './ModalCP';
import ModalAutorizate from './ModalAutorizate';
import ModalMessage from './ModalMessage';
import Header from '../../../Components/Header';
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import jsonTbl from './test.json'
import { forEach } from 'lodash';

const PagoImpuestos = () => { 
    const [selectedFile, setSelectedFile]                = useState([]);
    const [tiposImpuestos, setTiposImpuestos]                = useState([]);
    const [detTiposImpuestos, setDetTiposImpuestos]          = useState([]);
    const [selected_File, setSelected_File]                  = useState([]);
    const [xlsDataRespons, setXlsDataRespons]                = useState([]);
    const [xlsDownloadRespons, setXlsDownloadRespons]        = useState([]);
    const [showModalResponse, setShowModalResponse]          = useState(false);
    const [isOKSend, setIsOKSend]                            = useState(false);
    const [showModalConfirm,setShowModalConfirm]             = useState(false);
    const [typeConfirm,setTypeConfirm]                       = useState(false);
    const [showModalEdit,setShowModalEdit]                   = useState(false);
    const [isUpdate,setIsUpdate]                             = useState(false);
    const [total, setTotal]                                  = useState(0);
    const [saldoAfavor, setSaldoAfavor]                      = useState(0);
    const [loteGenerado,setLoteGenerado]                     = useState('');
    const [loteAut,setLoteAut]                               = useState([]);
    const [allLote,setAllLote]                               = useState([]);
    const [allTags,setAllTags]                               = useState([]);
    const [cat_Anios,setCatAnios]                            = useState([]);
    const [catEstados,setCatEstados]                         = useState([]);
    const [catMeses,setCatMeses]                             = useState([]);
    const [catTipoImpuestos,setCatTipoImpuestos]             = useState([]);
    const [catLotes,setCatLotes]                             = useState([]);
    const [optionAnio,setOptionAnio]                         = useState([]);
    const [optionMeses,setOptionMeses]                       = useState([]);
    const [optionTipoImpuestos,setOptionTipoImpuestos]       = useState([]);
    const [optionLotes,setOptionLotes]                       = useState([]);
    const [catEstatus,setCatEstatus]                         = useState([]);
    const [optionEstatus,setOptionEstatus]                   = useState([]);
    const [expirationDate,setExpirationDate]                   = useState(null);
    const [dataSolicitudesTbl,setDataSolicitudesTbl]         = useState([]);
    const [dataSolicitudes,setDataSolicitudes]               = useState([]);
    const [dataSolicitdUpdate,setDataSolicitudUpdate]        = useState([]);
    const [folioSuaModal,setFolioSuaModal]                   = useState('');
    const [prioritarioModal,setPrioritarioModal]             = useState(false);
    const [montoModal,seTmontoModal]                         = useState(0);
    const [catTipoImpuestoModal,setCatTipoImpuestoModal]     = useState([]);
    const [optTipoImpuestoModal,setOptTipoImpuestosModal]    = useState([]);
    const [catTipoImpuestoDetalle,setCatTipoImpuestoDetalle] = useState([]);
    const [optTipoImpuestoDetalle,setOptTipoImpuestosDetalle]= useState([]);
    const [catTipoImpDetailAll,setCatTipoImpDetailAll]       = useState([]);
    const [chckAll,setchckAll]                               = useState(false);
    const [chckAllM,setchckAllM]                             = useState(false);
    const [showModalPDF,setShowModalPDF]                     = useState(false);
    const [showTblSSM,setShowTblSSM]                         = useState(false);
    const [showModalCP,setShowModalCP]                       = useState(false);
    const [infoPDF,setInfoPDF]                               = useState('');
    const [filePDF,setFilePDF]                               = useState([]);
    const [fileComprobantePDF,setFileComprobantePDF]         = useState([]);
    const [dtModalPC,setDtModalPC]                           = useState([]);
    const [fileZip,setFileZIP]                               = useState([]);
    const [isZip,setIsZip]                                   = useState(false);
    const [canBeAuthorized,setCanBeAuthorized]               = useState(false);
    const [isCbxautorized,setIsCbxautorized]                 = useState(false);
    const [modalType,setModalTyppe]                          = useState(false);
    const [respFormat,setRespFormat]                         = useState([]);
    const [dataModalLC,setDataModalLC]                       = useState([]);
    const [valuesDownload,setValuesDownload]                 = useState([]);
    const [optionDwNl,setoptionDwNl]                         = useState([]);
    const [pagadoras,setPagadoras]                           = useState([]);
    const [cuentas,setCuentas]                                 = useState([]);
    const [cuentasAll,setCuentasAll]                                 = useState([]);
    const [optionCuenta,setOptionCuenta]                       = useState([]);
    const [optionPagadora,setOptionPagadora]                 = useState([]);
    const [pagadoraInicial,setPagadoraInicial]               = useState([]);
    const [idSolicitud,setIdSolicitud]                       = useState(0);
    const [isComprobanteOK,setIsComprobanteOK]               = useState(0);
    const [catTipoFormato,setCatTipoFormato]                 = useState([]);
    const [optTipoFormato,setOptTipoFormato]                 = useState([]);
    const [lineaCapturaF,setLineaCapturaF]                   = useState('');
    const [fechaPagoF,setfechaPagoF]                         = useState('');
    const [montoF,setMontoF]                                 = useState('');
    const [showTblTesoreria,setShowTblTesoreria]             = useState(false);
    const [esUnPDF,setEsUnPDF]                               = useState(false);
    const [statusMotivo,setStatusMotivo]                     = useState(false);
    const [motivText,setMotivText]                           = useState('');
    const [showModalMessage,setShowModalMessage]             = useState(false);
    const [showModalAut,setShowModalAut]                     = useState(false);
    const [updateSearch,setupdateSearch]                     = useState(false);
    const [dataMEssage,setDataMEssage]                       = useState('');
    const [optionAuxLotes,setOptionAuxLotes]                 = useState([]);
    const [sheetData,setSheetData]                           = useState([])

    const idTipoFormato =  window.location.pathname === '/PagoImpuestos' ? 1 : window.location.pathname ===  '/PagoImpuestosSS' ? 2 : 3
  

    const fileType = 'aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const sheetDataSS = [
            {
                'REGISTRO_PATRONAL':'',
                'RFC'              :'',
                'RAZON_SOCIAL'     :'',
                'PRIORITARIO'      :'',
                'TIPO_IMPUESTO'    :'',
                'DETALLE'          :'',
                'Monto'            :'',
                'MES'              :'',
                'ANIO'             :'',
                'FOLIO_SUA'        :''
        }]
    const sheetHelp = [
            {
                'MARCA'        :'Nombre Marca',
                'PRIORITARIO'  :'1 ó 0',
                'RFC'          :'XEXT990101NI4',
                'EMPRESA'      :'Nombre Empresa',
                'TIPO_IMPUESTO':'Impuesto',
                'DETALLE'      :'Detalle',
                'Monto'        :'0',
                'MES'          :'0',
                'ANIO'         :'0',
                'FORMATO'      :'',
                'ESTADO'       :'CDMX'
        
            },
            {
                'MARCA'        :'Nombre Marca',
                'PRIORITARIO'  :'1 ó 0',
                'RFC'          :'XEXT990101NI4',
                'EMPRESA'      :'Nombre Empresa',
                'TIPO_IMPUESTO':'Impuesto',
                'DETALLE'      :'Detalle',
                'Monto'        :'0',
                'MES'          :'0',
                'ANIO'         :'0',
                'FORMATO'      :'FEDERALES',
            },
            {
                'MARCA'        :'Nombre Marca',
                'PRIORITARIO'  :'1 ó 0',
                'RFC'          :'XEXT990101NI4',
                'EMPRESA'      :'Nombre Empresa',
                'TIPO_IMPUESTO':'Impuesto',
                'DETALLE'      :'Detalle',
                'Monto'        :'0',
                'MES'          :'0',
                'ANIO'         :'0',
                'FORMATO'      :'',
                'ESTADO'       :'EDOMEX'
        
            },
            {
                'MARCA'        :'Nombre Marca',
                'PRIORITARIO'  :'1 ó 0',
                'RFC'          :'XEXT990101NI4',
                'EMPRESA'      :'Nombre Empresa',
                'TIPO_IMPUESTO':'Impuesto',
                'DETALLE'      :'Detalle',
                'Monto'        :'0',
                'MES'          :'0',
                'ANIO'         :'0',
                'FORMATO'      :'',
                'ESTADO'       :'NL'
            }
         
        ]
    const sheetHelpSS = [
        {
            'REGISTRO_PATRONAL':'',
            'RFC'              :'',
            'RAZON_SOCIAL'     :'',
            'PRIORITARIO'      :'',
            'TIPO_IMPUESTO'    :'',
            'DETALLE'          :'',
            'Monto'            :'',
            'MES'              :'',
            'ANIO'             :'',
            'FOLIO_SUA'        :''
    }]
    useEffect(() => {
        const fecha = new Date();
        const añoActual = fecha.getFullYear();
        const mesActual = fecha.getMonth() + 1; 
        setIsOKSend(false)
        setSelected_File([])
        window.$('#liNuevoLoteImpuestos').click();
        getInfoPagosImp({
            idTipoFormato:idTipoFormato
        },resp =>{
            let auxAnios = []
            let auxMeses = []
            let auxTImpuesto = []
            let auxEstatus = []
            let auxLotes = []
            let auxFormatos = []
            setTiposImpuestos(resp[0].tiposImpuestos)
            setSheetData(resp[0].dataLayout)
            setDetTiposImpuestos(resp[0].detTiposImpuestos)
            resp[0].catAnios.forEach(a => auxAnios.push({label:a,value:a}))
            resp[0].tiposImpuestos.forEach(t => auxTImpuesto.push({label:t.descripcion,value:t.idTipoImpuesto}))
            resp[0].meses.forEach(m => auxMeses.push({label:m.Mes,value:m.idMes}))
            resp[0].SolicitudesPago.forEach(s => auxLotes.push({label:s.lotes,value:s.lotes}))
            resp[0].estatus.forEach(a =>{
                if(idTipoFormato === 3){
                    if(a.valor > 2) auxEstatus.push({label:a.descripcion,value:a.valor})
                }else auxEstatus.push({label:a.descripcion,value:a.valor})
            })
            auxFormatos = [
                {value:'MEX',label:'EDOMEX'},
                {value:'DIF',label:'CDMX'},
                {value:'NLE',label:'NLE'},
            ]
            setCatTipoFormato(auxFormatos)
            setCatAnios(auxAnios)
            setCatMeses(auxMeses)
            setCatLotes(auxLotes)
            setCatEstados(resp[0].estados)
            setCatTipoImpuestos(auxTImpuesto)
            setCatTipoImpuestoModal(auxTImpuesto)
            setCatTipoImpDetailAll(resp[0].detTiposImpuestos)
            setCatEstatus(auxEstatus)
            setDataSolicitudesTbl([])
            setOptionAnio(auxAnios.find(x => x.value === añoActual))
            setOptionMeses(auxMeses.find(x => x.value === mesActual))
            setOptionTipoImpuestos([])
            setOptionLotes([])
            setShowModalEdit(false)
            setDataSolicitudes([]) 
            setDataSolicitudesTbl([])
            switch(idTipoFormato){
                case 1:
                    setValuesDownload([
                        {value:0,label:'Con elementos seleccionados'},
                        {value:1,label:'Descarga Csv'},
                        {value:2, label:'Descarga lineas de captura (ZIP)'},
                        {value:3, label:'Descargar comprobantes de pago (ZIP)'},
                        {value:4, label:'Carga lineas de captura (ZIP)'},
                        {value:6, label:'Imprimir PDF Lineas de captura'},
                    ])
                    break;
                case 2:
                    setValuesDownload([
                        {value:0,label:'Con elementos seleccionados'},
                        {value:1,label:'Descarga Csv'},
                        {value:2, label:'Descarga lineas de captura (ZIP)'},
                        {value:3, label:'Descargar comprobantes de pago (ZIP)'},
                        {value:4, label:'Carga lineas de captura (ZIP)'},
                        {value:6, label:'Imprimir PDF Lineas de captura'},
                    ])
                    break;
                case 3:
                    setValuesDownload([
                        {value:0,label:'Con elementos seleccionados'},
                        {value:1,label:'Descarga Csv'},
                        {value:2, label:'Descarga lineas de captura (ZIP)'},
                        {value:3, label:'Descargar comprobantes de pago (ZIP)'},
                        {value:5, label:'Asignar comprobantes de pago'},
                        {value:6, label:'Imprimir PDF Lineas de captura'},
                        {value:7, label:'Imprimir PDF Comprobante'},
                    ])
                    toggleLoader(true)
                    getEmpresas({
                        esPagadora : true,
                        // ID_empresa : -1
                    },resp=>{
                    toggleLoader(false)
                        let auxPagadora = []
                        resp.forEach(p => auxPagadora.push({label:p.nombreCorto,value:p.ID_empresa}))
                        setPagadoras(auxPagadora)
                        getCuentas({},respCuentas=>{
                            setCuentasAll(respCuentas)                       
                        })
                    })
                    break;
                    default:
            }
            setoptionDwNl({value:0,label:'Con elementos seleccionados'})
        })
    },[idTipoFormato])
    const downloadHelp = async () => {
        let dataSet = []
        sheetData.forEach(d =>{
            dataSet.push({
                        'MARCA'        :d.marca,
                        'PRIORITARIO'  :d.prioritario ? 1 : 0,
                        'RFC'          :d.rfc,
                        'EMPRESA'      :d.empresa,
                        'TIPO_IMPUESTO':d.tipoImpuesto,
                        'DETALLE'      :d.detalle,
                        'Monto'        :'',
                        'MES'          :'',
                        'ANIO'         :'',
                        'FORMATO'      :d.formato,
                        'ESTADO'       :''
                    })
        }
        )
        let sheet_Data = idTipoFormato === 1 ? dataSet : sheetDataSS 
        let sheet_Help = idTipoFormato === 1 ? sheetHelp : sheetHelpSS 
        let nameHelp = idTipoFormato === 1 ? 'layout-Estatales-Federales' : 'layout-Seguridad-Social'
        const _data = XLSX.utils.json_to_sheet(sheet_Data)
        const _help = XLSX.utils.json_to_sheet(sheet_Help)
        const wb = idTipoFormato === 1 ?  
        {Sheets:{'data':_data,'ayuda':_help},SheetNames:['data','ayuda']} :
        {Sheets:{'data':_data},SheetNames:['data']}
        const excelBuffer = XLSX.write(wb,{bookType:'xlsx', type:'array'})
        const data = new Blob([excelBuffer],{type:fileType})
        FileSaver.saveAs(data,nameHelp + fileExtension)
    }
    const onSelectFile = () =>{
        if(selectedFile.length > 0){
            toggleLoader(true)
            setSelected_File(selectedFile)
            _load_File({
                tipoImpuestos:tiposImpuestos,
                detTipoImpuestos:detTiposImpuestos,
                idTipoFormato:idTipoFormato,
                ListTags:allTags,
                estados:catEstados
            },{archivo: selectedFile,},respons =>{
                let respData = respons[0];
                let aux = []
                toggleLoader(false)
                if(respons.length > 0){
                    if(respons[0].conErrores){
                        respons[0].errorFila = false;
                    setIsOKSend(false)
                    setShowModalResponse(true)
                    }else{
                        setIsOKSend(true)
                    }
                    let montoTotal = 0;
                    respons[0].filas.forEach((element,j) => {
                        element.mensaje = element.mensaje !== '' && element.mensaje !== undefined  && element.mensaje !== null ? element.mensaje : '';
                        if(idTipoFormato === 1){
                            let celdaletraN = 'A-B-C-D-E-F-G-H-I-J-K'
                            let indexCelda = '0';
                            let _ErroresFila = '';
                            for(let ic = 0;  ic < element.celdas.length ; ic++){
                                if(element.celdas[ic].Errores.length > 0){
                                    _ErroresFila += element.celdas[ic].Errores[0]
                                }
                                if(ic === 0) indexCelda = element.celdas[ic].Celda.substring(1,element.celdas[ic].Celda.length)
                                let _indexCelda = element.celdas[ic].Celda.substring(1,element.celdas[ic].Celda.length) 
                                let letraCelda = celdaletraN.split('-')[ic]
                                let celda = letraCelda+indexCelda
                                let celdaN = element.celdas.find(cl => cl.Celda === celda)
                                if(celdaN === undefined || celdaN === null){
                                    respData.filas[j].celdas.push({Celda:celda,Valor:'Este campo no existe ',ConErrores:true,Errores:["Este campo no puede ser vacío-"]})
                                }
                            }
                            let isMarca       = element.celdas.find(c => c.Celda === 'A'+ indexCelda);
                            let isPrioritario = element.celdas.find(c => c.Celda === 'B'+ indexCelda);
                            let isRFC = element.celdas.find(c => c.Celda         === 'C'+ indexCelda);
                            let isEmpresa = element.celdas.find(c => c.Celda     === 'D'+ indexCelda);
                            let isTIPO_IMPUESTO = element.celdas.find(c => c.Celda === 'E'+ indexCelda);
                            let isDETALLE       = element.celdas.find(c => c.Celda === 'F'+ indexCelda);
                            let isMONTO         = element.celdas.find(c => c.Celda === 'G'+ indexCelda);
                            let isMES           = element.celdas.find(c => c.Celda === 'H'+ indexCelda);
                            let isANIO          = element.celdas.find(c => c.Celda === 'I'+ indexCelda);
                            let montoString = isMONTO ? isMONTO['Valor'] : '0'
                            let monto = isMONTO ? Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(isMONTO['Valor']) : ''
                            let saldoP = parseFloat(montoString) > 0.00 ? parseFloat(montoString) : 0.00
                            montoTotal = montoTotal + saldoP;
                            let idEstado = element.celdas.find(c => c.Celda === 'K'+ indexCelda);
                            let estado = element.celdas.find(c => c.Celda === 'J'+ indexCelda);
                            aux.push(
                                {
                                    'MARCA'        :isMarca         ? isMarca['Valor']         : '',
                                    'PRIORITARIO'  :isPrioritario   ? isPrioritario['Valor']   : '',
                                    'RFC'          :isRFC           ? isRFC['Valor']           : '',
                                    'EMPRESA'      :isEmpresa       ? isEmpresa['Valor']       : '',
                                    'TIPO_IMPUESTO':isTIPO_IMPUESTO ? isTIPO_IMPUESTO['Valor'] : '',
                                    'DETALLE'      :isDETALLE       ? isDETALLE['Valor']       : '',
                                    'MONTO'        :isMONTO         ? monto                    : '',
                                    'MES'          :isMES           ? isMES['Valor']           : '',
                                    'ANIO'         :isANIO          ? isANIO['Valor']          : '',
                                    'IDESTADO'     :estado          ? estado['Valor']          : '',
                                    'ESTADO'       :idEstado        ? idEstado['Valor']        : estado ? estado['Valor'] : '',
                                    // 'NOMESTADOTBL' :estado          ? estado['Valor']          : '',
                                    'Error'        :_ErroresFila
                                }
                            )
                        }else{
                            let celdaletraNSS = 'A-B-C-D-E-F-G-H-I-J'
                            let indexCelda = '0'
                            let _ErroresFila = '';
                            for(let iss = 0;  iss < element.celdas.length ; iss++){
                                let indexRow = iss                                
                                if(iss === 0) indexCelda = element.celdas[iss].Celda.substring(1,element.celdas[iss].Celda.length)
                                let _indexCelda = element.celdas[iss].Celda.substring(1,element.celdas[iss].Celda.length) 
                                let letraCelda = celdaletraNSS.split('-')[iss]
                                let celda = letraCelda+indexCelda
                                let celdaN = element.celdas.find(cl => cl.Celda === celda)
                                if(element.celdas[indexRow].Errores.length > 0){
                                    _ErroresFila += element.celdas[indexRow].Errores[0]
                                }
                                switch(letraCelda){
                                    case 'A':
                                        if(element.celdas.find(c => c.Celda   === 'A'+ _indexCelda)) {
                                            if(element.celdas.find(c => c.Celda   === 'A'+ _indexCelda)['Valor'] === 'NoHayRegPatr')  respData.filas[j].celdas[0].Valor = ''
                                        }
                                        break;
                                    case 'J':
                                        break;
                                        default:
                                            break;
                                }
                                if(celdaN === undefined || celdaN === null){
                                    switch(letraCelda){
                                        case 'A':
                                            respData.filas[j].celdas.push({Celda:celda,ConErrores:false,Errores:[""]})
                                            break;
                                        case 'C':
                                            respData.filas[j].celdas.push({Celda:celda,ConErrores:false,Errores:[""]})
                                            break;
                                        case 'J':
                                            respData.filas[j].celdas.push({Celda:celda,ConErrores:false,Errores:[""]})
                                            break;
    
                                            default:
                                                respData.filas[j].celdas.push({Celda:celda,ConErrores:true,Errores:["El campo es obligatorio-"]})
                                                break;
                                    }
                                }
                            }
                            let isRegistro    = element.celdas.find(c => c.Celda   === 'A'+ indexCelda);
                            let isRFCSS         = element.celdas.find(c => c.Celda === 'B'+ indexCelda);
                            let isRazonSocial = element.celdas.find(c => c.Celda   === 'C'+ indexCelda);
                            let isPrioritario = element.celdas.find(c => c.Celda   === 'D'+ indexCelda);
                            let isTIPO_IMPUESTO = element.celdas.find(c => c.Celda === 'E'+ indexCelda);
                            let isDETALLE       = element.celdas.find(c => c.Celda === 'F'+ indexCelda);
                            let isMONTO         = element.celdas.find(c => c.Celda === 'G'+ indexCelda);
                            let isMES           = element.celdas.find(c => c.Celda === 'H'+ indexCelda);
                            let isANIO          = element.celdas.find(c => c.Celda === 'I'+ indexCelda);
                            let isFOLIO_SUA     = element.celdas.find(c => c.Celda === 'J'+ indexCelda);
                            let montoString = isMONTO ? isMONTO['Valor'] : '0'
                            let monto = Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(montoString)
                            let saldoP = parseFloat(montoString) > 0.00 ? parseFloat(montoString) : 0.00
                            montoTotal = montoTotal + saldoP;
                            aux.push(
                                {
                                    'REGISTRO_PATRONAL' : isRegistro     ? isRegistro['Valor'] === 'NoHayRegPatr' ? '' :  isRegistro['Valor']   : '',
                                    'RFC'               : isRFCSS        ? isRFCSS['Valor']         : '',
                                    'RAZON_SOCIAL'      :isRazonSocial   ? isRazonSocial['Valor']   : '',
                                    'PRIORITARIO'       :isPrioritario   ? isPrioritario['Valor']   : '',
                                    'TIPO_IMPUESTO'     :isTIPO_IMPUESTO ? isTIPO_IMPUESTO['Valor'] : '',
                                    'DETALLE'           :isDETALLE       ? isDETALLE['Valor']       : '',
                                    'MONTO'             :isMONTO         ? monto         : '',
                                    'MES'               :isMES           ? isMES['Valor']           : '',
                                    'ANIO'              :isANIO          ? isANIO['Valor']          : '',
                                    'FOLIO_SUA'         :isFOLIO_SUA     ? isFOLIO_SUA['Valor'] === 'NoHayFolioSua' ? '' : isFOLIO_SUA['Valor'] : '',
                                    'Error'             :_ErroresFila
                                }
                            )
                        }
                    });
                    setXlsDownloadRespons(aux)
                    setTotal(montoTotal);
                    setXlsDataRespons(respData)
                    toggleLoader(false)
                }
            })
        }else{ 
            fnNotification(['No se ha seleccionado ningún archivo', 'error', true])
        }
    }
    const _loadFile = () => {
        setShowModalConfirm(true)
        setTypeConfirm(false)
        setModalTyppe(false)
        setIsZip(false)
    }
    const cancelLoad = () => {
        setIsOKSend(false)
        setXlsDownloadRespons([])
        setSelected_File([])
    }
    const handleChangeCbxM = (rowVal,statusRow,_rows) => {
        let auxSolicitudes = [...dataSolicitudes]
        let indx = auxSolicitudes.findIndex(x => x.idSolicitud === rowVal.idSolicitud)
        auxSolicitudes[indx].check = statusRow
        setDataSolicitudes(auxSolicitudes)
    }
    const handleChangeChkAllM = (status) => {
        setchckAllM(status)
        let auxSolicitudes = [...dataSolicitudes]
        auxSolicitudes.forEach(element =>{
            element.check = status
        })
        setDataSolicitudesTbl(auxSolicitudes)
    }
    const handleChangeCbxAutr = (e) => {
        setIsCbxautorized(e.target.checked)
    }
    const uploadPdf = (row) => {
        window.$('#myinput'+row.original.idSolicitud).click();
    }
    const actionChenge = (e,row) => {
        let tiempo_Transcurrido = Date.now();
            let hoy = new Date(tiempo_Transcurrido);
        toggleLoader(true)
        setIsZip(false)
        sendPDF({
            formatB64Img:jsonTbl,
            typeSendPDF:'validatePdf',
            idSolicitud:row.original.idSolicitud,
            idTipoFormato:idTipoFormato,
            RFC: row.original.RFC,
            anio:row.original.anio,
            mes:row.original.mes,
            importeNF : row.original.importeTotal,
            importeTotalStr: row.original.importeTotal,
            idReferencia: row.original.idReferencia,
            registroPatronal: row.original.registroPatronal,

        },{archivo:e.target.files[0],},resp=>{
        if(resp.length > 0){
            let tiempo_Transcurrido = Date.now();
            let hoy = new Date(tiempo_Transcurrido);
            if(idTipoFormato === 1){
                let aux = []
                jsonTbl.forEach(e =>{
                    aux.push(e.description)
                })
                setShowModalConfirm(true)
                setDataModalLC(
                    {
                        registroPatronal: row.original.registroPatronal,
                        RFC:row.original.RFC,
                        RFCOK:row.original.RFCOK,
                        anio:row.original.anio,
                        yearOK:row.original.yearOK,
                        mes:row.original.mes,
                        monthOK:row.original.monthOK,
                        importeNF : row.original.importeTotal,
                        isImporteOK:row.original.isImporteOK,
                        idReferencia: row.original.idReferencia,
                        importeTotalStr:row.original.importeTotal,
                        listImg : resp[0].ListImgB64
                    },
                )
                setRespFormat(resp[0]);
            }else {
                if(resp.length > 1){
                }else{
                setRespFormat(resp[0]);
                setShowModalConfirm(true)
                toggleLoader(false)
                setDataModalLC(
                    {
                        registroPatronal: row.original.registroPatronal,
                        RFC:row.original.RFC,
                        anio:row.original.anio,
                        mes:row.original.mes,
                        idReferencia: row.original.idReferencia,
                        importeTotalStr:row.original.importeTotal,
                        
                    },
                )
                }
            }
        }
        toggleLoader(false)
        })
        setFilePDF(e.target.files[0])
        setTypeConfirm(true)
    }
        const showPDF = (pdf,isOne,isComprobante,isImg) => {
        setEsUnPDF(isOne)
        console.log('pdf',pdf)
        console.log('isOne',isOne)
        console.log('isComprobante',isComprobante)
        console.log('isImg',isImg)
        debugger
        if(pdf === '' && !isOne){
        debugger

            printLineaCaptura(isComprobante,false)
        }else{
        debugger

            if(isImg){
        debugger
        
                    setEsUnPDF(false)
                    printLineaCaptura(isComprobante,true,pdf)
            } 
            else{
        debugger

                setShowModalPDF(true)
                setInfoPDF(pdf)
            }
            
        }

        

    }
    const downloadPdf = (typpdf) => {
        let auxSolicitudes = [...dataSolicitudes]
        let newSolicitudes = []
        let isOkSend = false
        dataSolicitudes.forEach(element =>{
            if(element.check && element.pathLineaCaptura !== ""){
                if(element.fechaPago == ""){
                    element.fechaPago = null
                }
                newSolicitudes.push(element)
                isOkSend = true;
            }
        })
        if(isOkSend){
        toggleLoader(true)        
            donwnloadHelpDocument({
                typeHelp: 'PagoImpuestos',
                dataSolicitudes: newSolicitudes,
                typpdf:typpdf
                        }, collection =>{                          
                                toggleLoader(false)        
                            })
        }else{
            fnNotification(['No se ha seleccionado ningún archivo que contenga linea de captura', 'warning', true])
        }
    }
    const uploadZip = (type) =>{
        
        if(type === 1) window.$('#IUploadZip').click();
        if(type === 2) window.$('#IUploadZipCP').click();
    }
    const actionChangeZip = (e,isCompPagoOK) =>{
        const date = new Date();
            let dataSolicitudesDownload = []
            let dataSolicitudesPagoOk = []
            setIsZip(true)
            console.log(isCompPagoOK)
            // console.log(e.target.files)
            debugger
            let file = ""
            if(!isCompPagoOK){
            file = e.target.files[0]
                dataSolicitudes.forEach(element =>{
                    element.lineaCaptura = element.lineaCaptura.replace(/-/g, "") 
                    if(element.check) dataSolicitudesDownload.push(element)
                })
                console.log('file',file)
                console.log('file',file.type)
                if(file.type === 'application/pdf'){
                    fnNotification(['Archivo no valido, se esperaba un archivo tipo zip', 'error', true])

                    toggleLoader(false)

                    return false
                }

            }else
            {
                console.log('fileZip',fileZip)
                console.log(dataSolicitudes)
                debugger

                // dataSolicitudes.forEach(element =>{
                //     if(element.check) dataSolicitudesDownload.push(element)
                // })
            }
            toggleLoader(true)
            // sendPDF({
            //     typeSendPDF:'validatePdf',/*Validara pdfs que se encuentren dentro del archivo ya que en esta opción se mandan en zip */
            //     dataSolicitudes:isCompPagoOK ? listComprobantesOK(dataSolicitudes) : dataSolicitudesDownload,
            //     ListTags:allTags,
            //     idTipoFormato:idTipoFormato,
            //     isCompPagoOK: isCompPagoOK
            // },{archivo:isCompPagoOK ? fileZip : e.target.files[0],},resp=>{
            //     console.log(resp)
            //     debugger
            // if(resp.length > 0){
            //     setupdateSearch(true)

            //     let aux = []
            //     let auxLt = []
            //     Array.isArray(resp) && 
            //     resp.forEach((e,i) => {
            //         let newFechaV = '';
            //         if(e.fechaVencimiento) newFechaV =  e.fechaVencimiento.split('T')[0].split('-')[2] + '-' + e.fechaVencimiento.split('T')[0].split('-')[1] + '-' + e.fechaVencimiento.split('T')[0].split('-')[0]
            //         e.fechaVencimiento = newFechaV !== '' ? newFechaV : e.fechaVencimiento
            //         if(e.lineaCaptura) e.comentario = ''
            //         else e.comentario = 'No se encontro linea de captura'
            //         if(!e.RFCOK) e.comentario = 'La petición no coincide con ningún pdf del zip seleccionado'
            //         if(e.isRespOk) e.comentario = (idTipoFormato === 1 || idTipoFormato === 2)  ? 'Consolidado' : 'Comprobante correcto'
            //         else e.lineaCaptura = ''
            //         let lotIndx = auxLt.findIndex(x => x === e.lote)
            //         if(lotIndx === -1 && e.isRespOk) auxLt.push(e.lote)
            //     })
            //     if(idTipoFormato === 1 || idTipoFormato === 2){
            //         setRespFormat(resp);
            //         setFileZIP(file)
            //         setLoteAut(auxLt)
            //         setShowTblSSM(true); 
            //     }
            //     if(idTipoFormato === 3){
            //         if(isCompPagoOK){
            //             let notOK = false;
            //             resp.forEach(element => {
            //                 if(!element.isRespOk){
            //                     notOK = true;
            //                     element.comentario = "No se pudo cargar " + element.descripcion
            //                 }  
            //             });
            //             if(notOK){
            //                 fnNotification(['Se han detectado errores en los siguientes comprobantes', 'error', true])
            //                 setShowTblTesoreria(true);
            //                 setDataSolicitudes(resp)
            //             }else{
            //                 setShowTblTesoreria(false);
            //                 searchPetition(true)
            //                 fnNotification(['Comprobantes guardados exitosamente. ', 'success', true])
            //             }

            //         }else{
            //             setShowTblTesoreria(true);
            //             setDataSolicitudes(resp)
            //             setFileZIP(e.target.files[0])
            //         }
            //     }
            // }else{

            // }
            // toggleLoader(false)
            // })
            console.log(dataSolicitudesDownload)
            console.log(dataSolicitudes)
            console.log(isCompPagoOK)
            debugger
            upLoadFile({
                // typeSendPDF:'validatePdf',/*Validara pdfs que se encuentren dentro del archivo ya que en esta opción se mandan en zip */
                typeSendPDF:'UploadZipLC',/*Validara pdfs que se encuentren dentro del archivo ya que en esta opción se mandan en zip */
                // dataSolicitudes:isCompPagoOK ? listComprobantesOK(dataSolicitudes) : dataSolicitudesDownload,
                dataJSolicitudes:isCompPagoOK ? JSON.stringify(listComprobantesOK(dataSolicitudes)) : JSON.stringify(dataSolicitudesDownload),
                ListTags:allTags,
                idTipoFormato:idTipoFormato,
                isCompPagoOK: isCompPagoOK
            },{archivo:isCompPagoOK ? fileZip : e.target.files[0],},resp=>{
                // console.log(resppns)
                // let resp = JSON.parse(resppns.dataJSolicitudes)
                console.log(resp)

                debugger
            if(resp.length > 0){
                setupdateSearch(true)
                console.log(resp)
                debugger
                let aux = []
                let auxLt = []
                Array.isArray(resp) && 
                resp.forEach((e,i) => {
                    console.log(e)
                    let newFechaV = '';
                    if(e.fechaVencimiento) newFechaV =  e.fechaVencimiento.split('T')[0].split('-')[2] + '-' + e.fechaVencimiento.split('T')[0].split('-')[1] + '-' + e.fechaVencimiento.split('T')[0].split('-')[0]
                    e.fechaVencimientoTbl = newFechaV !== '' ? newFechaV : e.fechaVencimiento
                    if(e.lineaCaptura) e.comentario = ''
                    else e.comentario = 'No se encontro linea de captura'
                    if(!e.RFCOK) e.comentario = 'La petición no coincide con ningún pdf del zip seleccionado'
                    if(e.isRespOk) e.comentario = (idTipoFormato === 1 || idTipoFormato === 2)  ? 'Consolidado' : 'Comprobante correcto'
                    else e.lineaCaptura = ''
                    let lotIndx = auxLt.findIndex(x => x === e.lote)
                    if(lotIndx === -1 && e.isRespOk) auxLt.push(e.lote)
                })
                if(idTipoFormato === 1 || idTipoFormato === 2){
                console.log(resp)

                debugger

                    setRespFormat(resp);
                    setFileZIP(file)
                    setLoteAut(auxLt)
                    setShowTblSSM(true); 
                }
                if(idTipoFormato === 3){
                console.log(resp)
                debugger
                    if(isCompPagoOK){
                        debugger
                        let notOK = false;
                        resp.forEach(element => {
                            debugger
                            if(!element.isRespOk){
                                notOK = true;
                                element.comentario = "No se pudo cargar " + element.descripcion
                            }  
                        });
                console.log(resp)

                        debugger
                        if(notOK){
                            debugger
                            fnNotification(['Se han detectado errores en los siguientes comprobantes', 'error', true])
                            setShowTblTesoreria(true);
                            setDataSolicitudes(resp)
                        }else{
                            debugger
                            setShowTblTesoreria(false);
                            searchPetition(true)
                            fnNotification(['Comprobantes guardados exitosamente. ', 'success', true])
                        }

                    }else{
                        setShowTblTesoreria(true);
                        setDataSolicitudes(resp)
                        setFileZIP(file)
                    }
                }
            }else{
            toggleLoader(false)
                
            }
            toggleLoader(false)
            })
    }
   const listComprobantesOK = (resp) => {
    let auxResp = [];
                    resp.forEach(e => {
                        if(e.isRespOk){
                            e.idEstatus = 5
                            auxResp.push(e) 
                        } 
                    })
    return auxResp;
   }
   const showModalSavePDFZip = () =>{
    setShowModalConfirm(true)
    setModalTyppe(true)
   }
   const showModalSaveAutorizate = () =>{
    setShowModalConfirm(true)
    setModalTyppe(false)
   }
   const showModalAutorizate = () =>{
    setShowModalAut(true)
   }
   const cancelTblZip = () =>{
    setRespFormat([]);
    setShowTblSSM(false);
    
    // setDataSolicitudes(dataSolicitudes)
   }
   const autorizate_LC = () =>{
    console.log(loteAut)
    debugger
        if(loteAut.length > 0){
            toggleLoader(true)
            autorizatLC({
                lotesAut:loteAut
            },(respons,t,succes) =>{
                setShowModalConfirm(false)
                searchPetition(true)
                setShowTblSSM(false);
                toggleLoader(false)
                setchckAllM(false);
                setoptionDwNl([])  
                setShowModalAut(false)
                toggleLoader(false)
            })
        }else{
            fnNotification(['No se ha detectado ninguna linea de captura', 'error', true])
            setShowModalConfirm(false)
            searchPetition(true)
            setShowTblSSM(false);
            toggleLoader(false)
            setchckAllM(false);
            setoptionDwNl([]) 
        }
   } 
const updatePetition = (type,row) => {
    setIsUpdate(true)
    setShowModalEdit(true)
    if(optTipoFormato !== undefined){
        let auxTipoFormato = []
        row.original.idReferencia === 'MEX' && setOptTipoFormato({value:'MEX',label:'EDOMEX'})
        row.original.idReferencia === 'DIF' && setOptTipoFormato({value:'DIF',label:'CDMX'})
        row.original.idReferencia === 'NLE' && setOptTipoFormato({value:'NLE',label:'NLE'})
    }
    setupdateSearch(true)

    if(type === 1 ){
        let auxRow = {
            idSolicitudPago:row.original.idSolicitudPago,
            idSolicitudPagoDetalle:row.original.idSolicitudPagoDetalle,
            lote:row.original.lote,
            marca:row.original.marca,
            prioritario:row.original.prioritario,
            rfc:row.original.rfc,
            empresa:row.original.empresa,
            tipoImpuesto:row.original.tipoImpuesto,
            detalle:row.original.detalle,
            monto:row.original.importeDetalle,
            mes:row.original.mes,
            anio:row.original.anio,
            folioSua:'',
            isMultipleDetail: row.original.bandera === 0 ? true : false,
            idReferencia:row.original.idReferencia,
            formato :row.original.tipoImpuesto
        }
        setDataSolicitudUpdate(auxRow)
        setPrioritarioModal(row.original.idPrioritario)
        seTmontoModal(row.original.importeDetalle)
        if(row.original.bandera === 1){
            setOptTipoImpuestosModal({label:row.original.tipoImpuesto,value:row.original.idTipoImpuesto})
            setOptTipoImpuestosDetalle({label:row.original.detalle,value:row.original.idDetTipoImpuesto})
            let auxDetTipoImpuesto = []
            catTipoImpDetailAll.forEach(a =>{
                if(a.idTipoImpuesto === row.original.idTipoImpuesto)   
                auxDetTipoImpuesto.push({label:a.descripcion,value:a.idDetTipoImpuesto})
            })
            setCatTipoImpuestoDetalle(auxDetTipoImpuesto)
        }else{
            setOptTipoImpuestosModal([])
            setOptTipoImpuestosDetalle([])
        }
    }else{
        let auxRow = {
            idSolicitudPago:row.original.idSolicitudPago,
            idSolicitudPagoDetalle:row.original.idSolicitudPagoDetalle,
            lote:row.original.lote,
            registroPatronal:row.original.registroPatronal,
            rfc:             row.original.rfc,
            razonSocial:     row.original.empresa,
            prioritario:     row.original.prioritario,
            tipoImpuesto:    row.original.tipoImpuesto,
            detalle:         row.original.detalle,
            monto:           row.original.importeDetalle,
            mes:             row.original.mes,
            anio:            row.original.anio,
            folioSua:        row.original.folioSUA,
            isMultipleDetail:        row.original.bandera,
        }
        setDataSolicitudUpdate(auxRow)
        setFolioSuaModal(row.original.folioSUA)
        setPrioritarioModal(row.original.idPrioritario)
        seTmontoModal(row.original.importeDetalle)
        if(row.original.bandera === 1){
            setOptTipoImpuestosModal({label:row.original.tipoImpuesto,value:row.original.idTipoImpuesto})
            setOptTipoImpuestosDetalle({label:row.original.detalle,value:row.original.idDetTipoImpuesto})
            let auxDetTipoImpuestoSS = []
            catTipoImpDetailAll.forEach(a =>{
                if(a.idTipoImpuesto === row.original.idTipoImpuesto)   
                auxDetTipoImpuestoSS.push({label:a.descripcion,value:a.idDetTipoImpuesto})
            })
            setCatTipoImpuestoDetalle(auxDetTipoImpuestoSS)
        }else{
            setOptTipoImpuestosModal([])
            setOptTipoImpuestosDetalle([])
        }
    }
}
const deletePetition = (type,row) => {
    setShowModalEdit(true)
    setIsUpdate(false)
    if(type === 1){
        let auxRow = {
            idSolicitudPagoDetalle:row.original.idSolicitudPagoDetalle  
        }
        setDataSolicitudUpdate(auxRow)
    }
    else{
        let auxRow = {
            idSolicitudPagoDetalle:row.original.idSolicitudPagoDetalle        
        }
        setDataSolicitudUpdate(auxRow)
    }    
}
    const onCerrar = () => {
        setShowModalResponse(false)
        setSelected_File([])
        setRespFormat([]);
    }
    const onCerrarModalConfir = () => {
        setShowModalConfirm(false)
        setIsComprobanteOK(0)
    }
    const onCloseMP = () => {
        setShowModalPDF(false)
    }
    const onCloseEdit = () => {
        setShowModalEdit(false)
    }
    const onAceptUpdate = (type) => {
        if(type)
        {
            toggleLoader(true)
            if(isUpdate){
                if(montoModal === null || montoModal === 0  || montoModal === '0' || montoModal === undefined || montoModal === ''){
                    fnNotification(['El monto debe de ser mayor a cero', 'error', true])
                    toggleLoader(false)
                    return null;
                } 
                let solicitud_Multiple = dataSolicitudesTbl.filter(s => s.idSolicitudPago === dataSolicitdUpdate.idSolicitudPago)
                if(solicitud_Multiple.length === 1){
                    if(optTipoImpuestoDetalle.length === 0){
                        fnNotification(['El campo Detalle no puede ser vacío', 'error', true])
                        toggleLoader(false)
                        return null;
                    }
                }
                if(optTipoFormato !== undefined && idTipoFormato === 1 && optTipoImpuestoModal.value === 3){
                    if(optTipoFormato.length === 0){ 
                        fnNotification(['El campo formato no puede ser vacío', 'error', true])
                        toggleLoader(false)
                        return null;
                    }
                }
                updateSolicitudes({
                    idSolicitudPago:dataSolicitdUpdate.idSolicitudPago,
                    idSolicitudPagoDetalle:dataSolicitdUpdate.idSolicitudPagoDetalle,
                    idTipoImpuesto:optTipoImpuestoModal.value,
                    idDetTipoImpuesto:optTipoImpuestoDetalle.value,
                    idPrioritario:prioritarioModal,
                    monto:montoModal,
                    folioSUA:'',
                    idTipoFormato:idTipoFormato,
                    idReferencia:optTipoImpuestoModal.value === 3 ? optTipoFormato.value :  'Federales' 
                },(resp,t,succes)=>{
            toggleLoader(false)
                    if(succes){
                        let auxd = [...dataSolicitudesTbl]
                        const indx = auxd.findIndex(x => x.idSolicitudPagoDetalle === dataSolicitdUpdate.idSolicitudPagoDetalle)
                        let solicitudMultiple = auxd.filter(s => s.idSolicitudPago === dataSolicitdUpdate.idSolicitudPago)
                        if(solicitudMultiple.length === 1){
                            auxd[indx].tipoImpuesto = optTipoImpuestoModal.label
                            auxd[indx].idTipoImpuesto = optTipoImpuestoModal.value
                            auxd[indx].detalle = optTipoImpuestoDetalle.label
                            auxd[indx].idDetTipoImpuesto = optTipoImpuestoDetalle.value
                        }
                        auxd[indx].importeDetalle = montoModal
                        auxd.forEach((element,i) => {
                            if(element.idSolicitudPago === dataSolicitdUpdate.idSolicitudPago) {
                                auxd[i].idPrioritario = prioritarioModal
                                auxd[i].prioritario = prioritarioModal ? 'Si' : 'No'
                                auxd[i].idReferencia = optTipoImpuestoModal.value === 3 ? optTipoFormato.value :  'Federales' 
                            }
                        });
                        setDataSolicitudesTbl(auxd)
                        setShowModalEdit(false)
                        searchPetition(false)
                        searchPetition(true)
                    }
                })
            }else{
                deleteSolicitudes({
                    idSolicitudPagoDetalle:dataSolicitdUpdate.idSolicitudPagoDetalle
                },(resp,t,succes) => {
            toggleLoader(false)
            setupdateSearch(true)

                    if(succes){
                        let auxd = [...dataSolicitudesTbl]
                        let indx = auxd.findIndex(x => x.idSolicitudPagoDetalle === dataSolicitdUpdate.idSolicitudPagoDetalle)
                        let r = auxd[indx]
                        auxd.splice(indx, 1);
                        setDataSolicitudesTbl(auxd)
                        setShowModalEdit(false)
                    }
                })
            }
        }
        else
        {
            if(isUpdate){
                if(montoModal === null || montoModal === 0  || montoModal === '0' || montoModal === undefined || montoModal === '' || montoModal < 0){
                    fnNotification(['El monto debe de ser mayor a cero', 'error', true])
                    return null;
                }
                if(folioSuaModal === null  || folioSuaModal === undefined || folioSuaModal === ''){
                    fnNotification(['El campo Folio sua no puede ser vacío', 'error', true])
                    return null;
                }
                let solicitud_MultipleSS = dataSolicitudesTbl.filter(s => s.idSolicitudPago === dataSolicitdUpdate.idSolicitudPago)
                if(solicitud_MultipleSS.length === 1){
                    if(optTipoImpuestoDetalle.length === 0){
                    fnNotification(['El campo Detalle sua no puede ser vacío', 'error', true])
                    return null;
                    }
                }
                updateSolicitudes({
                    idSolicitudPago:dataSolicitdUpdate.idSolicitudPago,
                    idSolicitudPagoDetalle:dataSolicitdUpdate.idSolicitudPagoDetalle,
                    idTipoImpuesto:optTipoImpuestoModal.value,
                    idDetTipoImpuesto:optTipoImpuestoDetalle.value,
                    idReferencia:folioSuaModal,
                    idPrioritario:prioritarioModal,
                    monto:montoModal
                },(resp,t,succes) =>{
                    if(succes){
                        let auxd = [...dataSolicitudesTbl]
                        const indx = auxd.findIndex(x => x.idSolicitudPagoDetalle === dataSolicitdUpdate.idSolicitudPagoDetalle)
                        let solicitudMultipleSS = auxd.filter(s => s.idSolicitudPago === dataSolicitdUpdate.idSolicitudPago)
                        if(solicitudMultipleSS.length === 1){
                            auxd[indx].tipoImpuesto = optTipoImpuestoModal.label
                            auxd[indx].idTipoImpuesto = optTipoImpuestoModal.value
                            auxd[indx].detalle = optTipoImpuestoDetalle.label
                            auxd[indx].idDetTipoImpuesto = optTipoImpuestoDetalle.value
                        }
                        auxd[indx].importeDetalle = montoModal
                        auxd.forEach((element,i) => {
                            if(element.idSolicitudPago === dataSolicitdUpdate.idSolicitudPago) {
                                auxd[i].idPrioritario = prioritarioModal
                                auxd[i].prioritario = prioritarioModal ? 'Si' : 'No'
                                auxd[i].folioSUA = folioSuaModal
                            }
                        });
                        setDataSolicitudesTbl(auxd)
                        setShowModalEdit(false)
                        searchPetition(false)
                        searchPetition(true)
                    }
                })
            }else{
                deleteSolicitudes({
                    idSolicitudPagoDetalle:dataSolicitdUpdate.idSolicitudPagoDetalle
                },(resp,t,succes) => {
                    if(succes){
                        let auxd = [...dataSolicitudesTbl]
                        let indx = auxd.findIndex(x => x.idSolicitudPagoDetalle === dataSolicitdUpdate.idSolicitudPagoDetalle)
                        let r = auxd[indx]
                        auxd.splice(indx, 1);
                        setDataSolicitudesTbl(auxd)
                        setShowModalEdit(false)
                    }
                })
            }
        }
    }
    const onAceptConfirm = () => {
        debugger
        toggleLoader(true)
        saveInfoPagosImp({
            idTipoFormato:idTipoFormato,
            jsonSave:JSON.stringify(xlsDownloadRespons)
        },(response,type,succes,o) =>{
            if(succes){
                setSelected_File([])
                setIsOKSend(false)
                setShowModalConfirm(false)
                let _SaldoAfavor =   parseFloat(response.saldoAfavor) ? response.saldoAfavor : 0.00
                setSaldoAfavor(_SaldoAfavor)
                setLoteGenerado(response.loteGenerado)
                let auxLotes = []
                let auxOptionLote = optionAuxLotes
                setDataSolicitudesTbl(response.SolicitudesPago)
                response.AllSolicitudesPago.forEach(s => auxLotes.push({label:s.lotes,value:s.lotes}))
                setCatLotes(auxLotes)
                setOptionAnio([])
                setOptionMeses([])
                auxOptionLote = auxOptionLote.push({label:response.loteGenerado,value:response.loteGenerado})
                setOptionLotes(auxOptionLote[0])    
                if(response.loteGenerado !== ''){
                }
                toggleLoader(false)
                setTimeout(() => {
                    setOptionLotes(optionAuxLotes[0])    
                    setOptionAuxLotes([])
                    window.$('#liDetalleImpuestos').click();
                },10);
            }
            else
            {
                let auxFilas = xlsDataRespons.filas
                xlsDataRespons.errorFila = true;
                if(response.jsonRespuesta !== undefined && response.jsonRespuesta){
                let _jsonRespuesta = JSON.parse(response.jsonRespuesta) 
                    let  celEF = [{idCel:1,cel:'A',nombre:"MARCA"},{idCel:2,cel:'B',nombre:"PRIORITARIO"},{idCel:3,cel:'C', nombre:'RFC'},{idCel:4,cel:'D',nombre:"EMPRESA"},{idCel:5,cel:'E',nombre:"TIPO_IMPUESTO"},{idCel:6, cel:'F',nombre:"DETALLE"},{idCel:7,cel:'G',nombre:"MONTO"},{idCel:8,cel:'H',nombre:"MES"},{idCel:8,cel:'I',nombre:"ANIO"},{idCel:9,cel:'K',nombre:"ESTADO"}]
                    let  celSS = [{idCel:1,cel:'A',nombre:"REGISTRO_PATRONAL"},{idCel:2,cel:'B',nombre:"RFC"},{idCel:3,cel:'C', nombre:'RAZON_SOCIAL'},{idCel:4,cel:'D',nombre:"PRIORITARIO"},{idCel:5,cel:'E',nombre:"TIPO_IMPUESTO"},{idCel:6, cel:'F',nombre:"DETALLE"},{idCel:7,cel:'G',nombre:"MONTO"},{idCel:8,cel:'H',nombre:"MES"},{idCel:8,cel:'I',nombre:"ANIO"}]
                    let auxNameCel = idTipoFormato === 1 ? celEF : celSS
                    for(let fi = 0; fi < auxFilas.length; fi++){
                        for(let c = 0; c < auxFilas[fi].celdas.length; c++){
                            let celS = auxNameCel.find(x => x.cel === auxFilas[fi].celdas[c].Celda.substring(0,1))
                            if(auxFilas[fi].celdas[c].Celda.substring(0,1) !== 'J'){
                                auxFilas[fi].celdas[c].id = celS.idCel
                                if(auxFilas[fi].celdas[c].Valor === undefined){
                                    auxFilas[fi].celdas[c].Valor = ""
                                    auxFilas[fi].celdas[c].Nombre = celS.nombre
                                    auxFilas[fi].celdas[c].ConErrores = true
                                    auxFilas[fi].celdas[c].Errores = []
                                    auxFilas[fi].celdas[c].Errores.push('El campo '+celS.nombre+ ' no puede ser vacío')
                                    auxFilas[fi].mensaje = " Registro con errores(ver detalle) "
                                    auxFilas[fi].celdasConErrores = true
                                }
                            }else    auxFilas[fi].celdas[c].id = 9                    
                        }
                    }
                    for(let i=0; i < _jsonRespuesta.length; i++){
                        for(let j=0; j<auxFilas.length; j++){
                        const { celdas } = auxFilas[j]
                        let _montoFila = 0;
                        if(auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "MONTO")]) 
                        _montoFila = Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "MONTO")].Valor)
                        let _formatMes = '';
                        if(auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "MES")])
                        _formatMes = auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "MES")].Valor.length === 1 ? '0'+auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "MES")].Valor : auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "MES")].Valor;
                        if(idTipoFormato === 1){
                            if(
                                _jsonRespuesta[i].PRIORITARIO   === auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "PRIORITARIO")].Valor &&
                                _jsonRespuesta[i].RFC           === auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "RFC")].Valor &&
                                _jsonRespuesta[i].EMPRESA       === (auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "EMPRESA")] ? auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "EMPRESA")].Valor : 0) &&
                                _jsonRespuesta[i].TIPO_IMPUESTO === auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "TIPO_IMPUESTO")].Valor &&
                                _jsonRespuesta[i].DETALLE       === auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "DETALLE")].Valor &&
                                _jsonRespuesta[i].MONTO       === _montoFila &&
                                _jsonRespuesta[i].MES           === _formatMes &&
                                _jsonRespuesta[i].ANIO          === auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "ANIO")].Valor 
                                && _jsonRespuesta[i].Error         !== ''
                            )
                            {
                                xlsDataRespons.archivoProcesado = false
                                xlsDataRespons.conErrores = true
                                xlsDataRespons.mensaje = 'El documento no puede ser procesado.'
                                auxFilas[j].celdasConErrores = true
                                xlsDownloadRespons[i].Error = _jsonRespuesta[i].Error.split(':')[1]
                                auxFilas[j].mensaje = _jsonRespuesta[i].Error.split(':')[1]
                            }
                        }else {
                            if(
                                _jsonRespuesta[i].PRIORITARIO   === auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "PRIORITARIO")].Valor  &&
                                _jsonRespuesta[i].RFC           === auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "RFC")].Valor &&
                                _jsonRespuesta[i].RAZON_SOCIAL  === auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "RAZON_SOCIAL")].Valor &&
                                _jsonRespuesta[i].TIPO_IMPUESTO === auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "TIPO_IMPUESTO")].Valor  &&
                                _jsonRespuesta[i].DETALLE       === auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "DETALLE")].Valor  &&
                                _jsonRespuesta[i].MONTO         === _montoFila &&
                                _jsonRespuesta[i].MES           === _formatMes &&
                                _jsonRespuesta[i].ANIO          === auxFilas[j].celdas[celdas.findIndex(c => c.Nombre === "ANIO")].Valor 
                                && _jsonRespuesta[i].Error         !== ''
                            )
                            {
                                auxFilas[j].celdasConErrores = true
                                xlsDownloadRespons[i].Error = _jsonRespuesta[i].Error.split(':')[1]
                                auxFilas[j].mensaje = _jsonRespuesta[i].Error.split(':')[1]
                            }
                        }
                        }
                    }
                setShowModalResponse(true)
                }
                setShowModalConfirm(false)
                toggleLoader(false)
            }
        })
    }
    const onSaveLineaCaptura = (isZip) => {
        toggleLoader(true)
        if(isZip){
            let dataSolicitudesUpload = []
            respFormat.forEach(element =>{
                    if(element.isRespOk){
                        element.fechaVencimiento = element.fechaVencimiento.split('-')[2]+'-'+element.fechaVencimiento.split('-')[1]+'-'+element.fechaVencimiento.split('-')[0]
                        dataSolicitudesUpload.push(element)
                    }
                })
                if(dataSolicitudesUpload.length > 0){
                    sendPDF({
                        typeSendPDF:'zipValido',
                        dataSolicitudes:dataSolicitudesUpload,
                        idEstatus:2
                    },{archivo:fileZip,},(resp,t,succes) => {
                        if(!modalType){
                            autorizate_LC()
                        }else{
                            setShowModalConfirm(false)
                            searchPetition(true)
                            setShowTblSSM(false);
                            toggleLoader(false)
                            setchckAllM(false);
                            setoptionDwNl([]) 
                            setRespFormat([]) 
                        }
                        setDataSolicitudesTbl([])
                    })
                }else{
                    fnNotification(['No se ha detectado ninguna linea de captura', 'error', true])
                    setShowModalConfirm(false)
                    searchPetition(true)
                    setShowTblSSM(false);
                    toggleLoader(false)
                    setchckAllM(false);
                    setoptionDwNl([])  
                    setRespFormat([]) 
                    setDataSolicitudesTbl([])
                }
        }else {
            sendPDF({
                typeSendPDF:'PDFvalido',
                idSolicitud:respFormat.idSolicitud,
                lineaCaptura:respFormat.lineaCaptura,
                fechaLimite:respFormat.fechaLimite,
                fechaVencimiento:respFormat.fechaVencimiento,
                idEstatus:2,
                idFormato: idTipoFormato
            },{archivo:filePDF,},(resp,t,succes) => {
                setShowModalConfirm(false)
                searchPetition(true)
                setShowTblSSM(false);
                toggleLoader(false)
                setRespFormat([]) 
                setDataSolicitudesTbl([])
            })
        }
    }
    const onSaveLineaCapturaZip = (isZip) => {
        toggleLoader(true)
        console.log(respFormat)

        debugger
        if(isZip){
            let dataSolicitudesUpload = []
            respFormat.forEach(element =>{
                    if(element.isRespOk){
                        // element.fechaVencimiento = element.fechaVencimiento.split('-')[2]+'-'+element.fechaVencimiento.split('-')[1]+'-'+element.fechaVencimiento.split('-')[0]
                        dataSolicitudesUpload.push(element)
                    }
                })
                console.log(dataSolicitudesUpload)
                if(dataSolicitudesUpload.length > 0){
                    // sendPDF2({
                        upLoadFile({
                        // typeSendPDF:'savePdf',
                        typeSendPDF:'UploadZipLC_OK',
                        // dataSolicitudes: dataSolicitudesUpload,
                        dataJSolicitudes: JSON.stringify(dataSolicitudesUpload),
                        idEstatus:2
                    },{archivo:fileZip},(resp,t,succes) => {
                        debugger
                        if(!modalType){
                            autorizate_LC()
                        }else{
                            setShowModalConfirm(false)
                            searchPetition(true)
                            setShowTblSSM(false);
                            setchckAllM(false);
                            setoptionDwNl([]) 
                            setRespFormat([]) 
                        }
                        setDataSolicitudesTbl([])
                    })
                }else{
                    fnNotification(['No se ha detectado ninguna linea de captura', 'error', true])
                    setShowModalConfirm(false)
                    searchPetition(true)
                    setShowTblSSM(false);
                    toggleLoader(false)
                    setchckAllM(false);
                    setoptionDwNl([])  
                    setRespFormat([]) 
                    setDataSolicitudesTbl([])
                }
        }else {
            sendPDF({
                typeSendPDF:'PDFvalido',
                idSolicitud:respFormat.idSolicitud,
                lineaCaptura:respFormat.lineaCaptura,
                fechaLimite:respFormat.fechaLimite,
                fechaVencimiento:respFormat.fechaVencimiento,
                idEstatus:2,
                idFormato: idTipoFormato
            },{archivo:filePDF,},(resp,t,succes) => {
                setShowModalConfirm(false)
                searchPetition(true)
                setShowTblSSM(false);
                toggleLoader(false)
                setRespFormat([]) 
                setDataSolicitudesTbl([])
            })
        }
    }
    const changeSelect =(event,name) => {
        if(name.name === 'lote'){
            if(event !== null && event !== undefined){
                setCanBeAuthorized(true)
                setOptionLotes(event) 
            }else{
                setOptionLotes([]) 
            }
        }
        let valueN = event !== null && event !== undefined ? event : []
        switch(name.name){
            case 'anio':
                setOptionAnio(valueN)
                break
            case 'periodo':
               setOptionMeses(valueN) 
                break
            case 'lote':
                break
            case 'tipoImpuesto':
                setOptionTipoImpuestos(valueN)
                break
            case 'estatus':
                setOptionEstatus(valueN)           
                break
            case 'funcElement':
                setoptionDwNl(valueN) 
                break
                default:
        }
    }
    const changeTipoImpModal =(event,name) => {
        switch(name.name){
            case 'tipoImpuesto':
                if(event !== null && event !== undefined){
                    setOptTipoImpuestosModal(event)
                    setOptTipoImpuestosDetalle([])
                        let auxDetTipoImpuesto = []
                        catTipoImpDetailAll.forEach(a =>{
                            if(a.idTipoImpuesto === event.value)   
                            auxDetTipoImpuesto.push({label:a.descripcion,value:a.idDetTipoImpuesto})
                        })
                        setCatTipoImpuestoDetalle(auxDetTipoImpuesto)
                } else setOptTipoImpuestosModal([])
                break
            case 'tipoImpDetalle':
                event !== null && event !== undefined ? setOptTipoImpuestosDetalle(event) : setOptTipoImpuestosDetalle([])
                break
            case 'tipoFormato':
                setOptTipoFormato(event)
                break;
                default:
        }
    }
    const getPetitionAll = () => {
        toggleLoader(true)
        setIsCbxautorized(false)
        getSolicitudes({
            endPoint:'GetSolicitudes',
            idPrioritario:null,
            idTipoFormato:idTipoFormato,
        },resp=>{
            setAllLote(resp)
            toggleLoader(false)
        })
    }
    const onSearchDataDetail = () => {
        if(dataSolicitudesTbl.length === 0) setTotal(0)
    }
    const searchPetition =(isMonitor,isUpdate) => {
        setLoteAut([])
        if(!isUpdate) setDataSolicitudes([]) 
        let auxltAut = []
        if(isMonitor){
            if( optionAnio.value === undefined  && 
                optionMeses.value === undefined && 
                optionLotes.value === undefined && 
                optionTipoImpuestos.value === undefined &&
                optionEstatus.value === undefined && 
                expirationDate === null
                ){
                fnNotification(['No se ha seleccionado ningún filtro para el monitor', 'error', true])
                setDataSolicitudesTbl([])
                return null;
            }
        }else{
            if( optionAnio.value === undefined && 
                optionMeses.value === undefined && 
                optionLotes.value === undefined && 
                optionTipoImpuestos.value === undefined){
                fnNotification(['No se ha seleccionado ningún filtro para el detalle', 'error', true])
                setDataSolicitudesTbl([])
                return null;
            }
        }
        toggleLoader(true)
        console.log(isMonitor)
        debugger
        getSolicitudes({
                endPoint:isMonitor ? 'GetSolicitudes' :'GetSolicitudesDetalle',
                // idPrioritario:null,
                isMonitor:isMonitor,
                anio:optionAnio.value,
                mes:optionMeses.value,
                lote:optionLotes.value,
                idTipoImpuesto:optionTipoImpuestos.value,
                idTipoFormato:idTipoFormato,
                fechaVencimiento:isMonitor ? expirationDate : null,
                idEstatus:isMonitor ? optionEstatus.value : undefined,
            },resp=>{
                console.log(resp)
                debugger
                toggleLoader(false);
                let auxSolDet = resp
                let auxAut = false
                auxSolDet.sort(function (a, b) {
                    if (a.esPagado > b.esPagado) {
                      return 1;
                    }
                    if (a.esPagado < b.esPagado) {
                      return -1;
                    }
                    // a must be equal to b
                    return 0;
                  });
                auxSolDet.map((e,i)  => {
                        if(optionLotes.value){
                        let auxAu2 = countPetition(e.lote,e,resp)
                        if(auxAu2 > 0){
                            if(validateIsAutorized(e.lote,e,resp)){
                                auxAut = true
                                let indx = auxltAut.findIndex(x => x === e.lote)
                                if(indx === -1) auxltAut.push(e.lote)
                            } 
                        }
                        }
                        if(idTipoFormato === 3){
                            // e.ba_nco = cuentasAll.find(c => c.idCuenta === e.idCuenta) ? cuentasAll.find(c => c.idCuenta === e.idCuenta).numerodecuenta + '-'+ cuentasAll.find(c => c.idCuenta === e.idCuenta).banco :'-'
                            e.ba_nco = cuentasAll.find(c => c.idCuenta === e.idCuenta) ? cuentasAll.find(c => c.idCuenta === e.idCuenta).banco :'Not_Banco'
                            e.folio_SUA = e.lote.substring(0,3) === 'ISS' ? e.idReferencia : ''
                            e.Format = e.lote.substring(0,3) === 'IEF' ? e.idReferencia : ''
                        }
                        e.check = false
                        e.periodo = e.mes+'-'+e.anio
                        e.fechaVencimiento = e.fechaVencimiento !== undefined ? e.fechaVencimiento.split('T')[0] : ''
                        e.fechaPago = e.fechaPago !== undefined ? e.fechaPago.split('T')[0] : ''
                        e.fechaAct = e.fechaAct !== undefined ? e.fechaAct.split('T')[0] : ''
                        return null
                    }
                    )
                let _tot = 0
                let _totF = 0
                if(!isMonitor){
                    resp.forEach(element =>{
                        let saldoP = parseFloat(element.importeDetalle) > 0.00 ? parseFloat(element.importeDetalle) : 0.00
                        let saldoF = parseFloat(element.importeDetalle) < 0.00 ? parseFloat(element.importeDetalle) : 0.00
                        if(element.importeDetalle !== undefined){
                            _tot = _tot + saldoP 
                            _totF = _totF + saldoF 
                        }  
                        else _tot = 0
                    })
                    setTotal(_tot)
                    setSaldoAfavor(_totF)
                    setDataSolicitudesTbl(auxSolDet)
                }else{
                    let montoTotal = 0.00
                    auxSolDet.forEach(e =>{
                        let saldoP = parseFloat(e.importeTotal) > 0.00 ? parseFloat(e.importeTotal) : 0.00
                        montoTotal = montoTotal + saldoP;
                    })
                    setTotal(montoTotal)
                    if(idTipoFormato === 3){
                        let newAux = []
                        auxSolDet.forEach(element =>{
                            if(element.idEstatus > 2) newAux.push(element)
                        })
                        setDataSolicitudes(newAux) 
                        setTimeout(() => {
                            setShowTblTesoreria(false);
                        }, );
                    }else setDataSolicitudes(auxSolDet) 
                }
                toggleLoader(false)
                setLoteAut(auxltAut);
                if(auxAut && resp.length >0) setIsCbxautorized(true)
                else{
                    setIsCbxautorized(false)
                    setCanBeAuthorized(false)
                    }    
            })
    }
    const countPetition = (lote,e,tbl) => {
        let resp = 0;
        if(allLote.length > 0) {
            let loteOrigin = allLote.filter(x => x.lote === lote)
            let loteTbl = tbl.filter(x => x.lote === lote)
            if(loteOrigin.length === loteTbl.length) resp =loteOrigin.length
            else resp = 0;
        }
        return resp
    }
    const validateIsAutorized = (lote,e,tbl) => {
        let isCountOK = false
        let loteOrigin = allLote.filter(x => x.lote === lote)
        let loteTbl = tbl.filter(x => x.lote === lote)
        let auxCntPet = 0;
        for(let i = 0; i < loteTbl.length; i++) {
            if(loteTbl[i].idEstatus === 2) auxCntPet++
        }
        if(auxCntPet === loteTbl.length){
            return true;
        }else return false;
    }
    const changeCheck = (e) =>   setPrioritarioModal(e.target.checked)
    const changeData = (e) =>{
        if(e.target.name === 'folioSua') setFolioSuaModal(e.target.value)
        else {
            let val = parseFloat(e.target.value) > 0 ? parseFloat(e.target.value) : 0;
            seTmontoModal(val)
        } 
    } 
    const changeDate = (e) => { 
        setExpirationDate(e.target.value) 
    }
  const handleChangeCbx = (rowVal,statusRow,_rows) => {
    let auxSolicitudes = [...dataSolicitudesTbl]
    let indx = auxSolicitudes.findIndex(x => x.idSolicitudPagoDetalle === rowVal.idSolicitudPagoDetalle)
    auxSolicitudes[indx].check = statusRow
    setDataSolicitudesTbl(auxSolicitudes)
}
const handleChangeChkAll = (status) => {
    setchckAll(status)
    let auxSolicitudes = [...dataSolicitudesTbl]
    auxSolicitudes.forEach(element =>{
        element.check = status
    })
    setDataSolicitudesTbl(auxSolicitudes)
}
const showModalCargaCP = (row) => {
    setOptionCuenta([])
    setOptionPagadora([])
    setCuentas([])
    let auxPagadora = pagadoras.find(p => p.value === row.original.idPagadora)
    let auxPag = []
    if(auxPagadora !== undefined) {
        setOptionPagadora(auxPagadora)
        auxPag.push(auxPagadora)
        setPagadoraInicial(auxPag)
    }
    let auxCuentasCbx = cuentasAll.filter(c => c.idEmpresa === row.original.idPagadora)
    
    let auxCuenta = cuentasAll.find(c => c.idCuenta === row.original.idCuenta)
    if(auxCuentasCbx !== undefined && auxCuentasCbx !== null){
    let auxCuentas = []
    auxCuentasCbx.forEach(c => {
        auxCuentas.push({label:c.banco+'-'+c.numerodecuenta,value:c.idCuenta})
    })
    setCuentas(auxCuentas)
    }
    if(auxCuenta !== undefined && auxCuenta !== null){
        if(auxPagadora !== undefined && auxPagadora !== null){
            if(auxPagadora.value !== 4210){
                setOptionCuenta({value:auxCuenta.idCuenta,label:auxCuenta.banco+'-'+auxCuenta.numerodecuenta})
                }
        }else{
            setOptionCuenta({value:auxCuenta.idCuenta,label:auxCuenta.banco+'-'+auxCuenta.numerodecuenta})
        }
    }
    setIdSolicitud(row.original.idSolicitud)
    let dataModal = {
        impuesto:row.original.tipoImpuesto,
        empresa:row.original.empresa,
        lineaCaptura:row.original.lineaCaptura,
        impuestoTotal:row.original.importeTotal,
        fechaVencimiento:row.original.fechaVencimiento,
        importeTotal:row.original.importeTotal,
    }
    setDtModalPC(dataModal)
    setShowModalCP(true)
}
const changeSelectCP = (e, v) => {
    if(v.name === 'cuenta') setOptionCuenta(e)
    else{
        setOptionPagadora(e)
        setOptionCuenta([])
        let auxCuentas = []
        cuentasAll.forEach(c => {
            // if(e.value === c.modulo_id) auxCuentas.push({label:c.banco+'-'+c.numerodecuenta,value:c.idCuenta})
            if(e.value === c.idEmpresa) auxCuentas.push({label:c.banco+'-'+c.numerodecuenta,value:c.idCuenta})
        })
        setCuentas(auxCuentas)
    }   
}
const closeMCP = () => {
    setPagadoraInicial([])
    setShowModalCP(false)
    setIsComprobanteOK(0)
}
const cambiaPagadora = () => {
    toggleLoader(true)
    if(optionPagadora.length === 0){
        fnNotification(['El campo pagadora no puede ser vacío', 'error', true])
        toggleLoader(false)
        return false
    }
    let _IdPagadora = 0; 
    let _IdCuenta = 0; 
    
    if(optionPagadora.label === 'EFECTIVO'){
        _IdPagadora = optionPagadora.value
        if(cuentasAll.find(x => x.banco === 'EFECTIVO') !== undefined) _IdCuenta = cuentasAll.find(x => x.banco === 'EFECTIVO').idCuenta
        toggleLoader(false)

    }else{
        _IdPagadora = optionPagadora.value
        _IdCuenta = optionCuenta.value
        toggleLoader(false)
     }
    if(optionCuenta.length === 0 && optionPagadora.value !== _IdPagadora){
        fnNotification(['El campo Banco no puede ser vacío', 'error', true])
        toggleLoader(false)
        return false
    }
    updateSolicitud({
        idSolicitud:idSolicitud,
        idPagadora:_IdPagadora,
        idCuenta:_IdCuenta
    },(resp,typ,succes) =>{
        console.log(resp)
        console.log(typ)
        console.log(succes)
        debugger
        if(succes){
            setShowModalCP(false)
            toggleLoader(false)
            searchPetition(true,false)
        }
    })
}
const actionChangePDFCP = (e) => {
    let tiempo_Transcurrido = Date.now();
            let hoy = new Date(tiempo_Transcurrido);
    toggleLoader(true)
    setIsZip(false)
    let _file = e.target.files[0]
    setupdateSearch(true)
    sendPDF({
        formatB64Img:jsonTbl,
        typeSendPDF:'validatePdf',
        idTipoFormato:idTipoFormato,
        lineaCaptura:dtModalPC.lineaCaptura,
        importeTotal:dtModalPC.importeTotal,
        fechaVencimiento:dtModalPC.fechaVencimiento
    },{archivo:_file,},resp=>{
        let tiempo_Transcurrido = Date.now();
            let hoy = new Date(tiempo_Transcurrido);
        if(resp.length > 0){
            setFileComprobantePDF(_file)
            if(resp[0].isRespOk){
                setIsComprobanteOK(1)
                setfechaPagoF(resp[0].fechaPago)
            }else{
                setIsComprobanteOK(2)
                setFileComprobantePDF([])
                setfechaPagoF('')
                setLineaCapturaF(dtModalPC.lineaCaptura)
            }
        }
        toggleLoader(false)      
    })
}
const saveReceipt = () => {
    if(lineaCapturaF === "" && (isComprobanteOK === 2 || isComprobanteOK === 4) ){
        fnNotification(['No se ha agregado la linea de captura', 'error', true])
        return false
    }
    if(fechaPagoF === "" && (isComprobanteOK === 2 || isComprobanteOK === 4) ){
        fnNotification(['No se ha agregado fecha de pago', 'error', true])
        return false
    }
    if(fileComprobantePDF.length < 1) {
        fnNotification(['No se ha seleccionado el archivo', 'error', true])
        return false
    }
    toggleLoader(true)
    sendPDF({
        typeSendPDF:'PDFvalido',
        idSolicitud:idSolicitud,
        idEstatus:5,
        idFormato: idTipoFormato,
        fechaPago:fechaPagoF,
        lineaCaptura:lineaCapturaF,
        importeTotal:montoF,
        isUploadM:isComprobanteOK === 2 ? true : false,
    },{archivo:fileComprobantePDF,},(resp,t,succes) => {
        setShowModalCP(false)
        setIsComprobanteOK(0)
        searchPetition(true)
        toggleLoader(false)
        setLineaCapturaF('')
        cambiaPagadora()
    })
}
const changeDLC = (e) => {
    switch(e.target.name){
        case 'lineaCpatur':
            setLineaCapturaF(e.target.value)
            break;
        case 'mont':
            setMontoF(e.target.value)
            break;
        case 'fechaPago':
            setfechaPagoF(e.target.value)
            break;
            default: 
    }
}
const changeForceFile = (e) => {
    setFileComprobantePDF(e.target.files[0])
}
const rechazaSolicitud = () => {
    toggleLoader(true)
    if(statusMotivo && motivText === ''){
        fnNotification(['No se puede rechazar la solicitud sin poner un motivo', 'error', true])
        toggleLoader(false)
        return false
    }
    updateSolicitud({
        idSolicitud:idSolicitud,
        idSolicitudPagoDetalle:dataSolicitdUpdate.idSolicitudPagoDetalle,
        idEstatus:4,
        idTipoFormato:idTipoFormato,
        motivoRechazo: statusMotivo ? motivText : null
    },(resp,t,succes) =>{
        if(succes){
            setShowModalCP(false)
            searchPetition(true,true)
            setMotivText('')
            setStatusMotivo(false)
        }
        toggleLoader(false)
    })
}
const actionChangeZipCP = (e) =>{
}
const cancelSendZipCP = () =>{
    setupdateSearch(true)
    setShowTblTesoreria(false)
    searchPetition(true)
}
const sendZIP_CP = () =>{
    debugger
    actionChangeZip('e',true)
}
const actionChengeTestPDF = (e) => {
    toggleLoader(true)
    sendPDFTST({
        typeSendPDF:'validateTestSenFile',
        dataSolicitudes:[],
        ListTags:allTags,
        idTipoFormato:8,
        typeR:2
    },{archivo:e.target.files[0],},resp=>{
    if(resp.length > 0){
    }
    toggleLoader(false)
    })
}
const printLineaCaptura = (isComprobante,type,path) => {
        console.log(isComprobante)
        console.log(type)
        console.log(path)
        debugger
        let auxSolicitudes = [...dataSolicitudes]
        toggleLoader(true)
        let newSolicitudes = []
        let isOkSend = false
            if(type){
        debugger

                let newSolicitud = auxSolicitudes.find(x => x.idSolicitud === path)  
                newSolicitud.isComprobante = true
                newSolicitudes.push(newSolicitud)
            }else{
        debugger

                dataSolicitudes.forEach(e =>{
                    if(e.fechaPago == "") {e.fechaPago = null}
                    if(isComprobante){
                        if(e.check && e.pathComprobantePago !== ""){
                            e.isComprobante = true
                            newSolicitudes.push(e)
                            isOkSend = true;
                        }
                    }else{
        debugger

                        if(e.check && e.pathLineaCaptura !== ""){
                            e.isComprobante = false
                            newSolicitudes.push(e)
                            isOkSend = true;
                        }
                    }
                })
            }

        printPDFSolicitud({
            dataSolicitudes:newSolicitudes,
        },(resp,t,s) => {
            setShowModalPDF(true)
            setInfoPDF(resp[0])
            toggleLoader(false)
        })
}
const printComprobante = () => {
}
const changeIsComprobanteOK = (t) =>{
    setStatusMotivo(false)
    setLineaCapturaF(dtModalPC.lineaCaptura)
    pagadoraInicial.length > 0 ? setIsComprobanteOK(t) :
    fnNotification(['No se puede cargar un comprobante si no se ha guardado una pagadora', 'error', true])
} 
const showMotivo = (s) => setStatusMotivo(s)
const changeTextMotiv = (e) => setMotivText(e.target.value)
const loadFileComp = () => {
    setStatusMotivo(false)
    pagadoraInicial.length > 0 ? window.$('#IUploadCP').click() :
    fnNotification(['No se puede cargar un comprobante si no se ha guardado una pagadora', 'error', true])
    }
const showReason = (reason,id) => {
    setShowModalMessage(true)
    setDataMEssage(reason)
}
const onCloseMessage = () => {
    setShowModalMessage(false)
    setDataMEssage('')
}
const styleRow ={
    color:'blue',
}
const _getTotal = (type) =>{
    let montoTotal = 0.00
    dataSolicitudes.forEach(e =>{
        let saldoP = 0.00
        saldoP = type === 1 ? 
                parseFloat(e.importeTotal) > 0.00 ? parseFloat(e.importeTotal) : 0.00 : 
                parseFloat(e.importeTotal) < 0.00 ? parseFloat(e.importeTotal) : 0.00
        montoTotal = montoTotal + saldoP;
    })
    return montoTotal
}
return (
    <Fragment > 
    <Header title={inicializa(idTipoFormato).title +' '+ Session.getInfoAmbiente()} breadcrum={inicializa(idTipoFormato).breadcrum}/>
    <Container title={inicializa(idTipoFormato).title} contBody={
            <ContainerBody 
            idTipoFormato            ={idTipoFormato} 
            nombre                  ={idTipoFormato === 1 ? ' Estatales y Federales' : idTipoFormato === 2 ? 'Seguro Social' : 'Tesorería'}           
            showTblSSM               ={showTblSSM}       
            changeSelect             ={changeSelect}     
            changeDate               ={changeDate}     
            expirationDate           ={expirationDate}     
            cat_Anios                ={cat_Anios}     
            optionAnio               ={optionAnio}    
            catMeses                 ={catMeses}       
            optionMeses              ={optionMeses}    
            catLotes                 ={catLotes}     
            optionLotes              ={optionLotes}  
            catTipoImpuestos         ={catTipoImpuestos}    
            optionTipoImpuestos      ={optionTipoImpuestos} 
            catEstatus               ={catEstatus} 
            optionEstatus            ={optionEstatus}
            respFormat               ={respFormat}
            dataSolicitudes          ={dataSolicitudes}
            downloadHelp             ={downloadHelp}
            _loadFile                ={_loadFile}
            cancelLoad               ={cancelLoad}
            xlsDownloadRespons={xlsDownloadRespons}
            searchPetition           ={searchPetition}
            handleChangeCbxM         ={handleChangeCbxM}
            handleChangeChkAllM      ={handleChangeChkAllM}            
            chckAllM                 ={chckAllM}  
            uploadPdf                ={uploadPdf}
            actionChenge             ={actionChenge}
            showPDF                  ={showPDF}
            canBeAuthorized          ={canBeAuthorized}
            isCbxautorized           ={isCbxautorized}
            handleChangeCbxAutr      ={handleChangeCbxAutr}
            showModalSavePDFZip      = {showModalSavePDFZip}
            showModalSaveAutorizate  = {showModalSaveAutorizate} 
            showModalAutorizate  = {showModalAutorizate} 
            cancelTblZip             = {cancelTblZip}
            valuesDownload           = {valuesDownload}
            optionDwNl               = {optionDwNl}
            downloadPdf              = {downloadPdf}
            uploadZip                = {uploadZip}
            actionChangeZip          = {actionChangeZip}
            autorizate_LC            = {autorizate_LC}
            dataSolicitudesTbl       ={dataSolicitudesTbl}                  
            updatePetition           ={updatePetition}
            deletePetition           ={deletePetition}
            handleChangeCbx          ={handleChangeCbx}
            handleChangeChkAll       ={handleChangeChkAll}
            chckAll                  ={chckAll}
            getPetitionAll           ={getPetitionAll}
            onSearchDataDetail           ={onSearchDataDetail}
            selected_File            ={selected_File}
            select_File             ={(file) => setSelectedFile(file)}
            onSelectFile            ={onSelectFile}
            isOKSend                ={isOKSend}
            total                   ={total}
            saldoAfavor                   ={saldoAfavor}
            showModalCargaCP        ={showModalCargaCP}
            changeSelectCP          ={changeSelectCP}
            actionChangeZipCP       ={actionChangeZipCP}
            actionChengeTestPDF     = {actionChengeTestPDF}
            showTblTesoreria        = {showTblTesoreria}
            cancelSendZipCP         = {cancelSendZipCP}
            sendZIP_CP              = {sendZIP_CP}
            printLineaCaptura       = {printLineaCaptura}
            printComprobante        = {printComprobante}
            showReason              = {showReason}
            styleRow                = {styleRow}
            _getTotal               ={_getTotal}
            updateSearch            ={updateSearch}

            />
    } 
    spaceTitle='6' spaceBtn='6'/>
    <div>
        <ModalRespuesta 
                    show={showModalResponse}
                    onCerrar={onCerrar}
                    xlsDataRespons={xlsDataRespons}
                    xlsDownloadRespons={xlsDownloadRespons}
                    idTipoFormato={idTipoFormato}
        /> 
        <ModalConfirma 
                    show={showModalConfirm}
                    onAceptConfirm={onAceptConfirm}
                    onCerrar={onCerrarModalConfir}
                    typeConfirm={typeConfirm}
                    onSaveLineaCaptura={onSaveLineaCaptura}
                    onSaveLineaCapturaZip={onSaveLineaCapturaZip}
                    respFormat={respFormat}
                    dataModalLC={dataModalLC}
                    idTipoFormato={idTipoFormato}
                    errorModal={errorModal}
                    isErrorZip={false}
                    isZip={isZip}
                    lote={respFormat.length > 0 ? respFormat[0].lote : ''}
                    modalType={modalType}
        />
        <ModalAutorizate 
         show={showModalAut}
         autorizateLC={() => autorizate_LC(1)}
         onCerrar={() => setShowModalAut(false)}
        />
        <ModalPDF
        show={showModalPDF}
        dataPDF={infoPDF}
        onCloseMP={onCloseMP}
        isOne={esUnPDF}
        />
        <ModalMessage
        show={showModalMessage}
        dataMEssage={dataMEssage}
        onCloseMessage={onCloseMessage}
        />
        <ModalEditaDetalle 
            showModalEdit={showModalEdit}
            isUpdate={isUpdate}
            onCloseEdit={onCloseEdit}
            onAceptUpdate={onAceptUpdate}
            catTipoImpuestos={catTipoImpuestoModal}
            optionTipoImpuestos={optTipoImpuestoModal}
            catTipoImpuestoDetalle={catTipoImpuestoDetalle}
            optTipoImpuestoDetalle={optTipoImpuestoDetalle}
            dataSolicitdUpdate={dataSolicitdUpdate}
            changeSelect={changeTipoImpModal}
            folioSua={folioSuaModal}
            prioritario={prioritarioModal}
            monto={montoModal}
            changeCheck={changeCheck}
            changeData={changeData}
            EF={idTipoFormato === 1 ? true : false}
            catTipoFormato={catTipoFormato}
            optTipoFormato={optTipoFormato}
        />
      <ModalCP 
                    showModalCP       = {showModalCP}
                    closeMCP          = {closeMCP}
                    changeSelectCP    = {changeSelectCP}
                    dtModalPC         = {dtModalPC}
                    pagadoras         = {pagadoras}
                    cuentas            = {cuentas}
                    optionCuenta       ={optionCuenta}
                    optionPagadora    ={optionPagadora}
                    cambiaPagadora    ={cambiaPagadora}
                    actionChangePDFCP ={actionChangePDFCP}
                    showModalCargaCP  = {showModalCargaCP}
                    isComprobanteOK   = {isComprobanteOK}
                    saveReceipt       = {saveReceipt}
                    changeDLC         = {changeDLC}
                    changeForceFile   = {changeForceFile}
                    rechazaSolicitud  = {rechazaSolicitud}
                    changeIsComprobanteOK={changeIsComprobanteOK}
                    showMotivo={showMotivo}
                    statusMotivo={statusMotivo}
                    changeTextMotiv={changeTextMotiv}
                    motivText={motivText}
                    lineaCapturaF={lineaCapturaF}
                    loadFileComp={loadFileComp}
                    fechaPago={fechaPagoF} 
        />
    </div>   
    </Fragment>
);
};
export default PagoImpuestos;