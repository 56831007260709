import Buttons from '../Buttons/Buttons';
import React, {useEffect, useState} from 'react';
import Image from 'react-bootstrap/Image'
import Select from '../Select/SelectSC'
import iconOk from '../../Components/icons/icon-ok-big.png'
import iconFail from  '../../Components/icons/icon-fail.png'
import iconWarning from '../../Components/icons/icon-warning-gray.png'
import Header from '../Header';

const hiddenColumns = (type) => {
    // console.log(type)
    switch(type) {
        case 'REL_SOLICITUDES_MOV':
            return ['chckAllS','acciones','evidencia']
        default:
            return  [] 
    } 
}
const inicializa = (type) => {
    switch(type) {
        case 1:
            return{
                title: 'Movomientos entre compañias',
                breadcrum: [{ nombre: 'Tesorería' }, { nombre: 'Movomientos entre compañias' }]
            }           
            case 2:
                return{
                    title: 'Pago de impuestos',
                    breadcrum: [{ nombre: 'Seguridad social' }, { nombre: 'Pago de impuestos' }]
                }
            case 3: 
            return{
                title: 'Pago de impuestos',
                breadcrum: [{ nombre: 'Tesorería' }, { nombre: 'Pago de impuestos' }]
            }
                
                
            default: 

    }
   
}



const optinsA =[
    {value:'1' ,label:'Impuestos Estatales y Federales'},
    {value:'2' ,label:'Otros'}
]

const calasTabs = {
    tabUser : 'active',
    tabVista : '',
    tabContUser : 'tab-pane active',
    tabContVista : 'tab-pane',
    tabUexpanded : 'true',
    tabVexpanded : 'false'
}
const swichTabs = {
    tabUser : '',
    tabVista : 'active',
    tabContUser : '',
    tabContVista : '',
    tabUexpanded : '',
    tabVexpanded : ''
}
const errorModal = [{
    errorRight : 'text-right errModalX',
    errorLeft : 'text-left errModalX',
    cRight : 'text-right',
    cLeft : 'text-left',
}]

const columns = (type,empresasOrigenDestino,cuentasOrigen,bancosOrigen,changeComboSolicitudes,estatusSolicitudes,handleCbxSolicitud,showDetailSolFond,showDispSaldo) =>{
   
    return [
                { 
                id: "chckAllS",
                Header: "",
                // Header: () => {
                //     return <div className="border-checkbox-section">
                //         <div className="border-checkbox-group border-checkbox-group-success">
                //         {/* <div className="border-checkbox-group border-checkbox-group-success" style={{padding: '0 0 0 42%'}}> */}
                //         <input
                //                 onChange={e => {}}
                //                 checked={false}
                //                 className="border-checkbox"
                //                 type="checkbox"
                //                 id={"chkAllSTblMonitor"}
                //             />
                //             <label className="border-checkbox-label"
                //                 htmlFor={"chkAllSTblMonitor"}></label>
                //         </div>
                //     </div>
    
                // },
                Cell: k =>(
                    <div className="border-checkbox-section">
                        <div className="border-checkbox-group border-checkbox-group-success">
                            <input
                                onChange={(e) => handleCbxSolicitud(k.row.original, e.target.checked, k)}
                                checked={k.row.original.check}
                                className="border-checkbox"
                                type="checkbox"
                                id={"chkSolS" + k.row.original.idSolicitud}
                            />
                            <label className="border-checkbox-label"
                                htmlFor={"chkSolS" + k.row.original.idSolicitud}></label>
                        </div>
                    </div>)
        },
        {
            id:'idM',
            Header: K => <div>ID</div>,
            accessor: 'idSolicitud',
        },
        {
            id:'tipoMovimiento',
            Header: "TipoMovimiento",
            accessor: 'tipoMovimiento',
        },
        {
            id:'concepto',
            Header: "Concepto",
            accessor: 'concepto',
        },
        {
            id:'EmpresaBancoCuentaOrigen',
            Header: () => (
            <div className="text-center">Empresa&nbsp;Banco&nbsp;Cuenta&nbsp;Origen</div>
                ),
            // Header: "Empresa",
            Cell: k =>{
            return <><div>
            <Select 
                class={'combSolicitudes'}
                name={'EmpresaBancoCuentaOrigen'}
                actionChange={(e) => changeComboSolicitudes(e,k.row.original)}
                options={empresasOrigenDestino}
                value={k.row.original.optionEmpresaBancoCuenta.value}

                />
                </div>
            </>
            }
        },
        {
            id:'EmpresaBancoCuentaDestino',
            Header: () => (<div className="text-center">&nbsp;&nbsp;&nbsp;Empresa&nbsp;Banco&nbsp;Cuenta&nbsp;Destino&nbsp;&nbsp;&nbsp;</div>),
            // Header: "EmpresaDestino",
            Cell: k =>(<div className="text-center">{k.row.original.empresaDestino}-{k.row.original.bancoDestino}-{k.row.original.cuentaDestino}</div>),
            accessor: 'empresaDestino',
        },
        {
            id:'importe',
            Header: "Importe",
            accessor: 'montoSolicitado',
        },
        {
            id:'estatus',
            Header: () => (<div className="text-center">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Estatus&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>),

            // Header: "Estatus",
            Cell: k =>{
                return <>
                <Select 
                    class={'combSolicitudes'}
                    name={'estatusSolicitud'}
                    actionChange={(e) => changeComboSolicitudes(e,k.row.original)}
                    options={estatusSolicitudes}
                    value={k.row.original.optionEstatus.value}
    
                    />
                </>}
        },
        {
            id:'acciónes',
            // Header: "Acciones",
            Header: () => (<div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Acciones&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> ),
            Cell: k =>(<>
                    <Buttons
                            btnSave={true} btnSaveAction={(e) => showDetailSolFond(k.row.original)} btnSaveText={"detalle de solicitud de fondeo "}  btnSaveShowIcon={true} btnSaveIconClass={'fa fa-list'}
                            // btnDelete={!dataSaved.isSaved} btnDeleteAction={clickDelete} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                            btnCancel={true} btnCancelAction={() => showDispSaldo(k.row.original)} btnCancelText={"disponibilidad de saldo"} btnCancelShowIcon={true} btnCancelIconClass={'fa fa-list'} 
                            />

            </>
            ),

            // accessor: 'estatus',
        },
    ]
}
const columnsDisponibilidad = (type,handleChangeChkAll,chckAll,handleChangeCbx) =>{
    const getDate = (_date) =>{
        // Cell: k =>(<span>{k.row ? getAllByPlaceholderText: 'not'}</span>),
        // console.log(_date)
        let auxDate = ''
        let dateR = ''
            if(_date)
            {
                auxDate = _date.split('T')
                dateR = auxDate[0].split('-')[2] +'/' + auxDate[0].split('-')[1] +'/'+ auxDate[0].split('-')[0] +" " +auxDate[1]
            }
          
            return dateR

    }
    const getSaldo = (saldo) =>{
        // console.log('k.row.original',saldo)
        let s = saldo.saldo.toString().substring(0,1)
        // console.log(s)
        if(s === "-") return true
        else return false


    }
    
    return [
        {
            id:'idMov',
            Header: "",
            accessor: 'idMovimiento',
        },
                { 
                id: "chckAll",
                // Header: "CheckAll",
                Header: () => {
                    return <div className="border-checkbox-section">
                        <div className="border-checkbox-group border-checkbox-group-success">
                        {/* <div className="border-checkbox-group border-checkbox-group-success" style={{padding: '0 0 0 42%'}}> */}
                        <input
                                onChange={e => handleChangeChkAll(e.target.checked)}
                                checked={chckAll}
                                className="border-checkbox"
                                type="checkbox"
                                id={"chkAllTblMonitor"}
                            />
                            <label className="border-checkbox-label"
                                htmlFor={"chkAllTblMonitor"}></label>
                        </div>
                    </div>
    
                },
                Cell: k =>(
                    <div className="border-checkbox-section">
                        <div className="border-checkbox-group border-checkbox-group-success">
                            <input
                                onChange={(e) => handleChangeCbx(k.row.original, e.target.checked, k)}
                                checked={k.row.original.check}
                                className="border-checkbox"
                                type="checkbox"
                                id={"chkSolM" + k.row.original.idRowSaldo}
                            />
                            <label className="border-checkbox-label"
                                htmlFor={"chkSolM" + k.row.original.idRowSaldo}></label>
                        </div>
                    </div>)
        },
        {
            id:'idM',
            Header: K => <div>ID</div>,
            accessor: 'idSaldo',
        },
        {
            id:'Empresa',
            Header: "Empresa",
            accessor: 'empresa',
        },
        {
            id:'banco',
            Header: "Banco",
            accessor: 'banco',
        },
        {
            id:'cuenta',
            Header: "Cuenta",
            accessor: 'cuenta',
        },
        {
            id:'saldo',
            Header: "Saldo",
            Cell: k =>(<div>{getSaldo(k.row.original) ? <span style={{color: 'red'}}>{k.row.original.saldo}</span>  : <span>{k.row.original.saldo}</span>}</div>),
            // Cell: k =>(k ?
            // <div>{k.row.original.saldo.substring(0, 1) === '-' ? <span style={{ color:'red'}}>{k.row.original.saldo}</span> : <span>{k.row.original.saldo}</span> }</div>:
            // <></>
            // ),
            accessor: 'saldo',
        },
        {
            id:'fechaMovimiento',
            Header: "Ult.Actualización",
            // Header: (k) =>(<label>{k.row ? k.row.original.fechaMovimiento : 'not'}</label>),
            Cell: k =>(<span>{k.row ? getDate(k.row.original.fechaMovimiento): 'not'}</span>),
            accessor: 'fechaMovimiento',
        },
        {
            id:'comentarios',
            Header: "Comentarios",
            accessor: 'comentarios',
        },
    ]
}
const columnsModal = (type,clickDeleteRow,empresasOrigenDestino,cuentasOrigen,bancosOrigen,changeComboSolicitudes,estatusSolicitudes,handleChangeChkAll,chckAllM,handleChangeCbx) =>{
    return [
        {
            id:'ID',
            Header: K => <div>{type === 0 ? '' : 'ID'}</div>,
            Cell: k =>(
            <div className="text-center">
                {
                    type === 0 ?
                    <Buttons
                    btnDelete={true} btnDeleteAction={(e) => clickDeleteRow(k,0)} btnDeleteText={"Eliminar"} btnDeleteShowIcon={true}
                    />: <div>{k.row.original.idSolicitud}</div>
                }
            </div>),
            accessor: 'idSolicitud',
        },
        {
            id:'tipoMovimiento',
            Header: "TipoMovimiento",
            accessor: 'tipoMovimiento',
        },
        {
            id:'concepto',
            Header: "Concepto",
            accessor: 'concepto',
        },
        {
            id:'EmpresaBancoCuentaOrigen',
            Header: () => (<div className="text-center">Empresa&nbsp;Banco&nbsp;Cuenta&nbsp;Origen</div>),
            // Header: "Empresa",
            Cell: k =>(<div className="text-center">{k.row.original.empresaOrigen}-{k.row.original.bancoOrigen}-{k.row.original.cuentaOrigen}</div>),
            accessor: 'empresaOrigen',
        },
        {
            id:'EmpresaBancoCuentaDestino',
            Header: () => (<div className="text-center">&nbsp;&nbsp;&nbsp;Empresa&nbsp;Banco&nbsp;Cuenta&nbsp;Destino&nbsp;&nbsp;&nbsp;</div>),
            // Header: "EmpresaDestino",
            Cell: k =>(<div className="text-center">{k.row.original.empresaDestino}-{k.row.original.bancoDestino}-{k.row.original.cuentaDestino}</div>),
            accessor: 'empresaDestino',
        },
        {
            id:'importe',
            Header: "Importe",
            accessor: 'montoSolicitado',
        },
        
       
    ]
}

const columnsEvidenncias2 = (numTab,showDetailMov,CurrencyFormat) =>{

     return[
        {
            id:'idM',
            Header: K => <div>ID</div>,
            Cell: k =>(<div className="text-center">{k.row.original.idMovimiento}</div>),
            accessor: 'idMovimiento',
        },
        {
            id:'tipoMov',
            Header: "Tipo movimiento",
            accessor: 'tipoMovimiento',
        },
        {
            id:'concepto',
            Header: "concepto",
            accessor: 'concepto',
        },
        {
            id:'fechasolicitud',
            Header: "Fecha Solicitud",
            accessor: 'fechasolicitud',
        },
      
        {
            id:'EmpresaBancoCuentaOrigen',
            Header: () => (<div className="text-center">Empresa&nbsp;Banco&nbsp;Cuenta&nbsp;Origen</div>),
            // Header: "Empresa",
            Cell: k =>(<div className="text-center">{k.row.original.empresaBancoOrigen}-{k.row.original.cuentaOrigen}</div>),
            accessor: 'empresaBancoOrigen',
            
        },
        {
            id:'EmpresaBancoCuentaDestino',
            Header: () => (<div className="text-center">&nbsp;&nbsp;&nbsp;Empresa&nbsp;Banco&nbsp;Cuenta&nbsp;Destino&nbsp;&nbsp;&nbsp;</div>),
            // Header: "EmpresaDestino",
            Cell: k =>(<div className="text-center">{k.row.original.empresaBancoDestino}-{k.row.original.cuentaDestino}</div>),
            accessor: 'empresaBancoDestino',
        },
        
        {
            // CurrencyFormat(785320.55)
            id:'Importe solicitado',
            Header: "Importe solicitado",
            Cell: k =>(<div className="text-center">{k.row.original.importesolicitado ? CurrencyFormat(parseFloat(k.row.original.importesolicitado)): '$0'}</div>),
            accessor: 'importesolicitado',
        },
        {
            id:'Importe aplicado',
            Header: "Importe aplicado",
            Cell: k =>(<div className="text-center">{k.row.original.importereaplicado !== "" ? CurrencyFormat(parseFloat(k.row.original.importereaplicado)) :  CurrencyFormat(0)}</div>),
 
            accessor: 'importereaplicado',
        },
        {
            id:'referencia',
            Header: "Referencia capturada",
            accessor: 'referencia',
        },
        {
            id:'observaciones',
            Header: "Observaciones",
            Cell: k =>(<div className="text-center">{k.row.original.respMessage}</div>),
 
            accessor: 'respMessage',
        },
        {
            id:'estatus',
            Header: "Estatus",
            Cell: k =>{
                switch (k.row.original.typePetMov) {
                    case 1:
                         return  <div className="text-center"><Image src={iconOk} roundedCircle className="icon-medium" /></div>
                    case 2:
                         return <div className="text-center"><Image src={iconWarning} roundedCircle className="icon-medium" /></div>
                    case 3:
                        return <div className="text-center"><Image src={iconFail} roundedCircle className="icon-medium" /></div>
                    default:
                        return <div className="text-center"></div>

                        break;
                }
            },
 
            accessor: 'respMessage',
        },
      
        {
            id:'actions',
            Header: 'Acciones',
            Cell: k =>(<>
                <Buttons
                        btnSave={true} btnSaveAction={e => showDetailMov(k.row.original,2)} btnSaveText={"detalle"}  btnSaveShowIcon={true} btnSaveIconClass={'fa fa-list'}
                        // btnCancel={numTab !== 9 } btnCancelAction={e =>{ k.row.original.idEstatus !== 4 ? loadEvidence(k.row.original,2): console.log('NotFunction')}} btnCancelText={"carga evidencia"} btnCancelShowIcon={true} btnCancelIconClass={k.row.original.idEstatus !== 4 ?'fa fa-upload':''} 
                        // btnDelete={true} btnDeleteAction={e => showModalDeleteMov(k.row.original,3)} btnDeleteText={"Eliminar"} btnDeleteShowIcon={true}

                        />

        </>
        ),




        }
     ]
}
const columnsEvidenncias = (numTab,showDetailMov,CurrencyFormat) =>{

    return[
       {
           id:'idM',
           Header: K => <div>{numTab === 4 || numTab === 9 ? 'IDSolicitud' : 'IDMovimiento'}</div>,
           Cell: k =>(<div className="text-center">{numTab === 4 || numTab === 9 ?  k.row.original.idSolicitud : k.row.original.idMovimiento}</div>),
           accessor: 'idMovimiento',
       },
       {
           id:'fechasolicitud',
           Header: "Fecha Solicitud",
           accessor: 'fechasolicitud',
       },
       {
           id:'referencia',
           Header: "Referencia capturada",
           accessor: 'referencia',
       },
       {
           id:'EmpresaBancoCuentaOrigen',
           Header: () => (<div className="text-center">Empresa&nbsp;Banco&nbsp;Cuenta&nbsp;Origen</div>),
           // Header: "Empresa",
           Cell: k =>(<div className="text-center">{k.row.original.empresaBancoOrigen}-{k.row.original.cuentaOrigen}</div>),
           accessor: 'empresaBancoOrigen',
           
       },
       {
           id:'EmpresaBancoCuentaDestino',
           Header: () => (<div className="text-center">&nbsp;&nbsp;&nbsp;Empresa&nbsp;Banco&nbsp;Cuenta&nbsp;Destino&nbsp;&nbsp;&nbsp;</div>),
           // Header: "EmpresaDestino",
           Cell: k =>(<div className="text-center">{k.row.original.empresaBancoDestino}-{k.row.original.cuentaDestino}</div>),
           accessor: 'empresaBancoDestino',
       },
       {
           // CurrencyFormat(785320.55)
           id:'Importe solicitado',
           Header: "Importe",
           Cell: k =>(<div className="text-center">{k.row.original.importesolicitado ? CurrencyFormat(parseFloat(k.row.original.importesolicitado)): '$0'}</div>),
           accessor: 'importesolicitado',
       },
       {
           id:'Importe aplicado',
           Header: "Importe",
           Cell: k =>(<div className="text-center">{k.row.original.importereaplicado !== "" ? CurrencyFormat(parseFloat(k.row.original.importereaplicado)) :  CurrencyFormat(0)}</div>),

           accessor: 'importereaplicado',
       },
       {
           id:'observaciones',
           Header: "Observaciones",
           Cell: k =>(<div className="text-center">{k.row.original.respMessage}</div>),

           accessor: 'respMessage',
       },
       {
           id:'estatus',
           Header: "Estatus",
           Cell: k =>{
               switch (k.row.original.typePetMov) {
                   case 1:
                        return  <div className="text-center"><Image src={iconOk} roundedCircle className="icon-medium" /></div>
                   case 2:
                        return <div className="text-center"><Image src={iconWarning} roundedCircle className="icon-medium" /></div>
                   case 3:
                       return <div className="text-center"><Image src={iconFail} roundedCircle className="icon-medium" /></div>
                   default:
                       break;
               }
           },

           accessor: 'respMessage',
       },
       {
           id:'actions',
           Header: 'Acciones',
           Cell: k =>(<>
               <Buttons
                       btnSave={true} btnSaveAction={e => showDetailMov(k.row.original,2)} btnSaveText={"detalle"}  btnSaveShowIcon={true} btnSaveIconClass={'fa fa-list'}
                       // btnCancel={numTab !== 9 } btnCancelAction={e =>{ k.row.original.idEstatus !== 4 ? loadEvidence(k.row.original,2): console.log('NotFunction')}} btnCancelText={"carga evidencia"} btnCancelShowIcon={true} btnCancelIconClass={k.row.original.idEstatus !== 4 ?'fa fa-upload':''} 
                       // btnDelete={true} btnDeleteAction={e => showModalDeleteMov(k.row.original,3)} btnDeleteText={"Eliminar"} btnDeleteShowIcon={true}

                       />

       </>
       ),




       }
    ]
}
const columnsProgramMov = (type,handleCbxMov,showDetailMov,showModalDeleteMov,loadEvidence,numTab,showPDF,typeAction) =>{
   
    return [
                { 
                id: "chckAllS",
                Header: "",
                Cell: k =>(
                    <div className="border-checkbox-section">
                        <div className="border-checkbox-group border-checkbox-group-success">
                            <input
                                onChange={(e) => handleCbxMov(k.row.original, e.target.checked, k)}
                                checked={k.row.original.check}
                                className="border-checkbox"
                                type="checkbox"
                                id={"chkSolS" + k.row.original.idMovimiento}
                            />
                            <label className="border-checkbox-label"
                                htmlFor={"chkSolS" + k.row.original.idMovimiento}></label>
                        </div>
                    </div>)
        },
        {
            id:'idM',
            Header: K => <div>{numTab === 4 || numTab === 9 ? 'IDSolicitud' : 'IDMovimiento'}</div>,
            Cell: k =>(<div className="text-center">{numTab === 4 || numTab === 9 ?  k.row.original.idSolicitud : k.row.original.idMovimiento}</div>),
            accessor: 'idMovimiento',
        },
        {
            id:'tipoMovimiento',
            Header: "TipoMovimiento",
            accessor: 'tipoMovimiento',
        },
        {
            id:'concepto',
            Header: "Concepto",
            accessor: 'concepto',
        },
        {
            id:'EmpresaBancoCuentaOrigen',
            Header: () => (<div className="text-center">Empresa&nbsp;Banco&nbsp;Cuenta&nbsp;Origen</div>),
            // Header: "Empresa",
            Cell: k =>(<div className="text-center">{k.row.original.empresaOrigen}-{k.row.original.bancoOrigen}-{k.row.original.cuantaOrigen}</div>),

            accessor: 'empresaOrigen',
            
        },
        {
            id:'EmpresaBancoCuentaDestino',
            Header: () => (<div className="text-center">&nbsp;&nbsp;&nbsp;Empresa&nbsp;Banco&nbsp;Cuenta&nbsp;Destino&nbsp;&nbsp;&nbsp;</div>),
            // Header: "EmpresaDestino",
            Cell: k =>(<div className="text-center">{k.row.original.empresaDestino}-{k.row.original.bancoDestino}-{k.row.original.cuentaDestino}</div>),
            accessor: 'empresaDestino',
        },
        {
            id:'importeSolicitado',
            Header: "Importesolicitado",
            accessor: 'montoSolicitado',
        },
        {
            id:'importeAplicado',
            Header: "Importeaplicado",
            accessor: 'montoAplicado',
        },
        {
            id:'estatus',
            Header: () => (<div className="text-center">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Estatus&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>),

            // Header: "Estatus",
            accessor: 'estatus',
            
        },
        {
            id:'evidencia',
            Header: () => (<div className="text-center">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Evidencia&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>),
            Cell: k =>(k.row.original.idEstatus === 4 &&
            <label title='Linea de captura' style={{cursor:'pointer',color:'red'}} onClick={() => showPDF(k.row.original.pathComprobanteMov)}><i className="fa fa-file-pdf-o fa-2x"></i></label>
            ),
            // Header: "Estatus",
            accessor: 'evidencia',
            
        },
        {
            id:'acciones',
            // Header: "Acciones",
            Header: () => (<div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Acciones&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> ),
            Cell: k =>(<>
                    <Buttons
                            // btnSave={true} btnSaveAction={(e) => showDetailSolFond(k.row.original)} btnSaveText={"detalle de solicitud de fondeo "}  btnSaveShowIcon={true} btnSaveIconClass={'fa fa-list'}
                            btnSave={numTab === 3} btnSaveAction={e => showDetailMov(k.row.original,1)} btnSaveText={"detalle de movimiento"}  btnSaveShowIcon={true} btnSaveIconClass={'fa fa-list'}
                            btnCancel={numTab !== 9 &&  k.row.original.idEstatus !== 4} btnCancelAction={e =>{loadEvidence(k.row.original,2)}} btnCancelText={"carga evidencia con estatus:"+k.row.original.idEstatus} btnCancelShowIcon={true} btnCancelIconClass={'fa fa-upload'} 
                            // btnDelete={true} btnDeleteAction={e => showModalDeleteMov(k.row.original,3,typeAction)} btnDeleteText={"Eliminar"} btnDeleteShowIcon={true} btnDeleteIconClass='fa fa-ban'
                            btnUpload={true} btnUploadAction={e => showModalDeleteMov(k.row.original,3,typeAction)} btnUploadText={"Cancelar movimiento"} btnUploadShowIcon={true} btnUploadIconClass='fa fa-close'
                            />
                    

            </>
            ),

            // accessor: 'estatus',
        },
    ]
}
const columnsConsiliacion = (handleChangeCbxM) =>{}
const dataDisponibilidad = [
    {
        idMovimiento:1,
        iD:'10001',
        empresa:'PLOWSERVE',
        banco:'Santander',
        cuenta:'061542555',
        nuevoSaldo:'1235,487',
        comentarios:'Coment...'     
    },
    {
        idMovimiento:2,
        iD:'10002',
        empresa:'RALP_3',
        banco:'BBVA',
        cuenta:'041540001',
        nuevoSaldo:'1035,400',
        comentarios:'Coment...2'     
    },
    {
        idMovimiento:3,
        iD:'10003',
        empresa:'LUG',
        banco:'BBVA',
        cuenta:'431540003',
        nuevoSaldo:'1105,402',
        comentarios:'Coment...3'     
    }

]
const toggleModal = (isShow) => {
    console.log('rr')

    isShow ? window.$('#modalDisponibilidadFront').modal('show') : 
    window.$('#modalDisponibilidadFront').modal('hide')
}
export {inicializa,columns,columnsDisponibilidad,hiddenColumns,dataDisponibilidad,toggleModal,columnsModal,columnsProgramMov,columnsEvidenncias,columnsEvidenncias2}
