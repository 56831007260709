import React from 'react';
import TableOK from './TableOK';
import Buttons from '../../Buttons/Buttons';
import CompDropZone from '../../CompDropZone';


const index = ({idTipoFormato,nombre,isOKSend,downloadHelp,xlsDownloadRespons,total,_loadFile,cancelLoad,selected_File,select_File,onSelectFile}) => {
    // console.log(CompDropzone)

    return (
        <div>
             {
                                isOKSend ? 
                                <>
                                <div className="row">
                                    <div className="col-md-12">
                                    <TableOK 
                                    idTipoFormato={idTipoFormato}
                                    xlsDownloadRespons={xlsDownloadRespons}
                                    total={total}
                                    _loadFile={_loadFile}
                                    cancelLoad={cancelLoad}
                                    />
                                    </div>

                                </div>

                                </> :
                                <>
                                <div className="row">
                                    <div className="col-ms-12">
                                        <div className="text-center">
                                        <label className="Span__titleDrop"> Nuevo lote de impuestos {nombre}</label>
                                        <CompDropZone 
                                        selected_File={selected_File}
                                        select_File={select_File}
                                         />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-1 text-left">
                                    <Buttons btnAyuda={true} btnAyudaAction={downloadHelp} btnAyudaText={'Ayuda'} btnAyudaShowIcon={true}/>
                                    </div>   
                                    <div className="col-md-1 text-left">
                                        <div className="">
                                            <Buttons 
                                            btnAdd  = {true}
                                            btnAddAction  = {() => onSelectFile()}
                                            btnAddText="Cargar"
                                            btnAddShowIcon={true}
                                            btnAddIconClass="fa fa-upload"
                                            /> 
                                        </div>                                    
                                    </div>   
                                    <div className="col-md-1 text-left">                                       
                                    </div>   
                                    <div className="col-md-5"></div>   
                                </div>
                                </>
                            } 
        </div>
    );
};

export default index;