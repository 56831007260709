import React  ,{useRef} from 'react';
import { Modal, Button, Accordion, Container, Row, Col} from 'react-bootstrap'
import Buttons from '../../../Components/Buttons/Buttons';
import Image from 'react-bootstrap/Image'
import iconOk from './icons/icon-ok-big.png'
import iconFail from './icons/icon-fail.png'

const ModalAutorizate = ({
    show,
    onCerrar,
    autorizateLC,
    onSaveLineaCaptura,
}) => {
    return (
        <>
        <Modal size="xl" show={show} animation={false} backdrop="static"  aria-labelledby="modalConfirmImp">
            <Modal.Header className="header">
                <Container>
                <Row>
                        <Col md="11" className="text-left">
                         <label>Autorizar lineas de captura</label>  
                        </Col>
                       
                </Row>
                </Container>
            </Modal.Header> 
            <Modal.Body style={{border:'1px solid black'}}>
                        <Row>
                        <Col md="12" className="text-center">
                                    <div>
                                    <span>Se ha identificado lote completo con lineas de captura </span>
                                    <label> {'¿Desea autorizar el lote a tesorería para proceder con el pago correspondiente ?'} </label> 
                                    </div>                            
                        </Col>
                       
                        </Row>
         
         

            

                

                    <Row>
                    <Col md="4"></Col>
                            <Col  md="2" >
                                    
                                    <Buttons 
                                    btnAdd={true} btnAddAction={() => autorizateLC()} btnAddText={ "Aceptar"} btnAddShowIcon={true} btnAddIconClass="fa fa-save"
                                    /> 
                            </Col>
                            <Col  md="2" >
                            <Buttons 
                                btnCancel={true} btnCancelAction={() => onCerrar()} btnCancelText="Cancelar" btnCancelShowIcon={true}
                                />

                            </Col>
                    <Col md="4"></Col>


                        
                    </Row>
                    
               
            </Modal.Body>

           </Modal>
        </>

    );
};

export default ModalAutorizate;