
import { resolveOnChange } from 'antd/lib/input/Input';
import { ExisteSession,APISIAN,toggleLoader } from '../../../ApiRequests/general'
import {fnNotification} from '../../../ApiRequests/Notification';
import axios from 'axios';
import { reduceHooks } from 'react-table';

const nuevaPeticionPost=()=> {
    var data = {
        method: 'post', 
        url: APISIAN,
        data: {}
    }
    return data;
}
const CallMethods=async(config)=> {
    let result=null;
    try {
        config.data = parametersRequest(config.data);
        result = await axios(config);
        return result;
    } catch (error) {
        fnNotification(['Error al conectar con api', 'error', true])    
        toggleLoader(false);
        return result;
    }
}
const CallMethodsWithOutParamaRequest=async(config)=> {
    let result=null;
    try {
      
        result = await axios(config);
        return result;
    } catch (error) {
        fnNotification(['Error al conectar con api', 'error', true])    
        toggleLoader(false);
        return result;
    }
}
const parametersRequest = request =>{
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()
    return request;
}
const parametersWithOutRequest = request =>{
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    request.token = ExisteSession.getInfoUsuario().token
    //request.connection = ExisteSession.conexion()
    return request;
}
const isNullOrWhiteSpace=(val)=>{
    var i=0;
  
    if(val==undefined)
    i++;
    if(val==null)
    i++;

    if(val==0)
        i++;

    if(val!=null && val!=undefined){
        if(val.toString().trim()=='')
        i++;

    }

    if(i>0)
     return true;
    else
    return false;

}
const getEmpresas=async()=>{
    let result=null;
    let data=nuevaPeticionPost();
    data.url+='Catalogos/catEmpresas/Get';
    data.data={activo:true};
    let response=await CallMethods(data);
    if(response){
       const {Data,Code,Message}=response.data;
       if(Code===200){
            if(Data.length>0){
                result=[];
                Data.sort((x,y)=> x.nombreLargo.trim().localeCompare(y.nombreLargo.trim())).forEach(element =>{
                    result.push({value:element.ID_empresa,label:element.nombreLargo})
                })
            }
            else
            fnNotification(['No se encontraron empresas', 'error', true])
       }else{
        fnNotification([Message, 'error', true])
       }
    }
    return result;
}
const getCriterios=async()=>{
    let result=null;
    let data=nuevaPeticionPost();
    data.url+='Catalogos/catCriterios/Get';
    data.data={criterio:'tipoDoctoFactura'};
    let response=await CallMethods(data);
    if(response){
       const {Data,Code,Message}=response.data;
       if(Code===200){
            if(Data.length>0){
                result=[];
                Data.forEach(element =>{
                    result.push({'value':element.valor==0?99:element.valor,'label':element.valorAdicional})
                })
            }
            else
            fnNotification(['No se encontraron criterios', 'error', true])
       }else{
        fnNotification([Message, 'error', true])
       }
    }
    return result;
}
const getinfoFacturas=async(idEmpresa,tipo,anio,idFiscal)=>{
    let result=null;
    let data=nuevaPeticionPost();
    data.url+='Facturacion/GetInformacionFactura';
    data.data={
        tipo,
        anio,
        idEmpresa,
        idFiscal
    };
    data.data=parametersWithOutRequest(data.data);
    let response=await CallMethodsWithOutParamaRequest(data);
    if(response){
       const {Data,Code,Message}=response.data;
       if(Code===200){
            if(Data.length>0){
                result=[];
                Data.forEach(element =>{
                    result.push({value:element.facId,label:element.idFiscal})
                })
            }
            else
            fnNotification(['No se encontro información de facturas', 'error', true])
       }else{
        fnNotification([Message, 'error', true])
       }
    }
    return result;
}
const getAnios = async () => {
    return new Promise((resolve,reject)=>{
        try{
            let result =[]; 
            let min = 2015;
            let max = new Date().getFullYear();
            var calculate = min;
        
            while (calculate <=max) {
                result.push({ 'value': calculate, 'label': calculate });
                calculate++;
            }
            resolve(result);
        }catch(error){
            reject(error);
        }
    });
};
const getZip=async(array)=>{
    let result=null;
    let data=nuevaPeticionPost();
    data.url+='Facturacion/GetPdfZipFactOComp';
    for(var i=0;i<array.length;i++){
       array[i]= parametersWithOutRequest(array[i]);
    }
    data.data=array;
    let response=await CallMethodsWithOutParamaRequest(data);
    if(response){
       const {Data,Code,Message}=response.data;
       if(Code===200){
            result=Data;
       }else if(Code===-1000){
        fnNotification([Message, 'info', true])
       }
       else{
        fnNotification([Message, 'error', true])
       }
    }
    return result;
}
export{
    getEmpresas,
    getCriterios,
    getinfoFacturas,
    getAnios,
    getZip,
    isNullOrWhiteSpace
}