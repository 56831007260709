import React from 'react';
import Select from 'react-select';
import Buttons from '../Buttons/Buttons';

const Filter = ({
    typeFilter,
    changeSelect,
    empresasBanco,
    allCuentas,
    optionEmpresa,
    optionCuenta,
    btnSearch,
    changeDate,
    tipoMovimiemto,
    empresas,
    changeSelectSolicitudes,
    changeDateSolicitud,
    optionTipoMovimiemto   ,
    optionEmpresaOrigen    ,
    optionEmpresaDestino   ,
    empresasOrigenDestino  
}) => {

    return (
        <>
            {
            
                typeFilter === 1 ?
                <div className="row">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Empresa</label>
                                        <Select
                                            className=""
                                            isClearable
                                            isSearchable
                                            name="empresa"
                                            onChange={(event,name) => changeSelect(event,name)}
                                            options={empresasBanco}
                                            value={optionEmpresa}
                                        />
                                </div>
                                <div className="col-md-6">
                                <label>Cuenta</label>
                                    <Select
                                        className=""
                                        isClearable
                                        isSearchable
                                        name="cuenta"
                                        onChange={(event,name) => changeSelect(event,name)}
                                        options={allCuentas}
                                        value={optionCuenta}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row"> 
                                <div className="col-md-10">
                                    <div>
                                    <label>Fecha&nbsp;Movimiento</label>
                                    <input className="form-control" type='date' onChange={(event,name) => changeDate(event,name)}  />
                                    </div>

                                </div>
                                <div className="col-md-2">
                                    <label>&nbsp;</label>
                                    <Buttons
                                            btnAdd  = {true}
                                            btnAddAction  = {() => btnSearch(typeFilter)}
                                            btnAddText="Buscar"
                                            btnAddShowIcon={true}
                                            btnAddIconClass="fa fa-search"
                                        />
                                </div>
                            </div>
                        </div>
                </div> : typeFilter ===  5 ? 
                <div className="row">
                    solicitudes
                </div> :
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-4">
                            <label>Tipo de -moviemiento</label>
                                <Select
                                    className=""
                                    isClearable
                                    isSearchable
                                    name="tipoMov"
                                    onChange={(event,name) => changeSelectSolicitudes(event,name)}
                                    options={tipoMovimiemto}
                                    value={optionTipoMovimiemto}
                                />
                            </div>
                            <div className="col-md-4">
                            <label>Empresa origen</label>
                                <Select
                                    className=""
                                    isClearable
                                    isSearchable
                                    name="empresaOrigen"
                                    onChange={(event,name) => changeSelectSolicitudes(event,name)}
                                    options={empresasBanco}
                                    value={optionEmpresaOrigen}
                                />
                            </div>
                            <div className="col-md-4">
                            <label>Empresa destino</label>
                                <Select
                                    className=""
                                    isClearable
                                    isSearchable
                                    name="empresaDestino"
                                    onChange={(event,name) => changeSelectSolicitudes(event,name)}
                                    options={empresasBanco}
                                    value={optionEmpresaDestino}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-9">
                                    <label>Fecha&nbsp;Movimiento</label>
                                    <input className="form-control" type='date' onChange={(event,name) => changeDateSolicitud(event,name)}  />

                            </div>
                            <div className="col-md-2">
                                <label>&nbsp;</label>
                                <Buttons
                                        btnAdd  = {true}
                                        btnAddAction  = {() => btnSearch(typeFilter)}
                                        btnAddText="Buscar"
                                        btnAddShowIcon={true}
                                        btnAddIconClass="fa fa-search"
                                    />
                            </div>

                        </div>

                    </div>
                </div>

            }
        </>
    );
};

export default Filter;