import React,{useState, useEffect, Fragment, cloneElement} from 'react';
import {getEmpresas,getCriterios,getinfoFacturas,getAnios,isNullOrWhiteSpace,getZip
} from './services';
import {tableColumns,inicializa} from './resources'
import Header from '../../../Components/Header';
import Session from '../../../Application/session'
import Buttons from '../../../Components/Buttons/Buttons'
import {fnNotification} from '../../../ApiRequests/Notification';
import SelectC from '../../../Components/Select/SelectSC';
import Select from 'react-select';
import {ExisteSession, toggleLoader} from '../../../ApiRequests/general'
import Item from 'antd/lib/list/Item';
import { saveAs } from 'file-saver';
const FacturasComplementos=()=>{
    const [dsEmpresas,setdsEmpresas]   = useState([])
    const [dsTipoDocto,setdsTipoDocto]   = useState([])
    const [dsAnios,setdsAnios]   = useState([])
    const [dsInfoFaturas,setdsInfoFaturas]   = useState([])
    const[idEmpresa,setIdEmpresa]=useState(null);
    const[idTipoDocto,setIdTipoDocto]=useState(0);
    const[anio,setAnio]=useState(0);
    const [idFiscal,setidFiscal] = useState('');
    const[facturaSian,setFacturaSian]= useState(null);
    useEffect( () => {
        const fectchData= async()=>{
                toggleLoader(true);
                let arr=await getEmpresas();
                if(arr)
                    setdsEmpresas(arr);
                arr=null;
                arr=await getCriterios();
                if(arr)
                    setdsTipoDocto(arr);  
                arr=null;
                arr=await getAnios();
                if(arr.length>0)
                setdsAnios(arr);

                toggleLoader(false);
        }
        fectchData();

    },[])
    const handleChange =async (e) => {
        toggleLoader(true);
        // if(e.target.name === 'cmbEmpresa'){
        //     setIdEmpresa(e.target.value)
        // }
        if(e.target.name === 'cmbTipo') {
            setIdTipoDocto(e.target.value)
        }
        if(e.target.name === 'cmbAnio'){
            setAnio(e.target.value)
        }
        if(e.target.name === 'txtIdFiscal'){
            setidFiscal(e.target.value)
        }
        setdsInfoFaturas([]);
        setFacturaSian(null);
        toggleLoader(false);
    }
    
    const SelectChange = (e) => {
        toggleLoader(true);
        setFacturaSian(e)
        toggleLoader(false);
    }
    const SelectEmpresaChange = (e) => {
        toggleLoader(true);
        setIdEmpresa(e)
        setdsInfoFaturas([]);
        setFacturaSian(null);
        toggleLoader(false);
    }
    const OnClickExport =async  () => {
        if(!validaDatos())return;
        toggleLoader(true);
        let array=[];
        let tipo=idTipoDocto;
        if(tipo==99)
            tipo=0;
        facturaSian.forEach(element => {
            let item={facturaSian:element.value.toString(),tipoObjeto:tipo};
            array.push(item);
        });
       
        let docto=await getZip(array);
        if(docto){
            
            var byteString = atob(docto.FileContents);
            var mimeString = docto.ContentType;
            var namefile=docto.FileDownloadName;
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            var out = new Blob([ab], {type: mimeString});
            var FileSaver = require('file-saver');
            FileSaver.saveAs(out, namefile,{type:mimeString});
        }
        toggleLoader(false);
    }
    const OnBuscarFacturas=async()=>{
        toggleLoader(true);
        let emp=isNullOrWhiteSpace(idEmpresa)?0:idEmpresa.value;
        let an=anio;
        let tipo=idTipoDocto;
        let fs=idFiscal;
       
      
        if(validaDatosBusqueda()){
            if(tipo==99)
                tipo=0;
            let arr= await getinfoFacturas(emp,tipo,an,fs);
            if(arr)
                setdsInfoFaturas(arr);
            else
                setdsInfoFaturas([]);
        }else{
            setdsInfoFaturas([]);
        }
        toggleLoader(false);
    }
    const validaDatos=()=>{
        let i=0;
        if(isNullOrWhiteSpace(idTipoDocto)){
            fnNotification(['El Tipo es obligatorio', 'warning', true]);
            return false;
        }
        if(isNullOrWhiteSpace(facturaSian)){
            fnNotification(['La Factura es obligatoria', 'warning', true]);
            return false;
        }
        if(isNullOrWhiteSpace(idEmpresa)){
            fnNotification(['La Empresa es obligatoria', 'warning', true]);
            return false;
        }

       
            return true;
    }
    const validaDatosBusqueda=()=>{
        let i=0;
        if(isNullOrWhiteSpace(idTipoDocto)){
            fnNotification(['El Tipo es obligatorio', 'warning', true]);
            return false;
        }
        if(isNullOrWhiteSpace(idEmpresa)){
            fnNotification(['La Empresa es obligatoria', 'warning', true]);
            return false;
        }

       
            return true;
    }

    return (
        <Fragment>
        <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum}/>
        <div className="lx-container container">
        <div className="panel panel-primary animated fadeInUp">
            <div className="panel-heading">
                <div className="row">
                    <div className="col-md-6">
                        <span>Facturas-Notas de Crédito-Complementos</span>                   

                    </div>
                    <div className="col-md-6 btnF">
                        {/* <Buttons btnAdd={true} btnAddAction={showModal} btnAddText={'Agregar'} btnAddShowIcon={true}/> */}
                    </div>

                </div>
                </div>
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-2 col-lg-2">
                            <div className='row'>
                                <div className="col-md-12 col-lg-4">
                                <div className="form-group">
                                    <label>Empresa:</label>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-8">
                                    <div className="form-group">
                                    <Select
                                                isMulti={false}
                                                onChange={(e)=>SelectEmpresaChange(e)}
                                                ID="cmbEmpresa"
                                                name="cmbEmpresa"
                                                options={dsEmpresas}
                                                value={idEmpresa}
                                                isClearable={true}
                                                placeholder={'Selecciona'}
                                            />
                                        {/* <SelectC
                                            actionChange={(e)=>handleChange(e)}
                                            ID="cmbEmpresa"
                                            name="cmbEmpresa"
                                            options={dsEmpresas}
                                            value={idEmpresa}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-md-2 col-lg-2">
                            <div className='row'>
                                <div className="col-md-12 col-lg-3">
                                <div className="form-group">
                                    <label>Tipo:</label>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-9">
                                    <div className="form-group">
                                        
                                        <SelectC
                                            actionChange={(e)=>handleChange(e)}
                                            ID="cmbTipo"
                                            name="cmbTipo"
                                            options={dsTipoDocto}
                                            value={idTipoDocto}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-lg-2">
                            <div className='row'>
                                <div className="col-md-12 col-lg-2">
                                <div className="form-group">
                                    <label>Año:</label>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-10">
                                    <div className="form-group">
                                        <SelectC
                                             actionChange={(e)=>handleChange(e)}
                                            ID="cmbAnio"
                                            name="cmbAnio"
                                            options={dsAnios}
                                            value={anio}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-2">
                            <div className='row'>
                                <div className="col-md-12 col-lg-3">
                                <div className="form-group">
                                    <label >Id Fiscal</label>
                                    </div>
                                </div>
                                <div className="col-md-10 col-lg-7">
                                    <div className="form-group">
                                        <input 
                                                type="text"
                                                className="form-control" 
                                                id="txtIdFiscal"
                                                name="txtIdFiscal"
                                                onChange={(e)=>handleChange(e)}
                                                value={idFiscal}
                                                placeholder="Id Fiscal" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-lg-2">
                                    <div className="row">
                                    <Buttons 
                                            btnExport ={true}  btnExportIconClass={'fa fa-search'} btnExportAction={()=>OnBuscarFacturas()}   btnExportText="Buscar Factura" btnExportClass={'btn btn-info'} btnExportShowIcon={true} 
                                           
                                        />   
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-4">
                            <div className='row'>
                                    <div className='col-md-1 col-lg-1' >
                                    </div>
                                    <div className="col-md-11 col-lg-2">
                                        <div className="form-group">
                                            <label>Factura:</label>
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-lg-7">
                                        <div className="form-group">
                                            <Select
                                                isMulti={true}
                                                onChange={(e)=>SelectChange(e)}
                                                ID="cmbFacturas"
                                                name="cmbFacturas"
                                                options={dsInfoFaturas}
                                                value={facturaSian}
                                                placeholder={'Selecciona'}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-2">
                                        <div className="row">
                                        <Buttons 
                                                btnExport ={true}  btnExportIconClass={'fa fa-file-pdf-o'} btnExportAction={OnClickExport}   btnExportText="Descargar archivo Zip." btnExportClass={'btn btn-info'} btnExportShowIcon={true} 
                                            
                                            />   
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <br/>
                 
                </div>  
        </div>
      </div> 

      </Fragment>
      
    );
};
export default FacturasComplementos;