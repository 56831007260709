import React from 'react';
import GeneralTable from '../GeneralTable';
import {columns,hiddenColumns} from './resources'
import Filter from './Filter'
import Buttons from '../Buttons/Buttons';


const MonitorSolicitud = ({
    typeTab,
    dataMonitor,
    tipoMovimiemto,
    empresas,
    solicitudes,
    allSolicitudes,
    estatusSolicitudes,
    changeSelectSolicitudes,
    changeDateSolicitud,
    btnSearch,
    optionTipoMovimiemto   ,
    optionEmpresaOrigen    ,    
    optionEmpresaDestino   ,
    cuentasSolicitudes     ,
    bancos                 ,
    cuentasOrigen          ,
    bancosOrigen           ,
    empresasOrigenDestino  ,
    nuevaSolicitud         ,
    nuevoMovimiento        ,
    empresasBanco          ,
    changeComboSolicitudes ,
    handleChangeChkAll     ,
    chckAllM               ,
    handleCbxSolicitud     ,
    isNuevoMov             ,
    showDetailSolFond      ,
    showDispSaldo     
    

}) => {
    console.log(typeTab)
    const BtnHeader = () =>{
        return<>
        <Buttons 
        btnAdd={true} btnAddAction={() => nuevaSolicitud(0)} btnAddShowIcon={true} btnAddText="Nueva solicitud" 
        btnSave={isNuevoMov} btnSaveAction={() => nuevaSolicitud(1)} btnSaveShowIcon={true} btnSaveText="Nuevo movimiento" btnSaveIconClass="fa fa-plus"
        />
        </>
        
           
       } 
    return (
        <div>
            <div className="form-group" >
            <Filter 
            typeFilter={typeTab}
            tipoMovimiemto={tipoMovimiemto}
            empresas={empresas}
            changeSelectSolicitudes={changeSelectSolicitudes}
            changeDateSolicitud={changeDateSolicitud}
            btnSearch={btnSearch}
            optionTipoMovimiemto   ={optionTipoMovimiemto}
            optionEmpresaOrigen    ={optionEmpresaOrigen}
            optionEmpresaDestino   ={optionEmpresaDestino}
            empresasOrigenDestino={empresasOrigenDestino}
            empresasBanco={empresasBanco}


            />
            </div>
            <GeneralTable
                                // columns={columnsImpuestos(handleChangeCbxM,handleChangeChkAllM,chckAllM,uploadPdf,actionChenge,showPDF,canBeAuthorized,isCbxautorized,handleChangeCbxAutr,idTipoFormato,showModalCargaCP)}
                                columns={columns(typeTab,empresasOrigenDestino,cuentasOrigen,bancosOrigen,changeComboSolicitudes,estatusSolicitudes,handleCbxSolicitud,showDetailSolFond,showDispSaldo)}
                                data={solicitudes}
                                // action={undefined}
                                doubleClick={(original,values) => {}} 
                                // isNotPagination={true}
                                // pageSize={500}
                                isCpmpBtn={true}
                                // // compnnt={idTipoFormato === 2 && showTblSSM  ?  BtnConfirmSS : <div></div>}
                                compnnt={BtnHeader}
                                hiddenColumnsIDs={
                                    hiddenColumns(typeTab)
                                }
                                // isCompTable={true}
                                // compTbl={BtnConfirmSS()}

                                />
        </div>
    );
};

export default MonitorSolicitud;