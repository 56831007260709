import React from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap'
import Buttons from '../../../Components/Buttons/Buttons';

const ModalPDF = ({
    show,
    dataPDF,
    isOne,
    onCloseMP
}) => {
    return (
        <Modal size="xl"  show={show} animation={false} backdrop="static"  aria-labelledby="modalConfirmImp">
        <Modal.Header className="header">
            <Row>
            <Col md="11">
            </Col>

                    <Col md="1" >
                    <div onClick={onCloseMP} className="btn btn-primary button-circle button-small" ><label style={{color:'white'}}>X</label></div>
                    </Col>
                   
            </Row>
        </Modal.Header>
        <Modal.Body style={{border:'1px solid black'}}>
            <Row>
            <Col md="12">
            {/* <iframe src={dataPDF} style={{width:'100%', height:'700px'}}   /> */}
            <iframe src={isOne ? dataPDF : 'data:application/pdf;base64,'+dataPDF}
            style={{width:'100%', height:'700px'}}
            title="file"/>
            </Col>
            </Row>
          

          


       
        
        </Modal.Body>

       </Modal>
    );
};

export default ModalPDF;