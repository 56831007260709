import React from 'react';
import { useDropzone } from "react-dropzone";

const Dropzone = ({selected_File,select_File}) => {

    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        maxFiles:1,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
        }
    });
    // const files = acceptedFiles.map(file => file.path);
    let files = []
    if(selected_File.length > 0) files = selected_File.map(file => file.path);
    if(acceptedFiles.length > 0) files = acceptedFiles.map(file => file.path);
    select_File(acceptedFiles);
    
    // setSelected_File(acceptedFiles)
    const tileFile = (files) =>  
    <div>
        
        {
        files.length === 1 ?
        <div>
            <div><i className="fa fa-file-excel-o fa-5x" aria-hidden="true"></i></div>
            <p>{files}</p>
        </div> :
        <div><ul>{files.map(f => <li><i className="fa fa-file-excel-o" aria-hidden="true"></i><p>{f}</p></li>)}</ul></div> 
        }   
    </div> 
    return (
        <section className="container">
            <div>&nbsp;</div>
                <div {...getRootProps({className: 'Files_DragAndDrop__area'})}>
                <input {...getInputProps()}        
                />
                <div>
                {files.length > 0 ?  tileFile(files) : <p>Arrastra archivo Excel o da clic aqui para seleccionar layout</p>}
                </div>
            </div>
            <aside>
            {/* <h4>Files</h4> */}
            {/* <ul>{files}</ul> */}
            </aside>
        </section>
        );
};
const index = ({selected_File,select_File}) => {
    return (
        <Dropzone
        selected_File={selected_File}
        select_File={select_File}
        />
    );
};

export default index;