import React from 'react';
import {columnsImpuestosDet,columnsImpuestosDetSS} from '../resources'
import Select from 'react-select';
import Buttons from '../../../Components/Buttons/Buttons';
import GeneralTable from '../../GeneralTable';
import {CSVLink,CSVDownload} from 'react-csv'
import {fnNotification} from '../../../ApiRequests/Notification'
import CompBtnTotal from '../../../Components/PagoImpuestos/LoteImpuestos/CompBtnTotal';

const index = ({
    idTipoFormato,                     
    changeSelect,                    
    cat_Anios,                       
    optionAnio,                     
    catMeses,                          
    optionMeses,                    
    catLotes,                        
    optionLotes,
    dataSolicitudesTbl    ,                  
    catTipoImpuestos      ,               
    optionTipoImpuestos   ,         
    searchPetition        ,
    updatePetition        ,
    deletePetition        ,
    handleChangeCbx       ,
    handleChangeChkAll    ,
    chckAll               ,
    total                 ,
    saldoAfavor
   
}) => {
    const cmpBtnFile = () => {
        let dataSolicitudesDownload = []
        if(dataSolicitudesTbl.length > 0) {
            dataSolicitudesTbl.forEach(element =>{
                if(element.check) dataSolicitudesDownload.push(element)
            })
    
            let fileName = "DetalleEF" 
            let _Headers =   [
                { label: "Lote"   , key: "lote" },
                { label: idTipoFormato === 1 ? "Marca" : "Registro Patronal"   , key: idTipoFormato === 1 ? "marca":"registroPatronal" },
                { label: "RFC", key: "rfc" },
                { label: idTipoFormato === 1 ? "Empresa" : "Razón Social" , key: "empresa" },
                { label: "Prioritario"     , key: "prioritario" },
                { label: "Tipo Impuesto"  , key: "tipoImpuesto" },
                { label: "Detalle"  , key: "detalle" },
                { label: "Monto", key: "importeDetalle" },
                { label: "Mes"       , key: "mes" },
                { label: "Año"       , key: "anio" }
            ]
            if(idTipoFormato === 2){
                _Headers.push({ label: "Folio SUA" , key: "folioSUA" })
                fileName = "DetalleSS"
            } 
            if(dataSolicitudesDownload.length > 0){
                return  <div className="row">
                <div className="col-md-12">
                <CSVLink data={dataSolicitudesDownload} 
        
                    headers={_Headers}     
                    filename={fileName}>
                    <Buttons 
                        btnExport= {true}  btnExportText={'Descargar'} btnExportShowIcon={true} />
                    </CSVLink>
                </div>
            </div>
            }else{
                return  <div className="row">
                <div className="col-md-12">
                    <Buttons 
                btnExport= {true} btnExportAction={() => fnNotification(['No se ha seleccionado ningún elemento para descargar', 'error', true]) } btnExportText={'Descargar'} btnExportShowIcon={true} />
                </div>
            </div>
    
            }

        }
       
    }
    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">
                <label>Año</label>
                <Select
                                className=""
                                isClearable
                                isSearchable
                                name="anio"
                                onChange={(event,name) => changeSelect(event,name)}
                                options={cat_Anios}
                                value={optionAnio}
                            />
                </div>
                <div className="col-md-2">
                <label>Periodo</label>
                <Select
                                className=""
                                isClearable
                                isSearchable
                                name="periodo"
                                onChange={(event,name) => changeSelect(event,name)}
                                options={catMeses}
                                value={optionMeses}
                            />

                </div>
                <div className="col-md-2">
                <label>Lote</label>
                <Select
                                className=""
                                isClearable
                                isSearchable
                                name="lote"
                                onChange={(event,name) => changeSelect(event,name)}
                                options={catLotes}
                                value={optionLotes}
                            />

                </div>
                <div className="col-md-2">
                <label>Tipo de impuesto</label>
                <Select
                                className=""
                                isClearable
                                isSearchable
                                name="tipoImpuesto"
                                onChange={(event,name) => changeSelect(event,name)}
                                options={catTipoImpuestos}
                                value={optionTipoImpuestos}
                            />

                </div>
                <div className="col-md-2">
                <label>&nbsp;</label>

                        <Buttons
                            btnAdd  = {true}
                            btnAddAction  = {() => searchPetition(false)}
                            btnAddText="Buscar"
                            btnAddShowIcon={true}
                            btnAddIconClass="fa fa-search"
                        />
                </div>
            </div>
            <div>&nbsp;</div>
            <div className="row">
                <div className="col-md-12">
                    <div className="mt-5">
                    {
                    <GeneralTable
                    columns={idTipoFormato === 1 ? columnsImpuestosDet(updatePetition,deletePetition,handleChangeCbx,handleChangeChkAll,chckAll) : columnsImpuestosDetSS(updatePetition,deletePetition,handleChangeCbx,handleChangeChkAll,chckAll)}
                    data={dataSolicitudesTbl}
                    // action={undefined}
                    doubleClick={(original,values) => {}} 
                    // isNotPagination={true}
                    // pageSize={500}
                    
                    isCpmpBtn={true}
                    isCompTable={true}
                    compTbl={<CompBtnTotal total={total} saldoAfavor={saldoAfavor} type={1}/>}
                    compnnt={cmpBtnFile}
                    hiddenColumnsIDs={['idDetalle','idSolicitudPago','idPrioritario','idTipoImpuesto','idDetTipoImpuesto','multiRow','idEstatus']}
                    // paramsCheckAll={paramsCheckAll}
                    
                

                    />
                } 

                </div>
    
                </div>
            </div>
        </div>
    );
};

export default index;