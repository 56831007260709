import React from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap'

const ModalMessage = ({
    show,
    dataMEssage,
    onCloseMessage,
}) => {
    return (
        <Modal size="xl"  show={show} animation={false} backdrop="static"  aria-labelledby="modalConfirmImp">
        <Modal.Header className="header">
            <Row>
            <Col md="11" >
                Motivo de rechazo 
            </Col>

                    <Col md="1" >
                    <div onClick={onCloseMessage} className="btn btn-primary button-circle button-small" ><label style={{color:'white'}}>X</label></div>
                    </Col>
                   
            </Row>
        </Modal.Header>
        <Modal.Body>
            <Row>
            <Col md="12">
                <div className="text-center" style={{color:'blue'}}>
                    <span>
                        {dataMEssage}
                    </span>
                </div>
            {/* <iframe src={isOne ? dataPDF : 'data:application/pdf;base64,'+dataPDF}
            style={{width:'100%', height:'700px'}}
            title="file"/> */}
            </Col>
            </Row>
          

          


       
        
        </Modal.Body>

       </Modal>
    );
};

export default ModalMessage;